import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import Button from "../../controls/components/Button";
import { NonFormCheckbox } from "../../controls/components/Checkbox";
import Link from "../../controls/components/Link";
import { getDialogStep, getPaymentAccount, getRedirect } from "../paymentModal.selectors"; 
import {
  setRedirect as setRedirectCreator,
  startPayment as startPaymentCreator, 
  setPaymentDialog as setPaymentDialogCreator,
} from "../paymentModal.actions";
import { connect } from "react-redux";
import { Divider } from "@material-ui/core";
import { getOrderIdFromUrl } from "modules/utilities/url";
import { bankAckStep, ccAckStep, mailAckStep, notStartedStep } from "../paymentModal.constants";
import { getCurrentTaxYear } from "../../order/order.selectors";
import "./AckIrsPayMethodDialog.css";

const ContentFooter = ({CheckBoxLabel, DisclaimerHtmlBody, UpdateLinkCaption, ChangePaymentMethodHandler, AckState, AckHandler}) => <>
<div className="my-gutter" dangerouslySetInnerHTML={{ __html: DisclaimerHtmlBody }} />
<Divider className="divStyle" />
<p className="paragraph mt-gutter">
  <NonFormCheckbox
    onChange={AckHandler}
    meta={{ touched: false, error: false }}
    name="registered-eftps-agreement"
    checked={AckState}
    label={CheckBoxLabel}
  ></NonFormCheckbox>
</p>
<p className="paragraph center">
  <Link
    clickHandler={ChangePaymentMethodHandler}
    text={UpdateLinkCaption}
  />
</p></>;


const AckIrsPayMethodDialog = ({
  startPayment,
  cancelPayment,
  cancelPaymentAndRedirectToEntry,
  dialogOpenStep,
  rtn,
  accountNumber
}) => {
  const [ackState, setAckState] = useState(false);

  const handleSubmit = () => {
    startPayment(() => setAckState(false));
  };  

  const handleCancel = () => {
    cancelPayment(() => setAckState(false));
  };  
  
  const handleAck = () => {
    setAckState(!ackState);
  };

  const { DisclaimerMailBody, DisclaimerMailCheckbox, 
          DisclaimerCreditCardBody, DisclaimerCreditCardCheckbox, 
          DisclaimerBankBody: __disclaimerBankBody, DisclaimerBankCheckbox, IRSPaymentPageURL } = window.i2290settings;

  const _disclaimerBankBody = __disclaimerBankBody + (__disclaimerBankBody.endsWith("missing") ? " test routing# {0} and account num {1}" : "");
  const DisclaimerBankBody = _disclaimerBankBody.replace('{0}',rtn).replace('{1}',accountNumber);

  if (![ccAckStep, mailAckStep, bankAckStep].includes(dialogOpenStep)) return <></>;

  const contentFooterProps = {
    ChangePaymentMethodHandler: () => cancelPaymentAndRedirectToEntry(() => setAckState(false)),
    AckState: ackState,
    AckHandler: handleAck,
  };
  const content = 
  ///////////////////////////////////////////////////////   CREDIT CARD ACKNOWLEDGE
  dialogOpenStep === ccAckStep ? <>
      <div className="brand-header heading largeSize">
        <p className="headingText xlargeSize">IMPORTANT</p>
        <p className="headingText">
          You Selected to Pay your Taxes Using a Credit Card
        </p>
      </div>
      <div className="heading">
        <p className="warningText largeSize">** AVOID HAVING TO CALL THE IRS LATER **</p>
        <Divider className="divStyle" />
        <p className="largeSize mt-gutter">
          Paying i2290 With Your Credit Card Does Not Pay Your Taxes
        </p>
        <p className="largeSize">
          Pay your taxes here: <a href={IRSPaymentPageURL} target="_blank">IRS.GOV CC PAYMENTS</a>
        </p>
        <ContentFooter 
          CheckBoxLabel={DisclaimerCreditCardCheckbox} 
          DisclaimerHtmlBody={DisclaimerCreditCardBody}
          UpdateLinkCaption="Change My IRS Payment Method" 
          {...contentFooterProps}/>
      </div>    
    </>
    ///////////////////////////////////////////////////////   MAIL ACKNOWLEDGE
  : dialogOpenStep === mailAckStep ? <>
      <div className="brand-header heading largeSize">
        <p className="headingText xlargeSize">IMPORTANT</p>
        <p className="headingText">
          You Have Selected to Pay Your 2290 Taxes by Mailing a Check 
        </p>
      </div>
      <div className="heading">
        <p className="warningText largeSize">** AVOID HAVING TO CALL THE IRS LATER **</p>
        <Divider className="divStyle" />
        <ContentFooter 
          CheckBoxLabel={DisclaimerMailCheckbox} 
          DisclaimerHtmlBody={DisclaimerMailBody}
          UpdateLinkCaption="Change My IRS Payment Method"  
          {...contentFooterProps}/>
      </div>
    </>
    ///////////////////////////////////////////////////////   BANK ACKNOWLEDGE
  : dialogOpenStep === bankAckStep ?  <>
    <div className="brand-header heading largeSize">
      <p className="headingText xlargeSize">IMPORTANT</p>
      <p className="headingText">
          Your Bank Numbers Are Part Of Your IRS Tax Return
      </p>
    </div>
    <div className="heading">
      <p className="warningText largeSize">** AVOID HAVING TO CALL THE IRS LATER **</p>
      <Divider className="divStyle" />
      <ContentFooter 
        CheckBoxLabel={DisclaimerBankCheckbox} 
        DisclaimerHtmlBody={DisclaimerBankBody} 
        UpdateLinkCaption="Update My Bank Account Information" 
        {...contentFooterProps}/>
    </div>
  </> 
    ///////////////////////////////////////////////////////   SHOW NOTHING for EFTPS
  : <></>;

  return (
    <Dialog
      className="payment-modal no-scroll"
      title=""
      closeIcon={false}
      style={{ fontSize: "medium" }}
    >
      <div className="row">
        <div className="col">
          {content}
        </div>
      </div>
      <div className="row">
        <div className="col clean-gutter-right">
          <Button
            primary={false}
            disabled={false}
            text="Cancel"
            onClick={handleCancel}
            width="100%"
          />
        </div>
        <div className="col clean-gutter-left">
          <Button
            type="submit"
            primary
            disabled={!ackState}
            text="Continue"
            onClick={handleSubmit}
            width="100%"
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {

  const { Rtn: rtn, AccountNumber: accountNumber } = getPaymentAccount(state) ?? {};

  return {
    dialogOpenStep: getDialogStep(state),
    redirect: getRedirect(state),
    currentTaxYear: getCurrentTaxYear(state),
    rtn,
    accountNumber
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startPayment: (next) => dispatch((dispatch) => dispatch(startPaymentCreator()).then(() => typeof next === "function" ? next() : undefined)),
  cancelPayment: (next) => dispatch((dispatch) => dispatch(setPaymentDialogCreator(notStartedStep)).then(() => typeof next === "function" ? next() : undefined)),
  cancelPaymentAndRedirectToEntry: (next) => dispatch((dispatch) => 
      dispatch(setPaymentDialogCreator(notStartedStep))
        .then(dispatch(setRedirectCreator("/wizard/4?order=" + getOrderIdFromUrl())))
        .then(() => typeof next === "function" ? next() : undefined))
});

export default connect(mapStateToProps, mapDispatchToProps)(AckIrsPayMethodDialog);
