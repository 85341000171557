import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";
import { STATE_NAME } from "./vehicles.constants";

export const POST_VEHICLE_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "POST_VEHICLE_ASYNC"
);

export const IMPORT_VEHICLES_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "IMPORT_VEHICLES_ASYNC"
);

export const PATCH_VEHICLE_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "PATCH_VEHICLE_ASYNC"
);

export const DELETE_VEHICLE_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "DELETE_VEHICLE_ASYNC"
);

export const TOGGLE_SHOW_IMPORTER = buildActionType(
  STATE_NAME,
  "TOGGLE_SHOW_IMPORTER"
);

export const SET_SHOW_IMPORTER = buildActionType(
  STATE_NAME,
  "SET_SHOW_IMPORTER"
);

export const SET_REDIRECT = buildActionType(STATE_NAME, "SET_REDIRECT");

export const STAGE_FOR_DELETION = buildActionType(
  STATE_NAME,
  "STAGE_FOR_DELETION"
);

export const TOGGLE_ALL_ARE_STAGED_FOR_DELETION = buildActionType(
  STATE_NAME,
  "TOGGLE_ALL_ARE_STAGED_FOR_DELETION"
);

export const STAGE_FOR_CREATION = buildActionType(
  STATE_NAME,
  "STAGE_FOR_CREATION"
);

export const CHANGE_CURRENT_PAGE = buildActionType(
  STATE_NAME,
  "CHANGE_CURRENT_PAGE"
);

export const CHANGE_PAGE_SIZE = buildActionType(STATE_NAME, "CHANGE_PAGE_SIZE");

export const UPDATE_WORKING_COPY = buildActionType(
  STATE_NAME,
  "UPDATE_WORKING_COPY"
);

export const UPDATE_STAGED_FOR_CREATION = buildActionType(
  STATE_NAME,
  "UPDATE_STAGED_FOR_CREATION"
);

export const UPDATE_TAXABLE_VEHICLE_STAGING = buildActionType(
  STATE_NAME,
  "UPDATE_TAXABLE_VEHICLE_STAGING"
);

export const UPDATE_CREDIT_VEHICLE_STAGING = buildActionType(
  STATE_NAME,
  "UPDATE_CREDIT_VEHICLE_STAGING"
);

export const REVERT_CHANGES = buildActionType(STATE_NAME, "REVERT_CHANGES");
