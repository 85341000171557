import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FaqWidgetHeader from "./FaqWidgetHeader";
import { getFaqIsExpandedHOF } from "../supportCenter.selectors";
import { toggleFaqIsExpanded as toggleFaqIsExpandedCreator } from "../supportCenter.actions";

class FaqWidgetHeaderContainer extends Component {
  render() {
    const {
      props: { text, isExpanded, toggleIsExpanded }
    } = this;
    return (
      <FaqWidgetHeader
        text={text}
        isExpanded={isExpanded}
        toggleIsExpanded={toggleIsExpanded}
      />
    );
  }
}

FaqWidgetHeaderContainer.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  isExpanded: getFaqIsExpandedHOF(props.faqName)(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  toggleIsExpanded: () => dispatch(toggleFaqIsExpandedCreator(props.faqName))
});

const ConnectedFaqWidgetHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqWidgetHeaderContainer);

ConnectedFaqWidgetHeaderContainer.propTypes = {
  text: PropTypes.string.isRequired,
  faqName: PropTypes.string.isRequired
};

export default ConnectedFaqWidgetHeaderContainer;
