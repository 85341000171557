export default [
  {
    display: "Aguascalientes",
    real: "AG"
  },
  {
    display: "Baja California",
    real: "BJ"
  },
  {
    display: "Baja California Sur",
    real: "BS"
  },
  {
    display: "Campeche",
    real: "CP"
  },
  {
    display: "Chiapas",
    real: "CH"
  },
  {
    display: "Chihuahua",
    real: "CI"
  },
  {
    display: "Coahuila",
    real: "CU"
  },
  {
    display: "Colima",
    real: "CL"
  },
  {
    display: "Distrito Federal",
    real: "DF"
  },
  {
    display: "Durango",
    real: "DG"
  },
  {
    display: "Guanajuato",
    real: "GJ"
  },
  {
    display: "Guerrero",
    real: "GR"
  },
  {
    display: "Hidalgo",
    real: "HG"
  },
  {
    display: "Jalisco",
    real: "JA"
  },
  {
    display: "Mexico (Estado)",
    real: "EM"
  },
  {
    display: "Michoacan",
    real: "MH"
  },
  {
    display: "Morelos",
    real: "MR"
  },
  {
    display: "Nayarit",
    real: "NA"
  },
  {
    display: "Nuevo Leon",
    real: "NL"
  },
  {
    display: "Oaxaca",
    real: "OA"
  },
  {
    display: "Puebla",
    real: "PU"
  },
  {
    display: "Queretaro",
    real: "QA"
  },
  {
    display: "Quintana Roo",
    real: "QR"
  },
  {
    display: "San Luis Potosi",
    real: "SL"
  },
  {
    display: "Sinaloa",
    real: "SI"
  },
  {
    display: "Sonora",
    real: "SO"
  },
  {
    display: "Tabasco",
    real: "TA"
  },
  {
    display: "Tamaulipas",
    real: "TM"
  },
  {
    display: "Tlaxcala",
    real: "TL"
  },
  {
    display: "Veracruz",
    real: "VZ"
  },
  {
    display: "Yucatan",
    real: "YC"
  },
  {
    display: "Zacatecas",
    real: "ZT"
  }
];
