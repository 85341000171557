import { STATE_NAME } from "./form.constants";
import order from "../order";

const {
  selectors: { getOrder }
} = order;

export const getState = state => state[STATE_NAME];

export const getEntities = state => state[STATE_NAME].entities;

export const getFormIdsByOrderId = (id, state) => getOrder(id, state).Forms;

export const getWidgets = state => state[STATE_NAME].ui.widgets;

export const getWidgetIsExpandedHof = id => state =>
  state[STATE_NAME].ui.widgets[id].isExpanded;

export const getTaxableVehiclesGridCurrentPageHof = id => state =>
  state[STATE_NAME].ui.widgets[id].taxableVehiclesGrid.currentPage;

export const getTaxableVehiclesGridPageSizeHof = id => state =>
  state[STATE_NAME].ui.widgets[id].taxableVehiclesGrid.pageSize;

export const getCreditVehiclesGridCurrentPageHof = id => state =>
  state[STATE_NAME].ui.widgets[id].creditVehiclesGrid.currentPage;

export const getCreditVehiclesGridPageSizeHof = id => state =>
  state[STATE_NAME].ui.widgets[id].creditVehiclesGrid.pageSize;

export const getFormOrder = state => state[STATE_NAME].ui.formOrder;

export const getFormHOF = id => state => state[STATE_NAME].entities[id];
