import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MaterialRadio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  root: {
    color: "black"
  }
};

const Radio = ({ value, label }) => (
  <FormControlLabel
    className="material-label-override"
    value={value}
    label={label}
    control={<MaterialRadio />}
  />
);

export default withStyles(styles)(Radio);
