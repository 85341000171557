import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CREDIT_REASON_SUSPENDED } from "../../constants";

const DateInput = ({ value }) => (
  <div className="k-input">
    <span>{value ? value.toLocaleDateString() : "Please select..."}</span>
  </div>
);

class DatePickerCell extends React.Component {
  today = new Date();

  handleChange = e => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  };

  render() {
    const dataValue = this.props.dataItem[this.props.field];

    let error = null;
    if (
      this.props.dataItem.errors &&
      this.props.dataItem.errors[this.props.field]
    ) {
      error = this.props.dataItem.errors[this.props.field];
    }

    if (!this.props.dataItem.inEdit) {
      return (
        <td {...this.props}>
          {dataValue === null || dataValue === undefined
            ? ""
            : this.props.dataItem[this.props.field].toLocaleDateString("en-US")}
        </td>
      );
    }

    return (
      <td {...this.props}>
        <DatePicker
          dateInput={DateInput}
          min={this.props.min}
          max={
            this.props.dataItem.CreditReason === CREDIT_REASON_SUSPENDED
              ? this.today
              : this.props.max
          }
          className="d-flex"
          style={{ width: "100%", display: "block" }}
          onChange={this.handleChange}
          value={dataValue}
        />
        {error && <span className="i2290-inline-error">{error}</span>}
      </td>
    );
  }
}

DatePickerCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date)
};

export default DatePickerCell;
