import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import modal from "../../../modules/modal";
import order from "../../../modules/order";

const {
  components: { Acknowledger }
} = modal;

const {
  selectors: { getIrsNotRespAcknowledgement },
  actions: { setIrsNotRespAcknowledgement: setIrsNotRespAcknowledgementCreator }
} = order;

class IrsNotRespAcknowledgerContainer extends Component {
  handleClose = () => {
    const {
      props: { setIrsNotRespAcknowledgement }
    } = this;
    setIrsNotRespAcknowledgement(null);
  };

  render() {
    const {
      handleClose,
      props: { irsNotRespAcknowledgement }
    } = this;

    return (
      <Acknowledger
        prompt={irsNotRespAcknowledgement}
        onClose={handleClose}
        isVisible={irsNotRespAcknowledgement !== null}
      />
    );
  }
}

IrsNotRespAcknowledgerContainer.propTypes = {
  irsNotRespAcknowledgement: PropTypes.string,
  setIrsNotRespAcknowledgement: PropTypes.func.isRequired
};

IrsNotRespAcknowledgerContainer.defaultProps = {
  irsNotRespAcknowledgement: null
};

const mapStateToProps = state => ({
  irsNotRespAcknowledgement: getIrsNotRespAcknowledgement(state)
});

const mapDispatchToProps = dispatch => ({
  setIrsNotRespAcknowledgement: prompt =>
    dispatch(setIrsNotRespAcknowledgementCreator(prompt))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IrsNotRespAcknowledgerContainer);
