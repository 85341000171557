import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";

const {
  components: { Button, TextField },
  normalizations: { normalizeBusinessName }
} = controls;

const BulkPricingForm = ({
  onSubmit,
  onClickCancel,
  companyNameValidations,
  companyPositionValidations,
  companyTypeValidations,
  companyFleetsFiledValidations,
  companyEverFiledValidations,
  companyFormsFiledValidations
}) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">Tell us about your Form 2290 needs</h2>
    <section>
      <div className="row">
        <div className="col-12 col-sm-6">
          <TextField
            name="BusinessName"
            label="Name of your company?"
            type="text"
            validations={companyNameValidations}
            normalize={normalizeBusinessName}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="CompanyPosition"
            type="text"
            label="Your position?"
            validations={companyPositionValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="CompanyType"
            label="Type of company?"
            type="text"
            validations={companyTypeValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="CompanyEverFiled"
            label="Have you ever eFiled an IRS Form 2290?"
            type="text"
            validations={companyEverFiledValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="CompanyFormsFiled"
            label="Roughly how many 2290 forms do you file annually?"
            type="text"
            validations={companyFormsFiledValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="CompanyFleetsFiled"
            label="How many annual filings will be for fleets of more than 24 vehicles?"
            type="text"
            validations={companyFleetsFiledValidations}
          />
        </div>
      </div>
    </section>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

BulkPricingForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  companyNameValidations: PropTypes.arrayOf(PropTypes.func),
  companyPositionValidations: PropTypes.arrayOf(PropTypes.func),
  companyTypeValidations: PropTypes.arrayOf(PropTypes.func),
  companyFleetsFiledValidations: PropTypes.arrayOf(PropTypes.func),
  companyEverFiledValidations: PropTypes.arrayOf(PropTypes.func),
  companyFormsFiledValidations: PropTypes.arrayOf(PropTypes.func)
};

BulkPricingForm.defaultProps = {
  companyNameValidations: [],
  companyPositionValidations: [],
  companyTypeValidations: [],
  companyFleetsFiledValidations: [],
  companyEverFiledValidations: [],
  companyFormsFiledValidations: []
};

export default BulkPricingForm;
