import React, { Component } from "react";
import PropTypes from "prop-types";
import { Upload } from "@progress/kendo-react-upload";
// import Papa from "papaparse";

// const fileStatuses = [
//   "UploadFailed",
//   "Initial",
//   "Selected",
//   "Uploading",
//   "Uploaded",
//   "RemoveFailed",
//   "Removing"
// ];

class Uploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  onAdd = ({ newState }) => {
    const {
      props: { setFiles, files, fileLimit }
    } = this;
    if (fileLimit !== null && files.length === fileLimit) {
      return this.setState({ error: `${fileLimit}-file limit` });
    }
    setFiles(newState);
  };

  onRemove = ({ newState }) => {
    const {
      props: { setFiles }
    } = this;
    this.setState({ error: null });
    setFiles(newState);
  };

  onProgress = ({ newState }) => {
    const {
      props: { setFiles }
    } = this;
    setFiles(newState);
  };

  onStatusChange = ({ newState }) => {
    const {
      props: { setFiles }
    } = this;
    setFiles(newState);
  };

  render() {
    const {
      onAdd,
      onRemove,
      onProgress,
      onStatusChange,
      props: { files }
    } = this;
    return (
      <div>
        <Upload
          batch={false}
          multiple
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          onProgress={onProgress}
          onStatusChange={onStatusChange}
          withCredentials={false}
          saveUrl="https://demos.telerik.com/kendo-ui/service-v4/upload/save"
          removeUrl="https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
        />
        {this.state.error && (
          <span className="i2290-inline-error">{this.state.error}</span>
        )}
      </div>
    );
  }
}

Uploader.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  fileLimit: PropTypes.number
};

Uploader.defaultProps = {
  fileLimit: null
};

export default Uploader;
