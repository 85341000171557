import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./supportHero.css";

const {
  components: { Button }
} = controls;

const SupportHero = ({ onClickCall, onClickEmail, onClickContactForm }) => (
  <section className="support-hero-section">
    <div className="row">
      <div className="col-12 col-md-6 my-auto">
        <h1>Our Promise</h1>
      </div>
      <div className="col-12 col-md-6 my-auto">
        <p>
          Contact us during business hours and we do not go home until we
          respond
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-4 mb-gutter">
        <Button
          width="100%"
          icon="phone"
          disabled={false}
          secondary
          text="(866) 444-2290"
          onClick={onClickCall}
        />
      </div>
      <div className="col-12 col-md-4 mb-gutter">
        <Button
          width="100%"
          disabled={false}
          secondary
          text="support@i2290.com"
          onClick={onClickEmail}
        />
      </div>
      <div className="col-12 col-md-4 mb-gutter">
        <Button
          width="100%"
          disabled={false}
          primary
          text="Contact Form"
          onClick={onClickContactForm}
        />
      </div>
    </div>
  </section>
);

SupportHero.propTypes = {
  onClickCall: PropTypes.func.isRequired,
  onClickEmail: PropTypes.func.isRequired,
  onClickContactForm: PropTypes.func.isRequired
};

export default SupportHero;
