import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProfileWidget from "./ProfileWidget";
import userContext from "../../../modules/userContext";

const {
  selectors: { getUserContext }
} = userContext;

class ProfileWidgetContainer extends Component {
  handleClickEdit = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/account");
  };

  render() {
    const {
      handleClickEdit,
      props: { user }
    } = this;

    let loading = true;
    let businessName = null;
    let ein = null;

    if (user.Id) loading = false;
    if (user.BusinessName) businessName = user.BusinessName;
    if (user.Ein) ein = user.Ein;

    return (
      <ProfileWidget
        onClickEdit={handleClickEdit}
        loading={loading}
        businessName={businessName}
        ein={ein}
      />
    );
  }
}

ProfileWidgetContainer.propTypes = {
  user: PropTypes.object
};

ProfileWidgetContainer.defaultProps = {
  user: {}
};

const mapStateToProps = state => ({
  user: getUserContext(state)
});

export default withRouter(connect(mapStateToProps)(ProfileWidgetContainer));
