import React from "react";
import { lookupMonthAbbreviation } from "modules/utilities/lookups";
import { fastFormatMoney } from "modules/utilities/math";
import { connect } from "react-redux";
import { getHistoryFormHOF, getHistoryOrderHOF } from "modules/order/order.selectors";
import { getFormIdFromUrl } from "modules/utilities/url";
import { postSendScheduleOneEmail } from "modules/order/order.actions";
import { getUserEmail } from "modules/userContext/userContext.selectors";
import { SendEmailDialog } from "modules/controls/components/SendEmailPrompt";

const LineDescriptionRender = ({formId, ...rest}) => {
  const {dateCategory, numberOfVins, hasCredits, netTax} = rest;
  return <span>
    <strong className="no-break">{`${lookupMonthAbbreviation(dateCategory) || "N/A"}: `}</strong>
    {`${numberOfVins} VIN${numberOfVins !== 1 ? "s" : ""} ${hasCredits ? "+ Credits" : ""} `}
    <span className="no-break">{`Net Tax: ${fastFormatMoney(
      netTax
    )}`}</span>
  </span>;
}

export const LineDescription = connect((state, props) => {  
  // @ts-ignore
  const form = props.formId ? getHistoryFormHOF(props.formId)(state) : getHistoryFormHOF(getFormIdFromUrl())(state); //getReportFormHOF on report screen
  const numberOfVins = form?.NumberOfVehicles ?? null;
  const netTax = form?.TotalTax || form?.TotalCredit ? (form?.TotalTax ?? 0) - (form?.TotalCredit ?? 0) : null;
  const hasCredits = form?.TotalCredit > 0;
  const dateCategory = form?.DateCategory ?? null;  
  return {dateCategory, numberOfVins, hasCredits, netTax};
})(LineDescriptionRender)


const SendSchedule1EmailDialogRender = ({
  isVisible,
  setIsVisible,
  formId, 
  onOk, 
  ...optional
}) => {
  // added via state
  const {handleOkEmailSchedule1, email, taxYear, ...custom} = optional;
  const minHeight="180px" /* Extra space to allow for an error message */
  return <SendEmailDialog
        optional={true}
        minHeight={minHeight}
        title={`${taxYear} - ${parseInt(taxYear, 10) + 1} HVUT Return`}
        onOk={handleOkEmailSchedule1}
        {...{isVisible, setIsVisible, ...custom}}
      >
        <LineDescription formId={formId}/><br/>
        <div className="mb-gutter"></div>
        Email Schedule 1 to: <div className="pl-half-gutter">{email}</div><br/>
        Send copies to (optional):
      </SendEmailDialog>;
}

export const SendSchedule1EmailDialog = connect(
  /** @param {{formId:number}} [props] */ (state, props) => {
  const form = props.formId ? getHistoryFormHOF(props.formId)(state) : getHistoryFormHOF(getFormIdFromUrl())(state);
  const order = props.formId && form?.OrderId ? getHistoryOrderHOF(form?.OrderId)(state) : getHistoryOrderHOF(form?.OrderId)(state); //getReportOrderHOF on report screen
  const userEmail = getUserEmail(state);

  return {email: userEmail, taxYear:order?.TaxYear};
},(dispatch, props) => {
  // @ts-ignore
  const formId = props.formId ?? getFormIdFromUrl();
  return {
    // @ts-ignore
    handleOkEmailSchedule1: (emails) => dispatch(postSendScheduleOneEmail(formId, emails.split(","))).then(props.onOk()),    
  }
})(SendSchedule1EmailDialogRender)


