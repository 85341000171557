import React from "react";
import SummaryContainer from "../components/SummaryContainer";

const withSummaryWidget = (
  HeaderComponent,
  BodyComponent,
  isExpandedSelector
) => ({ ...rest }) => (
  <SummaryContainer
    headerChildren={<HeaderComponent {...rest} />}
    bodyChildren={<BodyComponent {...rest} />}
    isExpandedSelector={isExpandedSelector}
    {...rest}
  />
);

export default withSummaryWidget;
