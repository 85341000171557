import { STATE_NAME } from "./supportCenter.constants";

export const getFaqIsExpandedHOF = faqName => state =>
  state[STATE_NAME].ui.faqWidgets[faqName];

export const getAttachments = state => state[STATE_NAME].ui.attachments;

export const getAttachmentsIsExpanded = state =>
  state[STATE_NAME].ui.attachmentsIsExpanded;

export const getRedirect = state => state[STATE_NAME].ui.redirect;
