import React from "react";
import PropTypes from "prop-types";
import { Grid as KendoGrid } from "@progress/kendo-react-grid";
// import controls from "../../controls";
import "./grid.css";

// const {
//   components: { Button }
// } = controls;

const Grid = ({
  data,
  // onInsert,
  // onClickImport,
  onChange,
  toolbarChildren,
  children,
  gridChildren,
  width,
  height
}) => (
  <KendoGrid
    style={{ width, height }}
    data={data}
    onItemChange={onChange}
    editField="inEdit"
  >
    {/* <GridToolbar> */}
    {/*  <Button */}
    {/*    onClick={onInsert} */}
    {/*    primary */}
    {/*    text="Add new" */}
    {/*    disabled={false} */}
    {/*    width="150px" */}
    {/*  /> */}
    {/*  <Button */}
    {/*    onClick={onClickImport} */}
    {/*    primary={false} */}
    {/*    text="Import" */}
    {/*    disabled={false} */}
    {/*    width="150px" */}
    {/*  /> */}
    {/* </GridToolbar> */}
    {toolbarChildren}
    {gridChildren}
    {children}
  </KendoGrid>
);

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  // onInsert: PropTypes.func.isRequired,
  // onClickImport: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  toolbarChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  gridChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Grid.defaultProps = {
  toolbarChildren: null
};

export default Grid;
