import validator from "validator";
import { lookupMonth } from "./lookups";
import { BANK, CC, CREDIT_REASON_SOLD, EFTPS, MAIL } from "../constants";

import { doIfString, isEmpty } from "./generic";

export const removeSpaces = v => doIfString(v, v => v.replace(/\s/g, ""));

export const cleanSpaces = v =>
  doIfString(v, v => v.replace(/\s\s+/g, " ").trim());

export const trim = v => doIfString(v, () => v.trim());

export const toUpper = v => doIfString(v, () => v.toUpperCase());

export const fitToMax = (v, m) => (v.length <= m ? v : v.slice(0, m));

export const isPhone = v => validator.isMobilePhone(v, "en-US");

export const isEIN = v => /^\d{2}-\d{7}$/g.test(v);

export const sanitizeEIN = v => cleanSpaces(v.replace(/[-]/g, ""));

export const sanitizePhone = v => cleanSpaces(v.replace(/[- )(]/g, ""));

export const sanitizeValue = v => {
  if (typeof v !== "string") return v;
  if (isEIN(v)) return sanitizeEIN(v);
  if (isPhone(v)) return sanitizePhone(v);
  if (v === "true" || v === "false") {
    return v === "true";
  }
  return v === "" ? null : cleanSpaces(v);
};

export const arrayToMap = (a, k) =>
  a.reduce((map, obj) => {
    map[obj[k]] = obj;
    return map;
  }, {});

export const formatDateDescription = (dateCategory, taxYear) => {
  const month = lookupMonth(dateCategory);
  return month === "July"
    ? `July ${taxYear} or Before`
    : `${month} ${dateCategory < 7 ? parseInt(taxYear, 10) + 1 : taxYear}`;
};

// mapFormValuesToPayload --------------------------------- *

export const mapContactAndSignerFormValuesToPayload = formValues =>
  (({ FirstName, Mi, LastName, OfficerTitle, Email, Tel1, Tel2 }) => ({
    FirstName: cleanSpaces(FirstName),
    Mi,
    LastName: cleanSpaces(LastName),
    OfficerTitle: cleanSpaces(OfficerTitle),
    Email,
    Tel1,
    Tel2
  }))(formValues);

export const mapSignerFormValuesToPayload = formValues =>
  (({ FirstName, Mi, LastName, OfficerTitle }) => ({
    FirstName: cleanSpaces(FirstName),
    Mi,
    LastName: cleanSpaces(LastName),
    OfficerTitle: cleanSpaces(OfficerTitle)
  }))(formValues);

export const mapCompanyInfoFormValuesToPayload = formValues =>
  (({
    BusinessName,
    Ein,
    Country,
    State,
    Address1,
    City,
    Zip,
    SoleProprietor
  }) => ({
    BusinessName: cleanSpaces(BusinessName),
    Ein: isEmpty(Ein) ? undefined : Ein,

    Country,
    State,
    Address1: cleanSpaces(Address1),
    City: cleanSpaces(City),
    Zip,
    SoleProprietor
  }))(formValues);

export const mapPaymentAccountToShortDesc = paymentAccount => {
  if (!paymentAccount) return null;

  switch (paymentAccount.PaymentMethod) {
    case BANK: {
      if (typeof paymentAccount.AccountNumber !== "string") return null;
      const acctLast4 = paymentAccount.AccountNumber.substr(-4);
      return `Checking Account ...${acctLast4}`;
    }
    case EFTPS:
      return "EFTPS";
    case MAIL:
      return "Mail a Check";
    case CC:
      return "Credit Card";
    default:
      return null;
  }
};

export const mapPaymentFormValuesToPayload = formValues => {
  const AgreeEftpsTerms =
    formValues["registered-eftps-agreement"] &&
    formValues["cannot-use-credit-card-agreement"] &&
    formValues["i2290-access-eftps-agreement"] &&
    formValues["amount-shown-agreement"];

  switch (formValues.PaymentAccount.PaymentMethod) {
    case BANK:
      return {
        PaymentAccount: (obj => ({
          Rtn: obj.PaymentAccount.Rtn,
          AccountNumber: obj.PaymentAccount.AccountNumber,
          AccountType: 1,
          PaymentMethod: BANK,
          Eftps: false,
          AgreeEftpsTerms: false
        }))(formValues)
      };
    case EFTPS:
      return {
        PaymentAccount: {
          Eftps: true,
          PaymentMethod: formValues.PaymentAccount.PaymentMethod,
          AccountNumber: null,
          AccountType: 1,
          Rtn: null,
          AgreeEftpsTerms
        }
      };
    case MAIL:
    case CC:
      return {
        PaymentAccount: {
          Eftps: true,
          PaymentMethod: formValues.PaymentAccount.PaymentMethod,
          AccountNumber: null,
          AccountType: 1,
          Rtn: null,
          AgreeEftpsTerms: false
        }
      };
    default:
      return false;
  }
};

export const mapTaxableVehicleToPayload = data =>
  (({
    Id,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable
  }) => ({
    Id: Id || undefined,
    Vin: toUpper(cleanSpaces(Vin)),
    DateCategory: trim(DateCategory),
    WeightCategory: toUpper(trim(WeightCategory)),
    Logging: toUpper(trim(Logging)),
    Agricultural: toUpper(trim(Agricultural)),
    Description: cleanSpaces(Description),
    Suspend: toUpper(trim(Suspend)),
    TaxValue: trim(TaxValue),
    IsTaxable
  }))(data);

export const mapCreditVehicleToPayload = data =>
  (({
    Id,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable,
    CreditEventDate,
    CreditReason,
    BuyerName,
    BuyerAddress1,
    BuyerAddress2,
    BuyerCity,
    BuyerState,
    BuyerCountry,
    BuyerZip,
    IsBuyerBusiness,
    CreditAmount,
    IsBuyerDataIncluded,
    TaxPaidPriorPeriod
  }) => ({
    Id: Id || undefined,
    Vin: toUpper(cleanSpaces(Vin)),
    DateCategory: trim(DateCategory),
    WeightCategory: toUpper(trim(WeightCategory)),
    Logging: toUpper(trim(Logging)),
    Agricultural,
    Description: cleanSpaces(Description),
    Suspend,
    TaxValue,
    IsTaxable,
    CreditEventDate: trim(CreditEventDate),
    CreditReason: trim(CreditReason),
    BuyerName:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? cleanSpaces(BuyerName)
        : null,
    BuyerAddress1:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? cleanSpaces(BuyerAddress1)
        : null,
    BuyerAddress2:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? cleanSpaces(BuyerAddress2)
        : null,
    BuyerCity:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? cleanSpaces(BuyerCity)
        : null,
    BuyerState:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? BuyerState
        : null,
    BuyerCountry:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? BuyerCountry
        : null,
    BuyerZip:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? BuyerZip
        : null,
    IsBuyerBusiness:
      CreditReason === CREDIT_REASON_SOLD && IsBuyerDataIncluded
        ? trim(IsBuyerBusiness)
        : null,
    IsBuyerDataIncluded:
      CreditReason === CREDIT_REASON_SOLD ? IsBuyerDataIncluded : null,
    CreditAmount,
    TaxPaidPriorPeriod
  }))(data);

// OTHER -------------------------------------- *
export const cleanVehicle = data => {
  const dateCategory =
    data.DateCategory === null || isNaN(+data.DateCategory)
      ? null
      : data.DateCategory.toString();
  const description = data.Description
    ? data.Description.slice(0, 50)
    : data.Description;
  return {
    ...data,
    Description: description,
    DateCategory: dateCategory,
    CreditEventDate: data.CreditEventDate
      ? new Date(data.CreditEventDate)
      : null
  };
};

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

const { format } = currencyFormatter;

export const formatCurrency = format;
