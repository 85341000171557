import { STATE_NAME } from "./vehicles.constants";
import controls from "../controls";

const {
  validations: { validateVehicleFormWithNullValid }
} = controls;

export const getState = state => state[STATE_NAME];

export const getEntities = state => state[STATE_NAME].entities;

export const getUI = state => state[STATE_NAME].ui;

export const getWorkingCopies = state => state[STATE_NAME].ui.workingCopies;

export const getRedirect = state => state[STATE_NAME].redirect;

export const getTaxableVehiclesShowImporter = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.showImporter;

export const getCreditVehiclesShowImporter = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.showImporter;

export const getTaxableVehiclesGridOrder = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.gridOrder;

export const getCreditVehiclesGridOrder = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.gridOrder;

export const getCreditsOrVehiclesStaged = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.stagedForDeletion ||
  state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation ||
  state[STATE_NAME].ui.creditVehiclesGrid.stagedForDeletion ||
  state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation ||
  state[STATE_NAME].ui.taxableVehiclesGrid.allAreStagedForDeletion ||
  state[STATE_NAME].ui.creditVehiclesGrid.allAreStagedForDeletion ||
  state[STATE_NAME].ui.taxableVehiclesGrid.showImporter ||
  state[STATE_NAME].ui.creditVehiclesGrid.showImporter;

export const getTaxableVehiclesStagedForDeletion = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.stagedForDeletion;

export const getTaxableVehiclesStagedForCreation = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation;

export const getCreditVehiclesStagedForDeletion = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.stagedForDeletion;

export const getCreditVehiclesStagedForCreation = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation;

export const getTaxableVehiclesAllAreStagedForDeletion = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.allAreStagedForDeletion;

export const getCreditVehiclesAllAreStagedForDeletion = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.allAreStagedForDeletion;

export const getTaxableVehiclesCurrentPage = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.currentPage;

export const getTaxableVehiclesPageSize = state =>
  state[STATE_NAME].ui.taxableVehiclesGrid.pageSize;

export const getCreditVehiclesCurrentPage = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.currentPage;

export const getCreditVehiclesPageSize = state =>
  state[STATE_NAME].ui.creditVehiclesGrid.pageSize;

export const getTaxableVehiclesErrorsExist = state =>
  !!Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && x.IsTaxable && x.errors).length ||
  !!(
    state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation &&
    state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation.errors
  );

export const getCreditVehiclesErrorsExist = state =>
  !!Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && !x.IsTaxable && x.errors).length ||
  !!(
    state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation &&
    state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation.errors
  );

export const getAnyTaxableVehiclesInEdit = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && x.IsTaxable && x.inEdit).length > 0 ||
  (state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation !== null &&
    state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation.inEdit === true);

export const getAnyCreditVehiclesInEdit = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && !x.IsTaxable && x.inEdit).length > 0 ||
  (state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation !== null &&
    state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation.inEdit === true);

export const getTaxableWorkingCopies = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && x.IsTaxable);

export const getCreditWorkingCopies = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && !x.IsTaxable);

export const getTaxableVehiclesInEdit = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && x.IsTaxable && x.inEdit)
    .concat(
      state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation !== null &&
        state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation.inEdit ===
          true
        ? state[STATE_NAME].ui.taxableVehiclesGrid.stagedForCreation
        : []
    );

export const getCreditVehiclesInEdit = state =>
  Object.keys(state[STATE_NAME].ui.workingCopies)
    .map(k => state[STATE_NAME].ui.workingCopies[k])
    .filter(x => x && !x.IsTaxable && x.inEdit)
    .concat(
      state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation !== null &&
        state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation.inEdit ===
          true
        ? state[STATE_NAME].ui.creditVehiclesGrid.stagedForCreation
        : []
    );

export const getGrossTax = state =>
  Object.keys(state[STATE_NAME].entities)
    .map(k => state[STATE_NAME].entities[k])
    .filter(
      x => x && x.IsTaxable && validateVehicleFormWithNullValid(x) === null
    )
    .reduce((a, x) => a + x.TaxValue, 0);
