import React from "react";
import PropTypes from "prop-types";
import TextField from "./TextField";
import { normalizePhone, formatPhone } from "../normalization";

const PhoneField = ({ name, label, onBlur, validations }) => (
  <TextField
    name={name}
    label={label}
    type="text"
    onBlur={onBlur}
    validations={validations}
    normalize={normalizePhone}
    format={formatPhone}
    autoComplete="no"
  />
);

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  validations: PropTypes.arrayOf(PropTypes.func)
};

PhoneField.defaultProps = {
  name: "Phone",
  label: "Phone",
  validations: null,
  onBlur: null
};

export default PhoneField;
