import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { SCHEDULE_ONE_SUPPORT_FORM_NAME } from "../supportCenter.constants";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import userContext from "../../../modules/userContext";
import { MAIL_SCHEDULE_ONE_SUBJECT } from "../../../modules/constants";
import ScheduleOneSupportForm from "./ScheduleOneSupportForm";
import {
  mapSupportFieldsToPayload,
  createDescription
} from "../supportCenter.utilities";
import controls from "../../../modules/controls";

const {
  selectors: { getUserContext }
} = userContext;

const {
  validations: { validateRequired, validateAddress, validateCity }
} = controls;

class ScheduleOneSupportFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { postSupportRequest }
    } = this;
    const payload = mapSupportFieldsToPayload(formValues);
    payload.Subject = MAIL_SCHEDULE_ONE_SUBJECT;
    payload.Description = createDescription({
      Name: formValues.MailName,
      ATTN: formValues.MailAttn,
      Address: formValues.Address1,
      City: formValues.City,
      State: formValues.State,
      Zip: formValues.Zip
    });
    postSupportRequest(payload);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const { handleSubmit, handleClickCancel } = this;
    return (
      <ScheduleOneSupportForm
        formName={SCHEDULE_ONE_SUPPORT_FORM_NAME}
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
        mailAddressValidations={[validateRequired, validateAddress]}
        mailCityValidations={[validateRequired, validateCity]}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...getUserContext(state),
    MailAddress: "",
    MailCity: "",
    MailState: "",
    MailCountry: "US",
    MailZip: ""
  }
});

const mapDispatchToProps = dispatch => ({
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: SCHEDULE_ONE_SUPPORT_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(ScheduleOneSupportFormContainer)
  )
);
