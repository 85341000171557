import components from "./components";
import * as actions from "./register.actions";
import reducer from "./register.reducer";
import * as constants from "./register.constants";
import * as selectors from "./register.selectors";

export default {
  components,
  actions,
  reducer,
  constants,
  selectors
};
