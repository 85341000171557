import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import controls from "../../controls";
import { BANK, CC, EFTPS, MAIL } from "../../constants";

const {
  components: { Link, Checkbox }
} = controls;

const RenderPaymentMethodField = ({ input: { value, onChange, onBlur } }) => {
  const [triggered, setTriggered] = useState("");

  const setPaymentType = val => {
    onChange(val);
    setTriggered(val);
  };

  useEffect(() => {
    if (triggered !== "") {
      onBlur();
      setTriggered("");
    }
  }, [triggered]);

  switch (value) {
    case BANK:
    case null:
      return (
        <React.Fragment>
          <Link
            text="Mail a Check"
            clickHandler={() => setPaymentType(MAIL)}
            onBlur={onBlur}
          />
          <span>, </span>
          <Link
            text="EFTPS"
            clickHandler={() => setPaymentType(EFTPS)}
            onBlur={onBlur}
          />
          <span> or </span>
          <Link
            text="Credit Card"
            clickHandler={() => setPaymentType(CC)}
            onBlur={onBlur}
          />
          <span>.</span>
        </React.Fragment>
      );
    case MAIL:
      return (
        <React.Fragment>
          <Link
            text="Checking Account Direct Debit (recommended)"
            clickHandler={() => setPaymentType(BANK)}
            onBlur={onBlur}
          />
          <span>, </span>
          <Link
            text="EFTPS"
            clickHandler={() => setPaymentType(EFTPS)}
            onBlur={onBlur}
          />
          <span> or </span>
          <Link
            text="Credit Card"
            clickHandler={() => setPaymentType(CC)}
            onBlur={onBlur}
          />
          <span>.</span>
        </React.Fragment>
      );
    case EFTPS:
      return (
        <React.Fragment>
          <Link
            text="Checking Account Direct Debit (recommended)"
            clickHandler={() => setPaymentType(BANK)}
            onBlur={onBlur}
          />
          <span>, </span>
          <Link
            text="Mail a Check"
            clickHandler={() => setPaymentType(MAIL)}
            onBlur={onBlur}
          />
          <span> or </span>
          <Link
            text="Credit Card"
            clickHandler={() => setPaymentType(CC)}
            onBlur={onBlur}
          />
          <span>.</span>
        </React.Fragment>
      );
    case CC:
      return (
        <React.Fragment>
          <Link
            text="Checking Account Direct Debit (recommended)"
            clickHandler={() => setPaymentType(BANK)}
            onBlur={onBlur}
          />
          <span>, </span>
          <Link
            text="Mail a Check"
            clickHandler={() => setPaymentType(MAIL)}
            onBlur={onBlur}
          />
          <span> or </span>
          <Link
            text="EFTPS"
            clickHandler={() => setPaymentType(EFTPS)}
            onBlur={onBlur}
          />
          <span>.</span>
        </React.Fragment>
      );
    default:
      return <div>error</div>;
  }
};

export const PaymentMethodField = ({ name, onBlur }) => (
  <Field
    name={name}
    component={RenderPaymentMethodField}
    onBlur={onBlur}
    type="text"
  />
);
