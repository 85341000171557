import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PaySubmit from "./PaySubmit";
import { getError, getIsUnlimitedSelected } from "../cart.selectors";
import order from "../../order";
import utilities from "../../utilities";
import { getVehicleValidationCounts } from "../../order/order.selectorsv2";
import {
  getTaxableVehiclesErrorsExist,
  getCreditVehiclesErrorsExist
} from "../../vehicles/vehicles.selectors";
import controls from "../../controls";
import { TERMS_AND_CONDITIONS_URI, PRIVACY_POLICY_URI } from "../../constants";
import {
  startPayment as startPaymentCreator
} from "../../paymentModal/paymentModal.actions";
import userContext from "../../userContext";
import { toggleDisclaimerIsVisible } from "modules/order/order.actions";

const {
  selectors: { getUserContext }
} = userContext;

const {
  selectors: {
    getOrderHOF,
    getUserIsCoveredByUnlimited,
    getNetTax,
    getDisclaimerIsVisible,
    getCurrentTaxYear
  },
  utilities: { getIsNoCharge },
  actions: {
    submitOrder: submitOrderCreator,
    toggleDisclaimerIsVisible: toggleDisclaimerIsVisibleCreator,
  }
} = order;

const {
  validations: {
    validateSignerFrom,
    validateBusinessForm,
    validatePaymentMethod
  }
} = controls;

const {
  getOrderIdFromUrl,
  transform: { mapPaymentAccountToShortDesc }
} = utilities;

class PaySubmitContainer extends Component {
  handleClickSubmission = () => {
    const {
      props: {
        isPaid,
        startPayment,
        isNoCharge,
        isWeightIncrease,
        userIsCoveredByUnlimited,
        submitOrder,
        total
      }
    } = this;
    if (
      isPaid ||
      isWeightIncrease ||
      userIsCoveredByUnlimited ||
      isNoCharge ||
      total <= 0
    ) {
      submitOrder(getOrderIdFromUrl());
    } else {
      startPayment();
    }
  };

  handleClickDisclaimer = () => {
    const {
      props: { toggleDisclaimerIsVisible }
    } = this;
    toggleDisclaimerIsVisible();
  };

  handleClickTerms = () => window.open(TERMS_AND_CONDITIONS_URI, "_blank");

  handleClickPrivacy = () => window.open(PRIVACY_POLICY_URI, "_blank");

  render() {
    const {
      props: {
        pin,
        isUnlimited,
        netTax,
        error,
        isWeightIncrease,
        disclaimerIsVisible,
        userIsCoveredByUnlimited,
        isNoCharge,
        noChargeString,
        invalidOrder,
        isPaid,
        paymentAccountShortDesc,
        onClickEdit
      },
      handleClickDisclaimer,
      handleClickPrivacy,
      handleClickTerms,
      handleClickSubmission,      
    } = this;

    const buttonText = invalidOrder
      ? "Form is incomplete. Unable to Submit"
      : "AGREE and Checkout";

    return (
      <PaySubmit
        isPaid={isPaid}
        onClickSubmission={handleClickSubmission}
        onClickDisclaimer={handleClickDisclaimer}
        onClickPrivacy={handleClickPrivacy}
        onClickTerms={handleClickTerms}
        onClickEdit={onClickEdit}
        onClickModal={this.props.startPayment}
        buttonText={buttonText}
        disclaimerIsVisible={disclaimerIsVisible}
        isSubmissionButtonDisabled={invalidOrder}
        isNoCharge={isNoCharge}
        noChargeString={noChargeString}
        isWeightIncrease={isWeightIncrease}
        isUnlimited={isUnlimited}
        userIsCoveredByUnlimited={userIsCoveredByUnlimited}
        netTax={netTax}
        paymentAccountShortDesc={paymentAccountShortDesc}
        error={error}
        pin={pin}
      />
    );
  }
}

PaySubmitContainer.propTypes = {
  isPaid: PropTypes.bool.isRequired,
  total: PropTypes.string,
  netTax: PropTypes.number,
  error: PropTypes.string,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  isWeightIncrease: PropTypes.bool,
  isNoCharge: PropTypes.bool.isRequired,
  noChargeString: PropTypes.string,
  companyFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfInvalidCreditVehicles: PropTypes.number,
  numberOfInvalidTaxableVehicles: PropTypes.number,
  paymentAccountShortDesc: PropTypes.string,
  onClickEdit: PropTypes.func,
  toggleDisclaimerIsVisible: PropTypes.func.isRequired,
};

PaySubmitContainer.defaultProps = {
  subTotal: null,
  taxAmount: null,
  total: null,
  error: null,
  noChargeString: null
};

const mapStateToProps = (state, props) => {
  const userContext = getUserContext(state);
  const orderId = getOrderIdFromUrl();
  const currentTaxYear = getCurrentTaxYear(state);
  const order = getOrderHOF(orderId)(state);
  const userIsCoveredByUnlimited = getUserIsCoveredByUnlimited(orderId)(state);
  const { ThirdPname, ThirdPphone, ThirdPpin, ThirdPyesNo } = order || {};
  const {
    vehiclesEntered,
    vehicleErrorCount,
    creditVehicleErrorCount
  } = getVehicleValidationCounts(state);

  const hasErrors = e => {
    return Object.keys(e).length > 0;
  };

  const formsInvalid = hasErrors({
    ...validateSignerFrom({
      ...userContext,
      ThirdPname,
      ThirdPphone,
      ThirdPpin,
      ThirdPyesNo:
        ThirdPyesNo === null || ThirdPyesNo === undefined ? false : ThirdPyesNo
    }),
    ...validateBusinessForm(userContext)
  });

  const vehiclesInvalid =
    vehiclesEntered === 0 ||
    vehicleErrorCount + creditVehicleErrorCount > 0 ||
    getTaxableVehiclesErrorsExist(state) ||
    getCreditVehiclesErrorsExist(state);

  const paymentInvalid =
    getNetTax(orderId)(state) !== 0 &&
    hasErrors(validatePaymentMethod(order) || {});

  let isWeightIncrease = null;
  let isNoCharge = false;
  let isPaid = false;
  let noChargeString = null;
  let netTax = getNetTax(orderId)(state);
  let isCurrentYear = false;
  let isFilingPreviousYear = false;
  let isUsingCC = false;

  if (order) {
    isWeightIncrease = order.WeightIncrease;
    isNoCharge = getIsNoCharge(order.PaymentTranNum);
    noChargeString = order.PaymentTranNum;
    isPaid = order.PaymentInError === false && order.PaymentTranNum !== null;
    isCurrentYear = order.TaxYear == currentTaxYear;
    isFilingPreviousYear = order.TaxYear < currentTaxYear;
    isUsingCC = order.PaymentAccount && (order.PaymentAccount.PaymentMethod || '') === 'CC';
  }

  return {
    paymentAccountShortDesc: mapPaymentAccountToShortDesc(
      order?.PaymentAccount
    ),
    isCurrentYear,
    isFilingPreviousYear,
    isUsingCC,
    pin: userContext && userContext.Password ? userContext.Password : "N/A",
    isPaid,
    isNoCharge,
    noChargeString,
    netTax,
    isUnlimited: getIsUnlimitedSelected(state),
    userIsCoveredByUnlimited,
    isWeightIncrease,
    error: getError(state),
    disclaimerIsVisible: getDisclaimerIsVisible(state),
    toggleDisclaimerIsVisible: toggleDisclaimerIsVisible(),  
    // ATTENTION: src\screens\new-user\components\WizardStepperContainer.js
    invalidOrder: formsInvalid || vehiclesInvalid || paymentInvalid
  };
};

const mapDispatchToProps = dispatch => ({
  toggleDisclaimerIsVisible: () => dispatch(toggleDisclaimerIsVisibleCreator()),
  startPayment: () => dispatch(startPaymentCreator()),
  submitOrder: orderId => dispatch(submitOrderCreator(orderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaySubmitContainer);
