import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { Field } from "redux-form";
import "./textField.css";
import { withStyles } from "@material-ui/core/styles";

const renderTextFieldInner = ({
  value,
  input,
  meta,
  className,
  needsTouch,
  muiStyle,
  ...custom
}) => {
    const { touched, error, active } = meta || {error: custom.error};
    const _input = input || {value};
    return (
      <TextField
        InputProps={{
          autoComplete: "new-password",
          onSelect: custom.onSelect || null,
          startAdornment: custom.startAdornment || null
        }}
        error={needsTouch ? touched && !!error : !!error}
        helperText={needsTouch ? touched && error : error}
        InputLabelProps={{
          shrink: custom.startAdornment ||
            active ||
            (_input.value !== "" && _input.value !== undefined) || false
        }}
        className={className
          ? `i2290-control i2290-text-input ${className}`
          : "i2290-control i2290-text-input"}
        variant="outlined"
        value={value}
        {...input}
        {...custom} />
    );
  };

// @ts-ignore this keeps with the original API of the control.
const renderTextField = ({value:_, ...props}) => renderTextFieldInner({...props, value:""});

const TextFieldWrapper = ({
  placeholder,
  label,
  name,
  type,
  validations,
  normalize,
  format,
  needsTouch,
  muiStyle,
  ...custom
}) => (
  <Field
    name={name}
    component={
      muiStyle !== null
        ? rest => withStyles(renderTextField(rest))
        : renderTextField
    }
    label={label}
    placeholder={placeholder}
    type={type}
    validate={validations}
    normalize={normalize}
    format={format}
    needsTouch={needsTouch}
    {...custom}
    autoComplete="no"
  />
);

TextFieldWrapper.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func,
  className: PropTypes.string,
  needsTouch: PropTypes.bool,
  muiStyle: PropTypes.object
};

TextFieldWrapper.defaultProps = {
  normalize: null,
  format: null,
  placeholder: "",
  validations: null,
  label: null,
  className: null,
  needsTouch: true,
  muiStyle: null
};

export const NonFormTextField = renderTextFieldInner;

export default TextFieldWrapper;
