import React from "react";
import PropTypes from "prop-types";
import "./designeeForm.css";

const SignerView = ({
  FirstName,
  Mi,
  LastName,
  OfficerTitle,
  Email,
  Tel1,
  Tel2
}) => (
  <div className="row widget-body">
    {FirstName + Mi + LastName !== "" ? (
      <div className="col-12 col-md-12">
        {FirstName} {Mi} {LastName}
      </div>
    ) : (
      <></>
    )}
    {OfficerTitle !== "" ? (
      <div className="col-12 col-md-12">{OfficerTitle}</div>
    ) : (
      <></>
    )}
    {Email !== "" ? <div className="col-12 col-md-12">{Email}</div> : <></>}
    {Tel1 !== "" ? <div className="col-12 col-md-12">{Tel1}</div> : <></>}
    {Tel2 !== "" ? <div className="col-12 col-md-12">{Tel2}</div> : <></>}
  </div>
);

SignerView.propTypes = {
  FirstName: PropTypes.string,
  Mi: PropTypes.string,
  LastName: PropTypes.string,
  OfficerTitle: PropTypes.string,
  Email: PropTypes.string,
  Tel1: PropTypes.string,
  Tel2: PropTypes.string
};

SignerView.defaultProps = {
  FirstName: "",
  Mi: "",
  LastName: "",
  OfficerTitle: "",
  Email: "",
  Tel1: "",
  Tel2: ""
};

export default SignerView;
