import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import ContactSupportForm from "./ContactSupportFormContainer";
import ProfileWidget from "./ProfileWidgetContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <div className="inline-sub">
      <span className="support-page-header">Do you need assistance?</span> Form
      2290 Specialists are here to help.
    </div>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <ContactSupportForm />
  </div>
);

const ContactSupportFormScreen = withChildren(children, SupportFormScreen);

export default ContactSupportFormScreen;
