import React from "react";
// import { Redirect, withRouter } from "react-router-dom";

const Home = ({ isAuthenticated, history }) => (
  <React.Fragment>
    {isAuthenticated ? history.push("/account") : history.push("/sign-in")}
  </React.Fragment>
);

export default Home;
