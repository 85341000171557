import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import { states } from "../data";

const UnitedStatesDropdown = ({ name, validations }) => (
  <Select name={name} label="State" validations={validations}>
    {[<option value={null} key="none-state" />].concat(
      states.US.map(State => (
        <option value={State.real} key={State.real}>
          {State.display}
        </option>
      ))
    )}
  </Select>
);

UnitedStatesDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.array
};

UnitedStatesDropdown.defaultProps = {
  validations: []
};

export default UnitedStatesDropdown;
