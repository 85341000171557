import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import IrsRejectedForm from "./IrsRejectedFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 className="support-page-header">Do you have an IRS-rejected return?</h2>
    <p style={{ marginBottom: "30px" }}>
      Getting your IRS-stamped Schedule 1 is our top priority. We are Form 2290
      e-file specialists and we know how to fix rejected returns. We normally
      have a solution in the same business day.
    </p>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <IrsRejectedForm />
  </div>
);

const IrsRejectedFormScreen = withChildren(children, SupportFormScreen);

export default IrsRejectedFormScreen;
