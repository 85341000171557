import React from "react";
import FormReport from "./FormReportContainer";

const FormReportScreen = () => (
  <div>
    <FormReport />
  </div>
);

export default FormReportScreen;
