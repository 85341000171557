import React from "react";
import PropTypes from "prop-types";
import { Input } from "@progress/kendo-react-inputs";

class TextCell extends React.Component {
  handleChange = e => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  };

  render() {
    const dataValue = this.props.dataItem[this.props.field];

    let error = null;
    if (
      this.props.dataItem.errors &&
      this.props.dataItem.errors[this.props.field]
    ) {
      error = this.props.dataItem.errors[this.props.field];
    }

    if (!this.props.dataItem.inEdit) {
      return (
        <td {...this.props}>
          {dataValue === null || dataValue === undefined
            ? ""
            : this.props.dataItem[this.props.field].toString()}
        </td>
      );
    }

    return (
      <td {...this.props}>
        <Input
          style={{ width: "100%" }}
          onChange={this.handleChange}
          value={dataValue}
        />
        {error && <span className="i2290-inline-error">{error}</span>}
      </td>
    );
  }
}

TextCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default TextCell;
