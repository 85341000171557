import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderSummaryPanelRepeater from "./OrderSummaryPanelRepeater";
import order from "../../../modules/order";
import { ORDER_NOT_SUBMITTED_STATUS } from "../../../modules/constants";

const {
  selectors: { getOrderHistorySortByIsActive }
} = order;

class OrderSummaryPanelRepeaterContainer extends Component {
  render() {
    const {
      props: { orderIds, isActive }
    } = this;
    return (
      <OrderSummaryPanelRepeater orderIds={orderIds} isActive={isActive} />
    );
  }
}

OrderSummaryPanelRepeaterContainer.propTypes = {
  orderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isActive: PropTypes.bool.isRequired
};

const mapStateToProps = (state, props) => {
  const orderHistorySort = getOrderHistorySortByIsActive(props.isActive)(state);
  // const formHistory = getFormHistory(state);
  return { orderIds: orderHistorySort };
};

const ConnectedOrderSummaryPanelRepeaterContainer = connect(mapStateToProps)(
  OrderSummaryPanelRepeaterContainer
);
ConnectedOrderSummaryPanelRepeaterContainer.propTypes = {
  isActive: PropTypes.bool.isRequired
};

export default ConnectedOrderSummaryPanelRepeaterContainer;
