import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import Register from "./Register";
import userContext from "../../../modules/userContext";
import {
  register as registerCreator,
  reset as resetCreator
} from "../register.actions";
import { getError, getSuccess } from "../register.selectors";
import { FORM_NAME } from "../register.constants";
import busyIndicator from "../../../modules/busyIndicator";
import {
  PRIVACY_POLICY_URI,
  TERMS_AND_CONDITIONS_URI
} from "../../../modules/constants";

const {
  selectors: { isBusy: isBusySelector }
} = busyIndicator;

const {
  selectors: {
    isAuthenticated: isAuthenticatedSelector,
    getError: getUserContextError
  },
  actions: { setUserContextError: setUserContextErrorCreator }
} = userContext;

class RegisterContainer extends React.Component {
  handleReduxFormSubmit = values => {
    const { register, isBusy } = this.props;
    if (isBusy) return;
    const {
      firstName,
      lastName,
      username,
      pin,
      phoneNumber,
      foundUsOption,
      details
    } = values;
    register(
      firstName ? firstName.trim() : firstName,
      lastName ? lastName.trim() : lastName,
      username.trim(),
      pin,
      phoneNumber,
      foundUsOption,
      details
    );
  };

  handleLogin = () => {
    const { formValues, signIn } = this.props;
    const { username, pin } = formValues;
    signIn(username.trim(), pin);
  };

  handleExistingEINError = () => {
    const { reset, setUserContextError } = this.props;
    reset();
    setUserContextError({
      Error: "EinFound",
      Message:
        "The entered User ID is already registered in the site. Please enter PIN to login. If you forgot your PIN, click here to get it."
    });
  };

  handleClickTermsConditions = () =>
    window.open(TERMS_AND_CONDITIONS_URI, "_blank");

  handleClickPrivacyPolicy = () => window.open(PRIVACY_POLICY_URI, "_blank");

  handleClickSignIn = () => {
    const { history } = this.props;
    history.push("/sign-in");
  };

  render() {
    const {
      submitError,
      userContextError,
      submitSuccess,
      signIn,
      formValues,
      isAuthenticated,
      history,
      reset
    } = this.props;
    return (
      <Register
        onClickPrivacyPolicy={this.handleClickPrivacyPolicy}
        onClickSignIn={this.handleClickSignIn}
        onClickTermsConditions={this.handleClickTermsConditions}
        handleReduxFormSubmit={this.handleReduxFormSubmit}
        handleLogin={this.handleLogin}
        submitError={submitError}
        userContextError={userContextError}
        submitSuccess={submitSuccess}
        signIn={signIn}
        formValues={formValues}
        isAuthenticated={isAuthenticated}
        history={history}
        handleExistingEINError={this.handleExistingEINError}
        resetError={reset}
      />
    );
  }
}

RegisterContainer.propTypes = {
  register: PropTypes.func.isRequired,
  submitError: PropTypes.object,
  userContextError: PropTypes.object,
  submitSuccess: PropTypes.bool,
  formValues: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  setUserContextError: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

RegisterContainer.defaultProps = {
  submitError: null,
  submitSuccess: false,
  formValues: {}
};

const mapStateToProps = state => ({
  submitError: getError(state),
  userContextError: getUserContextError(state),
  submitSuccess: getSuccess(state),
  formValues: getFormValues(FORM_NAME)(state),
  isAuthenticated: isAuthenticatedSelector(state),
  isBusy: isBusySelector(state)
});

const mapDispatchToProps = {
  setUserContextError: setUserContextErrorCreator,
  register: registerCreator,
  reset: resetCreator,
  signIn: userContext.actions.signIn
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
