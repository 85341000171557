import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import {
  BANK,
  CC,
  EFTPS,
  MAIL,
  PAYMENT_AGREEMENT,
  PAYMENT_AGREEMENT_PREVIEW_LENGTH
} from "../../constants";
import { PaymentMethodField } from "./PaymentMethodField";

const {
  components: { TextField, Select, Checkbox, Link },
  normalizations: { normalizeRoutingNum, normalizeAccountNum }
} = controls;

export const Checkboxes = ({ onBlur }) => {
  const [triggered, setTriggered] = useState("");

  const checkTerm = () => {
    setTriggered("x");
  };

  // Trigger change detection before calling onBlur
  useEffect(() => {
    if (triggered !== "") {
      onBlur();
      setTriggered("");
    }
  }, [triggered]);

  return (
    <div id="eftps-selected">
      <div className="eftps-selected">
        <Checkbox
          onClick={checkTerm}
          name="registered-eftps-agreement"
          label="I agree that I am a registered EFTPS Account User with the US Treasury Department"
        />
        <Checkbox
          onClick={checkTerm}
          name="cannot-use-credit-card-agreement"
          label="I understand that I cannot use a credit card to pay my tax (EFTPS is not a credit card payment)"
        />
        <Checkbox
          onClick={checkTerm}
          name="i2290-access-eftps-agreement"
          label="I understand i2290.com cannot access my EFTPS Account and I must log into my EFTPS account and issue the payment"
        />
        <Checkbox
          onClick={checkTerm}
          name="amount-shown-agreement"
          label="I agree I have made a payment using EFTPS for the amount shown above (I understand I will incur IRS penalties if I have not)"
        />
      </div>
    </div>
  );
};

const PaymentMethodForm = ({
  onBlur,
  formValues,
  onClickWeCanHelp,
  netTax,
  onClickMore,
  onClickIRSWebsite
}) => (
  <div>
    {formValues && formValues.PaymentAccount ? (
      <React.Fragment>
        {netTax !== null && netTax > 0 ? (
          <div>
            {(formValues.PaymentAccount.PaymentMethod === BANK ||
              formValues.PaymentAccount.PaymentMethod === null) && (
              <div>
                <div className="row BankInformationForm">
                  <div className="col-12 col-sm-6">
                    <TextField
                      label="Routing Number"
                      name="PaymentAccount.Rtn"
                      type="text"
                      onBlur={onBlur}
                      normalize={normalizeRoutingNum}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <TextField
                      label="Account Number"
                      name="PaymentAccount.AccountNumber"
                      type="text"
                      onBlur={onBlur}
                      normalize={normalizeAccountNum}
                    />
                  </div>
                </div>
                {onClickMore ? (
                  <p className="mt-2">
                    {PAYMENT_AGREEMENT.slice(
                      0,
                      PAYMENT_AGREEMENT_PREVIEW_LENGTH
                    )}
                    <Link clickHandler={onClickMore} text="...more" />
                  </p>
                ) : (
                  <p className="mt-2">{PAYMENT_AGREEMENT}</p>
                )}
              </div>
            )}
            {formValues.PaymentAccount.PaymentMethod === MAIL && (
              <div
                id="money-order-selected"
                className="dropdownView money-order-selected"
              >
                <div>
                  <p>
                    Don't know how to mail your payment to the IRS? Contact
                    Customer Support for assistance.{" "}
                    <Link
                      clickHandler={onClickWeCanHelp}
                      text="We can help you."
                    />
                  </p>
                </div>
              </div>
            )}
            {formValues.PaymentAccount.PaymentMethod === CC && (
              <div className="dropdownView money-order-selected">
                <div>
                  <p>
                    YOU MUST GO TO{" "}
                    <Link clickHandler={onClickIRSWebsite} text="IRS.gov" /> to
                    pay your taxes with a credit card. INSTANT-2290 cannot make
                    your tax payment or offer troubleshooting for this payment
                    method - questions should be directed to the IRS.
                  </p>
                </div>
              </div>
            )}
            {formValues.PaymentAccount.PaymentMethod === EFTPS && (
              <Checkboxes onBlur={onBlur}></Checkboxes>
            )}
            <div>
              <p className="mb-0">
                You may also use alternative payment methods:&nbsp;
                <PaymentMethodField
                  name="PaymentAccount.PaymentMethod"
                  onBlur={onBlur}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="w-100 text-center">
            <span>No tax liability. No payment method required.</span>
          </div>
        )}
      </React.Fragment>
    ) : (
      <span>Loading...</span>
    )}
  </div>
);

PaymentMethodForm.propTypes = {
  onClickIRSWebsite: PropTypes.func.isRequired,
  onClickMore: PropTypes.func,
  formValues: PropTypes.object.isRequired,
  onClickWeCanHelp: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  netTax: PropTypes.number
};

PaymentMethodForm.defaultProps = {
  onBlur: null,
  netTax: 0,
  onClickMore: null
};

export default PaymentMethodForm;
