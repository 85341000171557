import React, { Component } from "react";
import PropTypes from "prop-types";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";
import ZipField from "./ZipField";
import {
  normalizeUSZip,
  normalizeMXZip,
  normalizeCAZip,
  formatUSZip,
  formatMXZip,
  formatCAZip
} from "../normalization";

class ZipFieldContainer extends Component {
  render() {
    const {
      name,
      label,
      onBlur,
      countryFieldName,
      validations,
      onChange
    } = this.props;
    let { formValues } = this.props;

    formValues = { ...formValues, Country: formValues[countryFieldName] };

    const country = formValues[countryFieldName];

    let formatFunc = null;
    let normalizeFunc = null;

    switch (country) {
      case "US":
        formatFunc = formatUSZip;
        normalizeFunc = normalizeUSZip;
        break;
      case "MX":
        formatFunc = formatMXZip;
        normalizeFunc = normalizeMXZip;
        break;
      case "CA":
        formatFunc = formatCAZip;
        normalizeFunc = normalizeCAZip;
        break;
      default:
        formatFunc = formatUSZip;
        normalizeFunc = normalizeUSZip;
        break;
    }

    return (
      <ZipField
        name={name}
        label={label}
        validations={validations}
        normalize={normalizeFunc}
        format={formatFunc}
        onBlur={onBlur}
        onChange={onChange}
      />
    );
  }
}

ZipFieldContainer.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  formValues: PropTypes.object,
  formName: PropTypes.string.isRequired, // eslint-disable-line
  countryFieldName: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func)
};

ZipFieldContainer.defaultProps = {
  name: "Zip",
  label: "Zip",
  onBlur: null,
  countryFieldName: "Country",
  formValues: {},
  validations: []
};

const mapStateToProps = (state, { formName }) => ({
  formValues: getFormValues(formName)(state)
});

export default connect(mapStateToProps)(ZipFieldContainer);
