import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import utilities from "../../utilities";

const {
  components: { DetailsButton }
} = controls;

const {
  math: { fastFormatMoney }
} = utilities;

const FormWidgetHeader = ({
  month,
  netTaxDue,
  toggleIsExpanded,
  isExpanded,
  position,
  total
}) => (
  <div className="form-widget-header row">
    <span className="col my-auto">
      <strong>
        <span style={{ textTransform: "uppercase" }}>{month}</span> Form
      </strong>
      {total > 1 && <span>{` ${position} of ${total}`}</span>}
      {` Net Tax Due: ${fastFormatMoney(netTaxDue)}`}
    </span>
    <div className="col text-right my-auto">
      <DetailsButton isExpanded={isExpanded} onClick={toggleIsExpanded} />
    </div>
  </div>
);

FormWidgetHeader.propTypes = {
  month: PropTypes.string.isRequired,
  netTaxDue: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default FormWidgetHeader;
