import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./chevron.css";

const Chevron = ({ isUp, className, style, onClick }) => (
  <Fragment>
    {isUp ? (
      <span
        className={
          className
            ? `k-icon k-i-arrow-60-up i2290-chevron ${className}`
            : "k-icon k-i-arrow-60-up i2290-chevron"
        }
        style={style}
        onClick={onClick}
        aria-hidden
      />
    ) : (
      <span
        className={
          className
            ? `k-icon k-i-arrow-60-down i2290-chevron ${className}`
            : "k-icon k-i-arrow-60-down i2290-chevron"
        }
        style={style}
        onClick={onClick}
        aria-hidden
      />
    )}
  </Fragment>
);

Chevron.propTypes = {
  isUp: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired
};

Chevron.defaultProps = {
  className: null,
  style: null
};

export default Chevron;
