import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AccountFrame from "./AccountFrame";
import userContext from "../../../modules/userContext";
import error from "../../../modules/error";
import { constants } from "../../../modules/http";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";

const {
  selectors: { getRedirect },
  actions: { setRedirect: setRedirectCreator }
  // components: { WithRestrictedAccess }
} = userContext;

const {
  selectors: { getErrorType },
  actions: { resetError: resetErrorCreator }
} = error;

const { RESOURCE_NOT_FOUND_NAME } = constants;

class AccountFrameContainer extends Component {
  componentWillUnmount() {
    const {
      props: { errorType, resetError }
    } = this;
    if (errorType === RESOURCE_NOT_FOUND_NAME) {
      resetError();
    }
  }

  render() {
    const {
      props: {
        children,
        history: { push, location },
        redirect,
        setRedirect
      }
    } = this;

    return (
      <AccountFrame
        push={push}
        location={location}
        redirect={redirect}
        setRedirect={setRedirect}
      >
        {children}
      </AccountFrame>
    );
  }
}

AccountFrameContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  redirect: PropTypes.string,
  setRedirect: PropTypes.func,
  errorType: PropTypes.string,
  history: PropTypes.object.isRequired,
  resetError: PropTypes.func.isRequired
};

AccountFrameContainer.defaultProps = {
  redirect: null,
  errorType: null
};

const mapStateToProps = state => {
  const errorType = getErrorType(state);
  const redirect = getRedirect(state);

  return {
    redirect:
      errorType === RESOURCE_NOT_FOUND_NAME ? ORDER_HISTORY_ROUTE : redirect,
    errorType
  };
};

const mapDispatchToProps = dispatch => ({
  resetError: () => dispatch(resetErrorCreator()),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountFrameContainer)
);
