import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Form } from "redux-form";
import { Redirect, withRouter } from "react-router-dom";
import controls from "../../../modules/controls";
import { FORM_NAME } from "../signIn.constants";
import "./SignIn.css";
import busyIndicator from "../../../modules/busyIndicator";
import yellowLogo from "../../../modules/assets/logo.icon.min.png";

const { InlineBusyIndicator } = busyIndicator.components;
const {
  components: { TextField, Link, Button },
  validations: { validateSigninForm: validate },
  normalizations: { normalizeEmailOrEIN, normalizePin, formatEmailOrEIN }
} = controls;

const SignIn = ({
  handleReduxFormSubmit,
  handleSubmit,
  submitting,
  isAuthenticated,
  from,
  handleSignupClick,
  handleForgotPasswordClick,
  getError,
  resetError,
  onClickPrivacyPolicy,
  onClickTermsConditions
}) => (
  <Form onSubmit={handleSubmit(handleReduxFormSubmit)}>
    {isAuthenticated ? (
      <Redirect to={from} />
    ) : (
      <div className="row" id="wrapper">
        <div className="col-md-6 iconCol">
          <div className="imgContainer">
            <img src={yellowLogo} alt="yellow logo" />
          </div>
        </div>
        <div className="col-md-6 col-12 formCol d-flex justify-content-center align-items-center">
          <div className="formContainer">
            <div className="smallLogoContainer">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/914629/logo.final.png"
                id="smallLogo"
                width="300px"
                className="mb-gutter"
              />
              <h6 className="welcomeText">Welcome back!</h6>
              <h6 className="welcomeText">Please login to your account.</h6>
            </div>
            <div className="inputContainer">
              <TextField
                label="Email or EIN"
                name="username"
                type="text"
                normalize={normalizeEmailOrEIN}
                format={formatEmailOrEIN}
              />
            </div>
            <div className="inputContainer">
              <TextField
                label="5-digit PIN"
                name="pin"
                type="password"
                normalize={normalizePin}
              />
            </div>
            <div className="registrationContainer">
              <div className="row section">
                <div className="col-12 text-right">
                  <InlineBusyIndicator
                    errorSelector={getError}
                    reset={resetError}
                  />
                </div>
                <div className="col-12 text-center mb-gutter">
                  <Link
                    text="Forgot PIN?"
                    className="forgotPassword"
                    clickHandler={handleForgotPasswordClick}
                  />
                </div>
                <div className="col-12 text-center mb-gutter">
                  <Button
                    type="submit"
                    disabled={submitting}
                    primary
                    text="Sign In"
                    width="250px"
                  />
                </div>
                <div className="col-12 text-center mb-gutter">
                  <Link
                    text="Create new account"
                    clickHandler={handleSignupClick}
                  />
                </div>
                <div id="footerLinks" className="col-12">
                  <Link
                    clickHandler={onClickTermsConditions}
                    text="Terms & Conditions"
                  />
                  <Link
                    clickHandler={onClickPrivacyPolicy}
                    text="Privacy Policy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </Form>
);

SignIn.propTypes = {
  onClickTermsConditions: PropTypes.func.isRequired,
  onClickPrivacyPolicy: PropTypes.func.isRequired
};

export default withRouter(
  reduxForm({
    form: FORM_NAME,
    validate,
    touchOnBlur: false,
    initialValues: {
      username: "",
      pin: ""
    }
  })(SignIn)
);
