import React from "react";
import CommandCell from "./CommandCell";

const bindCommandCell = (editHOF, saveHOF, deleteHOF, cancelHOF) => props => (
  <CommandCell
    {...props}
    editField="inEdit"
    newField="Id"
    editConstructor={editHOF}
    saveConstructor={saveHOF}
    removeConstructor={deleteHOF}
    cancelConstructor={cancelHOF}
  />
);

export default bindCommandCell;
