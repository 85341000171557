import {
  buildActionType,
  buildAsyncActionType
} from "../../modules/utilities/reduxUtilities";
import { STATE_NAME } from "./supportCenter.constants";

export const TOGGLE_FAQ_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "TOGGLE_FAQ_IS_EXPANDED"
);

export const SET_ATTACHMENTS = buildActionType(STATE_NAME, "SET_ATTACHMENTS");

export const TOGGLE_ATTACHMENTS_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "TOGGLE_ATTACHMENTS_IS_EXPANDED"
);

export const SET_ATTACHMENTS_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "SET_ATTACHMENTS_IS_EXPANDED"
);

export const SET_REDIRECT = buildActionType(STATE_NAME, "SET_REDIRECT");

export const POST_SUPPORT_REQUEST = buildAsyncActionType(
  STATE_NAME,
  "POST_SUPPORT_REQUEST"
);
