import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import taxYear from "../../taxYear";
import vehicles from "../../vehicles";
import {
  getEntities as getFormEntities,
  getTaxableVehiclesGridCurrentPageHof,
  getTaxableVehiclesGridPageSizeHof
} from "../form.selectors";
import {
  changeCurrentPage as changeCurrentPageCreator,
  changePageSize as changePageSizeCreator
} from "../form.actions";
import controls from "../../controls";
import order from "../../order";
import utilities from "../../utilities";

const {
  selectors: { getTaxYear }
} = taxYear;

const {
  selectors: { getOrderHOF }
} = order;

const { getOrderIdFromUrl } = utilities;

const {
  components: { VehicleGrid, WeightIncreaseGrid },
  selectors: { getEntities: getVehicleEntities },
  constants: { TAXABLE_VEHICLES_SLICE_NAME },
  utilities: { sortVehicles }
} = vehicles;

const {
  validations: { validateVehicleFormWithNullValid: validate }
} = controls;

class FormVehicleGridContainer extends React.Component {
  state = {
    lastColumnWidth: undefined
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  }

  handlePageChange = ({ page: { skip, take } }) => {
    const {
      props: { changeCurrentPage, changePageSize }
    } = this;
    changeCurrentPage(skip / take);
    changePageSize(take);
  };

  handleResize = e => {
    if (
      e.target.innerWidth < 1585 &&
      this.state.lastColumnWidth === undefined
    ) {
      return this.setState({
        lastColumnWidth: 200
      });
    }

    if (e.target.innerWidth < 1585) {
      return;
    }

    return this.setState({
      lastColumnWidth: undefined
    });
  };

  // handleResize = e => {
  //   // major hackage
  //   const breakPoint = 800;
  //   if (!e) {
  //     // const gridWidth = document.getElementsByClassName("k-grid-container")[0]
  //     //   .offsetWidth;
  //
  //     if (window.innerWidth <= breakPoint && this.state.lastColumnWidth !== 200) {
  //       return this.setState({
  //         lastColumnWidth: 200,
  //         hitSmallest: window.innerWidth <= 845
  //       });
  //     }
  //     return this.setState({
  //       lastColumnWidth: Math.round(parseInt(window.innerWidth, 10) / 4)
  //     });
  //   }
  //
  //   if (e.target.innerWidth <= 845) {
  //     return this.setState({
  //       hitSmallest: true
  //     });
  //   }
  //
  //   if (e.target.innerWidth <= breakPoint && this.state.lastColumnWidth !== 200) {
  //     return this.setState({
  //       lastColumnWidth: 200,
  //       hitSmallest: false
  //     });
  //   }
  //   if (e.target.innerWidth > breakPoint) {
  //     const gridWidth = document.getElementsByClassName("k-grid-container")[0]
  //       .offsetWidth;
  //     return this.setState({
  //       lastColumnWidth: Math.round(parseInt(gridWidth, 10) / 4),
  //       hitSmallest: false
  //     });
  //   }
  //   return this.setState({
  //     hitSmallest: false
  //   });
  // };

  render() {
    const {
      handlePageChange,
      props: { taxYear, data, currentPage, pageSize, isWeightIncrease },
      state: { lockVin, lockTax, lastColumnWidth }
    } = this;

    return (
      <React.Fragment>
        {!isWeightIncrease ? (
          <VehicleGrid
            lockVin={lockVin}
            lockTax={lockTax}
            lastColumnWidth={lastColumnWidth}
            skip={currentPage * pageSize}
            take={pageSize}
            onPageChange={handlePageChange}
            height="300px"
            data={data}
            taxYear={taxYear}
            readOnly
          />
        ) : (
          <WeightIncreaseGrid
            skip={currentPage * pageSize}
            take={pageSize}
            onPageChange={handlePageChange}
            height="300px"
            width="auto"
            data={data}
          />
        )}
      </React.Fragment>
    );
  }
}

FormVehicleGridContainer.propTypes = {
  formId: PropTypes.number.isRequired, // eslint-disable-line
  taxYear: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  isWeightIncrease: PropTypes.bool.isRequired
};

FormVehicleGridContainer.defaultProps = {
  taxYear: null,
  data: []
};

const mapStateToProps = (state, props) => {
  const { formId } = props;
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  const formEntity = getFormEntities(state)[formId];
  const vehicleEntities = getVehicleEntities(state);

  const data = sortVehicles(vehicleEntities)
    .filter(a => formEntity.Vehicles.includes(a))
    .map(id => vehicleEntities[id])
    .filter(x => x.IsTaxable && !validate(x));

  return {
    isWeightIncrease: order ? order.WeightIncrease : false,
    data,
    taxYear: getTaxYear(state),
    currentPage: getTaxableVehiclesGridCurrentPageHof(formId)(state),
    pageSize: getTaxableVehiclesGridPageSizeHof(formId)(state)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  changeCurrentPage: pageNum =>
    dispatch(
      changeCurrentPageCreator(
        props.formId,
        pageNum,
        TAXABLE_VEHICLES_SLICE_NAME
      )
    ),
  changePageSize: pageSize =>
    dispatch(
      changePageSizeCreator(props.formId, pageSize, TAXABLE_VEHICLES_SLICE_NAME)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormVehicleGridContainer);
