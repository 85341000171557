import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import order from "../../order";
import utilities from "../../utilities";
import DesigneeView from "./DesigneeView";
import controls from "../../controls";

const {
  actions: { fetchOrder: fetchOrderCreator },
  selectors: { getOrderHOF, getThirdPyesNoHOF }
} = order;

const { getOrderIdFromUrl } = utilities;

const {
  normalizations: { formatPhone }
} = controls;

class DesigneeViewContainer extends Component {
  componentDidMount() {
    const { fetchOrder, orderIsInStore } = this.props;
    if (!orderIsInStore) fetchOrder(getOrderIdFromUrl());
  }

  render() {
    return (
      <React.Fragment>
        <DesigneeView {...this.props.initialValues} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { ThirdPname, ThirdPphone, ThirdPpin, ThirdPyesNo } = {
    ...(getOrderHOF(getOrderIdFromUrl())(state) || {}),
    ThirdPyesNo: getThirdPyesNoHOF(getOrderIdFromUrl())(state)
  };
  const initialValues = {
    ThirdPname: ThirdPname || "",
    ThirdPphone: formatPhone(ThirdPphone) || "",
    ThirdPpin: ThirdPpin || "",
    ThirdPyesNo: ThirdPyesNo ? true : false
  };

  return {
    initialValues,
    orderIsInStore: !!getOrderHOF(getOrderIdFromUrl())(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DesigneeViewContainer)
);
