export default [
  {
    display: "United States",
    real: "US"
  },
  {
    display: "Mexico",
    real: "MX"
  },
  {
    display: "Canada",
    real: "CA"
  }
];
