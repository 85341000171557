import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { change, getFormValues, reduxForm } from "redux-form";
import cart from "../../cart";
import order from "../../order";
import { submitOrder } from "../../order/order.actions";
import { getDefaultSku } from "../../order/order.selectors";
import { getUserAddress, getUserContext } from "../../userContext/userContext.selectors";
import utilities from "../../utilities";
import {
  makePayment as makePaymentCreator, setPaymentDialog as setPaymentDialogCreator
} from "../paymentModal.actions";
import { FORM_NAME, notStartedStep, stripeStep } from "../paymentModal.constants";
import {
  getDialogStep,
  getErrorMessage,
  getLoading, getRedirect
} from "../paymentModal.selectors";
import PaymentModal from "./paymentModal";

const {
  selectors: { getOrderHOF }
} = order;

const {
  selectors: { getPromoValue, getSku, getPaymentInfo }
} = cart;

const { getOrderIdFromUrl } = utilities;

class PaymentModalWithoutStripe extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  componentDidUpdate(prevProps) {
    const {
      props: { dialogIsVisible }
    } = this;
    if (prevProps.dialogIsVisible !== dialogIsVisible) {
      if (dialogIsVisible) {
        document.body.classList.add("modal-open-body");
      } else {
        document.body.classList.remove("modal-open-body");
      }
    }
  }
  
  onCopyAddress = () => {
    this.props.changeFields(FORM_NAME, "CcHolderAddress",this.props.addressToCopy.Address);
    this.props.changeFields(FORM_NAME, "CcHolderCountry",this.props.addressToCopy.Country);
    this.props.changeFields(FORM_NAME, "CcHolderState",this.props.addressToCopy.State);
    this.props.changeFields(FORM_NAME, "CcHolderZip",this.props.addressToCopy.Zip);
    this.props.changeFields(FORM_NAME, "CcHolderCity",this.props.addressToCopy.City);
    this.props.changeFields(FORM_NAME, "CcNotifyEmail",this.props.addressToCopy.Email);    
  }

  onSuccess = () => {
    const {
      submitCreditCard,
      formValues,
      promoCode,
      sku,
      submitOrder,
      noCartBalance
    } = this.props;
    const OrderId = getOrderIdFromUrl();
    noCartBalance
      ? submitOrder(OrderId)
      : submitCreditCard({
          ...formValues,
          OrderId,
          PromoCode: promoCode,
          SKU: sku
        });
  };

  render() {
    const {
      props: {
        dialogIsVisible,
        cancelDialog,
        submitError,
        redirect,
        paymentInfo,
        loading,
        noCartBalance
      }
    } = this;

    return (
      <React.Fragment>
        {redirect ? (
          <Redirect to={redirect} />
        ) : (
          <PaymentModal
            submitButtonIsDisabled={loading}
            total={paymentInfo.total}
            dialogIsVisible={dialogIsVisible}
            cancelPayment={cancelDialog}
            handleSubmit={this.handleSubmit}
            submitError={submitError}
            noCartBalance={noCartBalance}
            onCopyAddress={this.onCopyAddress}
          />
        )}
      </React.Fragment>
    );
  }
}

PaymentModalWithoutStripe.propTypes = {
  dialogIsVisible: PropTypes.bool,
  cancelDialog: PropTypes.func, 
  submitCreditCard: PropTypes.func,
  submitOrder: PropTypes.func,
  submitError: PropTypes.string,
  formValues: PropTypes.object,
  promoCode: PropTypes.string,
  sku: PropTypes.string,
  loading: PropTypes.bool.isRequired
};

PaymentModalWithoutStripe.defaultProps = {
  promoCode: "",
  sku: "",
  submitError: null,
  formValues: {}
};

const mapStateToProps = state => {
  const userContext = getUserContext(state);
  const userAddress = getUserAddress(state);
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  const defaultSku =
    typeof order !== "undefined"
      ? getDefaultSku(order.Id, userContext.Unlimited)(state)
      : undefined;
  const promoValue = getPromoValue(state);
  return {
    promoCode: promoValue ? promoValue.code : undefined,
    sku: getSku(defaultSku)(state),
    dialogIsVisible: getDialogStep(state) === stripeStep, 
    submitError: getErrorMessage(state),
    formValues: getFormValues(FORM_NAME)(state),
    redirect: getRedirect(state),
    paymentInfo: getPaymentInfo(state),
    loading: getLoading(state),
    addressToCopy: userAddress
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  //nextDialog: () => dispatch(setPaymentDialogCreator(notStartedStep)), //they are the same because the thank you action is not integrated
  cancelDialog: () => dispatch(setPaymentDialogCreator(notStartedStep)),
  submitCreditCard: payload =>
    dispatch(makePaymentCreator(props.stripe, payload)),
  submitOrder: orderId => dispatch(submitOrder(orderId)),
  changeFields: bindActionCreators({change}, dispatch)?.change
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    touchOnBlur: false,
    initialValues: {
      CcHolderCountry: "US",
      CcHolderState: null,
      CcHolderZip: ""
    }
  })(PaymentModalWithoutStripe)
);
