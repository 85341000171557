import * as actionTypes from "./forgotPin.actionTypes";
import doAsync from "../../modules/doAsync";

export const forgotPin = (email, ein) =>
  doAsync({
    actionType: actionTypes.FORGOT_PIN_ASYNC,
    url: "account/recover-password",
    httpConfig: {
      body: JSON.stringify({
        Email: email,
        EIN: ein.replace(/[-]/g, "")
      }),
      method: "post"
    },
    httpMethod: "post",
    mapResponseToPayload: r => r,
    noBusySpinner: true
  });

export const resetError = () => ({
  type: actionTypes.RESET_ERROR
});
