import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SupportHero from "./SupportHero";

class SupportHeroContainer extends Component {
  handleClickCall = () => {
    window.location.href = "tel:+8664442290";
  };

  handleClickContactForm = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/contact");
  };

  handleClickEmail = () => {
    window.location.href = "mailto:support@i2290.com";
  };

  render() {
    const { handleClickCall, handleClickContactForm, handleClickEmail } = this;

    return (
      <SupportHero
        onClickCall={handleClickCall}
        onClickContactForm={handleClickContactForm}
        onClickEmail={handleClickEmail}
      />
    );
  }
}

export default withRouter(SupportHeroContainer);
