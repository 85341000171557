import * as actionTypes from "./form.actionTypes";

export const toggleIsExpanded = id => ({
  type: actionTypes.TOGGLE_IS_EXPANDED,
  payload: id
});

export const changeCurrentPage = (id, pageNumber, sliceName) => ({
  type: actionTypes.CHANGE_CURRENT_PAGE,
  payload: {
    id,
    pageNumber,
    sliceName
  }
});

export const changePageSize = (id, pageSize, sliceName) => ({
  type: actionTypes.CHANGE_PAGE_SIZE,
  payload: {
    id,
    pageSize,
    sliceName
  }
});
