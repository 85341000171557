import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";

import { STATE_NAME } from "./cart.constants";

export const TOGGLE_WIDGET_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "TOGGLE_WIDGET_IS_EXPANDED"
);

export const CHANGE_PROMO_CODE = buildActionType(
  STATE_NAME,
  "CHANGE_PROMO_CODE"
);

export const CHANGE_IS_UNLIMITED = buildActionType(
  STATE_NAME,
  "CHANGE_IS_UNLIMITED"
);

export const TOGGLE_IS_UNLIMITED = buildActionType(
  STATE_NAME,
  "TOGGLE_IS_UNLIMITED"
);

export const FETCH_PRODUCTS_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_PRODUCTS_ASYNC"
);

export const FETCH_PROMO = buildAsyncActionType(STATE_NAME, "FETCH_PROMO");

export const CLEAR_PROMO = buildActionType(STATE_NAME, "CLEAR_PROMO");
