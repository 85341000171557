import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./wizardController.css";

const {
  components: { Button }
} = controls;

const WizardController = ({
  onNextClick,
  onBackClick,
  backButtonIsDisabled,
  step
}) => (
  <div className="wizard-ctrl">
    <Button
      icon="arrow-chevron-left"
      onClick={onBackClick}
      primary={false}
      text="Back"
      disabled={backButtonIsDisabled}
      type="button"
      width="100px"
    />
    <Button
      icon="arrow-chevron-right"
      className="next-button"
      textClass="next"
      onClick={onNextClick}
      primary
      text={step === 4 ? "Review" : "Next"}
      disabled={false}
      type="button"
      width="100px"
    />
  </div>
);

WizardController.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  backButtonIsDisabled: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired
};

export default WizardController;
