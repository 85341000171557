import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import userContext from "../../../modules/userContext";
import order from "../../../modules/order";
import utilities from "../../../modules/utilities";
import NewUserWizard from "./NewUserWizard";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";
import {
  getFormEntities,
  getOrderHOF,
  getRedirect,
  getVehicleEntities
} from "../../../modules/order/order.selectors";
import { Redirect } from "react-router-dom";

const { getOrderIdFromUrl } = utilities;

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

const {
  actions: { fetchOrders: fetchOrdersCreator }
} = order;

class NewUserWizardContainer extends Component {
  componentDidMount() {
    const { fetchUser, fetchOrders } = this.props;
    fetchUser();
    if (!getOrderIdFromUrl()) {
      fetchOrders();
    }
  }

  render() {
    return (
      <>
        {this.props.redirect && <Redirect to={this.props.redirect} />}
        <NewUserWizard match={this.props.match} />
      </>
    );
  }
}

NewUserWizardContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();

  const order = getOrderHOF(orderId)(state);
  const orderAlreadySubmitted =
    order && order.Forms.length && order.OrderStatus === "10";

  return {
    redirect: orderAlreadySubmitted ? ORDER_HISTORY_ROUTE : getRedirect(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator()),
  fetchOrders: () => dispatch(fetchOrdersCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserWizardContainer);
