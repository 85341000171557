import React from "react";
import BusyIndicator from "./BusyIndicatorContainer";
import "./globalBusyIndicator.css";

const GlobalBusyIndicator = ({ spinnerSize, errorText }) => (
  <div className="i2290-global-busy">
    {errorText !== null && (
      <span className="i2290-inline-error">{`Error: ${errorText}`}</span>
    )}
    <div style={{ marginLeft: "15px" }}>
      <BusyIndicator size={spinnerSize} />
    </div>
  </div>
);

export default GlobalBusyIndicator;
