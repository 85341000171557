import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs/dist/es/Dialog";
import { Form } from "redux-form";
import "./paymentModal.css";
import controls from "../../controls";
import cart from "../../cart";
import CcPaymentForm from "./ccPaymentForm";

const {
  components: { CartContentsContainer: CartContents },
} = cart;

const {
  components: { Button },
} = controls;

const PaymentModal = ({
  handleSubmit,
  dialogIsVisible,
  cancelPayment,
  submitError,
  total,
  submitButtonIsDisabled,
  noCartBalance,
  onCopyAddress,
}) => (
  <div>
    {dialogIsVisible && (
      <Form onSubmit={handleSubmit}>
        <Dialog className="payment-modal" onClose={cancelPayment}>
          {!noCartBalance && (
            <CcPaymentForm {...{ submitError, total, onCopyAddress }}>
              <CartContents></CartContents>
            </CcPaymentForm>
          )}
          {noCartBalance && <CartContents></CartContents>}
          <div className="row mt-gutter">
            <div className="col clean-gutter-right">
              <Button
                primary={false}
                disabled={false}
                text="Cancel"
                onClick={cancelPayment}
                width="100%"
              />
            </div>
            <div className="col clean-gutter-left">
              <Button
                type="submit"
                primary
                disabled={submitButtonIsDisabled}
                text="Submit to IRS"
                width="100%"
              />
            </div>
          </div>
        </Dialog>
      </Form>
    )}
  </div>
);

PaymentModal.propTypes = {
  submitButtonIsDisabled: PropTypes.bool.isRequired,
  dialogIsVisible: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onCopyAddress: PropTypes.func.isRequired,
};

export default PaymentModal;
