import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormWidgetRepeater from "./FormWidgetRepeater";
import { getFormOrder, getEntities } from "../form.selectors";
import { FORM_TYPE_8849 } from "../../constants";
import { getOrderVehicles } from "../../order/order.selectorsv2";

class FormWidgetRepeaterContainer extends Component {
  render() {
    const {
      props: { formIds, noCredits }
    } = this;
    return <FormWidgetRepeater formIds={formIds} noCredits={noCredits} />;
  }
}

FormWidgetRepeaterContainer.propTypes = {
  formIds: PropTypes.array,
  noCredits: PropTypes.bool
};

FormWidgetRepeaterContainer.defaultProps = {
  formIds: [],
  noCredits: false
};

const mapStateToProps = state => {
  const forms = getEntities(state);
  const formOrder = getFormOrder(state);
  const noCredits = getOrderVehicles(state, x => !x.IsTaxable).length === 0;
  return {
    formIds: formOrder.filter(id => forms[id].FormType !== FORM_TYPE_8849),
    noCredits
  };
};

export default connect(mapStateToProps)(FormWidgetRepeaterContainer);
