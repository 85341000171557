import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VehiclesAnimator from "./VehiclesAnimator";
import { getTaxableVehiclesShowImporter } from "../vehicles.selectors";
import { TAXABLE_VEHICLES_SLICE_NAME } from "../vehicles.constants";
import {
  toggleShowImporter as toggleShowImporterCreator,
  setShowImporter as setShowImporterCreator
} from "../vehicles.actions";

class VehiclesAnimatorContainer extends Component {
  componentWillUnmount() {
    const {
      props: { setShowImporter }
    } = this;
    setShowImporter(false);
  }

  render() {
    const { showImporter, toggleShowImporter } = this.props;
    return (
      <VehiclesAnimator
        showImporter={showImporter}
        toggleShowImporter={toggleShowImporter}
      />
    );
  }
}

VehiclesAnimatorContainer.propTypes = {
  showImporter: PropTypes.bool.isRequired,
  toggleShowImporter: PropTypes.func.isRequired,
  setShowImporter: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  showImporter: getTaxableVehiclesShowImporter(state)
});

const mapDispatchToProps = dispatch => ({
  toggleShowImporter: () =>
    dispatch(toggleShowImporterCreator(TAXABLE_VEHICLES_SLICE_NAME)),
  setShowImporter: showImporter =>
    dispatch(setShowImporterCreator(TAXABLE_VEHICLES_SLICE_NAME, showImporter))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehiclesAnimatorContainer)
);
