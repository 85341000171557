import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReceiptModal from "./ReceiptModal";
import order from "../../../modules/order";
import controls from "../../../modules/controls";

const {
  selectors: { getReceiptModalEmail },
  actions: {
    setActiveReceiptModal: setActiveReceiptModalCreator,
    changeReceiptModalEmail: changeReceiptModalEmailCreator,
    sendReceiptEmail: sendReceiptEmailCreator
  }
} = order;

const {
  validations: { validateEmail }
} = controls;

class ReceiptModalContainer extends Component {
  state = {
    touched: false,
    error: null
  };

  handleClose = () => {
    const {
      props: { setActiveReceiptModal }
    } = this;
    setActiveReceiptModal(null);
    this.setState({ touched: false, error: null });
  };

  handleClickSendReceipt = () => {
    const {
      props: { receiptModalEmail, sendReceiptEmail, orderId }
    } = this;
    const error = validateEmail(receiptModalEmail);
    if (error) {
      this.setState({ touched: true, error: error || null });
    } else {
      sendReceiptEmail(orderId, receiptModalEmail);
    }
  };

  handleChangeEmail = ({ target: { value } }) => {
    const {
      props: { changeReceiptModalEmail },
      state: { touched }
    } = this;
    changeReceiptModalEmail(value);
    if (touched) {
      const error = validateEmail(value);
      this.setState({ error: error || null });
    }
  };

  render() {
    const {
      handleClose,
      handleClickSendReceipt,
      handleChangeEmail,
      props: { orderId, receiptModalEmail },
      state: { error }
    } = this;
    return (
      <ReceiptModal
        error={error}
        orderId={orderId}
        onClose={handleClose}
        onClickSendReceipt={handleClickSendReceipt}
        onChangeEmail={handleChangeEmail}
        isVisible={orderId !== null}
        emailValue={receiptModalEmail}
      />
    );
  }
}

ReceiptModalContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
  setActiveReceiptModal: PropTypes.func.isRequired,
  receiptModalEmail: PropTypes.string.isRequired,
  changeReceiptModalEmail: PropTypes.func.isRequired,
  sendReceiptEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  receiptModalEmail: getReceiptModalEmail(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveReceiptModal: orderId =>
    dispatch(setActiveReceiptModalCreator(orderId)),
  changeReceiptModalEmail: val => dispatch(changeReceiptModalEmailCreator(val)),
  sendReceiptEmail: (orderId, email) =>
    dispatch(sendReceiptEmailCreator(orderId, email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptModalContainer);
