import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { UNLOCK_REQUEST_FORM_NAME } from "../supportCenter.constants";
import userContext from "../../../modules/userContext";
import UnlockRequestForm from "./UnlockRequestForm";
import { mapSupportFieldsToPayload } from "../supportCenter.utilities";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import { UNLOCK_REQUEST_SUBJECT } from "../../../modules/constants";

const {
  selectors: { getUserContext }
} = userContext;

class UnlockRequestFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { postSupportRequest }
    } = this;

    const payload = mapSupportFieldsToPayload(formValues);

    payload.Subject = UNLOCK_REQUEST_SUBJECT;

    postSupportRequest(payload);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const { handleSubmit, handleClickCancel } = this;
    return (
      <UnlockRequestForm
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValues: getUserContext(state)
});

const mapDispatchToProps = dispatch => ({
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: UNLOCK_REQUEST_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(UnlockRequestFormContainer)
  )
);
