import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import userContext from "../../../modules/userContext";
import {
  ORDER_HISTORY_ROUTE,
  PRIVACY_POLICY_URI,
  TERMS_AND_CONDITIONS_URI
} from "../../../modules/constants";
import SignIn from "./SignIn";
import { getSignInFormValues } from "../signIn.selectors";
import { authenticationError } from "../../../modules/userContext/userContext.selectors";
import busyIndicator from "../../../modules/busyIndicator";

const {
  selectors: { isBusy: isBusySelector }
} = busyIndicator;

const {
  selectors: { isAuthenticated, getError, getSuccess },
  actions: { resetError: resetErrorCreator, signIn: signInCreator }
} = userContext;

class LoginContainer extends React.Component {
  handleReduxFormSubmit = values => {
    if (this.props.isBusy) return;
    this.props.signIn(values.username.trim(), values.pin);
  };

  handleForgotPasswordClick = () => {
    this.props.history.push("/forgot-pin");
  };

  redirectToForgotPin = () => this.props.history.push("/forgot-pin");

  handleSignupClick = () => {
    const { resetError, submitError, history } = this.props;
    if (submitError && submitError.Error === "EinFound") {
      resetError();
    }
    history.push("/register");
  };

  handleClickPrivacyPolicy = () => window.open(PRIVACY_POLICY_URI, "_blank");

  handleClickTermsConditions = () =>
    window.open(TERMS_AND_CONDITIONS_URI, "_blank");

  render() {
    const { from } = this.props.location.state || {
      from: { pathname: ORDER_HISTORY_ROUTE }
    };
    const {
      isAuthenticated,
      authenticationError,
      submitError,
      submitSuccess,
      isBusy,
      resetError
    } = this.props;

    return (
      <SignIn
        onClickTermsConditions={this.handleClickTermsConditions}
        onClickPrivacyPolicy={this.handleClickPrivacyPolicy}
        handleReduxFormSubmit={this.handleReduxFormSubmit}
        isAuthenticated={isAuthenticated}
        authenticationError={authenticationError}
        submitError={submitError}
        submitSuccess={submitSuccess}
        from={from}
        isBusy={isBusy}
        getError={getError}
        redirectToForgotPin={this.redirectToForgotPin}
        handleSignupClick={this.handleSignupClick}
        handleForgotPasswordClick={this.handleForgotPasswordClick}
        resetError={resetError}
      />
    );
  }
}

LoginContainer.propTypes = {
  resetError: PropTypes.func.isRequired,
  submitError: PropTypes.object
};

LoginContainer.defaultProps = {
  submitError: null
};

const mapDispatchToProps = dispatch => ({
  signIn: (username, pin) => dispatch(signInCreator(username, pin)),
  resetError: () => dispatch(resetErrorCreator())
});

const mapStateToProps = state => ({
  signInFormValues: getSignInFormValues(state),
  isAuthenticated: isAuthenticated(state),
  authenticationError: authenticationError(state),
  submitError: getError(state),
  submitSuccess: getSuccess(state),
  isBusy: isBusySelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
