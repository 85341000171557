import React, { Component } from "react";
import PropTypes from "prop-types";
import { change as changeCreator } from "redux-form";
import { connect } from "react-redux";
import ComboBoxField from "./ComboBoxField";

class ComboBoxFieldContainer extends Component {
  handleChange = () => {
    const { change, formName } = this.props;
    change(formName, "IncreasedWeightCategory", "");
    change(formName, "IncreasedDateCategory", "");
  };

  render() {
    const { name, label, validations, onBlur, data, placeholder } = this.props;
    return (
      <ComboBoxField
        placeholder={placeholder}
        name={name}
        label={label}
        onChange={this.handleChange}
        validations={validations}
        onBlur={onBlur}
        data={data}
      />
    );
  }
}

ComboBoxFieldContainer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  onBlur: PropTypes.func,
  placeholder: PropTypes.string
};

ComboBoxFieldContainer.defaultProps = {
  validations: [],
  onBlur: null,
  placeholder: null
};

const mapDispatchToProps = dispatch => ({
  change: (formName, fieldName, value) =>
    dispatch(changeCreator(formName, fieldName, value))
});

export default connect(null, mapDispatchToProps)(ComboBoxFieldContainer);
