import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SupportCenterScreen from "./SupportCenterScreen";
import userContext from "../../../modules/userContext";
import { getCurrentTaxYear } from "../../../modules/order/order.selectors";

const {
  selectors: { getUserContext },
  actions: { fetchUser: fetchUserCreator }
} = userContext;

class SupportCenterScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchUser }
    } = this;
    fetchUser();
  }

  handleClick2290 = () => {
    window.open("https://www.irs.gov/pub/irs-pdf/f2290.pdf", "_blank");
  };

  handleClick2290Instructions = () => {
    window.open("https://www.irs.gov/pub/irs-pdf/i2290.pdf", "_blank");
  };

  handleClick4900 = () => {
    window.open("https://www.irs.gov/pub/irs-pdf/p4900.pdf", "_blank");
  };

  handleClickAbout2290 = () => {
    window.open("https://www.irs.gov/uac/about-form-2290", "_blank");
  };

  handleClickBulkPricing = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/bulk-pricing");
  };

  handleClickEinApplication = () => {
    window.open(
      "https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online",
      "_blank"
    );
  };

  handleClickFaqForTruckers = () => {
    window.open(
      "https://www.irs.gov/businesses/small-businesses-self-employed/faqs-for-truckers-who-e-file",
      "_blank"
    );
  };

  handleClickIrsRejectedReturn = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/irs-rejected");
  };

  handleClickMailScheduleOne = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/schedule-1");
  };

  handleClickNoticeOrLetter = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/notice");
  };

  handleClickTruckingTaxCenter = () => {
    window.open(
      "https://www.irs.gov/businesses/small-businesses-self-employed/trucking-tax-center",
      "_blank"
    );
  };

  handleClickUnlockRequest = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/unlock");
  };

  handleClickVinCorrection = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/vin-correction");
  };

  handleClickFileByPhone = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/file-by-phone");
  };

  render() {
    const {
      handleClick2290,
      handleClick2290Instructions,
      handleClick4900,
      handleClickAbout2290,
      handleClickBulkPricing,
      handleClickEinApplication,
      handleClickFaqForTruckers,
      handleClickIrsRejectedReturn,
      handleClickMailScheduleOne,
      handleClickNoticeOrLetter,
      handleClickTruckingTaxCenter,
      handleClickUnlockRequest,
      handleClickVinCorrection,
      handleClickFileByPhone,
      props: { user, currentTaxYear }
    } = this;

    let isLoggedIn = false;
    if (user && user.Id) isLoggedIn = true;

    return (
      <SupportCenterScreen
        currentTaxYear={currentTaxYear}
        isLoggedIn={isLoggedIn}
        onClick2290={handleClick2290}
        onClick2290Instructions={handleClick2290Instructions}
        onClick4900={handleClick4900}
        onClickAbout2290={handleClickAbout2290}
        onClickBulkPricing={handleClickBulkPricing}
        onClickEinApplication={handleClickEinApplication}
        onClickFaqForTruckers={handleClickFaqForTruckers}
        onClickIrsRejectedReturn={handleClickIrsRejectedReturn}
        onClickMailScheduleOne={handleClickMailScheduleOne}
        onClickNoticeOrLetter={handleClickNoticeOrLetter}
        onClickTruckingTaxCenter={handleClickTruckingTaxCenter}
        onClickUnlockRequest={handleClickUnlockRequest}
        onClickVinCorrection={handleClickVinCorrection}
        onClickFileByPhone={handleClickFileByPhone}
      />
    );
  }
}

SupportCenterScreenContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  user: PropTypes.object
};

SupportCenterScreenContainer.defaultProps = {
  user: {}
};

const mapStateToProps = state => ({
  user: getUserContext(state),
  currentTaxYear: getCurrentTaxYear(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SupportCenterScreenContainer)
);
