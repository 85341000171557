import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isInvalid } from "redux-form";
import userContext from "../../userContext";
import order from "../../order";
import utilities from "../../utilities";
import CompanyInfoWidgetBody from "./CompanyInfoWidgetBody";

const {
  selectors: { getCompanyInfoWidgetIsExpanded },
  actions: {
    fetchUser: fetchUserCreator,
    setCompanyInfoWidgetIsExpanded: setCompanyInfoWidgetIsExpandedCreator
  }
} = userContext;

const {
  selectors: { getOrderHOF }
} = order;

const { getOrderIdFromUrl } = utilities;

class CompanyInfoWidgetBodyContainer extends Component {
  componentDidMount() {
    const { fetchUser, userContextExists } = this.props;
    if (!userContextExists) fetchUser();
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        companyInfoWidgetIsExpanded,
        setCompanyInfoWidgetIsExpanded,
        isInvalid
      }
    } = this;
    if (!prevProps.isInvalid && isInvalid && !companyInfoWidgetIsExpanded) {
      setCompanyInfoWidgetIsExpanded(true);
    }
  }

  render() {
    return <CompanyInfoWidgetBody />;
  }
}

CompanyInfoWidgetBodyContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  companyInfoWidgetIsExpanded: PropTypes.bool.isRequired,
  setCompanyInfoWidgetIsExpanded: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const order = getOrderHOF(orderId)(state);

  let signerFormIsInvalid = false;
  let contactFormIsInvalid = false;
  let companyFormIsInvalid = false;

  if (order) {
    signerFormIsInvalid = isInvalid("signerSubmission")(state);
    contactFormIsInvalid = isInvalid("contactSubmission")(state);
    companyFormIsInvalid = isInvalid("companyInfoSubmission")(state);
  }

  return {
    companyInfoWidgetIsExpanded: getCompanyInfoWidgetIsExpanded(state),
    isInvalid:
      signerFormIsInvalid || contactFormIsInvalid || companyFormIsInvalid
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator()),
  setCompanyInfoWidgetIsExpanded: isExpanded =>
    dispatch(setCompanyInfoWidgetIsExpandedCreator(isExpanded))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyInfoWidgetBodyContainer);
