import React, { Component } from "react";
import PropTypes from "prop-types";
import { getFormValues } from "redux-form";
import { connect } from "react-redux";
import StateDropdown from "./StateDropdown";

class StateDropdownContainer extends Component {
  render() {
    const {
      name,
      label,
      formValues,
      countryFieldName,
      validations,
      onBlur
    } = this.props;
    const country = formValues[countryFieldName];
    return (
      <StateDropdown
        name={name}
        label={label}
        country={country}
        validations={validations}
        onBlur={onBlur}
      />
    );
  }
}

StateDropdownContainer.propTypes = {
  formValues: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired, // eslint-disable-line
  countryFieldName: PropTypes.string.isRequired,
  validations: PropTypes.array,
  onBlur: PropTypes.func
};

StateDropdownContainer.defaultProps = {
  formValues: {},
  validations: null,
  onBlur: null
};

const mapStateToProps = (state, { formName }) => ({
  formValues: getFormValues(formName)(state)
});

export default connect(mapStateToProps)(StateDropdownContainer);
