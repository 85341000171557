import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormWidgetHeader from "./FormWidgetHeader";
import { toggleIsExpanded } from "../form.actions";
import { getWidgetIsExpandedHof, getEntities } from "../form.selectors";
import utilities from "../../utilities";
import order from "../../order";
import { FORM_TYPE_8849 } from "../../constants";

const {
  getOrderIdFromUrl,
  lookups: { lookupMonth }
} = utilities;

const {
  selectors: { getOrder }
} = order;

class FormWidgetHeaderContainer extends Component {
  render() {
    const {
      props: { isExpanded, toggleIsExpanded, form, formIds, formId }
    } = this;
    const month = lookupMonth(form.DateCategory);
    const { TotalTax: totalTax, TotalCredit: totalCredit } = form;
    const netTaxDue = totalTax - totalCredit;
    return (
      <FormWidgetHeader
        position={formIds.indexOf(formId) + 1}
        total={formIds.length}
        month={month}
        netTaxDue={netTaxDue}
        isExpanded={isExpanded}
        toggleIsExpanded={toggleIsExpanded}
      />
    );
  }
}

FormWidgetHeaderContainer.propTypes = {
  formId: PropTypes.number.isRequired, // eslint-disable-line
  toggleIsExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const forms = getEntities(state);
  const form = forms[props.formId];
  const order = getOrder(getOrderIdFromUrl(), state);
  return {
    isExpanded: getWidgetIsExpandedHof(props.formId)(state),
    form,
    formIds: order
      ? [...order.Forms]
          .filter(id => forms[id].FormType !== FORM_TYPE_8849)
          .sort((x, y) => forms[y].DateCategory - forms[x].DateCategory)
      : []
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  toggleIsExpanded: () => dispatch(toggleIsExpanded(props.formId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormWidgetHeaderContainer);
