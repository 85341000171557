import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import order from "../../order";
import utilities from "../../utilities";

const {
  selectors: { getOrderHOF }
} = order;

const {
  getOrderIdFromUrl,
  math: { fastFormatMoney }
} = utilities;

const monthLookup = {
  12: "July",
  11: "August",
  10: "September",
  9: "October",
  8: "November",
  7: "December",
  6: "January",
  5: "February",
  4: "March",
  3: "April",
  2: "May",
  1: "June"
};

// const weightLookup = {
//   A: "A - 55,000 lbs.",
//   B: "B - 55,001 - 56,000 lbs.",
//   C: "C - 56,001 - 57,000 lbs.",
//   D: "D - 57,001 - 58,000 lbs.",
//   E: "E - 58,001 - 59,000 lbs.",
//   F: "F - 59,001 - 60,000 lbs.",
//   G: "G - 60,001 - 61,000 lbs.",
//   H: "H - 61,001 - 62,000 lbs.",
//   I: "I - 62,001 - 63,000 lbs.",
//   J: "J - 63,001 - 64,000 lbs.",
//   K: "K - 64,001 - 65,000 lbs.",
//   L: "L - 65,001 - 66,000 lbs.",
//   M: "M - 66,001 - 67,000 lbs.",
//   N: "N - 67,001 - 68,000 lbs.",
//   O: "O - 68,001 - 69,000 lbs.",
//   P: "P - 69,001 - 70,000 lbs.",
//   Q: "Q - 70,001 - 71,000 lbs.",
//   R: "R - 71,001 - 72,000 lbs.",
//   S: "S - 72,001 - 73,000 lbs.",
//   T: "T - 73,001 - 74,000 lbs.",
//   U: "U - 74,001 - 75,000 lbs.",
//   V: "Over 75,000 lbs."
// };

const transformField = (field, val, taxYear, dataItem) => {
  switch (field) {
    case "Suspend":
    case "Logging":
    case "Agricultural":
      return val ? "Yes" : "No";
    case "DateCategory":
      return `${monthLookup[val]} ${
        val < 7 ? parseInt(taxYear, 10) + 1 : taxYear
      }`;
    case "WeightCategory":
      return val;
    case "IncreasedWeightCategory":
      return `${dataItem.WeightDescription}`;
    case "CreditEventDate": {
      return val ? val.toLocaleDateString("en-US") : "N/A";
    }
    case "CreditAmount":
      return `${val === null ? "$-" : "-" + fastFormatMoney(val)}`;
    case "TaxValue":
    case "IncreasedTaxValue":
      return `${val === null ? "$-" : fastFormatMoney(val)}`;
    case "WeightIncreaseTaxDifference":
      return `${
        dataItem.IncreasedTaxValue === null || dataItem.TaxValue === null
          ? "$-"
          : fastFormatMoney(dataItem.IncreasedTaxValue - dataItem.TaxValue)
      }`;
    default:
      return val;
  }
};

class VehicleGridReadOnlyCell extends React.Component {
  render() {
    const {
      props: { field }
    } = this;

    const {
      taxYear,
      dataIndex,
      rowType,
      columnsCount,
      columnIndex,
      selectionChange,
      dataItem,
      ...tdProps
    } = this.props;

    const dataValue = dataItem[field];
    if (field === "TaxValue" || field === "CreditAmount") {
      return (
        <td
          {...tdProps}
          expanded={this.props.expanded.toString()}
          dispatch={this.props.dispatch && this.props.dispatch.toString()}
          className={
            dataItem.errors
              ? "amount-cell grid-text-error-color"
              : "amount-cell grid-text-color"
          }
        >
          {dataValue === null || dataValue === undefined
            ? "$-"
            : transformField(field, dataValue, taxYear, dataItem)}
        </td>
      );
    }
    if (field === "WeightIncreaseTaxDifference") {
      return (
        <td
          {...tdProps}
          expanded={this.props.expanded.toString()}
          dispatch={this.props.dispatch && this.props.dispatch.toString()}
          className={
            dataItem.errors
              ? "amount-cell grid-text-error-color"
              : "amount-cell grid-text-color"
          }
        >
          {transformField(field, dataValue, taxYear, dataItem)}
        </td>
      );
    }

    return (
      <td
        {...tdProps}
        expanded={this.props.expanded.toString()}
        dispatch={this.props.dispatch && this.props.dispatch.toString()}
        className={
          dataItem.errors ? "grid-text-error-color" : "grid-text-color"
        }
      >
        {dataValue === null || dataValue === undefined
          ? "None"
          : transformField(field, dataValue, taxYear, dataItem)}
      </td>
    );
  }
}

VehicleGridReadOnlyCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  taxYear: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const order = getOrderHOF(getOrderIdFromUrl())(state);

  return {
    taxYear: order ? order.TaxYear : ""
  };
};

export default connect(mapStateToProps)(VehicleGridReadOnlyCell);
