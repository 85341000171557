import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignerView from "./SignerView";
import userContext from "../../userContext";
import controls from "../../controls";

const {
  selectors: { getUserContext }
} = userContext;

const {
  normalizations: { formatPhone }
} = controls;

class SignerViewContainer extends Component {
  render() {
    return (
      <React.Fragment>
        <SignerView {...this.props.initialValues} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { FirstName, Mi, LastName, OfficerTitle, Email, Tel1, Tel2 } = {
    ...(getUserContext(state) || {})
  };
  const initialValues = {
    FirstName: FirstName || "",
    Mi: Mi || "",
    LastName: LastName || "",
    OfficerTitle: OfficerTitle || "",
    Email: Email || "",
    Tel1: formatPhone(Tel1) || "",
    Tel2: formatPhone(Tel2) || ""
  };

  return {
    initialValues
  };
};

export default withRouter(connect(mapStateToProps, null)(SignerViewContainer));
