import React from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";

const AutoHeightAnimator = ({ duration, isExpanded, children }) => (
  <AnimateHeight duration={duration} height={isExpanded ? "auto" : 0}>
    {children}
  </AnimateHeight>
);

AutoHeightAnimator.propTypes = {
  duration: PropTypes.number,
  isExpanded: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

AutoHeightAnimator.defaultProps = {
  duration: 500
};

export default AutoHeightAnimator;
