import React, { Fragment } from "react";
import PropTypes from "prop-types";

const HideIfUnauthenticated = ({ isAuthenticated, children }) => (
  <Fragment>{isAuthenticated && children}</Fragment>
);

HideIfUnauthenticated.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default HideIfUnauthenticated;
