import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import "./designeeForm.css";

const {
  components: { TextField, PhoneField },
  normalizations: { removeSpaces, normalizePin }
} = controls;

const DesigneeForm = ({
  onBlur,
  nameValidations,
  phoneValidations,
  pinValidations
}) => (
  <div className="row">
    <div className="col-12 col-md-4">
      <TextField
        label="Full Name"
        name="ThirdPname"
        type="text"
        onBlur={onBlur}
        validations={nameValidations}
        normalize={removeSpaces}
      />
    </div>
    <div className="col-12 col-md-4">
      <PhoneField
        name="ThirdPphone"
        validations={phoneValidations}
        onBlur={onBlur}
      />
    </div>
    <div className="col-12 col-md-4">
      <TextField
        label="5-digit PIN"
        name="ThirdPpin"
        type="text"
        validations={pinValidations}
        normalize={normalizePin}
        onBlur={onBlur}
      />
    </div>
  </div>
);

DesigneeForm.propTypes = {
  onBlur: PropTypes.func,
  nameValidations: PropTypes.arrayOf(PropTypes.func),
  phoneValidations: PropTypes.arrayOf(PropTypes.func),
  pinValidations: PropTypes.arrayOf(PropTypes.func)
};

DesigneeForm.defaultProps = {
  onBlur: null,
  nameValidations: [],
  phoneValidations: [],
  pinValidations: []
};

export default DesigneeForm;
