import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import signer from "../../../modules/signer";
import contact from "../../../modules/contact";
import designee from "../../../modules/designee";
import WizardController from "./WizardControllerContainer";

// https://edotor.net/?engine=dot#%23%20Place%20the%20cursor%20inside%20%22graph%22%20to%20get%20some%20refactoring%20options%0A%2F*%0Agraph%20%7B%0A%0A%20%20%20%20%23%20To%20refactor%20nodes%2C%20place%20the%20cursor%20left%20to%20a%20node%20name%0A%20%20%20%20a%20--%20b%0A%20%20%20%20a%20--%20c%3B%0A%20%20%20%20a%20--%20d%3B%0A%0A%20%20%20%20%23%20Hover%20over%20color%20names%20to%20get%20a%20color%20picker%0A%20%20%20%20b%20--%20b%20%5Bcolor%3Dblue%5D%0A%20%20%20%20b%20--%20d%20%5Bcolor%3D%22%23ff0000%22%5D%3B%0A%0A%20%20%20%20%23%20Get%20completion%20when%20assigning%20a%20shape%20or%20color%0A%20%20%20%20b%20%5Bshape%3Dbox%2C%20color%3Dyellow%5D%3B%0A%0A%20%20%20%20a%3B%20%23%20You%20can%20remove%20optional%20%3B%20by%20placing%20the%20cursor%20left%20to%20a%20semicolon%0A%7D*%2F%0Agraph%20G%20%7B%0A%20%20%20%20rankdir%3DLR%3B%0A%0A%20%20%20%20DesigneeFormWidCnt%20--%20DesigneeFormWid%20%0A%20%20%20%20DesigneeFormWid%20--%20DesigneeForm%20%5Blabel%3D%22check%20%26%5Cnanimation%22%5D%0A%0A%20%20%20%20SigningContainer%20--%20Signer%20%5Blabel%3D%22first%20%26%20cpny%20names%5Cnfor%20display%20only%22%5D%0A%20%20%20%20Signer%20--%20SignerForm%0A%20%20%20%20Signer%20--%20ContactForm%0A%20%20%20%20Signer%20--%20DesigneeFormWid%0A%0A%20%20%20%20Signer%20%5Blabel%3D%22Signer%5Cn%3Cform%3E%22%5D%0A%20%20%20%20%0A%20%20%20%20SigningContainer%20%5Blabel%3D%22SigningContainer%5CnSubmission%22%5D%0A%7D

const {
  components: { SignerFormContainer: SignerForm }
} = signer;

const {
  components: { ContactFormContainer: ContactForm }
} = contact;

const {
  components: { DesigneeFormWidgetContainer: DesigneeFormWidget }
} = designee;

const Signer = ({
  firstName,
  companyName,
  thirdPyesNo,
  onSubmit,
  userContextExists
}) => (
  <Form
    className="container-fluid signer widget-background drop-shadow p-gutter mb-gutter rad-corners SignerForm"
    onSubmit={onSubmit}
  >
    <div className="new-user-header-cntr mb-gutter">
      {userContextExists ? (
        <React.Fragment>
          <h1 className="header-size">
            {firstName || "Hello"}, who signs IRS Tax Forms for{" "}
            {companyName || "this company"}?
          </h1>
        </React.Fragment>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
    <div className="new-user-inner-content">
      <div className="row">
        <div className="col-12 col-md-6 clean-gutter-md-right">
          <SignerForm />
        </div>
        <div className="col-12 col-md-6 clean-gutter-md-left">
          <ContactForm showPin={false} />
        </div>
      </div>
      <div className="row">
        <div id="designee" className="col-24 col-md-12">
          <DesigneeFormWidget isExpanded={thirdPyesNo} radCorners={false} />
        </div>
      </div>
    </div>
    <div className="new-user-ctrl-area new-user-inner-content">
      <WizardController />
    </div>
  </Form>
);

Signer.propTypes = {
  firstName: PropTypes.string,
  companyName: PropTypes.string,
  userContextExists: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

Signer.defaultProps = {
  firstName: null,
  companyName: null
};

export default Signer;
