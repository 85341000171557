import React from "react";
import PropTypes from "prop-types";
import { DropDownList } from "@progress/kendo-react-dropdowns";

class DropdownField extends React.Component {
  handleChange = e => {
    const { onChange, dataItem, field, realKey, changeCallback } = this.props;
    onChange({
      dataItem,
      field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value[realKey]
    });
    if (changeCallback && field !== "Agricultural")
      changeCallback({ ...dataItem, [field]: e.target.value[realKey] });
  };

  render() {
    const { displayKey } = this.props;
    const dataValue = this.props.dataItem[this.props.field];

    let error = null;
    if (
      this.props.dataItem.errors &&
      this.props.dataItem.errors[this.props.field]
    ) {
      error = this.props.dataItem.errors[this.props.field];
    }

    if (!this.props.dataItem.inEdit) {
      if (dataValue !== undefined && dataValue !== null) {
        const dropdownItem = this.props.localizedData.find(
          c => c[this.props.realKey] === dataValue
        );

        if (dropdownItem) {
          return (
            <div>
              {`${this.props.label}: `}
              {dropdownItem[displayKey]}
            </div>
          );
        }
        return <div>{`${this.props.label}: `}None</div>;
        // const displayVal = this.props.localizedData.find(
        //   c => c[this.props.realKey] === dataValue
        // )[displayKey];
        // return <td>{displayVal}</td>;
      }
      return <div>{`${this.props.label}: `}None</div>;
    }

    return (
      <div>
        <DropDownList
          defaultItem={{
            [this.props.displayKey]: "(none)",
            [this.props.realKey]: null
          }}
          label={this.props.label}
          style={{ width: "100%" }}
          onChange={this.handleChange}
          value={this.props.localizedData.find(
            c => c[this.props.realKey] === dataValue
          )}
          data={this.props.localizedData}
          textField={this.props.displayKey}
        />
        {error && <span className="i2290-inline-error">{error}</span>}
      </div>
    );
  }
}

DropdownField.propTypes = {
  changeCallback: PropTypes.func,
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  displayKey: PropTypes.string,
  realKey: PropTypes.string,
  localizedData: PropTypes.arrayOf(PropTypes.object).isRequired
};

DropdownField.defaultProps = {
  displayKey: "display",
  realKey: "real",
  changeCallback: null
};

export default DropdownField;
