import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import { TERMS_AND_CONDITIONS_URI, WEBSITE_URI } from "../../constants";
import "./disclaimer.css";

const {
  components: { Link }
} = controls;

const openWebsite = () => window.open(WEBSITE_URI, "_blank");
const openTermsAndConditions = () =>
  window.open(TERMS_AND_CONDITIONS_URI, "_blank");

export const Disclaimer = ({ pin }) => (
  <div className="disclaimer">
    <p>
      I am signing this Tax Return and Consent to Disclosure (below), using my
      Self-Selected PIN: {pin}
    </p>
    <h2>Perjury Statement</h2>
    <p>
      Under penalties of perjury, I declare that I have examined my electronic
      return with accompanying statements and to the best of my knowledge and
      belief, they are true, correct and complete.
    </p>
    <h2>Reporting Agent Consent to Disclosure</h2>
    <p>
      I consent to allow INSTANT-2990 to transmit the return and accompanying
      data to the IRS and to receive from the IRS (a) an acknowledgement of
      receipt or reason for rejection of the transmission, (b) the reason for
      any delay in processing the return, and (c) the electronic IRS-Stamped
      Schedule 1 if applicable, all of which are required for electronic returns
      processing.
    </p>
    <h2>Consent to Disclosure</h2>
    <p>
      I hereby consent to the Internal Revenue Service (IRS) disclosing
      information about my payment of the Heavy Highway Vehicle Use Tax (HVUT)
      for this tax return, to the federal Department of Transportation (DOT),
      U.S. Customs and Border Protection (CBP), and to state Departments of
      Motor Vehicles (DMV). The information disclosed to the DOT, CBP, and to
      the state DMVs will be my Vehicle Identification Number (VIN) and
      verification that I have paid the HVUT. The IRS may disclose the
      information to the DOT, CBP, and to the DMVs of the 50 states and the
      District of Columbia who have other taxing, registration, or information
      collecting authority. I agree that the American Association of Motor
      Vehicle Administrators (AAMVA), a third-party nonprofit organization, may
      be used as an intermediary to transmit my VIN and payment information from
      the IRS to the state DMVs. I understand that the information to be
      disclosed is generally confidential under the laws applicable to the IRS
      and that the agency receiving the HVUT information is not bound by these
      laws and may use the information for any purposes as permitted by other
      federal laws and/or state law. If signed by a corporate officer or party
      other than the taxpayer, I certify that I have the authority to execute
      this consent to disclosure of tax information.
    </p>
    <p>
      I have read and accept all{" "}
      <Link clickHandler={openTermsAndConditions} text="Terms and Conditions" />{" "}
      of the <Link clickHandler={openWebsite} text="i2290.com" /> website.
    </p>
  </div>
);

Disclaimer.propTypes = {
  pin: PropTypes.number
};

Disclaimer.defaultProps = {
  pin: null
};

export default Disclaimer;
