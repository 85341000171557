import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues, isInvalid } from "redux-form";
import PaymentMethodWidget from "./PaymentMethodWidget";
import {
  getPaymentMethodWidgetIsExpanded,
  getNetTax,
  getPaymentAgreementIsOpen,
  getOrderHOF
} from "../order.selectors";
import {
  // eslint-disable-next-line max-len
  togglePaymentMethodWidgetIsExpanded as togglePaymentMethodWidgetIsExpandedCreator,
  togglePaymentAgreementIsOpen as togglePaymentAgreementIsOpenCreator,
  setPaymentMethodWidgetIsExpanded as setPaymentMethodWidgetIsExpandedCreator
} from "../order.actions";
import utilities from "../../utilities";
import { PAYMENT_METHOD_FORM_NAME } from "../order.constants";

const { getOrderIdFromUrl } = utilities;

class PaymentMethodWidgetContainer extends Component {
  componentDidUpdate(prevProps) {
    const {
      props: {
        widgetIsExpanded,
        setPaymentMethodWidgetIsExpanded,
        paymentMethodFormIsInvalid
      }
    } = this;
    if (
      !prevProps.paymentMethodFormIsInvalid &&
      paymentMethodFormIsInvalid &&
      !widgetIsExpanded
    ) {
      setPaymentMethodWidgetIsExpanded(true);
    }
  }

  render() {
    const {
      props: {
        widgetIsExpanded,
        toggleIsExpanded,
        formValues,
        netTax,
        togglePaymentAgreementIsOpen,
        paymentAgreementIsOpen
      }
    } = this;
    return (
      <PaymentMethodWidget
        paymentAgreementIsVisible={paymentAgreementIsOpen}
        onClosePaymentAgreement={togglePaymentAgreementIsOpen}
        netTax={netTax}
        paymentMethod={
          formValues.PaymentAccount
            ? formValues.PaymentAccount.PaymentMethod
            : null
        }
        accountType={formValues.PaymentAccount ? 1 : null}
        accountNumber={
          formValues.PaymentAccount
            ? formValues.PaymentAccount.AccountNumber
            : null
        }
        toggleIsExpanded={toggleIsExpanded}
        isExpanded={widgetIsExpanded || netTax === 0}
      />
    );
  }
}

PaymentMethodWidgetContainer.propTypes = {
  widgetIsExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  netTax: PropTypes.number,
  paymentAgreementIsOpen: PropTypes.bool.isRequired,
  togglePaymentAgreementIsOpen: PropTypes.func.isRequired,
  paymentMethodFormIsInvalid: PropTypes.bool.isRequired,
  setPaymentMethodWidgetIsExpanded: PropTypes.func.isRequired
};

PaymentMethodWidgetContainer.defaultProps = {
  formValues: {},
  netTax: null
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const order = getOrderHOF(orderId)(state);
  return {
    widgetIsExpanded: getPaymentMethodWidgetIsExpanded(state),
    netTax: getNetTax(orderId)(state),
    formValues: getFormValues(PAYMENT_METHOD_FORM_NAME)(state),
    paymentAgreementIsOpen: getPaymentAgreementIsOpen(state),
    paymentMethodFormIsInvalid: order
      ? isInvalid("paymentMethod")(state)
      : false
  };
};

const mapDispatchToProps = dispatch => ({
  toggleIsExpanded: () =>
    dispatch(togglePaymentMethodWidgetIsExpandedCreator()),
  togglePaymentAgreementIsOpen: () =>
    dispatch(togglePaymentAgreementIsOpenCreator()),
  setPaymentMethodWidgetIsExpanded: isExpanded =>
    dispatch(setPaymentMethodWidgetIsExpandedCreator(isExpanded))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodWidgetContainer);
