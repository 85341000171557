import { isNumber, isObject } from "lodash";
import { getOrderIdFromUrl } from "../../modules/utilities/url";
import {
  getFormEntities,
  getOrderHOF,
  getVehicleEntities
} from "./order.selectors";
import {
  validateVehicleForm,
  validateCreditVehicleForm,
  hasErrors
} from "../../modules/controls/validation.js";

// this file was added to avoid circular references

export const getOrderAlt = (state, _order) => {
  var order = isObject(_order)
    ? _order
    : isNumber(_order)
    ? getOrderHOF(_order)(state)
    : getOrderIdFromUrl() === null
    ? null
    : getOrderHOF(getOrderIdFromUrl())(state);
  return order === null ? undefined : order;
};

export const getVehicleValidationCounts = (state, _order) => {
  const vehicles = getOrderVehicles(state, undefined, _order);

  const r = {
    vehiclesEntered: vehicles.filter(v => v.IsTaxable).length,
    vehicleErrorCount: vehicles.filter(
      v => v.IsTaxable && hasErrors(validateVehicleForm(v))
    ).length,
    creditVehicleErrorCount: vehicles.filter(
      v => !v.IsTaxable && hasErrors(validateCreditVehicleForm(v))
    ).length
  };
  return r;
};

export const getOrderVehicles = (state, _filter, _order) => {
  const order = getOrderAlt(state, _order);

  if (order === undefined) {
    return [];
  }

  const filter = _filter || (_ => true);
  const forms = getFormEntities(state);
  const vehicles = getVehicleEntities(state);
  const vehicleIds = order.Forms.flatMap(id => forms[id].Vehicles);
  return vehicleIds.map(id => vehicles[id]).filter(filter);
};
