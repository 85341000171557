import * as actionTypes from "./vehicles.actionTypes";
import doAsync from "../doAsync";
import order from "../order";
import utilities from "../utilities";

const {
  utilities: { normalizeOrder, cleanOrder }
} = order;

const {
  transform: { cleanVehicle }
} = utilities;

export const importVehicles = (orderId, vehicles) =>
  doAsync({
    actionType: actionTypes.IMPORT_VEHICLES_ASYNC,
    url: `orders/${orderId}/import-vehicles`,
    httpConfig: {
      body: JSON.stringify(vehicles),
      headers: {
        ['wait-lock-key']: '*'
      }
    },
    httpMethod: "post",
    mapResponseToPayload: r => normalizeOrder(cleanOrder(r))
  });

export const toggleShowImporter = sliceName => ({
  type: actionTypes.TOGGLE_SHOW_IMPORTER,
  payload: {
    sliceName
  }
});

export const setShowImporter = (sliceName, showImporter) => ({
  type: actionTypes.SET_SHOW_IMPORTER,
  payload: {
    sliceName,
    showImporter
  }
});

export const toggleAllAreStagedForDeletion = sliceName => ({
  type: actionTypes.TOGGLE_ALL_ARE_STAGED_FOR_DELETION,
  payload: {
    sliceName
  }
});

export const changeCurrentPage = (pageNumber, sliceName) => ({
  type: actionTypes.CHANGE_CURRENT_PAGE,
  payload: {
    pageNumber,
    sliceName
  }
});

export const changePageSize = (pageSize, sliceName) => ({
  type: actionTypes.CHANGE_PAGE_SIZE,
  payload: {
    pageSize,
    sliceName
  }
});

export const stageForDeletion = (id, sliceName) => ({
  type: actionTypes.STAGE_FOR_DELETION,
  payload: {
    id,
    sliceName
  }
});

export const stageVehicle = (data, sliceName) => ({
  type: actionTypes.STAGE_FOR_CREATION,
  payload: {
    data,
    sliceName
  }
});

export const updateTaxableVehicleStaging = (data, taxTable) => ({
  type: actionTypes.UPDATE_TAXABLE_VEHICLE_STAGING,
  payload: {
    data,
    taxTable
  }
});

export const updateCreditVehicleStaging = data => ({
  type: actionTypes.UPDATE_CREDIT_VEHICLE_STAGING,
  payload: {
    data
  }
});

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  redirect
});

export const revertChanges = id => ({
  type: actionTypes.REVERT_CHANGES,
  payload: id
});

export const updateWorkingCopy = (id, update) => ({
  type: actionTypes.UPDATE_WORKING_COPY,
  payload: {
    id,
    update
  }
});

export const postVehicle = (orderId, payload, sliceName) =>
  doAsync({
    actionType: actionTypes.POST_VEHICLE_ASYNC,
    url: `orders/${orderId}/vehicles`,
    httpConfig: {
      body: JSON.stringify(payload),
      headers: {
        ['wait-lock-key']: '*'
      }
    },
    httpMethod: "post",
    mapResponseToPayload: r => ({
      id: r.Id,
      data: cleanVehicle(r),
      sliceName
    })
  });

export const putVehicle = (orderId, vehicleId, payload) =>
  doAsync({
    actionType: actionTypes.PATCH_VEHICLE_ASYNC,
    url: `orders/${orderId}/vehicles/${vehicleId}`,
    httpConfig: {
      body: JSON.stringify(payload),
      headers: {
        ['wait-lock-key']: '*'
      }
    },
    httpMethod: "put",
    mapResponseToPayload: r => ({
      id: r.Id,
      data: cleanVehicle(r)
    })
  });

export const deleteVehicle = (orderId, vehicleId, sliceName) =>
  doAsync({
    actionType: actionTypes.DELETE_VEHICLE_ASYNC,
    url: `orders/${orderId}/vehicles/${vehicleId}`,
    httpMethod: "delete",
    httpConfig:{
      headers:{
        ['wait-lock-key']: '*'
      }
    },
    mapResponseToPayload: r => ({
      ...normalizeOrder(cleanOrder(r)),
      id: vehicleId,
      sliceName
    })
  });
