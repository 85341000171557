import React from "react";
import * as PropTypes from "prop-types";
import { Form } from "redux-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import AccountCircle from "@material-ui/icons/AttachMoney";
import InputAdornment from "@material-ui/core/InputAdornment";
import controls from "../../controls";
import {
  CREDIT_REASON_DESTROYED,
  CREDIT_REASON_SOLD,
  CREDIT_REASON_STOLEN,
  CREDIT_REASON_SUSPENDED
} from "../../constants";
import { weightData } from "../data";
import { fastFormatMoney } from "../../utilities/math";
import { CREDIT_VEHICLE_EDITOR_FORM_NAME } from "../vehicles.constants";
import ZipFieldContainer from "../../controls/components/ZipFieldContainer";
import CountryDropdown from "../../controls/components/CountryDropdown";
import StateDropdown from "../../controls/components/StateDropdown";
import { isEmpty } from "../../utilities/generic";
import { computeMaxCredit } from "../../utilities/taxYear";

const {
  components: { TextField, Select, Button, Checkbox, DatePicker },
  normalizations: {
    normalizeVIN,
    normalizeAddress,
    normalizeBuyerName,
    normalizeCurrency,
    formatCurrency
  }
} = controls;

const creditReasonData = [
  { display: "Sold", real: CREDIT_REASON_SOLD },
  { display: "Stolen", real: CREDIT_REASON_STOLEN },
  { display: "Destroyed", real: CREDIT_REASON_DESTROYED },
  { display: "Suspended", real: CREDIT_REASON_SUSPENDED }
];

const CreditVehicleEditor = ({
  onSubmit,
  onClickCancel,
  onClickDelete,
  onChange,
  onCreditAmountBlur,
  creditAmount,
  data,
  minCreditEventDate,
  maxCreditEventDate,
  isDeletable,
  onCloseCancelModal,
  cancelModalIsOpen,
  onConfirmCancelModal,
  taxTable
}) => {
  const inputRef = React.useRef();
  const [selectionStart, setSelectionStart] = React.useState();

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(selectionStart, selectionStart);
    }
  });

  const updateSelectionStart = () => {
    const oldCurrency = formatCurrency(data.CreditAmount);

    let inputValueToUse = inputRef.current.value;

    if (inputValueToUse === ".") {
      setSelectionStart(inputRef.current.selectionStart + 1);
      return;
    }

    if (inputValueToUse[inputValueToUse.length - 1] === ".") {
      inputValueToUse = inputValueToUse.slice(0, -1);
    }
    const newCurrency = formatCurrency(
      parseFloat(inputValueToUse.replace(/[,$]/g, ""))
    );

    const oldNumberOfCommas = (oldCurrency.match(/,/g) || []).length;

    const newNumberOfCommas = (newCurrency.match(/,/g) || []).length;

    let selectionStart;
    if (newNumberOfCommas > oldNumberOfCommas) {
      selectionStart = inputRef.current.selectionStart + 1;
    } else if (newNumberOfCommas < oldNumberOfCommas) {
      selectionStart = inputRef.current.selectionStart - 1;
    } else {
      // eslint-disable-next-line prefer-destructuring
      selectionStart = inputRef.current.selectionStart;
    }

    setSelectionStart(selectionStart);
  };

  const maxCreditAmount = computeMaxCredit(
    data.WeightCategory,
    data.Logging,
    taxTable
  );

  return (
    <div className="p-gutter pb-0">
      {cancelModalIsOpen && (
        <Dialog
          width="320px"
          title="Please confirm"
          onClose={onCloseCancelModal}
        >
          <React.Fragment>
            <p
              style={{
                margin: "25px",
                textAlign: "center"
              }}
            >
              Are you sure you want to discard the changes?
            </p>
            <div className="row">
              <div className="col clean-gutter-right">
                <Button
                  disabled={false}
                  text="No"
                  secondary
                  onClick={onCloseCancelModal}
                  width="100%"
                />
              </div>
              <div className="col clean-gutter-left">
                <Button
                  disabled={false}
                  text="Yes"
                  primary
                  onClick={onConfirmCancelModal}
                  width="100%"
                />
              </div>
            </div>
          </React.Fragment>
        </Dialog>
      )}
      <Form onSubmit={onSubmit} className="row">
        <div className="col-12 col-sm-6">
          <TextField
            label="VIN"
            name="Vin"
            type="text"
            normalize={normalizeVIN}
            onChange={onChange}
          />
        </div>
        <div className="col-12 col-sm-6">
          <Select
            name="WeightCategory"
            label="Gross Weight"
            onChange={onChange}
          >
            {[<option value={null} key="none-state" />].concat(
              weightData.map(x => (
                <option value={x.real} key={x.real}>
                  {x.display}
                </option>
              ))
            )}
          </Select>
        </div>
        <div className="col-12 col-sm-6">
          <Select
            name="CreditReason"
            label="Credit Reason"
            // validations={[validateDropdown]}
            onChange={onChange}
          >
            {[<option value={null} key="none-state" />].concat(
              creditReasonData.map(x => (
                <option value={x.real} key={x.real}>
                  {x.display}
                </option>
              ))
            )}
          </Select>
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <DatePicker
            label="Credit Date"
            name="CreditEventDate"
            minDate={minCreditEventDate}
            maxDate={maxCreditEventDate}
            onBlur={null}
            onAccept={v => {
              onChange({ target: { name: "CreditEventDate" } }, v);
            }}
          />
        </div>
        <div
          className={
            data.CreditReason === CREDIT_REASON_SUSPENDED
              ? "col-12 col-sm-6 mb-2 mb-sm-0 order-1 order-sm-2 order-md-1 col-md-3 edit-credits-logging-check-ctnr"
              : "col-12 col-sm-6 offset-sm-6 offset-md-0 col-md-3 edit-credits-logging-check-ctnr"
          }
        >
          <Checkbox name="Logging" label="Forestry" onChange={onChange} />
        </div>
        {data.CreditReason === CREDIT_REASON_SUSPENDED && (
          <React.Fragment>
            <div className="col-12 col-sm-6 order-1">
              <TextField
                type="text"
                name="CreditAmount"
                label="Credit Amount"
                onKeyDown={e => {
                  if (
                    !(e.keyCode >= 48 && e.keyCode <= 57) &&
                    !(e.keyCode >= 96 && e.keyCode <= 105) &&
                    !(e.keyCode >= 37 && e.keyCode <= 40) &&
                    e.keyCode !== 8 &&
                    e.keyCode !== 46 &&
                    e.keyCode !== 190 &&
                    e.keyCode !== 110 &&
                    e.keyCode !== 9
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e, v) => {
                  updateSelectionStart();
                  onChange(e, v);
                }}
                normalize={normalizeCurrency}
                format={formatCurrency}
                inputRef={inputRef}
                onBlur={onCreditAmountBlur}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                }
              />
              <p className="mb-gutter">Max Credit Amount: ${maxCreditAmount}</p>
            </div>
            <div className="w-100 order-3" />
          </React.Fragment>
        )}
        {data.CreditReason === CREDIT_REASON_SOLD && (
          <div className="col-12">
            <Checkbox
              name="IsBuyerBusiness"
              label="Sold to business?"
              onChange={onChange}
              color="primary"
            />
            <div className="row">
              <div className="col-12 col-sm-6 col-md-8">
                <TextField
                  type="text"
                  name="BuyerName"
                  label="Buyer Name"
                  normalize={normalizeBuyerName}
                  onChange={onChange}
                />
              </div>
              <div className="w-100" />
              <div className="col-12 col-sm-6 col-md-4">
                <TextField
                  type="text"
                  name="BuyerAddress1"
                  label="Address 1"
                  onChange={onChange}
                  normalize={normalizeAddress}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <TextField
                  type="text"
                  name="BuyerAddress2"
                  label="Address 2"
                  onChange={onChange}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <CountryDropdown
                  onCountryChange={onChange}
                  label="Country"
                  name="BuyerCountry"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <TextField
                  type="text"
                  name="BuyerCity"
                  label="City"
                  onChange={onChange}
                  normalize={normalizeAddress}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <StateDropdown
                  name="BuyerState"
                  country={data.BuyerCountry}
                  label="State"
                  onChange={onChange}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <ZipFieldContainer
                  name="BuyerZip"
                  formName={CREDIT_VEHICLE_EDITOR_FORM_NAME}
                  countryFieldName="BuyerCountry"
                  label="Zip"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
        {data.CreditReason !== CREDIT_REASON_SUSPENDED && (
          <div className="col-12 mb-gutter">
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              Credit Amount:{" "}
              {!isEmpty(creditAmount) ? fastFormatMoney(creditAmount) : "$-"}
            </span>
          </div>
        )}
        <div className="col order-last">
          <Button
            primary
            text="Save"
            onClick={onSubmit}
            disabled={false}
            className="mb-gutter mr-gutter"
          />
          <Button
            primary={false}
            text="Cancel"
            disabled={false}
            onClick={onClickCancel}
            className="mb-gutter"
          />
        </div>
        {isDeletable && (
          <div className="col-auto order-last">
            <Button
              primary={false}
              text="Delete"
              disabled={false}
              onClick={onClickDelete}
              className="mb-gutter"
            />
          </div>
        )}
      </Form>
    </div>
  );
};

CreditVehicleEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  creditAmount: PropTypes.number,
  minCreditEventDate: PropTypes.object.isRequired,
  maxCreditEventDate: PropTypes.object.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  cancelModalIsOpen: PropTypes.bool.isRequired,
  onCloseCancelModal: PropTypes.func.isRequired,
  onConfirmCancelModal: PropTypes.func.isRequired
};

export default CreditVehicleEditor;
