import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";
import { getAttachments } from "../supportCenter.selectors";
import { setAttachments } from "../supportCenter.actions";

const {
  components: { Button, TextField, UploaderContainer: Uploader }
} = controls;

const NoticeOrLetterForm = ({ onSubmit, onClickCancel, bodyValidations }) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">Tell us about your letter</h2>
    <section>
      <div className="row">
        <div className="col">
          <p>
            On the first page of your letter, in the top right corner, it says{" "}
            <strong>"NOTICE"</strong> followed by a few letters or numbers.
            Please enter that code plus any additional information here:
          </p>
          <TextField
            name="Body"
            type="text"
            multiline
            rows={8}
            validations={bodyValidations}
          />
        </div>
      </div>
    </section>
    <h2 className="underline-head">Attach a copy of the IRS Letter/Notice</h2>
    <section className="attachment-section">
      <Uploader
        getFiles={getAttachments}
        setFiles={setAttachments}
        fileLimit={5}
      />
    </section>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

NoticeOrLetterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  bodyValidations: PropTypes.arrayOf(PropTypes.func)
};

NoticeOrLetterForm.defaultProps = {
  bodyValidations: []
};

export default NoticeOrLetterForm;
