import React, { Component } from "react";
import PropTypes from "prop-types";
import { change as changeCreator } from "redux-form";
import { connect } from "react-redux";
import CountryDropdown from "./CountryDropdown";

class CountryDropdownContainer extends Component {
  handleCountryChange = () => {
    const { change, formName, stateFieldName, zipFieldName } = this.props;
    change(formName, stateFieldName, "");
    change(formName, zipFieldName, "");
  };

  render() {
    const { name, label, validations, onBlur } = this.props;
    return (
      <CountryDropdown
        name={name}
        label={label}
        onCountryChange={this.handleCountryChange}
        validations={validations}
        onBlur={onBlur}
      />
    );
  }
}

CountryDropdownContainer.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  stateFieldName: PropTypes.string.isRequired,
  zipFieldName: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  onBlur: PropTypes.func
};

CountryDropdownContainer.defaultProps = {
  validations: [],
  onBlur: null
};

const mapDispatchToProps = dispatch => ({
  change: (formName, fieldName, value) =>
    dispatch(changeCreator(formName, fieldName, value))
});

export default connect(null, mapDispatchToProps)(CountryDropdownContainer);
