import isString from "lodash/isString";
import { VEHICLES_STEP } from "../../../src/modules/constants";

export const getWizardStepFromUrl = () => {
  const urlParams = window.location.pathname;
  const step = urlParams.substring(urlParams.lastIndexOf("/") + 1);
  return step == null ? null : +step;
};


export const getOrderIdFromUrl = (comp) => {
  const orderId = comp?.props?.location?.state?.order;
  if (orderId)
    return orderId;  

  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("order") || null;
};

export const getOrderId = getOrderIdFromUrl;

export const getCreditsOrVehiclesFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cred = isString(urlParams.get("Credits"));
  return isString(urlParams.get("Credits"));
};

export const getFormIdFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("form") || null;
};

export const getVehiclesUrlFromId = orderId =>
  orderId
    ? `/wizard/${VEHICLES_STEP}?order=${orderId}`
    : `/wizard/${VEHICLES_STEP}?order=${getOrderIdFromUrl()}`;

export const getCreditsUrlFromId = orderId =>
  orderId
    ? `/wizard/${VEHICLES_STEP}?order=${orderId}&Credits`
    : `/wizard/${VEHICLES_STEP}?order=${getOrderIdFromUrl()}&Credits`;
