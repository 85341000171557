import React, { Component } from "react";
import MaterialSelect from "@material-ui/core/NativeSelect";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ReactDOM from "react-dom";
import "./select.css";
import PropTypes from "prop-types";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    this.setState({
      // new way of doing refs has a bug with material ui, have to do old way
      labelWidth: ReactDOM.findDOMNode(this.inputLabel).offsetWidth // eslint-disable-line react/no-find-dom-node
    });
  }

  render() {
    const {
      value,
      placeholder,
      label,
      name,
      children,
      validations,
      normalize,
      format,
      className,
      onChange,
      ...custom
    } = this.props;

    const {
      state: { labelWidth }
    } = this;
    const shouldShrink = !!value;
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel
          shrink={shouldShrink}
          ref={ref => {
            this.inputLabel = ref;
          }}
        >
          {label}
        </InputLabel>
        <MaterialSelect
          className={className}
          value={value}
          onChange={onChange}
          input={
            <OutlinedInput
              className="i2290-select-input"
              labelWidth={labelWidth}
              notched={shouldShrink}
            />
          }
          {...custom}
        >
          {children}
        </MaterialSelect>
      </FormControl>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func,
  className: PropTypes.string,
  needsTouch: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
  normalize: null,
  format: null,
  placeholder: "",
  validations: null,
  label: null,
  className: null,
  needsTouch: true
};

export default Select;
