import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "./wizardStepper.css";
import WizardStep from "./WizardStep";
import {
  STEP_IS_DISABLED,
  STEP_IS_INVALID,
  STEP_IS_VALID
} from "../../../modules/constants";

const WizardStepper = ({
  steps,
  isFormScreen,
  companyFormIsInvalid,
  signerFormIsInvalid,
  paymentMethodFormIsInvalid,
  vehiclesIsInvalid,
  vehiclesIsDisabled,
  getIsInProcess
}) => {
  const valid2 = [
    companyFormIsInvalid ? STEP_IS_INVALID : STEP_IS_VALID,
    vehiclesIsInvalid
      ? STEP_IS_INVALID
      : vehiclesIsDisabled
      ? STEP_IS_DISABLED
      : STEP_IS_VALID,
    signerFormIsInvalid ? STEP_IS_INVALID : STEP_IS_VALID,
    paymentMethodFormIsInvalid ? STEP_IS_INVALID : STEP_IS_VALID,
    ""
  ];

  return (
    <div
      className="wizard-stepper justify-content-md-between
      flex-sm-row flex-row flex-md-column"
    >
      {steps.map((s, i) => (
        <React.Fragment key={`fstep-${s}`}>
          <WizardStep
            isFormScreen={isFormScreen}
            stepNumber={i + 1}
            stepTitle={s}
            valid={valid2[i] || ""}
            key={`wstep-${s}`}
            inProcess={getIsInProcess(`/wizard/${i + 1}`)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

WizardStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  valid: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFormScreen: PropTypes.bool
};

WizardStepper.defaultProps = {
  isFormScreen: false
};

export default withRouter(WizardStepper);
