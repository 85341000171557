import React from "react";
// import PropTypes from "prop-types";
import WeightIncreaseForm from "./WeightIncreaseFormContainer";
import hoc from "../../hoc";

const {
  hof: { withAccountFrame }
} = hoc;

const WeightIncreaseScreen = () => (
  <div className="weight-increase-screen">
    <WeightIncreaseForm />
  </div>
);

WeightIncreaseScreen.propTypes = {};

WeightIncreaseScreen.defaultProps = {};

export default withAccountFrame(WeightIncreaseScreen);
