import React from "react";
import PropTypes from "prop-types";
import hoc from "../../hoc";
import OrderSummaryPanelRepeater from "./OrderSummaryPanelRepeaterContainer";
import ReceiptModal from "./ReceiptModalContainer";
import CurrentYearFiler from "../../dashboard/components/CurrentTaxYearFilerContainer";
import IrsNotRespAcknowledger from "./IrsNotRespAcknowledgerContainer";
import "./orderHistoryScreen.css";

const {
  hof: { withAccountFrame }
} = hoc;

const OrderHistoryScreen = ({
  activeReceiptModal,
  hasActiveOrders,
  hasOrderHistory,
  heroTaxYear
}) => {
  let screenMarkup = null;

  if (heroTaxYear && !hasOrderHistory) {
    screenMarkup = (
      <div className="col-12 col-lg-6 offset-lg-3">
        <CurrentYearFiler />
      </div>
    );
  } else if (heroTaxYear && hasOrderHistory) {
    screenMarkup = (
      <React.Fragment>
        <div className="col-12 col-lg-6 clean-gutter-lg-right mb-gutter">
          <CurrentYearFiler />
        </div>
        <div className="col-12 col-lg-6 clean-gutter-lg-left">
          <OrderSummaryPanelRepeater isActive={false} />
        </div>
      </React.Fragment>
    );
  } else if (hasActiveOrders && hasOrderHistory) {
    screenMarkup = (
      <React.Fragment>
        <div className="col-12 col-lg-6 clean-gutter-lg-right">
          <OrderSummaryPanelRepeater isActive />
        </div>
        <div className="col-12 col-lg-6 clean-gutter-lg-left">
          <OrderSummaryPanelRepeater isActive={false} />
        </div>
      </React.Fragment>
    );
  } else if (hasActiveOrders && !hasOrderHistory) {
    screenMarkup = (
      <div className="col-12 col-lg-6 offset-lg-3">
        <OrderSummaryPanelRepeater isActive />
      </div>
    );
  } else if (hasOrderHistory && !hasActiveOrders) {
    screenMarkup = (
      <div className="col-12 col-lg-6 offset-lg-3">
        <OrderSummaryPanelRepeater isActive={false} />
      </div>
    );
  }

  return (
    <div className="order-history-screen">
      <IrsNotRespAcknowledger />
      <ReceiptModal orderId={activeReceiptModal} />
      <div className="row">{screenMarkup}</div>
    </div>
  );
};

OrderHistoryScreen.propTypes = {
  activeReceiptModal: PropTypes.number,
  hasActiveOrders: PropTypes.bool.isRequired,
  hasOrderHistory: PropTypes.bool.isRequired
};

OrderHistoryScreen.defaultProps = {
  activeReceiptModal: null
};

export default withAccountFrame(OrderHistoryScreen);
