import { Elements, StripeProvider } from "react-stripe-elements";
import React from "react";
import PaymentModalWithStripe from "./paymentModalWithStripe";
import { noCartBalance } from "../../cart/cart.selectors.v2";
import { connect } from "react-redux";
import PaymentModalWithoutStripe from "./paymentModalWithoutStripe";
import { changePromoCode, clearPromo } from "../../cart/cart.actions";
import {
  fetchWeightIncreaseVehicles as fetchWeightIncreaseVehiclesCreator,
  setRedirect as setRedirectCreator
} from "../../../screens/weightIncrease/weightIncrease.actions";

class PaymentModalContainer extends React.Component {
  componentWillUnmount() {
    const {
      props: { clearPromo, changePromoCode }
    } = this;
    clearPromo();
    changePromoCode("");
  }

  render() {
    const { noCartBalance } = this.props;

    const style = {
      base: {
        fontSize: "24px",
        color: "#32325d",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#ccc"
        }
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238"
        }
      }
    };

    if (noCartBalance === undefined || noCartBalance === null) return <></>;

    if (noCartBalance)
      return (
        <PaymentModalWithoutStripe
          noCartBalance={noCartBalance}
        ></PaymentModalWithoutStripe>
      );

    // noinspection JSUnresolvedVariable
    return (
      <StripeProvider
        apiKey={window.i2290settings.stripePublicKey}
        style={style}
      >
        <Elements>
          <PaymentModalWithStripe noCartBalance={noCartBalance} />
        </Elements>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => ({
  noCartBalance: noCartBalance(state)
});

const mapDispatchToProps = dispatch => ({
  changePromoCode: val => dispatch(changePromoCode(val)),
  clearPromo: () => dispatch(clearPromo())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentModalContainer);
