import { DEFAULT_TICKET_CHANNEL, DEFAULT_TICKET_STATUS } from "modules/constants";
import utilities from "../../modules/utilities";

const {
  logic: { isEmpty }
} = utilities;

export const mapSupportFieldsToPayload = values =>
  (({ FirstName, LastName, Email, Fax, Tel1, Mobile, Language, BusinessName }) => ({    
    FirstName,
    LastName,
    Email,
    Fax,
    Phone: Tel1,
    Mobile,
    Language: Language,
    Channel: DEFAULT_TICKET_CHANNEL,
    Status: DEFAULT_TICKET_STATUS,
    BusinessName
  }))(values);

export const createDescription = (data = {}) =>
  Object.keys(data)
    .reduce(
      (a, k) => (!isEmpty(data[k]) ? a + `${k}: ${data[k]}<br />` : a + ""),
      ""
    )
    .slice(0, -2);

export const createListDescription = (data = []) =>
  data
    .map(x =>
      Object.keys(x)
        .reduce((a, k) => (!isEmpty(x[k]) ? a + `${k}: ${x[k]}, ` : a + ""), "")
        .slice(0, -2)
    )
    .reduce((a, s) => a + `${s}<br />`, "")
    .slice(0, -6);
