import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { reduxForm, getFormValues } from "redux-form";
import Signer from "./Signer";
import userContext from "../../../modules/userContext";
import utilities from "../../../modules/utilities";
import order from "../../../modules/order";
import { FORM_NAME } from "../newUser.constants";
import { saveOnBlur } from "../../../modules/userContext/userContext.actions";
import { getFieldOnTab } from "../../../modules/userContext/userContext.selectors";

const {
  getOrderIdFromUrl,
  transform: { mapContactAndSignerFormValuesToPayload }
} = utilities;

const {
  selectors: { getRedirect, getUserContext },
  actions: { patchUser: patchUserCreator, setRedirect: setRedirectCreator }
} = userContext;

const {
  actions: { fetchOrder: fetchOrderCreator, patchOrder: patchOrderCreator },
  selectors: { getOrderHistory, getOrderHOF, getThirdPyesNoHOF }
} = order;

class SignerContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  componentDidMount() {
    const { redirect, setRedirect, fetchOrder } = this.props;
    fetchOrder(getOrderIdFromUrl());
    if (redirect !== null) {
      setRedirect(null);
    }
  }

  componentDidUpdate() {
    if (this.props.fieldOnTab !== "") {
      // this would be better made into a ref
      const ref = document.getElementsByClassName("SignerForm")[0];
      if (ref !== undefined) {
        const fieldOnTab = ref.querySelector(
          `[name="${this.props.fieldOnTab}"]`
        );
        fieldOnTab && fieldOnTab.focus();
      }
    }
  }

  onSuccess = formValues => {
    const { patchUser, orders } = this.props;

    // const currentStep = parseInt(pathname.split("/").pop(), 10);

    const urlOrderId = getOrderIdFromUrl();
    if (!urlOrderId) {
      const orderIds = Object.keys(orders);
      if (orderIds.length !== 1) {
        throw new Error(); // TODO
      }

      return patchUser(
        mapContactAndSignerFormValuesToPayload(formValues),
        `/wizard/4?order=${orderIds[0]}`
      );
    }

    patchUser(
      mapContactAndSignerFormValuesToPayload(formValues),
      `/wizard/4?order=${urlOrderId}`
    );
  };

  render() {
    const {
      userContext,
      redirect,
      location: { pathname },
      formValues
    } = this.props;
    const firstName = userContext.FirstName || null;
    const companyName = userContext.BusinessName || null;
    const userContextExists = !!userContext.Id;

    return (
      <React.Fragment>
        {redirect && redirect !== pathname && <Redirect to={redirect} />}
        <Signer
          userContextExists={userContextExists}
          onSubmit={this.handleSubmit}
          firstName={firstName}
          thirdPyesNo={!!(formValues || {}).ThirdPyesNo}
          companyName={companyName}
        />
      </React.Fragment>
    );
  }
}

SignerContainer.propTypes = {
  userContext: PropTypes.object
};

SignerContainer.defaultProps = {
  userContext: {}
};

const mapStateToProps = state => {
  const { ThirdPname, ThirdPphone, ThirdPpin, ThirdPyesNo } = {
    ...(getOrderHOF(getOrderIdFromUrl())(state) || {}),
    ThirdPyesNo: getThirdPyesNoHOF(getOrderIdFromUrl())(state)
  };
  const initialValues = {
    ...getUserContext(state),
    ThirdPname: ThirdPname || "",
    ThirdPphone: ThirdPphone || "",
    ThirdPpin: ThirdPpin || "",
    ThirdPyesNo: ThirdPyesNo ? true : false
  };
  return {
    orders: getOrderHistory(state),
    userContext: { ...initialValues },
    redirect: getRedirect(state),
    fieldOnTab: getFieldOnTab(state),
    formValues: getFormValues(FORM_NAME)(state) || {
      ThirdPyesNo: ThirdPyesNo ? true : false
    },
    initialValues
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true)),
  patchUser: (user, redirect) => dispatch(patchUserCreator(user, redirect)),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect)),
  saveOnBlur: fieldOnTab => dispatch(saveOnBlur(fieldOnTab))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: FORM_NAME,
      destroyOnUnmount: false,
      enableReinitialize: true,
      forceUnregisterOnUnmount: true,
      keepDirtyOnReinitialize: true,
      touchOnBlur: false
    })(SignerContainer)
  )
);
