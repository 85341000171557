import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import { OTHER_REASON_NAME } from "../../../screens/supportCenter/supportCenter.constants";

const CorrectionDropdown = ({ name, validations, label, onBlur, onChange }) => (
  <Select
    name={name}
    label={label}
    validations={validations}
    onBlur={onBlur}
    onChange={onChange}
  >
    <option value={null} />
    <option value="It has transposed characters">
      It has transposed characters
    </option>
    <option value="It was already filed for this tax period">
      It was already filed for this tax period
    </option>
    <option value="This vehicle was sold prior to July">
      This vehicle was sold prior to July
    </option>
    <option value="Is not recognized as a vehicle owned by this company">
      Is not recognized as a vehicle owned by this company
    </option>
    <option value="The VIN is from the trailer">
      The VIN is from the trailer
    </option>
    <option value={OTHER_REASON_NAME}>Other</option>
  </Select>
);

CorrectionDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.array,
  label: PropTypes.string,
  onBlur: PropTypes.func
};

CorrectionDropdown.defaultProps = {
  validations: [],
  label: "Explanation of Correction",
  onBlur: null
};

export default CorrectionDropdown;
