export const BUSINESS_STEP = 1;
export const VEHICLES_STEP = 2;
export const SIGNER_STEP = 3;
export const PAYMENT_STEP = 4;
export const REVIEW_STEP = 5;

export const BANK = "BANK";
export const CC = "CC";
export const MAIL = "MAIL";
export const EFTPS = "EFTPS";
export const CC_ENUM_VAL = 0;
export const EFTPS_ENUM_VAL = 1;
export const MAIL_ENUM_VAL = 2;
export const BANK_ENUM_VAL = 3;
export const STATUS_ACCEPTED = "A";
export const STATUS_REJECTED = "X";
export const STATUS_NOT_SUBMITTED = null;
export const STATUS_SUBMITTED = "S";
export const STATUS_RECEIVED = "R";
export const STATUS_PENDING = "P";
export const STATUS_TEST_DELAY = "DT";
export const STATUS_P18 = "P18";
export const STATUS_X_EARLY_FILE = "XEarlyFile";
export const STATUS_S1_NULL_A = "S1NullA";
export const CREDIT_REASON_SOLD = "Sold";
export const CREDIT_REASON_STOLEN = "Stolen";
export const CREDIT_REASON_DESTROYED = "Destroyed";
export const CREDIT_REASON_SUSPENDED = "Suspended";
export const STEP_IS_DISABLED = "disabled";
export const STEP_IS_INVALID = "invalid";
export const STEP_IS_VALID = "valid";
export const STEP_IS_NOTCHECKED = "notchecked";
export const TAXABLE_TEMPLATE_URI =
  "/assets/templates/vehicle-upload-template.csv";
export const CREDIT_TEMPLATE_URI =
  "/assets/templates/credit-upload-template.csv";
export const NO_VEHICLES_ERROR_MESSAGE =
  "There are no vehicles. Please add or upload.";
export const INVALID_VEHICLES_ERROR_MESSAGE =
  "Missing or invalid vehicle data encountered. Please revise below or upload.";
export const INVALID_VEHICLE_NUMBER_ERROR_MESSAGE = n =>
  `${n} invalid vehicles were encountered. Please revise below or upload.`;
export const INVALID_CREDITS_ERROR_MESSAGE =
  "Missing or invalid credit data encountered. Please revise below or upload.";
export const INVALID_CREDITS_NUMBER_ERROR_MESSAGE = n =>
  `${n} invalid credits were encountered. Please revise below or upload.`;
export const UNLOCK_REQUEST_SUBJECT =
  "Please unlock my most recent unfiled Form 2290 for editing.";
export const IRS_REJECTED_SUBJECT = "IRS Rejected Order Support Request";
export const BULK_PRICING_SUBJECT = "Bulk Pricing Request";
export const NOTICE_OR_LETTER_SUBJECT = "IRS Notice Review Support Request";
export const MAIL_SCHEDULE_ONE_SUBJECT = "Mail Schedule 1 Support Request";
export const VIN_CORRECTION_SUBJECT = "VIN Correction Support Request";
export const FILE_BY_PHONE_SUBJECT = "File By Phone";
export const DEFAULT_TICKET_STATUS = "New";
export const DEFAULT_TICKET_CHANNEL= "Web";
export const WEIGHT_INCREASE_CART_MESSAGE =
  "Weight Increase order is free of charge.";
export const UNLIMITED_CART_MESSAGE =
  "This order is free of charge under the Unlimited Plan.";
export const NO_CHARGE_STRING_CAPS = "NO CHARGE";
export const WAIVE_FEE = "FREE ORDER";
export const PRIVACY_POLICY_URI = "https://i2290.com/privacy-policy";
export const SUPPORT_URI = "https://i2290.com/support";
export const TERMS_AND_CONDITIONS_URI =
  "https://i2290.com/terms-and-conditions";
export const WEBSITE_URI = "https://i2290.com";
export const APP_URI = process.env.REACT_APP_SITE_URI;
export const LOGO_URI =
  "https://s3.amazonaws.com/i2290/icon.square.yellow.min.png";
export const EDIT_CREDITS_ROUTE = "/edit-credits";
export const EDIT_VEHICLES_ROUTE = "/edit-vehicles";
export const ORDER_HISTORY_ROUTE = "/order-history";
export const FORM_ROUTE = "/form";
export const VIN_CORRECTION_ROUTE = "/vin-correction";
export const WEIGHT_INCREASE_ROUTE = "/weight-increase";
export const FORM_TYPE_8849 = "8849";
export const NOT_FILED_YET_STATUS_LABEL = "Incomplete";
export const ROUTING_NUM_INVALID_MSG = "Invalid routing number";
export const PAYMENT_AGREEMENT =
  "I authorize the U.S. Treasury and its designated Financial Agent to initiate an electronic funds withdrawal (direct debit) against the account indicated above, and the financial institution to debit the entry to this account. I also authorize the financial institutions involved in the processing of the electronic payment of taxes to receive confidential information necessary to answer inquiries and resolve issues related to the payment.";
export const PAYMENT_AGREEMENT_PREVIEW_LENGTH = 106;
export const VIN_CORRECTION_DD_VAL = "VIN_CORRECTION";
export const WEIGHT_INCREASE_DD_VAL = "WEIGHT_INCREASE";
export const ORDER_SUBMITTED_STATUS = "10";
export const ORDER_NOT_SUBMITTED_STATUS = "00";
export const IRS_EFTPS_SITE_URI = "https://www.eftps.gov";
export const IRS_CC_SITE_URI =
  "https://www.irs.gov/payments/pay-taxes-by-credit-or-debit-card";
export const WHITE_LOGO_URI =
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/914629/logo.icon.final.white.png";
export const CREDIT_CARD_ERROR =
  "Credit Card error. Carefully check your information and retry.";
export const ORDER_SUBMISSION_ERROR =
  "Submission error. Carefully check your information and retry.";
export const IMPORT_VEHICLE_HEADER =
  "VIN,VehicleDate,WeightCategory,Suspended,Logging,Agricultural,Description";
export const IMPORT_CREDIT_HEADER =
  "VIN,VehicleCreditDate,WeightCategory,Logging,Reason,IsBuyerBusiness,BuyerName,BuyerAddress1,BuyerAddress2,BuyerCity,BuyerState,BuyerZip,BuyerCountry,TaxPaidPriorPeriod";
