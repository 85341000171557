import React, { useState } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import utilities from "../../utilities";
import AnimateHeight from "react-animate-height";
import Chevron from "modules/controls/components/Chevron";

const {
  math: { fastFormatMoney },
} = utilities;

const WithStyles = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  },
})(Checkbox);

const Label = ({ isUnlimited, onChangeUnlimited, productPrice }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="col">
      <div onClick={() => setOpen(!open)} className="row" style={{fontWeight: "bold", cursor: "pointer"}}>
        Need Unlimited Filings for the Tax Year?
        <Chevron style={{fontWeight: "bold"}}/>
      </div>
      <div className="row ml-1 pt-1">
        <AnimateHeight duration={500} height={open || isUnlimited ? "auto" : 0}>
            <FormControlLabel
              control={
                <WithStyles
                  checked={isUnlimited}
                  onChange={onChangeUnlimited}
                  value="checkedB"
                  color="primary"
                />
              }
              label={`Upgrade to Unlimited for ${fastFormatMoney(productPrice)}`}
            />
        </AnimateHeight>
      </div>
    </div>
  );
};

Label.propTypes = {
  isUnlimited: PropTypes.bool.isRequired,
  onChangeUnlimited: PropTypes.func.isRequired,
  productPrice: PropTypes.number,
};

export default Label;
