import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import hoc from "../../hoc";
import companyInfo from "../../../modules/companyInfo";
import form from "../../../modules/form";
import controls from "../../../modules/controls";
import order from "../../../modules/order";
import cart from "../../../modules/cart";
import newUser from "../../../../src/screens/new-user";
import designee from "../../../modules/designee";
import "./formScreen.css";
import payment from "../../../modules/paymentModal";

import {
  STEP_IS_INVALID,
  STEP_IS_VALID,
  STEP_IS_NOTCHECKED
} from "../../../modules/constants";
import NotCurrentYearDialog from "../../../modules/paymentModal/components/notCurrentYearDialog";
import ThankYouDialog from "../../../modules/paymentModal/components/thankYouDialog";
import AckIrsPayMethodDialog from "modules/paymentModal/components/AckIrsPayMethodDialog";

const {
  components: { TaxYearDropdownContainer: TaxYearDropdown }
} = order;

const {
  components: { PaymentModalContainer: PaymentModal }
} = payment;

const {
  components: { CompanyInfoViewContainer: CompanyInfoView }
} = companyInfo;

const {
  components: { PaySubmitContainer: PaySubmit }
} = cart;

const {
  components: { WizardStepperContainer: WizardStepper }
} = newUser;

const {
  components: { FormWidgetRepeaterContainer: FormWidgetRepeater }
} = form;

const {
  components: { Button }
} = controls;

const {
  hof: { withAccountFrame }
} = hoc;

const {
  components: {
    DesigneeViewContainer: DesigneeView,
    SignerViewContainer: SignerView
  }
} = designee;

const FormScreen = ({
  onClickEditSigner,
  onClickEditCompany,
  isWeightIncrease,
  redirect,
  orderId,
  onClickEditPayment
}) => (
  <React.Fragment>
    {redirect && <Redirect to={redirect} />}
    <div className="form-screen">
      <div className="row justify-content-lg-center">
        <div className="col-2 col-sm-12 col-md-3 col-xl-2">
          <WizardStepper
            isFormScreen
            steps={[
              "Business",
              "Vehicles/Credits",
              "Signer",
              "Pay the IRS",
              "Review"
            ]}
            valid={[
              STEP_IS_INVALID,
              STEP_IS_VALID,
              STEP_IS_INVALID,
              STEP_IS_NOTCHECKED,
              STEP_IS_NOTCHECKED
            ]}
          />
        </div>
        <div className="col-10 col-sm-12 col-lg-8">
          <div className="rad-corners mb-gutter secondary-white-bg form-padding">
            {!isWeightIncrease && (
              <div className="summary-widget">
                <div className="summary-header no-bottom-padding bg-white">
                  <div className="row">
                    <div className="col-12 col-lg-2 mb-gutter mb-lg-0 order-id-label">
                      <span>Order #</span>
                      <br />
                      <strong>{orderId}</strong>
                    </div>
                    <div className="col-12 col-lg mb-gutter my-lg-auto">
                      <div className="mx-auto ml-lg-0 mr-lg-0">
                        <TaxYearDropdown label="Tax Period" width="300px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* ============== Company section ========== */}
            <div className="summary-widget">
              <div className="summary-header low-top-padding bg-white">
                <div className="form-widget-header row">
                  <div className="col-12 col-md my-auto">Business</div>
                  <div className="col text-right">
                    <Button
                      primary={false}
                      text="Edit"
                      onClick={onClickEditCompany}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="widget-body row">
              <div className="col-12" id="signer-view">
                <CompanyInfoView radCorners={false} />
              </div>
            </div>
            {/* ============== Legacy company section ========== 
            <div id="company">
              <CompanyInfoWidget radCorners={false} />
            </div>            
            */}
            {/* ============== Form section ========== */}
            <FormWidgetRepeater radCorners={false} />
            {/* ============== Signer section ========== */}
            <div className="row no-gutters vertical-divider">
              <div className="col-6" id="signer-view">
                <div className="summary-widget">
                  <div className="summary-header bg-white">
                    <div className="form-widget-header row">
                      <div className="col-12 col-md my-auto">Signed by</div>
                      <div className="col text-right">
                        <Button
                          primary={false}
                          text="Edit"
                          onClick={onClickEditSigner}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <SignerView />
                <DesigneeView />
              </div>
              <div className="col-6" id="designee-view">
                <PaySubmit onClickEdit={onClickEditPayment} />
              </div>
            </div>
            <div className="widget-body row"></div>
          </div>
        </div>
        <PaymentModal />
        <NotCurrentYearDialog />
        <AckIrsPayMethodDialog />
        <ThankYouDialog />

        {/*
        <div className="col-12 col-xl-3 pl-gutter pl-xl-0 form-screen-right">
          <div id="payment">
            <PaymentMethodWidget />
          </div>
          <CartWidget />
        </div>
        
        */}
      </div>
    </div>
  </React.Fragment>
);

FormScreen.propTypes = {
  onClickEditVehicles: PropTypes.func.isRequired,
  onClickEditCreditVehicles: PropTypes.func.isRequired,
  onClickEditSigner: PropTypes.func.isRequired,
  onClickEditCompany: PropTypes.func.isRequired,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfCreditVehicles: PropTypes.number,
  isWeightIncrease: PropTypes.bool,
  redirect: PropTypes.string,
  orderId: PropTypes.string.isRequired
};

FormScreen.defaultProps = {
  numberOfTaxableVehicles: 0,
  numberOfCreditVehicles: 0,
  redirect: null
};

export default withAccountFrame(FormScreen);
