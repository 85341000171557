import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";

const {
  components: { TextField },
  normalizations: { normalizeMiddleInitial, normalizeTitle }
} = controls;

const SignerForm = ({ onBlur }) => (
  <React.Fragment>
    <TextField
      label="First Name"
      name="FirstName"
      type="text"
      onBlur={onBlur}
    />
    <TextField
      label="Middle Initial"
      name="Mi"
      type="text"
      onBlur={onBlur}
      normalize={normalizeMiddleInitial}
    />
    <TextField label="Last Name" name="LastName" type="text" onBlur={onBlur} />
    <TextField
      label="Title"
      name="OfficerTitle"
      type="text"
      onBlur={onBlur}
      normalize={normalizeTitle}
    />
  </React.Fragment>
);

SignerForm.propTypes = {
  onBlur: PropTypes.func
};

SignerForm.defaultProps = {
  onBlur: null
};

export default SignerForm;
