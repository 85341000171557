import {
  buildAsyncActionType,
  buildActionType
} from "../../modules/utilities/reduxUtilities";
import { STATE_NAME } from "./forgotPin.constants";

export const FORGOT_PIN_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FORGOT_PIN_ASYNC"
);

export const RESET_ERROR = buildActionType(STATE_NAME, "RESET_ERROR");
