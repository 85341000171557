import React from "react";
import PropTypes from "prop-types";
import { CREDIT_REASON_SOLD } from "../../constants";

class ExpandCell extends React.Component {
  render() {
    const {
      props: { dataItem, toggleIsExpandedHOF }
    } = this;
    const toggleIsExpanded = toggleIsExpandedHOF(dataItem);
    return (
      <td className="command-cell" {...this.props}>
        {dataItem.CreditReason === CREDIT_REASON_SOLD && (
          <React.Fragment>
            {dataItem.isExpanded ? (
              <span
                aria-hidden
                className="k-icon k-i-minus"
                onClick={toggleIsExpanded}
              />
            ) : (
              <span
                aria-hidden
                className="k-icon k-i-plus"
                onClick={toggleIsExpanded}
              />
            )}
          </React.Fragment>
        )}
      </td>
    );
  }
}

ExpandCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  toggleIsExpandedHOF: PropTypes.func.isRequired
};

export default ExpandCell;
