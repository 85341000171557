import React from "react";
import PropTypes from "prop-types";
import DisclaimerModal from "./DisclaimerModal";
import AckCopiedVehiclesModal from "./AckCopiedVehiclesModalContainer";
import ValidationSummary from "./ValidationSummaryContainer";
import "./submissionWidget.css";

const SubmissionWidget = ({
  pin,
  companyFormIsInvalid,
  signerFormIsInvalid,
  contactFormIsInvalid,
  designeeFormIsInvalid,
  paymentMethodFormIsInvalid,
  numberOfInvalidCreditVehicles,
  numberOfInvalidTaxableVehicles,
  disclaimerIsVisible,
  onClickDisclaimer
}) => (
  <div className="submission-widget mb-gutter mx-negative-gutter">
    <DisclaimerModal
      pin={pin}
      onClose={onClickDisclaimer}
      isVisible={disclaimerIsVisible}
    />
    <ValidationSummary
      companyFormIsInvalid={companyFormIsInvalid}
      signerFormIsInvalid={signerFormIsInvalid}
      contactFormIsInvalid={contactFormIsInvalid}
      designeeFormIsInvalid={designeeFormIsInvalid}
      paymentMethodFormIsInvalid={paymentMethodFormIsInvalid}
      numberOfInvalidTaxableVehicles={numberOfInvalidTaxableVehicles}
      numberOfInvalidCreditVehicles={numberOfInvalidCreditVehicles}
    />
    <AckCopiedVehiclesModal />
  </div>
);

SubmissionWidget.propTypes = {
  pin: PropTypes.string,
  companyFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  numberOfInvalidCreditVehicles: PropTypes.number,
  numberOfInvalidTaxableVehicles: PropTypes.number,
  disclaimerIsVisible: PropTypes.bool.isRequired,
  onClickDisclaimer: PropTypes.func.isRequired
};

SubmissionWidget.defaultProps = {
  pin: null,
  companyFormIsInvalid: false,
  signerFormIsInvalid: false,
  contactFormIsInvalid: false,
  designeeFormIsInvalid: false,
  paymentMethodFormIsInvalid: false,
  numberOfInvalidCreditVehicles: 0,
  numberOfInvalidTaxableVehicles: 0
};

export default SubmissionWidget;
