import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { importVehicles as importVehiclesCreator } from "../vehicles.actions";
import VehicleImporter from "./VehicleImporter";
import utilities from "../../utilities";
import controls from "../../controls";
import taxYear from "../../taxYear";
import { isBusy as isBusySelector } from "../../busyIndicator/busyIndicator.selector";
import order from "../../order";
import { TAXABLE_TEMPLATE_URI } from "../../constants";

const {
  utilities: { transformCSV }
} = controls;

const {
  selectors: { getTaxTable }
} = taxYear;

const { computeTax, getOrderIdFromUrl } = utilities;

const {
  selectors: { getOrderHOF }
} = order;

class VehicleImporterContainer extends Component {
  handleDrop = data => {
    const { importVehicles, taxTable, taxYear } = this.props;
    const transform = transformCSV(data, taxYear);
    let LineNumber = 1;
    transform.forEach(x => {
      x.TaxValue = computeTax(x, taxTable);
      x.IsTaxable = true;
      x.LineNumber = LineNumber;
      LineNumber += 1;
    });
    importVehicles(getOrderIdFromUrl(), data);
  };

  handleClickDownload = () => {
    window.location.href = TAXABLE_TEMPLATE_URI;
  };

  handleClickDownloadPriorYear = () => {
    const {
      props: { taxYear }
    } = this;
    window.location.href = `/api/${taxYear}_export.csv`;
  };

  render() {
    const {
      handleClickDownload,
      handleClickDownloadPriorYear,
      handleDrop,
      props: { onClickCancel, isBusy }
    } = this;
    return (
      <VehicleImporter
        onClickDownload={handleClickDownload}
        onClickCancel={onClickCancel}
        onDrop={handleDrop}
        isBusy={isBusy}
        onClickDownloadPriorYear={handleClickDownloadPriorYear}
      />
    );
  }
}

VehicleImporterContainer.propTypes = {
  onClickCancel: PropTypes.func.isRequired,
  importVehicles: PropTypes.func.isRequired,
  taxTable: PropTypes.object,
  taxYear: PropTypes.number,
  isBusy: PropTypes.bool.isRequired
};

VehicleImporterContainer.defaultProps = {
  taxTable: null,
  taxYear: null
};

const mapStateToProps = state => {
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  return {
    taxTable: getTaxTable(state),
    taxYear: order ? order.TaxYear : null,
    isBusy: isBusySelector(state)
  };
};

const mapDispatchToProps = dispatch => ({
  importVehicles: (orderId, vehicles) =>
    dispatch(importVehiclesCreator(orderId, vehicles))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleImporterContainer)
);
