import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CompanyInfoWidgetHeader from "./CompanyInfoWidgetHeader";
import userContext from "../../userContext";

const {
  selectors: { getUserContext, getCompanyInfoWidgetIsExpanded },
  actions: {
    toggleCompanyInfoWidgetIsExpanded: toggleCompanyInfoWidgetIsExpandedCreator
  }
} = userContext;

class CompanyInfoWidgetHeaderContainer extends Component {
  render() {
    const {
      companyInfoWidgetIsExpanded,
      toggleCompanyInfoWidgetIsExpanded,
      userContext: {
        BusinessName: companyName,
        Ein: ein,
        City: city,
        State: state,
        Zip: zip,
        FirstName: firstName,
        LastName: lastName,
        OfficerTitle: title
      }
    } = this.props;

    const loading =
      !companyName &&
      !ein &&
      !city &&
      !state &&
      !zip &&
      !firstName &&
      !lastName &&
      !title;

    return (
      <CompanyInfoWidgetHeader
        isExpanded={companyInfoWidgetIsExpanded}
        onClick={toggleCompanyInfoWidgetIsExpanded}
        loading={loading}
        companyName={companyName}
        ein={ein}
        city={city}
        state={state}
        zip={zip}
        firstName={firstName}
        lastName={lastName}
        title={title}
      />
    );
  }
}

CompanyInfoWidgetHeaderContainer.propTypes = {
  userContext: PropTypes.object,
  companyInfoWidgetIsExpanded: PropTypes.bool.isRequired,
  toggleCompanyInfoWidgetIsExpanded: PropTypes.func.isRequired
};

CompanyInfoWidgetHeaderContainer.defaultProps = {
  userContext: {}
};

const mapStateToProps = state => ({
  userContext: getUserContext(state),
  companyInfoWidgetIsExpanded: getCompanyInfoWidgetIsExpanded(state)
});

const mapDispatchToProps = dispatch => ({
  toggleCompanyInfoWidgetIsExpanded: () =>
    dispatch(toggleCompanyInfoWidgetIsExpandedCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyInfoWidgetHeaderContainer);
