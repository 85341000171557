import * as actionTypes from "./paymentModal.actionTypes";
import { CREDIT_CARD_ERROR, ORDER_SUBMISSION_ERROR } from "../constants";
import { notStartedStep, previousYearStep, stripeStep } from "./paymentModal.constants";
import { SUBMIT_ORDER } from "../order/order.actionTypes";
import { LOCATION_CHANGE } from "react-router-redux";

const initialState = {
  dialogOpen: false,
  dialogOpenName: notStartedStep,
  warningDialogOpen: false,
  submitError: "",
  redirect: null,
  loading: false,
  bankValidated: null,
  fullBankAccount: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_STRIPE_TOKEN:
      return {
        ...state,
        loading: true
      };
    case actionTypes.TOGGLE_DIALOG:
      return {
        ...state,
        submitError: "",
        dialogOpen: !state.dialogOpen,
        dialogOpenName: !state.dialogOpen ? stripeStep : notStartedStep,
        loading: false,
        fullBankAccount: state.fullBankAccount !== 'error' ? state.fullBankAccount : '',
      };
    case actionTypes.TOGGLE_WARNING_DIALOG:
      return {
        ...state,
        warningDialogOpen: !state.warningDialogOpen,
        dialogOpenName: !state.warningDialogOpen ? previousYearStep : notStartedStep
      };
    case actionTypes.OPEN_DIALOG_NAME:
      return {
        ...state,
        dialogOpenName: action.dialogOpenName,
        dialogOpen: false,
        warningDialogOpen: false,
        fullBankAccount: state.fullBankAccount !== 'error' ? state.fullBankAccount : ''
      };
    case actionTypes.TOGGLE_THANK_YOU_DIALOG:
      return {
        ...state,
        thankYouOpen: !state.thankYouOpen
      };
    case actionTypes.STRIPE_PROCESSING_ASYNC.REQUESTED:
      return {
        ...state,
        submitError: "",
        loading: true
      };
    case actionTypes.FETCH_VERIFY_BANK_ASYNC.REQUESTED:
      return {
        ...state,
        bankValidated: null,
        fullBankAccount: ''
      };
    case actionTypes.FETCH_VERIFY_BANK_ASYNC.RECEIVED:
      return {
        ...state,
        bankValidated: action.payload.bankValidated,
        fullBankAccount: action.payload.fullBankAccount,
      };
    case actionTypes.FETCH_VERIFY_BANK_ASYNC.ERROR:
      return {
        ...state,
        bankValidated: null,
        fullBankAccount: 'error',
      };
    case SUBMIT_ORDER.RECEIVED:
    case actionTypes.STRIPE_PROCESSING_ASYNC.RECEIVED:
      return {
        ...state,
        submitError: "",
        dialogOpen: !state.dialogOpen,
        dialogOpenName: notStartedStep,  // TODO: incorporate thank you steps
        thankYouOpen: !state.thankYouOpen,
        loading: false
      };
    case actionTypes.STRIPE_PROCESSING_ASYNC.ERROR:
      return {
        ...state,
        submitError: CREDIT_CARD_ERROR,
        loading: false
      };
    case SUBMIT_ORDER.ERROR:
      return {
        ...state,
        submitError: ORDER_SUBMISSION_ERROR,
        loading: false
      };
    case actionTypes.SHOW_ERROR:
      return {
        ...state,
        submitError: action.message,
        loading: false
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        redirect: null
      };
    default:
      return state;
  }
}
