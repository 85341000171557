import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isInvalid } from "redux-form";
import ValidationSummary from "./ValidationSummary";
import {
  getOrderHOF,
  getFormEntities,
  getVehicleEntities
} from "../order.selectors";
import { setPaymentMethodWidgetIsExpanded as setPaymentMethodWidgetIsExpandedCreator } from "../order.actions";
import utilities from "../../utilities";
import controls from "../../controls";
import userContext from "../../userContext";

const { getOrderIdFromUrl } = utilities;

const {
  validations: {
    validateVehicleFormWithNullValid: validateTaxable,
    validateCreditVehicleFormWithNullValid: validateCredit
  }
} = controls;

const {
  actions: {
    setCompanyInfoWidgetIsExpanded: setCompanyInfoWidgetIsExpandedCreator
  }
} = userContext;

class ValidationSummaryContainer extends Component {
  handleClickBusinessInfoError = el => {
    const {
      props: { setCompanyInfoWidgetIsExpanded }
    } = this;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
    setCompanyInfoWidgetIsExpanded(true);
  };

  handleClickPaymentMethodError = el => {
    const {
      props: { setPaymentMethodWidgetIsExpanded }
    } = this;
    el.scrollIntoView({ behavior: "smooth", block: "end" });
    setPaymentMethodWidgetIsExpanded(true);
  };

  handleClickDesigneeError = el => {
    el.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  handleClickTaxableError = () => {
    const {
      props: {
        router: {
          history: { push }
        }
      }
    } = this;
    push(`/edit-vehicles?order=${getOrderIdFromUrl()}`);
  };

  handleClickCreditError = () => {
    const {
      props: {
        router: {
          history: { push }
        }
      }
    } = this;
    push(`/edit-credits?order=${getOrderIdFromUrl()}`);
  };

  render() {
    const {
      handleClickBusinessInfoError,
      handleClickCreditError,
      handleClickDesigneeError,
      handleClickPaymentMethodError,
      handleClickTaxableError,
      props: {
        companyFormIsInvalid,
        signerFormIsInvalid,
        contactFormIsInvalid,
        designeeFormIsInvalid,
        paymentMethodFormIsInvalid,
        numberOfInvalidTaxableVehicles,
        numberOfInvalidCreditVehicles,
        numberOfTaxableVehicles
      }
    } = this;
    const isBusinessInfoError =
      !!companyFormIsInvalid || !!signerFormIsInvalid || !!contactFormIsInvalid;
    return (
      <ValidationSummary
        numberOfTaxableVehicles={numberOfTaxableVehicles}
        onClickBusinessInfoError={handleClickBusinessInfoError}
        onClickTaxableError={handleClickTaxableError}
        isBusinessInfoError={isBusinessInfoError}
        isDesigneeError={!!designeeFormIsInvalid}
        numberOfInvalidCreditVehicles={numberOfInvalidCreditVehicles}
        onClickDesigneeError={handleClickDesigneeError}
        onClickPaymentMethodError={handleClickPaymentMethodError}
        isPaymentMethodError={!!paymentMethodFormIsInvalid}
        numberOfInvalidTaxableVehicles={numberOfInvalidTaxableVehicles}
        onClickCreditError={handleClickCreditError}
      />
    );
  }
}

ValidationSummaryContainer.propTypes = {
  companyFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfInvalidCreditVehicles: PropTypes.number,
  numberOfInvalidTaxableVehicles: PropTypes.number,
  setCompanyInfoWidgetIsExpanded: PropTypes.func.isRequired,
  setPaymentMethodWidgetIsExpanded: PropTypes.func.isRequired
};

ValidationSummaryContainer.defaultProps = {
  companyFormIsInvalid: false,
  signerFormIsInvalid: false,
  contactFormIsInvalid: false,
  designeeFormIsInvalid: false,
  paymentMethodFormIsInvalid: false,
  numberOfInvalidCreditVehicles: 0,
  numberOfInvalidTaxableVehicles: 0
};

const mapStateToProps = state => {
  let numberOfInvalidTaxableVehicles = 0;
  let numberOfInvalidCreditVehicles = 0;
  let numberOfTaxableVehicles = 0;

  const order = getOrderHOF(getOrderIdFromUrl())(state);
  if (order && order.Forms.length) {
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    const orderVehicles = vehicleIds.map(id => vehicles[id]);
    orderVehicles.forEach(x => {
      if (x.IsTaxable) {
        numberOfTaxableVehicles += 1;
        if (validateTaxable(x)) {
          numberOfInvalidTaxableVehicles += 1;
        }
        return;
      }
      if (validateCredit(x)) {
        numberOfInvalidCreditVehicles += 1;
      }
    });
  }

  return {
    numberOfTaxableVehicles,
    numberOfInvalidTaxableVehicles,
    numberOfInvalidCreditVehicles,
    signerFormIsInvalid: order ? isInvalid("signerSubmission")(state) : false,
    contactFormIsInvalid: order ? isInvalid("contactSubmission")(state) : false,
    designeeFormIsInvalid: order ? isInvalid("designee")(state) : false,
    companyFormIsInvalid: order
      ? isInvalid("companyInfoSubmission")(state)
      : false,
    paymentMethodFormIsInvalid: order
      ? isInvalid("paymentMethod")(state)
      : false
  };
};

const mapDispatchToProps = dispatch => ({
  setCompanyInfoWidgetIsExpanded: isExpanded =>
    dispatch(setCompanyInfoWidgetIsExpandedCreator(isExpanded)),
  setPaymentMethodWidgetIsExpanded: isExpanded =>
    dispatch(setPaymentMethodWidgetIsExpandedCreator(isExpanded))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationSummaryContainer);
