import React from "react";
import { Form } from "redux-form";
import PropTypes from "prop-types";
import companyInfo from "../../../modules/companyInfo";
import controls from "../../../modules/controls";
import "./companyInfo.css";
import WizardController from "./WizardControllerContainer";

const {
  components: { CompanyInfoFormContainer: CompanyInfoForm }
} = companyInfo;
const {
  validations: {
    validateZipByCountry,
    validateOptionalEIN,
    validateBusinessName,
    validateAddress,
    validateCity,
    validateRequired
  }
} = controls;

const CompanyInfo = ({
  onSubmit,
  onChange,
  firstName,
  formName,
  userContextExists
}) => (
  <Form
    className="container-fluid widget-background drop-shadow p-gutter mb-gutter rad-corners"
    onSubmit={onSubmit}
  >
    <div className="new-user-header-cntr mb-gutter">
      {userContextExists ? (
        <React.Fragment>
          <h1 className="header-size">
            Hi{firstName && ` ${firstName}`}, let's start with some company
            information.
          </h1>
        </React.Fragment>
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
    <div className="new-user-inner-content">
      <CompanyInfoForm
        onChange={onChange}
        businessNameValidations={[validateRequired, validateBusinessName]}
        einValidations={[validateOptionalEIN]}
        address1Validations={[validateRequired, validateAddress]}
        cityValidations={[validateRequired, validateCity]}
        zipValidations={[validateRequired, validateZipByCountry]}
      />
      {/*
        businessStructureValidations={[validateDropdown]}
        countryValidations={[validateDropdown]}
      einIsDisabled={false}/> */}
    </div>
    <div className="new-user-ctrl-area new-user-inner-content">
      <WizardController />
    </div>
  </Form>
);

CompanyInfo.propTypes = {
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  userContextExists: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

CompanyInfo.defaultProps = {
  firstName: null
};

export default CompanyInfo;
