import { STATE_NAME } from "./order.constants";
import { FLEET_SKU, STANDARD_SKU, UNLIMITED_SKU } from "../cart/cart.constants";
import { FORM_TYPE_8849, ORDER_NOT_SUBMITTED_STATUS } from "../constants";

export const getState = state => state[STATE_NAME];

export const getEntities = state => state[STATE_NAME].entities;

export const getActiveOrderId = state => state[STATE_NAME].ui.activeOrderId;

export const getOrder = (id, state) => state[STATE_NAME].entities[id];

export const getOrderHOF = id => state => state[STATE_NAME].entities[id];

export const getRedirect = state => state[STATE_NAME].ui.redirect;

export const getFormEntities = state => state.forms.entities;

export const getTaxYears = state => state[STATE_NAME].ui.taxInfo.taxYears;

export const getCurrentTaxYearObject = state =>
  state[STATE_NAME].ui.taxInfo.currentTaxYear;

export const getHeroTaxYearObject = state =>
  state[STATE_NAME].ui.taxInfo.heroTaxYear;

export const getCurrentTaxYear = state =>
  state[STATE_NAME].ui.taxInfo.currentTaxYear
    ? state[STATE_NAME].ui.taxInfo.currentTaxYear.TaxYear
    : null;

export const getVehicleEntities = state => state.vehicles.entities;

export const getDesigneeWidgetIsExpanded = state =>
  state[STATE_NAME].ThirdPyesNo !== null &&
  state[STATE_NAME].ThirdPyesNo !== undefined
    ? state[STATE_NAME].ThirdPyesNo
    : false;

export const getThirdPyesNoHOF = id => state =>
  state[STATE_NAME].entities[id]
    ? state[STATE_NAME].entities[id].ThirdPyesNo
    : null;

export const getPaymentMethodWidgetIsExpanded = state =>
  state[STATE_NAME].ui.paymentMethodWidgetIsExpanded;

export const getNetTax = id => state => {
  let netTax = null;
  const order = getOrderHOF(id)(state);
  if (order && order.Forms) {
    const forms = getFormEntities(state);
    netTax = order.Forms.map(id => forms[id])
      .filter(x => x.FormType !== FORM_TYPE_8849)
      .reduce((a, v) => a + v.TotalTax - v.TotalCredit, 0);
  }
  return netTax;
};

export const getOrderHistory = state => state[STATE_NAME].ui.history.orders;

export const getScheduleOneStagedForDownload = state =>
  state[STATE_NAME].ui.history.scheduleOneStagedForDownload;

export const getFormHistory = state => state[STATE_NAME].ui.history.forms;

export const getHistoryOrderHOF = id => state =>
  state[STATE_NAME].ui.history.orders[id];

export const getUserHasSubmittedOrder = state =>
  Object.keys(state[STATE_NAME].ui.history.orders).reduce(
    (a, x) =>
      state[STATE_NAME].ui.history.orders[x].OrderStatus === "10" ? true : a,
    false
  );

export const getHistoryFormHOF = id => state =>
  state[STATE_NAME].ui.history.forms[id];

export const getReportOrderHOF = id => state =>
  state[STATE_NAME].ui.reports.orders[id];

export const getReportFormHOF = id => state =>
  state[STATE_NAME].ui.reports.forms[id];

export const getReportVehicles = state => state[STATE_NAME].ui.reports.vehicles;

export const getActiveReceiptModal = state =>
  state[STATE_NAME].ui.activeReceiptModal;

export const getReceiptModalEmail = state =>
  state[STATE_NAME].ui.receiptModalEmail;

export const getNumberOfTaxableVehiclesHOF = id => state => {
  const order = getOrderHOF(id)(state);
  const forms = getFormEntities(state);
  const vehicles = getVehicleEntities(state);
  const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));

  return vehicleIds.filter(id => vehicles[id].IsTaxable).length;
};

export const getCanBeEdited = order => 
  (order && order.Forms && order.Forms.length && order.OrderStatus === "10") || 
  (order && order.Archived)

export const getCoveredByUnlimitedHOF = (
  id,
  userUnlimitedYearText
) => state => {
  const order = getOrderHOF(id)(state);
  if (!order) return false;
  if (userUnlimitedYearText) {
    const taxYear = parseInt(order.TaxYear, 10);
    const unlimitedYear = parseInt(userUnlimitedYearText, 10);
    return taxYear <= unlimitedYear;
  }
  return false;
};

// better function. delete above.
export const getUserIsCoveredByUnlimited = orderId => state => {
  const order = getOrderHOF(orderId)(state);
  const user = state.userContext.entity;
  if (!order || !user.Id) return false;
  if (!user.Unlimited) return false;
  return parseInt(order.TaxYear, 10) <= parseInt(user.Unlimited, 10);
};

export const getDefaultSku = (id, userUnlimitedYearText) => state => {
  const coveredByUnlimited = getCoveredByUnlimitedHOF(
    id,
    userUnlimitedYearText
  )(state);
  const numberOfTaxableVehicles = getNumberOfTaxableVehiclesHOF(id)(state);

  return coveredByUnlimited
    ? UNLIMITED_SKU
    : numberOfTaxableVehicles <= 24
    ? STANDARD_SKU
    : FLEET_SKU;
};

export const getActiveOrders = state => state[STATE_NAME].ui.activeOrders;

export const getTaxYearError = state => state[STATE_NAME].ui.taxYearError;

export const getAckCopiedVehiclesModalIsDismissed = state =>
  state[STATE_NAME].ui.ackCopiedVehiclesModalIsDismissed;

export const getIrsNotRespAcknowledgement = state =>
  state[STATE_NAME].ui.history.irsNotRespAcknowledgement;

export const getPaymentAgreementIsOpen = state =>
  state[STATE_NAME].ui.paymentAgreementIsOpen;

export const getDisclaimerIsVisible = state =>
  state[STATE_NAME].ui.disclaimerIsVisible;

const getOrderHistorySort = state => state[STATE_NAME].ui.history.sort;

export const getOrderHistoryByIsActive = isActive => state => {
  const orderHistorySort = getOrderHistorySort(state);
  const orderHistory = getOrderHistory(state);
  return orderHistorySort
    .map(id => orderHistory[id])
    .filter(x =>
      x.OrderStatus === ORDER_NOT_SUBMITTED_STATUS ? isActive : !isActive
    );
};

export const getOrderHistorySortByIsActive = isActive => state => {
  return getOrderHistoryByIsActive(isActive)(state).map(o => o.Id);
};
