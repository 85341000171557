import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormReportScreen from "./FormReportScreen";
import utilities from "../../../modules/utilities";
import order from "../../../modules/order";
import userContext from "../../../modules/userContext";

const { getOrderIdFromUrl } = utilities;

const {
  actions: { fetchHistoryOrder: fetchHistoryOrderCreator }
} = order;

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

class FormReportScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchUser, fetchHistoryOrder }
    } = this;
    fetchUser();
    fetchHistoryOrder(getOrderIdFromUrl());
  }

  render() {
    return <FormReportScreen />;
  }
}

FormReportScreenContainer.propTypes = {
  fetchHistoryOrder: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchHistoryOrder: orderId => dispatch(fetchHistoryOrderCreator(orderId)),
  fetchUser: () => dispatch(fetchUserCreator())
});

export default connect(null, mapDispatchToProps)(FormReportScreenContainer);
