import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "qs";
import { withRouter } from "react-router-dom";
import {
  getGrossTax,
  getTaxableVehiclesAllAreStagedForDeletion,
  getTaxableVehiclesCurrentPage,
  getTaxableVehiclesGridOrder,
  getTaxableVehiclesPageSize,
  getTaxableVehiclesStagedForCreation,
  getTaxableVehiclesStagedForDeletion,
  getWorkingCopies
} from "../vehicles.selectors";
import {
  changeCurrentPage as changeCurrentPageCreator,
  changePageSize as changePageSizeCreator,
  deleteVehicle as deleteVehicleCreator,
  stageForDeletion as stageForDeletionCreator,
  stageVehicle as stageVehicleCreator,
  toggleAllAreStagedForDeletion as toggleAllAreStagedForDeletionCreator
} from "../vehicles.actions";
import { TAXABLE_VEHICLES_SLICE_NAME } from "../vehicles.constants";
import grid from "../../grid";
import order from "../../order";
import controls from "../../controls";
import taxYear from "../../taxYear";
import modal from "../../modal";
import hoc from "../../hoc";
import utilities from "../../utilities";
import VehicleGrid from "./VehicleGrid";
import VehicleEditorContainer from "./VehicleEditorContainer";
import { getCurrentTaxYear } from "../../order/order.selectors";

const { getOrderIdFromUrl, computeTax } = utilities;

const {
  components: { Modal }
} = modal;

const {
  hof: { bindComponent }
} = hoc;

const {
  components: { Button }
} = controls;

const {
  actions: { fetchTaxTable: fetchTaxTableCreator },
  selectors: { getTaxTable }
} = taxYear;

const {
  components: { CommandCell }
} = grid;

const {
  selectors: { getOrder },
  actions: { deleteVehicles: deleteVehiclesCreator }
} = order;

class VehicleGridContainer extends React.Component {
  constructor(props) {
    super(props);
    this.CommandCell = bindComponent(
      { editHOF: this.editHOF, deleteHOF: this.removeHOF },
      CommandCell
    );
  }

  componentDidMount() {
    const { taxTable, fetchTaxTable } = this.props;
    if (!taxTable) {
      fetchTaxTable();
    }
  }

  getOrderId = () => {
    const {
      props: {
        location: { search }
      }
    } = this;
    const { order: orderId } = qs.parse(search.slice(1));
    return orderId;
  };

  handleClickImport = () => {
    const { onClickImport } = this.props;
    onClickImport();
  };

  handleClickDeleteAll = () => {
    const {
      props: { toggleAllAreStagedForDeletion }
    } = this;

    toggleAllAreStagedForDeletion();
  };

  deleteAll = () => {
    const {
      getOrderId,
      props: { deleteVehicles }
    } = this;
    deleteVehicles(getOrderId());
  };

  handleClickAddNew = () => {
    const { stageVehicle, taxTable } = this.props;

    stageVehicle({
      Vin: "",
      Description: "",
      Logging: false,
      Agricultural: false,
      Suspend: false,
      WeightCategory: "V",
      DateCategory: "",
      IsTaxable: true,
      TaxValue: computeTax(
        {
          WeightCategory: "V",
          DateCategory: "",
          Logging: false
        },
        taxTable
      )
    });
  };

  editHOF = dataItem => () => {
    const {
      props: { stageVehicle }
    } = this;

    stageVehicle(dataItem);
  };

  removeHOF = dataItem => () => {
    const { stageForDeletion } = this.props;
    stageForDeletion(dataItem.Id);
  };

  deleteStaged = () => {
    const {
      getOrderId,
      props: { stagedForDeletion, deleteVehicle }
    } = this;

    if (stagedForDeletion === null) return;

    deleteVehicle(getOrderId(), stagedForDeletion);
  };

  unstageForDeletion = () => {
    const { stageForDeletion } = this.props;
    stageForDeletion(null);
  };

  // handleChange = event => {
  //   const { updateStagedForCreation, updateWorkingCopy } = this.props;
  //   const { value, field, dataItem } = event;
  //
  //   if (!field) return;
  //
  //   const update = { [field]: value };
  //
  //   if (field === "Vin") {
  //     update.Vin = normalizeVIN(value);
  //   }
  //
  //   if (field === "Description") {
  //     update.Description = normalizeDescription(value);
  //   }
  //
  //   if (field === "WeightCategory") {
  //     update.Suspend = value === "W";
  //   }
  //
  //   if (field === "Suspend" && value) {
  //     update.WeightCategory = "W";
  //   }
  //
  //   if (dataItem.errors) {
  //     update.errors = validate({ ...event.dataItem, ...update });
  //   }
  //
  //   if (!event.dataItem.Id) {
  //     return updateStagedForCreation(update);
  //   }
  //
  //   updateWorkingCopy(event.dataItem.Id, update);
  // };

  handlePageChange = ({ page: { skip, take } }) => {
    const {
      props: { changeCurrentPage, changePageSize }
    } = this;
    changeCurrentPage(skip / take);
    changePageSize(take);
  };

  render() {
    const {
      props: {
        taxYear,
        workingCopies,
        stagedForDeletion,
        allAreStagedForDeletion,
        toggleAllAreStagedForDeletion,
        currentPage,
        pageSize,
        grossTax,
        stagedForCreation,
        taxTable
      },
      deleteAll,
      deleteStaged,
      handleChange,
      CommandCell,
      handleClickAddNew,
      handleClickImport,
      handleClickDeleteAll,
      unstageForDeletion,
      handlePageChange
    } = this;

    return (
      <div>
        <Modal
          isVisible={stagedForDeletion !== null}
          onClose={unstageForDeletion}
          prompt="Are you sure you want to delete this vehicle?"
          title="Please confirm"
          actions={
            <React.Fragment>
              <div className="col clean-gutter-right">
                <Button
                  disabled={false}
                  text="No"
                  primary={false}
                  onClick={unstageForDeletion}
                  width="100%"
                />
              </div>
              <div className="col clean-gutter-left">
                <Button
                  disabled={false}
                  text="Yes"
                  primary
                  onClick={deleteStaged}
                  width="100%"
                />
              </div>
            </React.Fragment>
          }
        />
        <Modal
          isVisible={allAreStagedForDeletion}
          onClose={toggleAllAreStagedForDeletion}
          prompt="Are you sure you want to delete all vehicles?"
          title="Please confirm"
          actions={
            <React.Fragment>
              <div className="col clean-gutter-right">
                <Button
                  disabled={false}
                  text="No"
                  primary={false}
                  onClick={toggleAllAreStagedForDeletion}
                  width="100%"
                />
              </div>
              <div className="col clean-gutter-left">
                <Button
                  disabled={false}
                  text="Yes"
                  primary
                  onClick={deleteAll}
                  width="100%"
                />
              </div>
            </React.Fragment>
          }
        />
        {stagedForCreation ? (
          <VehicleEditorContainer taxTable={taxTable} />
        ) : (
          <VehicleGrid
            totalTax={grossTax}
            skip={currentPage * pageSize}
            take={pageSize}
            readOnly={false}
            onPageChange={handlePageChange}
            data={workingCopies}
            taxYear={taxYear}
            CommandCell={CommandCell}
            onChange={handleChange}
            onClickAddNew={handleClickAddNew}
            onClickImport={handleClickImport}
            onClickDeleteAll={handleClickDeleteAll}
          />
        )}
      </div>
    );
  }
}

VehicleGridContainer.propTypes = {
  onClickImport: PropTypes.func.isRequired,
  taxYear: PropTypes.string.isRequired,
  deleteVehicle: PropTypes.func.isRequired,
  fetchTaxTable: PropTypes.func.isRequired,
  taxTable: PropTypes.object,
  deleteVehicles: PropTypes.func.isRequired,
  allAreStagedForDeletion: PropTypes.bool.isRequired,
  toggleAllAreStagedForDeletion: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired,
  grossTax: PropTypes.number
};

VehicleGridContainer.defaultProps = {
  taxTable: null,
  grossTax: null
};

const mapStateToProps = state => {
  const order = getOrder(getOrderIdFromUrl(), state);
  const stagedForCreation = getTaxableVehiclesStagedForCreation(state);
  const currentTaxYear = getCurrentTaxYear(state);
  const workingCopies = getWorkingCopies(state);
  const gridOrder = getTaxableVehiclesGridOrder(state);
  const gridData = gridOrder.map(id => workingCopies[id]);

  return {
    workingCopies: gridData,
    stagedForCreation,
    stagedForDeletion: getTaxableVehiclesStagedForDeletion(state),
    taxYear: order ? order.TaxYear : currentTaxYear,
    taxTable: getTaxTable(state),
    allAreStagedForDeletion: getTaxableVehiclesAllAreStagedForDeletion(state),
    currentPage: getTaxableVehiclesCurrentPage(state),
    pageSize: getTaxableVehiclesPageSize(state),
    grossTax: getGrossTax(state)
  };
};

const mapDispatchToProps = dispatch => ({
  stageForDeletion: id =>
    dispatch(stageForDeletionCreator(id, TAXABLE_VEHICLES_SLICE_NAME)),
  stageVehicle: payload =>
    dispatch(stageVehicleCreator(payload, TAXABLE_VEHICLES_SLICE_NAME)),
  deleteVehicle: (orderId, vehicleId) =>
    dispatch(
      deleteVehicleCreator(orderId, vehicleId, TAXABLE_VEHICLES_SLICE_NAME)
    ),
  fetchTaxTable: () => dispatch(fetchTaxTableCreator()),
  deleteVehicles: orderId => dispatch(deleteVehiclesCreator(orderId, true)),
  toggleAllAreStagedForDeletion: () =>
    dispatch(toggleAllAreStagedForDeletionCreator(TAXABLE_VEHICLES_SLICE_NAME)),
  changeCurrentPage: pageNum =>
    dispatch(changeCurrentPageCreator(pageNum, TAXABLE_VEHICLES_SLICE_NAME)),
  changePageSize: pageSize =>
    dispatch(changePageSizeCreator(pageSize, TAXABLE_VEHICLES_SLICE_NAME))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehicleGridContainer)
);
