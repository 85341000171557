import React from "react";
import Modal from "../../../modules/modal/components/Modal";
import Button from "../../../modules/controls/components/Button";

const LastYearVehicleModal = ({
  showImportModal,
  handleCloseImportModal,
  handleClickNo,
  handleClickYes
}) => (
  <Modal
    showClose={false}
    isVisible={showImportModal}
    onClose={handleCloseImportModal}
    prompt="Would you like us to load them?"
    title="We found vehicles from a prior tax year"
    subPrompt="(you can still make changes before you file)"
    actions={
      <React.Fragment>
        <div className="col clean-gutter-right">
          <Button
            disabled={false}
            text="No, thanks"
            primary={false}
            onClick={handleClickNo}
            width="100%"
          />
        </div>
        <div className="col clean-gutter-left">
          <Button
            disabled={false}
            text="Yes, please"
            primary
            onClick={handleClickYes}
            width="100%"
          />
        </div>
      </React.Fragment>
    }
  />
);

export default LastYearVehicleModal;
