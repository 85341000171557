import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { IRS_REJECTED_FORM_NAME } from "../supportCenter.constants";
import {
  getAttachments,
  getAttachmentsIsExpanded
} from "../supportCenter.selectors";
import {
  toggleAttachmentsIsExpanded as toggleAttachmentsIsExpandedCreator,
  setAttachmentsIsExpanded as setAttachmentsIsExpandedCreator,
  setAttachments,
  postSupportRequest as postSupportRequestCreator
} from "../supportCenter.actions";
import { mapSupportFieldsToPayload } from "../supportCenter.utilities";
import userContext from "../../../modules/userContext";
import utilities from "../../../modules/utilities";
import { IRS_REJECTED_SUBJECT } from "../../../modules/constants";
import IrsRejectedForm from "./IrsRejectedForm";

const {
  selectors: { getUserContext }
} = userContext;

const { getOrderIdFromUrl } = utilities;

class IrsRejectedFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  componentWillUnmount() {
    const {
      props: { setAttachmentsIsExpanded }
    } = this;
    setAttachmentsIsExpanded(false);
  }

  onSuccess = formValues => {
    const {
      props: { attachments, postSupportRequest }
    } = this;
    const orderId = getOrderIdFromUrl();
    const payload = mapSupportFieldsToPayload(formValues);

    payload.Subject = IRS_REJECTED_SUBJECT;
    payload.Description = formValues.Body || "";
    if (orderId) payload.OrderId = orderId;

    postSupportRequest(payload, attachments);
  };

  handleClickAttach = () => {
    const {
      props: { toggleAttachmentsIsExpanded }
    } = this;
    toggleAttachmentsIsExpanded();
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const {
      handleSubmit,
      handleClickCancel,
      handleClickAttach,
      props: { attachmentsIsExpanded }
    } = this;
    return (
      <IrsRejectedForm
        onClickAttach={handleClickAttach}
        attachmentsIsExpanded={attachmentsIsExpanded}
        getAttachments={getAttachments}
        setAttachments={setAttachments}
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
      />
    );
  }
}

IrsRejectedFormContainer.propTypes = {
  attachments: PropTypes.array.isRequired,
  attachmentsIsExpanded: PropTypes.bool.isRequired,
  toggleAttachmentsIsExpanded: PropTypes.func.isRequired,
  setAttachmentsIsExpanded: PropTypes.func.isRequired,
  postSupportRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  initialValues: getUserContext(state),
  attachments: getAttachments(state),
  attachmentsIsExpanded: getAttachmentsIsExpanded(state)
});

const mapDispatchToProps = dispatch => ({
  toggleAttachmentsIsExpanded: () =>
    dispatch(toggleAttachmentsIsExpandedCreator()),
  setAttachmentsIsExpanded: isExpanded =>
    dispatch(setAttachmentsIsExpandedCreator(isExpanded)),
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: IRS_REJECTED_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(IrsRejectedFormContainer)
  )
);
