import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { routerReducer } from "react-router-redux";
import userContext from "./userContext";
import error from "./error";
import busyIndicator from "./busyIndicator";
import pendingRequest from "./pendingRequest";
import vehicles from "./vehicles";
import taxYear from "./taxYear";
import order from "./order";
import form from "./form";
import paymentModal from "./paymentModal";
import cart from "./cart";
import httpCache from "./httpCache";
import account from "../screens/account";
import weightIncrease from "../screens/weightIncrease";
import supportCenter from "../screens/supportCenter";
import forgotPin from "../screens/forgot-pin";
import register from "../screens/register";

const appReducer = combineReducers({
  [userContext.constants.STATE_NAME]: userContext.reducer,
  [error.constants.STATE_NAME]: error.reducer,
  [busyIndicator.constants.STATE_NAME]: busyIndicator.reducer,
  [pendingRequest.constants.STATE_NAME]: pendingRequest.reducer,
  [httpCache.constants.STATE_NAME]: httpCache.reducer,
  [account.constants.STATE_NAME]: account.reducer,
  [supportCenter.constants.STATE_NAME]: supportCenter.reducer,
  [forgotPin.constants.STATE_NAME]: forgotPin.reducer,
  [register.constants.STATE_NAME]: register.reducer,
  [vehicles.constants.STATE_NAME]: vehicles.reducer,
  [weightIncrease.constants.STATE_NAME]: weightIncrease.reducer,
  [taxYear.constants.STATE_NAME]: taxYear.reducer,
  [order.constants.STATE_NAME]: order.reducer,
  [form.constants.STATE_NAME]: form.reducer,
  [cart.constants.STATE_NAME]: cart.reducer,
  [paymentModal.constants.STATE_NAME]: paymentModal.reducer,
  form: formReducer,
  routing: routerReducer
});

const rootReducer = (state, action) => {
  if (action.type === userContext.actionTypes.LOGOUT_ASYNC.RECEIVED) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
