import * as actionTypes from "./forgotPin.actionTypes";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.FORGOT_PIN_ASYNC.REQUESTED:
      return {
        ...state,
        success: false
      };
    case actionTypes.FORGOT_PIN_ASYNC.RECEIVED:
      return {
        ...state,
        success: true,
        error: null
      };
    case actionTypes.FORGOT_PIN_ASYNC.ERROR:
      return {
        ...state,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
}
