import React, { Component } from "react";
import controls from "../../../modules/controls";
import ContactInfoSupportForm from "./ContactInfoSupportForm";
import { validateLastName } from "../../../modules/controls/validation";

const {
  validations: {
    validateRequired,
    validatePhone,
    validateEmail,
    validateOptionalPhone,
    validateFirstName
  }
} = controls;

class ContactInfoSupportFormContainer extends Component {
  render() {
    return (
      <ContactInfoSupportForm
        emailValidations={[validateRequired, validateEmail]}
        firstNameValidations={[validateRequired, validateFirstName]}
        lastNameValidations={[validateRequired, validateLastName]}
        tel1Validations={[validateRequired, validatePhone]}
        mobileValidations={[validateOptionalPhone]}
      />
    );
  }
}

export default ContactInfoSupportFormContainer;
