import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormSummaryPanel from "./FormSummaryPanelContainer";

const FormSummaryPanelRepeater = ({ formIds, orderId }) => (
  <Fragment>
    {formIds.length ? (
      formIds.map(formId => (
        <FormSummaryPanel formId={formId} orderId={orderId} />
      ))
    ) : (
      <div className="row form-placeholder">
        <div className="col-12 col-sm py-gutter my-auto form-info">
          <span>0 VINs</span>
        </div>
        <div className="col-12 col-sm mb-gutter mb-sm-0 d-flex justify-content-sm-end align-items-center text-left text-sm-right">
          <span className="i2290-inline-error">Incomplete</span>
        </div>
      </div>
    )}
  </Fragment>
);

FormSummaryPanelRepeater.propTypes = {
  formIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderId: PropTypes.number.isRequired
};

export default FormSummaryPanelRepeater;
