import * as actionTypes from "./order.actionTypes";

const initialState = {
  entities: {},

  ui: {
    taxInfo: {},
    history: {
      orders: {},
      forms: {},
      sort: [],
      irsNotRespAcknowledgement: null,
      scheduleOneStagedForDownload: null
    },
    reports: {
      orders: {},
      forms: {},
      vehicles: {}
    },
    paymentAgreementIsOpen: false,
    ackCopiedVehiclesModalIsDismissed: false,
    taxYearError: null,
    activeOrders: {},
    redirect: null,
    designeeWidgetIsExpanded: false,
    paymentMethodWidgetIsExpanded: true,
    activeOrderId: null,
    activeReceiptModal: null,
    receiptModalEmail: "",
    disclaimerIsVisible: false,
    vehicleChangeInProcess: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "vbp/vehicles/POST_VEHICLE_ASYNC_REQUESTED_ASYNC":
    case "vbp/vehicles/POST_VEHICLE_ASYNC_RECEIVED_ASYNC":
      return {
        ...state,
        ui: {
          ...state.ui,
          vehicleChangeInProcess: action.type.includes("REQUESTED")
        }
      };
    case actionTypes.TOGGLE_DISCLAIMER_IS_VISIBLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          disclaimerIsVisible: !state.ui.disclaimerIsVisible
        }
      };
    case actionTypes.SET_DISCLAIMER_IS_VISIBLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          disclaimerIsVisible: action.payload
        }
      };
    case actionTypes.TOGGLE_PAYMENT_AGREEMENT_IS_OPEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentAgreementIsOpen: !state.ui.paymentAgreementIsOpen
        }
      };
    case actionTypes.SET_PAYMENT_AGREEMENT_IS_OPEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentAgreementIsOpen: action.payload
        }
      };
    case actionTypes.SET_IRS_NOT_RESP_ACKOWLEDGEMENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          history: {
            ...state.ui.history,
            irsNotRespAcknowledgement: action.payload
          }
        }
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.payload
        }
      };
    case actionTypes.SET_STAGED_SCHEDULE_ONE:
      return {
        ...state,
        ui: {
          ...state.ui,
          history: {
            ...state.ui.history,
            scheduleOneStagedForDownload: action.payload
          }
        }
      };
    case actionTypes.FETCH_SCHEDULE_ONE_URI.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          history: {
            ...state.ui.history,
            scheduleOneStagedForDownload: action.payload.Url
          }
        }
      };
    case actionTypes.FETCH_SCHEDULE_ONE_URI.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          history: {
            ...state.ui.history,
            scheduleOneStagedForDownload: null
          }
        }
      };
    case actionTypes.SUBMIT_ORDER.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.payload.redirect
        }
      };
    case actionTypes.SET_ACK_COPIED_VEHICLES_MODAL_IS_DISMISSED:
      return {
        ...state,
        ui: {
          ...state.ui,
          ackCopiedVehiclesModalIsDismissed: action.payload
        }
      };
    case actionTypes.FETCH_ACTIVE_ORDERS_ASYNC.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeOrders: action.payload
        }
      };
    case actionTypes.CHANGE_RECEIPT_MODAL_EMAIL:
      return {
        ...state,
        ui: {
          ...state.ui,
          receiptModalEmail: action.payload
        }
      };
    case actionTypes.SEND_RECEIPT_EMAIL.REQUESTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          receiptModalEmail: "",
          activeReceiptModal: null
        }
      };
    case actionTypes.SET_TAX_YEAR_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          taxYearError: action.payload
        }
      };
    case actionTypes.SET_ACTIVE_RECEIPT_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeReceiptModal: action.payload,
          receiptModalEmail:
            action.payload === null ? "" : state.ui.receiptModalEmail
        }
      };
    case actionTypes.FETCH_ORDERS_ASYNC.RECEIVED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          history: {
            orders: { ...action.payload.data.entities.Orders },
            forms: { ...action.payload.data.entities.Forms },
            sort: action.payload.sort
          },
          redirect: action.payload.redirect || null
        }
      };
    }
    case actionTypes.FETCH_TAX_YEARS_ASYNC.RECEIVED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          taxInfo: action.payload
        }
      };
    }
    case actionTypes.FETCH_HISTORY_ORDER_ASYNC.RECEIVED: {
      return {
        ...state,
        ui: {
          ...state.ui,
          reports: {
            orders: {
              ...state.ui.reports.orders,
              [action.payload.result]:
                action.payload.entities.Orders[action.payload.result]
            },
            forms: { ...action.payload.entities.Forms },
            vehicles: { ...action.payload.entities.Vehicles }
          },
          redirect: action.payload.redirect || null
        }
      };
    }

    case "vbp/vehicles/DELETE_VEHICLE_ASYNC_RECEIVED_ASYNC":
    case "vbp/vehicles/IMPORT_VEHICLES_ASYNC_RECEIVED_ASYNC":
    case actionTypes.DELETE_VEHICLES_ASYNC.RECEIVED:
    case actionTypes.FETCH_ORDER_ASYNC.RECEIVED:
    case actionTypes.POST_ORDER_ASYNC.RECEIVED:
      return {
        ...state,
        entities: {
          // ...state.entities, <-- leave this commented out
          [action.payload.result]:
            action.payload.entities.Orders[action.payload.result]
        },
        ui: {
          ...state.ui,
          redirect: action.payload.redirect || null
        }
      };
    case actionTypes.PATCH_ORDER_ASYNC.RECEIVED: {
      const activeOrders = { ...state.ui.activeOrders };
      if (
        state.entities[action.payload.result].TaxYear.toString() !==
        action.payload.entities.Orders[action.payload.result].TaxYear.toString()
      ) {
        delete activeOrders[state.entities[action.payload.result].TaxYear];
        activeOrders[
          action.payload.entities.Orders[action.payload.result].TaxYear
        ] = action.payload.result;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.result]:
            action.payload.entities.Orders[action.payload.result]
        },
        ui: {
          ...state.ui,
          redirect: action.payload.redirect || null,
          activeOrders
        }
      };
    }

    case actionTypes.TOGGLE_PAYMENT_METHOD_WIDGET_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentMethodWidgetIsExpanded: !state.ui.paymentMethodWidgetIsExpanded
        }
      };
    case actionTypes.SET_PAYMENT_METHOD_WIDGET_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          paymentMethodWidgetIsExpanded: action.payload
        }
      };
    default:
      return state;
  }
}
