import * as actionTypes from "./supportCenter.actionTypes";

const initialState = {
  entities: {},
  ui: {
    faqWidgets: {
      faqAIsExpanded: false,
      faqBIsExpanded: false,
      faqCIsExpanded: false,
      faqDIsExpanded: false,
      faqEIsExpanded: false,
      faqFIsExpanded: false,
      faqGIsExpanded: false,
      faqHIsExpanded: false,
      faqIIsExpanded: false
    },
    attachments: [],
    attachmentsIsExpanded: false,
    redirect: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.payload
        }
      };
    case actionTypes.POST_SUPPORT_REQUEST.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.payload.redirect
        }
      };
    case actionTypes.TOGGLE_FAQ_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          faqWidgets: {
            ...state.ui.faqWidgets,
            [action.payload]: !state.ui.faqWidgets[action.payload]
          }
        }
      };
    case actionTypes.SET_ATTACHMENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          attachments: action.payload
        }
      };
    case actionTypes.TOGGLE_ATTACHMENTS_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          attachmentsIsExpanded: !state.ui.attachmentsIsExpanded
        }
      };
    case actionTypes.SET_ATTACHMENTS_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          attachmentsIsExpanded: action.payload
        }
      };
    default:
      return state;
  }
}
