import * as actionTypes from "./userContext.actionTypes";
import doAsync from "../doAsync";
import controls from "../controls";

const {
  normalizations: { normalizeEIN, normalizePhone, normalizeZipByCountry }
} = controls;

export const signIn = (username, password) =>
  doAsync({
    actionType: actionTypes.LOGIN_ASYNC,
    url: "account/login",
    httpConfig: {
      body: JSON.stringify({ username, password }),
      method: "post"
    },
    httpMethod: "post",
    mapResponseToPayload: r => r,
    noBusySpinner: true
  });

export const logout = () =>
  doAsync({
    actionType: actionTypes.LOGOUT_ASYNC,
    url: "account/logout",
    httpMethod: "get",
    mapResponseToPayload: r => r,
    noBusySpinner: true
  });

export const updateUserContext = user => ({
  type: actionTypes.UPDATE_USER_CONTEXT,
  payload: user
});

export const setUserContextError = error => ({
  type: actionTypes.SET_USER_CONTEXT_ERR,
  payload: error
});

export const toggleCompanyInfoWidgetIsExpanded = () => ({
  type: actionTypes.TOGGLE_COMPANY_INFO_WIDGET_IS_EXPANDED
});

export const setCompanyInfoWidgetIsExpanded = isExpanded => ({
  type: actionTypes.SET_COMPANY_INFO_WIDGET_IS_EXPANDED,
  payload: isExpanded
});

const mapUserToRequest = user => {
  if (!user)
    throw new Error(
      "Attempt to create a user update request. With an empty user object"
    );
  const authorityToOperate =
    user.DriverAuthority && user.DriverAuthority.AuthorityToOperate;
  const request = {
    ...user,
    DriverAuthority: {
      ...user.DriverAuthority,
      ...(authorityToOperate === true || authorityToOperate === false
        ? {
            AuthorityToOperate: authorityToOperate
              ? "LeaseContractCompany"
              : "MyOwn"
          }
        : {})
    }
  };
  return request;
};

const cleanUser = user => {
  if (user.Ein) {
    user.Ein = normalizeEIN(user.Ein);
  }
  if (user.Tel1) {
    user.Tel1 = normalizePhone(user.Tel1);
  }
  if (user.Tel2) {
    user.Tel2 = normalizePhone(user.Tel2);
  }

  Object.keys(user).forEach(k => {
    if (user[k] === null) {
      user[k] = "";
    }
  });

  Object.keys(user.DriverAuthority).forEach(k => {
    if (user.DriverAuthority[k] === null) {
      user.DriverAuthority[k] = "";
    }
  });

  if (user.DriverAuthority.Zip) {
    user.DriverAuthority.Zip = normalizeZipByCountry(
      user.DriverAuthority.Zip,
      null,
      { Country: user.DriverAuthority.Country }
    );
  }

  if (user.DriverAuthority.RecipientPhone) {
    user.DriverAuthority.RecipientPhone = normalizePhone(
      user.DriverAuthority.RecipientPhone
    );
  }
  if (user.DriverAuthority) {
    user.DriverAuthority.AuthorityToOperate =
      user.DriverAuthority.AuthorityToOperate === "LeaseContractCompany";
  }

  // if (Array.isArray(r.driverAuthority) && r.driverAuthority.length === 1) {
  //   r.driverAuthority = r.driverAuthority[0]; // eslint-disable-line
  // } else {
  //   r.driverAuthority = {
  //     authorityToOperate: "MyOwn",
  //     mcnumber: "",
  //     loadBoards: "",
  //     hasPermitCompanyCompliance: false,
  //     hasFactoringCompany: false,
  //     companyName: null,
  //     address: null,
  //     city: null,
  //     state: null,
  //     zip: null,
  //     recipientName: null,
  //     recipientDepartment: null,
  //     recipientEmail: null,
  //     recipientPhone: null,
  //     whenMightYouGetAuthority: null
  //   };
  // }

  return user;
};

export const fetchUser = () =>
  doAsync({
    actionType: actionTypes.FETCH_USER_ASYNC,
    url: "account",
    httpMethod: "get",
    mapResponseToPayload: r => ({
      ...cleanUser(r),
      redirect: null
    }),
    noBusySpinner: false
  });

export const patchUser = (user, redirect) =>
  doAsync({
    actionType: actionTypes.PATCH_USER_ASYNC,
    url: "account",
    httpConfig: {
      body: JSON.stringify(mapUserToRequest(user)),
      headers: {
        ['wait-lock-key']: '*'
      }
    },
    httpMethod: "patch",
    mapResponseToPayload: r => ({
      ...cleanUser(r),
      redirect: redirect || null
    })
  });

export const resetUserContext = () => ({
  type: actionTypes.RESET_USER_CONTEXT
});

export const resetError = () => ({
  type: actionTypes.RESET_ERROR
});

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  redirect
});

export const saveOnBlur = fieldOnTab => ({
  type: actionTypes.SAVE_ON_BLUR,
  fieldOnTab
});

// Will create a request with either
// (1) stubSuccess property to fake a successful server authentication
// (2) stubError property to fake a server authentication error
// function fakeAuthentication(email, password) {
//   const response = {};
//
//   let stubError;
//   let permissions = [];
//   let displayName;
//   let username;
//
//   if (email === "bak@clear-launch.com") {
//     displayName = "Bak Zoumanigui";
//     username = "BakZoumanigui";
//     permissions = ["can-do-anything"];
//   } else if (email === "orion@clear-launch") {
//     displayName = "Orion Jensen";
//     username = "OrionJensen";
//   } else if (email === "Phil.boyer@i2290.com") {
//     displayName = "Phil Boyer";
//     username = "PhilBoyer";
//   } else {
//     stubError = {
//       statusCode: NOT_FOUND
//     };
//   }
//
//   if (password !== "12345") {
//     stubError = {
//       statusCode: BAD_REQUEST
//     };
//   }
//
//   if (stubError) {
//     response.stubError = stubError;
//   } else {
//     response.stubSuccess = {
//       email,
//       displayName,
//       permissions,
//       username
//     };
//   }
//
//   return response;
// }
