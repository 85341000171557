import * as actionTypes from "./supportCenter.actionTypes";
import doAsync from "../../modules/doAsync";

export const toggleFaqIsExpanded = faqName => ({
  type: actionTypes.TOGGLE_FAQ_IS_EXPANDED,
  payload: faqName
});

export const setAttachments = attachments => ({
  type: actionTypes.SET_ATTACHMENTS,
  payload: attachments
});

export const setAttachmentsIsExpanded = isExpanded => ({
  type: actionTypes.SET_ATTACHMENTS_IS_EXPANDED,
  payload: isExpanded
});

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  payload: redirect
});

export const toggleAttachmentsIsExpanded = () => ({
  type: actionTypes.TOGGLE_ATTACHMENTS_IS_EXPANDED
});

export const postSupportRequest = (fields = {}, attachments = [], redirect = '/thank-you') => {
  const data = new FormData();

  Object.keys(fields).forEach(k => {
    data.append(k, fields[k]);
  });

  attachments.forEach(x => {
    data.append("file", x.getRawFile(), x.name);
  });

  return doAsync({
    actionType: actionTypes.POST_SUPPORT_REQUEST,
    url: "support-ticket",
    httpConfig: {
      body: data,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      }
    },
    mapResponseToPayload: () => ({
      redirect
    }),
    noBusySpinner: true
  });
};
