import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import order from "../../../modules/order";
import { FORM_ROUTE } from "../../../modules/constants";
import OrderSummaryPanel from "./OrderSummaryPanel";

const {
  actions: { setActiveReceiptModal: setActiveReceiptModalCreator },
  selectors: { getHistoryOrderHOF, getFormHistory }
} = order;

class OrderSummaryPanelContainer extends Component {
  handleClickReceipt = () => {
    const {
      props: { orderId, setActiveReceiptModal }
    } = this;
    setActiveReceiptModal(orderId);
  };

  handleClickFinish = () => {
    const {
      props: {
        order,
        history: { push }
      }
    } = this;
    push(`${FORM_ROUTE}?order=${order.Id}`);
  };

  render() {
    const {
      handleClickReceipt,
      handleClickFinish,
      props: { orderId, order, isSubmitted, submissionDate }
    } = this;

    let taxYear = null;
    let summaryPrefix = null;
    let paymentSummary = null;
    let showReceiptButton = false;

    if (order) {
      if (order.TaxYear) taxYear = parseInt(order.TaxYear, 10);
      if (order.VinCorrection) summaryPrefix = "VIN CORRECTION";
      if (order.WeightIncrease) summaryPrefix = "WEIGHT INCREASE";
      if (
        order.TransactionAmount !== null &&
        order.TransactionAmount !== undefined &&
        order.TransactionAmount > 0
      ) {
        paymentSummary = `Filing Fee: $${order.TransactionAmount}`;
        showReceiptButton = true;
      } else if (
        order.PaymentTranNum !== null &&
        order.PaymentTranNum !== undefined &&
        order.PaymentTranNum.includes("No Charge")
      ) {
        paymentSummary = "No Charge";
      } else if (order.PaymentMessage === "Unlimited") {
        paymentSummary = "Unlimited";
      } else {
        paymentSummary = "";
      }
    }

    return (
      <OrderSummaryPanel
        showReceiptButton={showReceiptButton}
        paymentSummary={paymentSummary}
        submissionDate={submissionDate}
        onClickFinish={handleClickFinish}
        summaryPrefix={summaryPrefix}
        isSubmitted={isSubmitted}
        orderId={orderId}
        taxYear={taxYear}
        onClickReceipt={handleClickReceipt}
      />
    );
  }
}

OrderSummaryPanelContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
  order: PropTypes.object,
  setActiveReceiptModal: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  submissionDate: PropTypes.object
};

OrderSummaryPanelContainer.propTypes = {
  order: null,
  submissionDate: null
};

const mapStateToProps = (state, props) => {
  const order = getHistoryOrderHOF(props.orderId)(state);
  const formHistory = getFormHistory(state);

  let isSubmitted = false;
  let submissionDate = null;

  if (order && order.Forms) {
    for (let i = 0; i < order.Forms.length; i += 1) {
      if (formHistory[order.Forms[i]].SubmissionStatus !== null) {
        isSubmitted = true;
        submissionDate = new Date(formHistory[order.Forms[i]].FileDate);
      }
    }
  }

  return {
    order,
    isSubmitted,
    submissionDate
  };
};

const mapDispatchToProps = dispatch => ({
  setActiveReceiptModal: orderId =>
    dispatch(setActiveReceiptModalCreator(orderId))
});

const ConnectedOrderSummaryPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryPanelContainer);

ConnectedOrderSummaryPanelContainer.propTypes = {
  isActive: PropTypes.bool.isRequired
};

export default withRouter(ConnectedOrderSummaryPanelContainer);
