import React from "react";
import PropTypes from "prop-types";
import "./designeeForm.css";

const DesigneeView = ({ ThirdPyesNo, ThirdPname, ThirdPphone, ThirdPpin }) => (
  <div className="row widget-body mt-gutter">
    {ThirdPyesNo ? (
      <>
        <div className="col-12 col-md-12 font-weight-bold">
          Third party designee
        </div>
        <div className="col-12 col-md-12">{ThirdPname}</div>
        <div className="col-12 col-md-12">{ThirdPphone}</div>
        <div className="col-12 col-md-12">PIN: {ThirdPpin}</div>
      </>
    ) : (
      <div className="col-12 col-md-12">No Third party designee</div>
    )}
  </div>
);

DesigneeView.propTypes = {
  ThirdPyesNo: PropTypes.bool,
  ThirdPname: PropTypes.string,
  ThirdPphone: PropTypes.string,
  ThirdPpin: PropTypes.string
};

DesigneeView.defaultProps = {
  ThirdPyesNo: false,
  ThirdPname: "",
  ThirdPphone: "",
  ThirdPpin: ""
};

export default DesigneeView;
