import React from "react";
import * as PropTypes from "prop-types";
import Button from "./Button";

const DetailsButton = ({ isExpanded, onClick }) => (
  <Button
    onClick={onClick}
    disabled={false}
    text="Details"
    primary
    className="details-button"
    icon={isExpanded ? "arrow-chevron-up" : "arrow-chevron-down"}
  />
);

DetailsButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default DetailsButton;
