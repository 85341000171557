import React from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
// import controls from "../../controls";
import "./summary.css";

// const {
//   components: { Button }
// } = controls;

const Summary = ({
  isExpanded,
  // toggleIsExpanded,
  headerChildren,
  bodyChildren,
  radCorners
}) => (
  <div className={radCorners ? "summary-widget rad-corners" : "summary-widget"}>
    <div className="summary-header bg-white">
      {headerChildren}
      {/* <div className="row"> */}
      {/*  <div className="col mb-2 mb-md-0">{headerChildren}</div> */}
      {/*  <div className="col-12 col-md-3 text-right my-auto"> */}
      {/*    <Button */}
      {/*      onClick={toggleIsExpanded} */}
      {/*      primary */}
      {/*      text={isExpanded ? "Hide" : "Show"} */}
      {/*      disabled={false} */}
      {/*      width="150px" */}
      {/*    /> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
    <AnimateHeight duration={500} height={isExpanded ? "auto" : 0}>
      {bodyChildren}
    </AnimateHeight>
  </div>
);

Summary.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  // toggleIsExpanded: PropTypes.func.isRequired,
  headerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  bodyChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  radCorners: PropTypes.bool
};

Summary.defaultProps = {
  radCorners: true
};

export default Summary;
