import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
  GridToolbar
} from "@progress/kendo-react-grid";
import controls from "../../controls";
import utilities from "../../utilities";
import VehicleGridReadOnlyCell from "./VehicleGridReadOnlyCell";
import "./vehicleGrid.css";

const {
  components: { Button }
} = controls;

const {
  math: { fastFormatMoney },
  generic: { isEmpty }
} = utilities;

const VehicleGrid = ({
  data,
  readOnly,
  CommandCell,
  onClickAddNew,
  onClickImport,
  onClickDeleteAll,
  skip,
  take,
  onPageChange,
  totalTax
}) => (
  // 10px padding
  <Grid
    className={`vehicle-grid tax-grid${
      data.length <= 10 ? " rounded-tax-grid" : ""
    }`}
    data={data.slice(skip, skip + take)}
    skip={skip}
    take={take}
    total={data.length}
    pageable={
      data.length > 10
        ? { type: "input", buttonCount: 5, pageSizes: [10, 20, 50, 100] }
        : false
    }
    onPageChange={onPageChange}
  >
    <GridNoRecords>No vehicle found</GridNoRecords>
    {!readOnly && (
      <GridToolbar>
        <div className="row align-items-center">
          {!readOnly && (
            <div className="col-auto my-auto text-left">
              <strong>
                <h2 className="vehicle-grid-total mb-0">
                  Number of Vehicles: {data.length}
                </h2>
                <h2 className="vehicle-grid-total mb-gutter mb-sm-0">
                  Total Tax:{" "}
                  {isEmpty(totalTax) ? "N/A" : fastFormatMoney(totalTax)}
                </h2>
              </strong>
            </div>
          )}
          <div className="col-12 col-sm text-nowrap d-flex justify-content-end">
            <Button
              className="d-inline"
              onClick={onClickAddNew}
              primary
              text="Add New"
              disabled={false}
            />
            <Button
              className="grid-button-spaced d-inline"
              onClick={onClickImport}
              secondary
              text="Upload"
              disabled={false}
            />
            <Button
              className="grid-button-spaced d-inline"
              onClick={onClickDeleteAll}
              secondary
              text="Delete All"
              disabled={false}
            />
          </div>
        </div>
      </GridToolbar>
    )}
    {/* Agricultural: false
BuyerAddress1: null
BuyerAddress2: null
BuyerCity: null
BuyerCountry: null
BuyerName: null
BuyerState: null
BuyerZip: null
CorrectedVehicleId: null
CorrectionExplanation: null
CreatedDate: "2021-05-13T12:02:04.517"
CreditAmount: 0
CreditEventDate: null
CreditReason: null
DateCategory: "3"
Description: "tytrytryrtytry"
Id: 2101359
IncreasedTaxValue: null
IncreasedWeightCategory: null
IsBuyerBusiness: null
IsBuyerDataIncluded: null
IsTaxable: true
LineNumber: 0
Logging: false
LossDateCategory: null
Suspend: false
TaxValue: 137.5
ValidationResult: null
Vin: "1GC2CVE8XFZ159110"
WeightCategory: "V"
WeightDescription: "V - Over 75,000 lbs." */}
    {readOnly && (
      <Column field="Vin" title="VIN" cell={VehicleGridReadOnlyCell} />
    )}
    {readOnly && (
      <Column
        field="Description"
        title="Description"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {readOnly && (
      <Column
        field="WeightDescription"
        title="Weight Description"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {readOnly && (
      <Column
        field="TaxValue"
        title="Tax"
        editable={false}
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        field="Vin"
        title="VIN"
        width="180px"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        width="300px"
        field="Description"
        title="Description"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        width={readOnly ? "120px" : "120px"}
        field="WeightCategory"
        title="Gross Weight"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        field="DateCategory"
        title="First Used"
        width="130px"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        width="93px"
        field="TaxValue"
        title="Tax"
        editable={false}
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && <Column field="" title="" />}
    {!readOnly && (
      <Column className="command-cell" width="100" cell={CommandCell} locked />
    )}
  </Grid>
);

VehicleGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  onClickAddNew: PropTypes.func,
  onClickImport: PropTypes.func,
  onClickDeleteAll: PropTypes.func,
  skip: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalTax: PropTypes.number
};

VehicleGrid.defaultProps = {
  readOnly: false,
  onClickAddNew: null,
  onClickImport: null,
  totalTax: null
};

export default VehicleGrid;
