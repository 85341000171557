const monthLookup = {
  12: "July",
  11: "August",
  10: "September",
  9: "October",
  8: "November",
  7: "December",
  6: "January",
  5: "February",
  4: "March",
  3: "April",
  2: "May",
  1: "June"
};

const monthAbbreviationLookup = {
  12: "July",
  11: "Aug",
  10: "Sept",
  9: "Oct",
  8: "Nov",
  7: "Dec",
  6: "Jan",
  5: "Feb",
  4: "March",
  3: "April",
  2: "May",
  1: "June"
};

const importNameLookup = {
  VIN: "Vin",
  VEHICLECREDITDATE: "VehicleCreditDate",
  WEIGHTCATEGORY: "WeightCategory",
  LOGGING: "Logging",
  REASON: "CreditReason",
  ISBUYERBUSINESS: "IsBuyerBusiness",
  BUYERNAME: "BuyerName",
  BUYERADDRESS1: "BuyerAddress1",
  BUYERADDRESS2: "BuyerAddress2",
  BUYERCITY: "BuyerCity",
  BUYERSTATE: "BuyerState",
  BUYERZIP: "BuyerZip",
  BUYERCOUNTRY: "BuyerCountry",
  TAXPAIDPRIORPERIOD: "TaxPaidPriorPeriod",
  VEHICLEDATE: "VehicleDate",
  SUSPENDED: "Suspend",
  AGRICULTURAL: "Agricultural",
  DESCRIPTION: "Description"
};

const monthCSVLookup = {
  "01": 6,
  "02": 5,
  "03": 4,
  "04": 3,
  "05": 2,
  "06": 1,
  "07": 12,
  "08": 11,
  "09": 10,
  "10": 9,
  "11": 8,
  "12": 7
};

const weightLookup = {
  A: "A - 55,000 lbs.",
  B: "B - 55,001 - 56,000 lbs.",
  C: "C - 56,001 - 57,000 lbs.",
  D: "D - 57,001 - 58,000 lbs.",
  E: "E - 58,001 - 59,000 lbs.",
  F: "F - 59,001 - 60,000 lbs.",
  G: "G - 60,001 - 61,000 lbs.",
  H: "H - 61,001 - 62,000 lbs.",
  I: "I - 62,001 - 63,000 lbs.",
  J: "J - 63,001 - 64,000 lbs.",
  K: "K - 64,001 - 65,000 lbs.",
  L: "L - 65,001 - 66,000 lbs.",
  M: "M - 66,001 - 67,000 lbs.",
  N: "N - 67,001 - 68,000 lbs.",
  O: "O - 68,001 - 69,000 lbs.",
  P: "P - 69,001 - 70,000 lbs.",
  Q: "Q - 70,001 - 71,000 lbs.",
  R: "R - 71,001 - 72,000 lbs.",
  S: "S - 72,001 - 73,000 lbs.",
  T: "T - 73,001 - 74,000 lbs.",
  U: "U - 74,001 - 75,000 lbs.",
  V: "Over 75,000 lbs."
};

const monthOrderLookup = {
  12: 1,
  11: 2,
  10: 3,
  9: 4,
  8: 5,
  7: 6,
  6: 7,
  5: 8,
  4: 9,
  3: 10,
  2: 11,
  1: 12
};

const dateMonthToDateCategory = {
  6: 12,
  7: 11,
  8: 10,
  9: 9,
  10: 8,
  11: 7,
  0: 6,
  1: 5,
  2: 4,
  3: 3,
  4: 2,
  5: 1
};

export const lookupMonth = k => monthLookup[k];

export const lookupMonthAbbreviation = k => monthAbbreviationLookup[k];

export const lookupDateCategoryByJavascriptDateMonth = k =>
  dateMonthToDateCategory[k];

export const lookupMonthCSV = k => monthCSVLookup[k];

export const lookupWeight = k => weightLookup[k];

export const lookupImportName = k => importNameLookup[k] || "";

export const lookupMonthOrder = k => monthOrderLookup[k];

export const getFriendlyStructureName = soleProprietor => {
  switch (soleProprietor) {
    case true:
      return "Sole Proprietor";
    case false:
      return "Inc, LLC, LP";
    default:
      return null;
  }
};
