import React, { Component } from "react";
import PropTypes from "prop-types";
import { getFormSyncErrors, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import utilities from "../../utilities";

const {
  transform: { sanitizeValue }
} = utilities;

const withFieldSaver = (WrappedComponent, formName, validate) => {
  class WithFieldSaver extends Component {
    componentDidUpdate(prevProps) {
      const { initialValues, formSyncErrors, touch, untouch } = this.props;
      const {
        initialValues: prevInitialValues,
        formSyncErrors: prevFormSyncErrors
      } = prevProps;
      if (
        initialValues === prevInitialValues ||
        formSyncErrors === prevFormSyncErrors
      )
        return;

      if (formSyncErrors) {
        const latestErrors = validate(initialValues);
        touch(
          ...Object.keys(formSyncErrors).filter(k =>
            initialValues.hasOwnProperty(k)
          )
        );
        untouch(
          ...Object.keys(latestErrors).filter(
            k => latestErrors[k] === undefined
          )
        );
      }
    }

    handleSave = e => {
      const { initialValues, formSyncErrors, patch } = this.props;
      const sanitizedValue = sanitizeValue(e.target.value);

      if (!e.target.value && !initialValues[e.target.name]) {
        return;
      }

      if (sanitizedValue === initialValues[e.target.name]) {
        return;
      }

      if (
        formSyncErrors &&
        (formSyncErrors[e.target.name] !== undefined ||
          formSyncErrors.Email !== undefined)
      ) {
        return;
      }

      patch({
        [e.target.name]: sanitizedValue
      });
    };

    render() {
      return <WrappedComponent save={this.handleSave} />;
    }
  }

  WithFieldSaver.propTypes = {
    patch: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formSyncErrors: PropTypes.object
  };

  WithFieldSaver.defaultProps = {
    initialValues: {}
  };

  const mapStateToProps = state => ({
    formValues: getFormValues(formName)(state),
    formSyncErrors: getFormSyncErrors(formName)(state)
  });

  return connect(mapStateToProps)(
    reduxForm({
      form: formName,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
      destroyOnUnmount: false
    })(WithFieldSaver)
  );
};

export default withFieldSaver;
