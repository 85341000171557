import * as actionTypes from "./form.actionTypes";
import order from "../order";

const { actionTypes: orderActionTypes } = order;

const initialState = {
  entities: {},
  ui: {
    formOrder: [],
    widgets: {},
    redirect: null,
    paymentAgreementIsOpen: false
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_CURRENT_PAGE:
      return {
        ...state,
        ui: {
          ...state.ui,
          widgets: {
            ...state.ui.widgets,
            [action.payload.id]: {
              ...state.ui.widgets[action.payload.id],
              [action.payload.sliceName]: {
                ...state.ui.widgets[action.payload.id][
                  action.payload.sliceName
                ],
                currentPage: action.payload.pageNumber
              }
            }
          }
        }
      };
    case actionTypes.CHANGE_PAGE_SIZE:
      return {
        ...state,
        ui: {
          ...state.ui,
          widgets: {
            ...state.ui.widgets,
            [action.payload.id]: {
              ...state.ui.widgets[action.payload.id],
              [action.payload.sliceName]: {
                ...state.ui.widgets[action.payload.id][
                  action.payload.sliceName
                ],
                pageSize: action.payload.pageSize
              }
            }
          }
        }
      };

    case "vbp/vehicles/DELETE_VEHICLE_ASYNC_RECEIVED_ASYNC":
    case "vbp/vehicles/IMPORT_VEHICLES_ASYNC_RECEIVED_ASYNC":
    case orderActionTypes.DELETE_VEHICLES_ASYNC.RECEIVED:
    case orderActionTypes.POST_ORDER_ASYNC.RECEIVED:
    case orderActionTypes.FETCH_ORDER_ASYNC.RECEIVED: {
      const formVehicleWidgets = {};
      action.payload.entities.Orders[action.payload.result].Forms.forEach(x => {
        formVehicleWidgets[x] = {
          isExpanded: true,
          taxableVehiclesGrid: {
            currentPage: 0,
            pageSize: 10
          },
          creditVehiclesGrid: {
            currentPage: 0,
            pageSize: 10
          }
        };
      });

      return {
        ...state,
        entities: {
          ...action.payload.entities.Forms
        },
        ui: {
          ...state.ui,
          formOrder: action.payload.entities.Orders[
            action.payload.result
          ].Forms.filter(
            id => action.payload.entities.Forms[id].DateCategory !== 0
          ).sort(
            (x, y) =>
              action.payload.entities.Forms[y].DateCategory -
              action.payload.entities.Forms[x].DateCategory
          ),
          widgets: formVehicleWidgets
        }
      };
    }
    case actionTypes.TOGGLE_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          widgets: {
            ...state.ui.widgets,
            [action.payload]: {
              ...state.ui.widgets[action.payload],
              isExpanded: !state.ui.widgets[action.payload].isExpanded
            }
          }
        }
      };
    default:
      return state;
  }
}
