// import controls from "../controls";
import { CREDIT_REASON_SOLD } from "../constants";

// const {
//   constants: { NO_SELECTION_DROPDOWN_VALUE }
// } = controls;

// export const cleanVehicle = v => {
//   const dateCategory = v.DateCategory ? v.DateCategory.toString() : null;
//   return {
//     ...v,
//     DateCategory: dateCategory
//   };
// };
//
// export const createLookup = values => {
//   const realLookup = {
//     "Please select": NO_SELECTION_DROPDOWN_VALUE
//   };
//
//   values.forEach(v => {
//     realLookup[v.display] = v.real;
//   });
//
//   const displayLookup = {
//     [NO_SELECTION_DROPDOWN_VALUE]: "Please select"
//   };
//
//   values.forEach(v => {
//     displayLookup[v.real] = v.display;
//   });
//
//   return {
//     realLookup,
//     displayLookup
//   };
// };

export const mapTaxableVehicleToPayload = data =>
  (({
    Id,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable
  }) => ({
    Id: Id || undefined,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable
  }))(data);

export const mapCreditVehicleToPayload = data =>
  (({
    Id,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable,
    CreditEventDate,
    CreditReason,
    BuyerName,
    BuyerAddress1,
    BuyerAddress2,
    BuyerCity,
    BuyerState,
    BuyerCountry,
    BuyerZip,
    IsBuyerBusiness,
    CreditAmount,
    IsBuyerDataIncluded,
    TaxPaidPriorPeriod
  }) => ({
    Id: Id || undefined,
    Vin,
    DateCategory,
    WeightCategory,
    Logging,
    Agricultural,
    Description,
    Suspend,
    TaxValue,
    IsTaxable,
    CreditEventDate,
    CreditReason,
    BuyerName: CreditReason === CREDIT_REASON_SOLD ? BuyerName : null,
    BuyerAddress1: CreditReason === CREDIT_REASON_SOLD ? BuyerAddress1 : null,
    BuyerAddress2: CreditReason === CREDIT_REASON_SOLD ? BuyerAddress2 : null,
    BuyerCity: CreditReason === CREDIT_REASON_SOLD ? BuyerCity : null,
    BuyerState: CreditReason === CREDIT_REASON_SOLD ? BuyerState : null,
    BuyerCountry: CreditReason === CREDIT_REASON_SOLD ? BuyerCountry : null,
    BuyerZip: CreditReason === CREDIT_REASON_SOLD ? BuyerZip : null,
    IsBuyerBusiness:
      CreditReason === CREDIT_REASON_SOLD ? IsBuyerBusiness : null,
    IsBuyerDataIncluded:
      CreditReason === CREDIT_REASON_SOLD ? IsBuyerDataIncluded : null,
    CreditAmount,
    TaxPaidPriorPeriod
  }))(data);

export const sortVehicles = (vehicles, withErrors = false) => {
  const lineSort = (_x, _y) =>
    (vehicles[_x].LineNumber || 0) - (vehicles[_y].LineNumber || 0);

  const errorSort = (_x, _y) => {
    if (vehicles[_x].errors) {
      return -1;
    }
    if (vehicles[_y].errors) {
      return 1;
    }
    return 0;
  };

  return Object.keys(vehicles)
    .filter(x => vehicles[x] !== undefined)
    .map(x => parseInt(x, 10))
    .sort((x, y) =>
      !withErrors
        ? lineSort(x, y)
        : errorSort(x, y) === 0
        ? lineSort(x, y)
        : errorSort(x, y)
    );
};
