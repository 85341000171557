import * as actionTypes from "./vehicles.actionTypes";
import controls from "../controls";
import order from "../order";
import { CREDIT_REASON_SOLD } from "../constants";
import * as utilities from "./vehicles.utilities";

const {
  validations: {
    validateVehicleFormWithNullValid: validate,
    validateCreditVehicleFormWithNullValid: validateCredit
  }
} = controls;

const { sortVehicles } = utilities;

const { actionTypes: orderActionTypes } = order;

const initialState = {
  entities: {},
  ui: {
    taxableVehiclesGrid: {
      gridOrder: [],
      showImporter: false,
      stagedForCreation: null,
      stagedForDeletion: null,
      allAreStagedForDeletion: false,
      currentPage: 0,
      pageSize: 10
    },
    creditVehiclesGrid: {
      gridOrder: [],
      showImporter: false,
      stagedForCreation: null,
      stagedForDeletion: null,
      allAreStagedForDeletion: false,
      currentPage: 0,
      pageSize: 10
    },
    redirect: null,
    workingCopies: {}
  }
};

export default function reducer(state = initialState, action) {
  function sortNewOrder(workingCopies) {
    return Object.keys(workingCopies)
      .map(x => parseInt(x, 10))
      .sort((x, y) => {
        if (workingCopies[x].errors) {
          return -1;
        }
        if (workingCopies[y].errors) {
          return 1;
        }
        return (
          (workingCopies[x].LineNumber || 0) -
          (workingCopies[y].LineNumber || 0)
        );
      });
  }

  switch (action.type) {
    case actionTypes.CHANGE_CURRENT_PAGE:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            currentPage: action.payload.pageNumber
          }
        }
      };

    case actionTypes.CHANGE_PAGE_SIZE:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            pageSize: action.payload.pageSize
          }
        }
      };

    case actionTypes.TOGGLE_ALL_ARE_STAGED_FOR_DELETION:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            allAreStagedForDeletion: !state.ui[action.payload.sliceName]
              .allAreStagedForDeletion
          }
        }
      };

    case actionTypes.STAGE_FOR_CREATION:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            stagedForCreation: action.payload.data
            // currentPage: 0
          }
        }
      };

    case actionTypes.UPDATE_TAXABLE_VEHICLE_STAGING:
      return {
        ...state,
        ui: {
          ...state.ui,
          taxableVehiclesGrid: {
            ...state.ui.taxableVehiclesGrid,
            stagedForCreation: {
              ...state.ui.taxableVehiclesGrid.stagedForCreation,
              ...action.payload.data
              // TaxValue: computeTax(action.payload.data, action.payload.taxTable)
            }
          }
        }
      };

    case actionTypes.UPDATE_CREDIT_VEHICLE_STAGING:
      return {
        ...state,
        ui: {
          ...state.ui,
          creditVehiclesGrid: {
            ...state.ui.creditVehiclesGrid,
            stagedForCreation: {
              ...state.ui.creditVehiclesGrid.stagedForCreation,
              ...action.payload.data
            }
          }
        }
      };

    case actionTypes.STAGE_FOR_DELETION:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            stagedForDeletion: action.payload.id
          }
        }
      };

    case actionTypes.TOGGLE_SHOW_IMPORTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            showImporter: !state.ui[action.payload.sliceName].showImporter
          }
        }
      };

    case actionTypes.SET_SHOW_IMPORTER:
      return {
        ...state,
        ui: {
          ...state.ui,
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            showImporter: action.payload.showImporter
          }
        }
      };

    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.redirect
        }
      };

    case orderActionTypes.DELETE_VEHICLES_ASYNC.RECEIVED:
    case orderActionTypes.POST_ORDER_ASYNC.RECEIVED:
    case orderActionTypes.FETCH_ORDER_ASYNC.RECEIVED: {
      if (!action.payload.entities.Vehicles) {
        return initialState;
      }

      let workingCopies = JSON.parse(
        JSON.stringify(action.payload.entities.Vehicles)
      );

      let taxableVehiclesGridOrder;
      let creditVehiclesGridOrder;

      if (action.payload.shouldValidate) {
        Object.keys(workingCopies).forEach(k => {
          workingCopies[k].errors = workingCopies[k].IsTaxable
            ? validate(workingCopies[k])
            : validateCredit(workingCopies[k]);
        });

        const newOrder = sortVehicles(workingCopies, true);

        taxableVehiclesGridOrder = newOrder.filter(
          x => workingCopies[x].IsTaxable
        );

        creditVehiclesGridOrder = newOrder.filter(
          x => !workingCopies[x].IsTaxable
        );

        workingCopies = newOrder.reduce((acc, curr) => {
          acc[curr] = workingCopies[curr];
          return acc;
        }, {});
      } else {
        const vehicleIds = [].concat(
          ...action.payload.entities.Orders[action.payload.result].Forms.map(
            id => action.payload.entities.Forms[id].Vehicles
          )
        );

        const newOrder = sortVehicles(workingCopies);

        taxableVehiclesGridOrder = newOrder.filter(
          x => workingCopies[x].IsTaxable && vehicleIds.includes(x)
        );

        creditVehiclesGridOrder = newOrder.filter(
          x => !workingCopies[x].IsTaxable && vehicleIds.includes(x)
        );
      }

      return {
        ...state,
        entities: {
          ...workingCopies
        },
        ui: {
          ...state.ui,
          workingCopies,
          taxableVehiclesGrid: {
            ...state.ui.taxableVehiclesGrid,
            gridOrder: taxableVehiclesGridOrder,
            allAreStagedForDeletion: false
          },
          creditVehiclesGrid: {
            ...state.ui.creditVehiclesGrid,
            gridOrder: creditVehiclesGridOrder,
            allAreStagedForDeletion: false
          }
        }
      };
    }

    case actionTypes.IMPORT_VEHICLES_ASYNC.RECEIVED: {
      if (!action.payload.entities.Vehicles) {
        return initialState;
      }

      const workingCopies = JSON.parse(
        JSON.stringify(action.payload.entities.Vehicles)
      );

      Object.keys(workingCopies).forEach(k => {
        const errors = workingCopies[k].IsTaxable
          ? validate(workingCopies[k])
          : validateCredit(workingCopies[k]);
        workingCopies[k].errors = errors;
        workingCopies[k].inEdit = !!errors;
        workingCopies[k].isExpanded =
          workingCopies[k].CreditReason === CREDIT_REASON_SOLD;
      });

      const newOrder = sortNewOrder(workingCopies);

      const taxableVehiclesGridOrder = newOrder.filter(
        x => workingCopies[x].IsTaxable
      );

      const creditVehiclesGridOrder = newOrder.filter(
        x => !workingCopies[x].IsTaxable
      );

      return {
        ...state,
        entities: action.payload.entities.Vehicles,
        ui: {
          ...state.ui,
          workingCopies,
          taxableVehiclesGrid: {
            ...state.ui.taxableVehiclesGrid,
            gridOrder: taxableVehiclesGridOrder,
            stagedForCreation: null,
            allAreStagedForDeletion: false,
            showImporter: false,
            currentPage: 0
          },
          creditVehiclesGrid: {
            ...state.ui.creditVehiclesGrid,
            gridOrder: creditVehiclesGridOrder,
            stagedForCreation: null,
            allAreStagedForDeletion: false,
            showImporter: false,
            currentPage: 0
          }
        }
      };
    }

    case actionTypes.UPDATE_WORKING_COPY:
      return {
        ...state,
        ui: {
          ...state.ui,
          workingCopies: {
            ...state.ui.workingCopies,
            [action.payload.id]: {
              ...state.ui.workingCopies[action.payload.id],
              ...action.payload.update
            }
          }
        }
      };

    case actionTypes.POST_VEHICLE_ASYNC.RECEIVED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload.data
        },
        ui: {
          ...state.ui,
          workingCopies: {
            ...state.ui.workingCopies,
            [action.payload.id]: {
              ...action.payload.data,
              inEdit: false,
              isExpanded:
                action.payload.data.CreditReason === CREDIT_REASON_SOLD
            }
          },
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            gridOrder: [
              action.payload.id,
              ...state.ui[action.payload.sliceName].gridOrder
            ],
            stagedForCreation: null
          }
        }
      };

    case actionTypes.PATCH_VEHICLE_ASYNC.RECEIVED:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload.data
        },
        ui: {
          ...state.ui,
          workingCopies: {
            ...state.ui.workingCopies,
            [action.payload.id]: {
              ...action.payload.data,
              inEdit: false,
              isExpanded:
                action.payload.data.CreditReason === CREDIT_REASON_SOLD
            }
          },
          taxableVehiclesGrid: {
            // TODO key off slicename
            ...state.ui.taxableVehiclesGrid,
            stagedForCreation: null
          },
          creditVehiclesGrid: {
            ...state.ui.creditVehiclesGrid,
            stagedForCreation: null
          }
        }
      };

    case actionTypes.DELETE_VEHICLE_ASYNC.RECEIVED: {
      const position = state.ui[action.payload.sliceName].gridOrder.indexOf(
        action.payload.id
      );

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: undefined
        },
        ui: {
          ...state.ui,
          workingCopies: {
            ...state.ui.workingCopies,
            [action.payload.id]: undefined
          },
          [action.payload.sliceName]: {
            ...state.ui[action.payload.sliceName],
            gridOrder: [
              ...state.ui[action.payload.sliceName].gridOrder.slice(
                0,
                position
              ),
              ...state.ui[action.payload.sliceName].gridOrder.slice(
                position + 1
              )
            ],
            stagedForDeletion: null,
            stagedForCreation: null
          }
        }
      };
    }

    case actionTypes.REVERT_CHANGES:
      return {
        ...state,
        ui: {
          ...state.ui,
          workingCopies: {
            ...state.ui.workingCopies,
            [action.payload]: {
              ...state.entities[action.payload]
            }
          }
        }
      };

    default:
      return state;
  }
}
