import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";

class FooterContainer extends Component {
  handleClickTermsConditions = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/terms-conditions");
  };

  handleClickPrivacyPolicy = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/privacy-policy");
  };

  render() {
    const { handleClickPrivacyPolicy, handleClickTermsConditions } = this;
    return (
      <Footer
        onClickPrivacyPolicy={handleClickPrivacyPolicy}
        onClickTermsConditions={handleClickTermsConditions}
      />
    );
  }
}

export default withRouter(FooterContainer);
