import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs";
import TextField from "@material-ui/core/TextField";
import "./receiptModal.css";
import controls from "../../../modules/controls";

const {
  components: { Button }
} = controls;

const ReceiptModal = ({
  isVisible,
  orderId,
  onClose,
  onClickSendReceipt,
  emailValue,
  onChangeEmail,
  error
}) => (
  <React.Fragment>
    {isVisible && (
      <Dialog title={`Order #${orderId}`} onClose={onClose}>
        <div className="receipt-modal-body">
          <TextField
            variant="outlined"
            label="Enter e-mail"
            value={emailValue}
            onChange={onChangeEmail}
            onKeyPress={e => (e.key === "Enter" ? onClickSendReceipt() : false)}
          />
          {error && <span className="i2290-inline-error">{error}</span>}
        </div>
        <div className="row">
          <div className="col clean-gutter-right">
            <Button
              secondary
              disabled={false}
              text="Cancel"
              onClick={onClose}
              width="100%"
            />
          </div>
          <div className="col clean-gutter-left">
            <Button
              disabled={false}
              primary
              onClick={onClickSendReceipt}
              text="Submit"
              width="100%"
            />
          </div>
        </div>
      </Dialog>
    )}
  </React.Fragment>
);

ReceiptModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickSendReceipt: PropTypes.func.isRequired,
  emailValue: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  error: PropTypes.string
};

ReceiptModal.defaultProps = {
  error: null
};

export default ReceiptModal;
