import React from "react";
import PropTypes from "prop-types";
import utilities from "../../utilities";
import "./totalTaxIndicator.css";

const {
  math: { fastFormatMoney }
} = utilities;

const TotalTaxIndicator = ({ totalTax }) => (
  <div className="static-summ-head total-tax-ind">
    <div className="row h-100">
      <div className="col text-left my-auto">
        <span>Total Tax:</span>
      </div>
      <div className="col text-right my-auto">
        <h2>{totalTax !== null ? fastFormatMoney(totalTax) : "N/A"}</h2>
      </div>
    </div>
  </div>
);

TotalTaxIndicator.propTypes = {
  totalTax: PropTypes.number
};

TotalTaxIndicator.defaultProps = {
  totalTax: null
};

export default TotalTaxIndicator;
