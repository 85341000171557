import React from "react";
import { Form } from "redux-form";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import WizardController from "./WizardControllerContainer";
import order from "../../../modules/order";
import { BANK, MAIL, EFTPS, CC } from "../../../modules/constants";

const {
  components: { PaymentMethodForm }
} = order;

const {
  components: { Link }
} = controls;

const IRSPayment = ({
  onSubmit,
  onBlur,
  onClickSkip,
  onClickIRSWebsite,
  onClickEFTPSWebsite,
  onClickWeCanHelp,
  formValues,
  netTax
}) => (
  <Form
    className="container-fluid widget-background drop-shadow p-gutter mb-gutter rad-corners"
    onSubmit={onSubmit}
  >
    {formValues && formValues.PaymentAccount ? (
      <React.Fragment>
        <div
          className="new-user-header-cntr mb-gutter"
          style={{ maxWidth: "800px", margin: "auto" }}
        >
          <h1 className="header-size">How will you pay your taxes?</h1>
          {(() => {
            switch (
              netTax === 0 ? "" : formValues.PaymentAccount.PaymentMethod
            ) {
              case BANK:
                return (
                  <React.Fragment>
                    <h2 className="subheader-size">
                      Checking Account Direct Debit (recommended)
                    </h2>
                  </React.Fragment>
                );
              case MAIL:
                return (
                  <React.Fragment>
                    <h2 className="subheader-size">
                      I will mail my IRS payment
                    </h2>
                  </React.Fragment>
                );
              case CC:
                return (
                  <React.Fragment>
                    <h2 className="subheader-size">
                      I will pay with my Credit Card at{" "}
                      <Link
                        clickHandler={onClickIRSWebsite}
                        text="IRS.gov"
                      ></Link>
                    </h2>
                  </React.Fragment>
                );
              case EFTPS:
                return (
                  <React.Fragment>
                    <h2 className="subheader-size">
                      I will pay using EFTPS at{" "}
                      <Link
                        clickHandler={onClickEFTPSWebsite}
                        text="EFTPS.gov"
                      ></Link>
                    </h2>
                  </React.Fragment>
                );
              default:
                return false;
            }
          })()}
        </div>
        <div className="new-user-inner-content mb-gutter">
          <PaymentMethodForm
            onClickIRSWebsite={onClickIRSWebsite}
            onClickEFTPSWebsite={onClickEFTPSWebsite}
            netTax={netTax}
            onClickWeCanHelp={onClickWeCanHelp}
            formValues={formValues}
            onBlur={onBlur}
          />
        </div>
      </React.Fragment>
    ) : (
      <span>Loading...</span>
    )}

    <div className="new-user-ctrl-area new-user-inner-content">
      <WizardController />
    </div>
  </Form>
);

IRSPayment.propTypes = {
  onClickIRSWebsite: PropTypes.func.isRequired,
  onClickEFTPSWebsite: PropTypes.func.isRequired,
  netTax: PropTypes.number,
  onClickSkip: PropTypes.func.isRequired,
  onClickWeCanHelp: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired
};

IRSPayment.defaultProps = {
  netTax: null
};

export default IRSPayment;
