/* eslint-disable react/no-array-index-key */

import React, { Component } from "react";
import PropTypes from "prop-types";
import MaterialSelect from "@material-ui/core/NativeSelect";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ReactDOM from "react-dom";
import {
  VIN_CORRECTION_DD_VAL,
  WEIGHT_INCREASE_DD_VAL
} from "../../../modules/constants";
import "./filer.css";
import LastYearVehicleModal from "../../dashboard/components/LastYearVehiclesModal";

class Filer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    this.setState({
      // new way of doing refs has a bug with material ui, have to do old way
      labelWidth: ReactDOM.findDOMNode(this.inputLabel).offsetWidth // eslint-disable-line react/no-find-dom-node
    });
  }

  render() {
    const {
      value,
      label,
      onChange,
      options,
      showImportModal,
      handleClickNo,
      handleClickYes
    } = this.props;

    const {
      state: { labelWidth }
    } = this;
    const shouldShrink = !!value;

    return (
      <FormControl fullWidth variant="outlined" className="filer">
        <LastYearVehicleModal
          handleClickNo={handleClickNo}
          handleClickYes={handleClickYes}
          showImportModal={showImportModal}
          handleCloseImportModal={handleClickNo}
        />
        <InputLabel
          shrink={shouldShrink}
          ref={ref => {
            this.inputLabel = ref;
          }}
        >
          {label}
        </InputLabel>
        <MaterialSelect
          value={value}
          onChange={onChange}
          input={
            <OutlinedInput labelWidth={labelWidth} notched={shouldShrink} />
          }
        >
          <option value="">Start a new...</option>
          {options &&
            options.map((x, key) => (
              <option key={key} value={x.TaxYear}>
                {x.TaxYearDescription}
              </option>
            ))}
          <option value={VIN_CORRECTION_DD_VAL}>VIN Correction</option>
          <option value={WEIGHT_INCREASE_DD_VAL}>Weight Increase</option>
        </MaterialSelect>
      </FormControl>
    );
  }
}

Filer.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Filer.defaultProps = {
  label: null
};

export default Filer;
