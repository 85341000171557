import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./currentTaxYearFiler.css";
import LastYearVehicleModal from "./LastYearVehiclesModal";

const {
  components: { Button }
} = controls;

const CurrentTaxYearFiler = ({
  firstName,
  onClickFileReturn,
  loading,
  heroTaxYear,
  showImportModal,
  handleClickNo,
  handleClickYes
}) => (
  <div className="current-year-filer rad-corners">
    <LastYearVehicleModal
      handleClickYes={handleClickYes}
      handleClickNo={handleClickNo}
      showImportModal={showImportModal}
      handleCloseImportModal={handleClickNo}
    />
    <section className="section-1">
      <h1>{loading ? "Loading..." : `Hi ${firstName}`}</h1>
      <h2>{heroTaxYear.HeroMessage}</h2>
      <Button
        text="File Now"
        primary
        disabled={false}
        onClick={onClickFileReturn}
      />
    </section>
    <section className="section-2 row">
      <div className="col-12">
        <div className="player-wrapper">
          <iframe src="https://player.vimeo.com/video/353608429?h=293f622e58" width="640" height="360" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
        </div>
      </div>
    </section>
  </div>
);

CurrentTaxYearFiler.propTypes = {
  firstName: PropTypes.string,
  onClickFileReturn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

CurrentTaxYearFiler.defaultProps = {
  firstName: null
};

export default CurrentTaxYearFiler;
