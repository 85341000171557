import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import "./validationSummary.css";

const {
  components: { Link }
} = controls;

const ValidationSummary = ({
  numberOfInvalidTaxableVehicles,
  numberOfInvalidCreditVehicles,
  isBusinessInfoError,
  isDesigneeError,
  isPaymentMethodError,
  onClickTaxableError,
  onClickCreditError,
  numberOfTaxableVehicles
}) => (
  <ul className="validation-summary alert-danger">
    {numberOfTaxableVehicles === 0 && (
      <li>
        There are no vehicles. Click{" "}
        <Link clickHandler={onClickTaxableError} text="here" /> to add vehicles.
      </li>
    )}
    {numberOfInvalidTaxableVehicles > 0 && (
      <li>
        You have {numberOfInvalidTaxableVehicles} invalid vehicle
        {numberOfInvalidTaxableVehicles !== 1 && "s"}. Click{" "}
        <Link clickHandler={onClickTaxableError} text="here" /> to correct.
      </li>
    )}
    {numberOfInvalidCreditVehicles > 0 && (
      <li>
        You have {numberOfInvalidCreditVehicles} invalid credit
        {numberOfInvalidCreditVehicles !== 1 && "s"}. Click{" "}
        <Link clickHandler={onClickCreditError} text="here" /> to correct.
      </li>
    )}
    {isBusinessInfoError && (
      <li>
        Something is wrong with your Business/Contact information. Please
        correct.
      </li>
    )}
    {isDesigneeError && (
      <li>
        Something is wrong with your 3rd Party Designee information. Please
        correct.
      </li>
    )}
    {isPaymentMethodError && (
      <li>Something is wrong with your payment method. Please correct.</li>
    )}
  </ul>
);

ValidationSummary.propTypes = {
  numberOfTaxableVehicles: PropTypes.number.isRequired,
  numberOfInvalidTaxableVehicles: PropTypes.number.isRequired,
  numberOfInvalidCreditVehicles: PropTypes.number.isRequired,
  isBusinessInfoError: PropTypes.bool.isRequired,
  isDesigneeError: PropTypes.bool.isRequired,
  isPaymentMethodError: PropTypes.bool.isRequired,
  onClickTaxableError: PropTypes.func.isRequired,
  onClickCreditError: PropTypes.func.isRequired
};

export default ValidationSummary;
