import React, { Component } from "react";
import { connect } from "react-redux";
import EditVehiclesScreen from "./EditVehiclesScreen";
import utilities from "../../../modules/utilities";
import order from "../../../modules/order";
import taxYear from "../../../modules/taxYear";
import vehicles from "../../../modules/vehicles";
import userContext from "../../../modules/userContext";
import { FORM_ROUTE, ORDER_HISTORY_ROUTE } from "../../../modules/constants";
import {
  getCanBeEdited,
  getFormEntities,
  getOrderHOF,
  getVehicleEntities
} from "../../../modules/order/order.selectors";
import { getTaxableVehiclesStagedForCreation } from "../../../modules/vehicles/vehicles.selectors";
import { Redirect } from "react-router-dom";

const {
  selectors: {
    getTaxableVehiclesErrorsExist,
    getTaxableVehiclesShowImporter,
    getTaxableVehiclesInEdit
  },
  actions: {
    toggleShowImporter: toggleShowImporterCreator,
    putVehicle: putVehicleCreator,
    postVehicle: postVehicleCreator
  },
  constants: { TAXABLE_VEHICLES_SLICE_NAME }
} = vehicles;

const {
  actions: {
    fetchOrder: fetchOrderCreator,
    fetchActiveOrders: fetchActiveOrdersCreator
  }
} = order;

const {
  selectors: { getTaxTable },
  actions: { fetchTaxTable: fetchTaxTableCreator }
} = taxYear;

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

const {
  getOrderIdFromUrl,
  transform: { mapTaxableVehicleToPayload }
} = utilities;

class EditVehiclesScreenContainer extends Component {
  componentDidMount() {
    const {
      props: {
        taxTable,
        fetchTaxTable,
        fetchOrder,
        fetchActiveOrders,
        fetchUser
      }
    } = this;
    fetchUser();
    fetchActiveOrders();
    fetchOrder(getOrderIdFromUrl());
    if (!taxTable) {
      fetchTaxTable();
    }
  }

  handleClickEditCreditVehicles = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/edit-credits?order=${orderId}`);
  };

  handleClickBack = () => {
    const {
      props: {
        vehiclesInEdit,
        putVehicle,
        postVehicle,
        history: { push }
      }
    } = this;

    const orderId = getOrderIdFromUrl();

    vehiclesInEdit
      .map(x => mapTaxableVehicleToPayload(x))
      .forEach(x =>
        x.Id ? putVehicle(orderId, x.Id, x) : postVehicle(orderId, x)
      );

    push(`${FORM_ROUTE}?order=${orderId}`);
  };

  render() {
    const {
      handleClickBack,
      handleClickEditCreditVehicles,
      props: {
        invalidVehiclesExist,
        showImporter,
        toggleShowImporter,
        numberOfCreditVehicles,
        vehicleStaging
      }
    } = this;
    return (
      <>
        {this.props.redirect && <Redirect to={this.props.redirect} />}
        <EditVehiclesScreen
          vehicleStaging={vehicleStaging}
          showImporter={showImporter}
          toggleShowImporter={toggleShowImporter}
          invalidVehiclesExist={invalidVehiclesExist}
          onClickBack={handleClickBack}
          numberOfCreditVehicles={numberOfCreditVehicles}
          onClickEditCreditVehicles={handleClickEditCreditVehicles}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const vehicleStaging = getTaxableVehiclesStagedForCreation(state);
  let numberOfCreditVehicles = 0;

  const order = getOrderHOF(orderId)(state);
  if (order && order.Forms.length) {
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    numberOfCreditVehicles = vehicleIds.reduce(
      (a, id) => (!vehicles[id].IsTaxable ? a + 1 : a),
      0
    );
  }
  return {
    orderId,
    vehicleStaging,
    taxTable: getTaxTable(state),
    invalidVehiclesExist: getTaxableVehiclesErrorsExist(state),
    showImporter: getTaxableVehiclesShowImporter(state),
    vehiclesInEdit: getTaxableVehiclesInEdit(state),
    numberOfCreditVehicles,
    redirect: getCanBeEdited(order) ? ORDER_HISTORY_ROUTE : undefined
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true)),
  fetchTaxTable: () => dispatch(fetchTaxTableCreator()),
  fetchActiveOrders: () => dispatch(fetchActiveOrdersCreator()),
  toggleShowImporter: () =>
    dispatch(toggleShowImporterCreator(TAXABLE_VEHICLES_SLICE_NAME)),
  putVehicle: (orderId, vehicleId, payload) =>
    dispatch(putVehicleCreator(orderId, vehicleId, payload)),
  postVehicle: (orderId, payload) =>
    dispatch(postVehicleCreator(orderId, payload, TAXABLE_VEHICLES_SLICE_NAME)),
  fetchUser: () => dispatch(fetchUserCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditVehiclesScreenContainer);
