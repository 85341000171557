import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import WizardController from "./WizardController";
import utilities from "../../../modules/utilities";
import controls from "../../../modules/controls";
import userContext from "../../../modules/userContext";
import { getOrderAlt } from "../../../modules/order/order.selectorsv2";

const { getOrderIdFromUrl, getWizardStepFromUrl } = utilities;

const {
  actions: { setRedirect: setRedirectCreator }
} = userContext;

const {
  validations: { validateOptionalMiddleInitial }
} = controls;

class WizardControllerContainer extends Component {
  filterInvalidSignerFormValues = payload => {
    if (validateOptionalMiddleInitial(payload.Mi)) {
      delete payload.Mi;
    }
    return payload;
  };

  handleBackClick = () => {
    this.handleClick(-1);
  };

  handleNextClick = () => {
    this.handleClick(1);
  };

  handleClick = dir => {
    const {
      props: {
        setRedirect,
        isOrderLoaded,
        isWeightIncrease,
        location: { pathname }
      }
    } = this;

    const currentStep = parseInt(pathname.split("/").pop(), 10);
    const orderId = getOrderIdFromUrl();
    let redirectStep = currentStep + dir;

    if (redirectStep === 2 && isWeightIncrease && isOrderLoaded)
      redirectStep += dir;

    if (redirectStep == 5) {
      setRedirect(`/form?order=${orderId}`);
    } else if (orderId) {
      setRedirect(`/wizard/${redirectStep}?order=${orderId}`);
    }
  };

  render() {
    const {
      location: { pathname }
    } = this.props;
    const currentStep = parseInt(pathname.split("/").pop(), 10);
    const backButtonIsDisabled = currentStep === 1;

    return (
      <WizardController
        step={currentStep}
        onNextClick={this.handleNextClick}
        onBackClick={this.handleBackClick}
        backButtonIsDisabled={backButtonIsDisabled}
      />
    );
  }
}

const mapStateToProps = state => {
  const order = getOrderAlt(state) || { WeightIncrease: false };
  return {
    isOrderLoaded: typeof order !== "undefined",
    isWeightIncrease: !!order.WeightIncrease
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setRedirect: redirect => dispatch(setRedirectCreator(redirect))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WizardControllerContainer)
);
