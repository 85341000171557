import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import taxYear from "../../taxYear";
import vehicles from "../../vehicles";
import {
  getEntities as getFormEntities,
  getCreditVehiclesGridCurrentPageHof,
  getCreditVehiclesGridPageSizeHof
} from "../form.selectors";
import {
  changeCurrentPage as changeCurrentPageCreator,
  changePageSize as changePageSizeCreator
} from "../form.actions";
import controls from "../../controls";

const {
  selectors: { getTaxYear }
} = taxYear;

const {
  components: { CreditVehiclesGrid },
  selectors: { getEntities: getVehicleEntities },
  constants: { CREDIT_VEHICLES_SLICE_NAME },
  utilities: { sortVehicles }
} = vehicles;

const {
  validations: { validateCreditVehicleFormWithNullValid: validate }
} = controls;

class FormVehicleGridContainer extends React.Component {
  state = {
    lastColumnWidth: undefined
  };

  componentDidMount() {
    // window.addEventListener("resize", this.handleResize);
    // window.dispatchEvent(new Event("resize"));
    // this.handleResize();
  }

  handlePageChange = ({ page: { skip, take } }) => {
    const {
      props: { changeCurrentPage, changePageSize }
    } = this;
    changeCurrentPage(skip / take);
    changePageSize(take);
  };

  handleResize = e => {
    if (
      e.target.innerWidth < 1585 &&
      this.state.lastColumnWidth === undefined
    ) {
      return this.setState({
        lastColumnWidth: 200
      });
    }

    if (e.target.innerWidth < 1585) {
      return;
    }

    return this.setState({
      lastColumnWidth: undefined
    });
  };

  render() {
    const {
      handlePageChange,
      props: { taxYear, data, currentPage, pageSize },
      state: { lastColumnWidth }
    } = this;
    return (
      <CreditVehiclesGrid
        lastColumnWidth={lastColumnWidth}
        skip={currentPage * pageSize}
        take={pageSize}
        onPageChange={handlePageChange}
        height="300px"
        width={200}
        data={data}
        taxYear={taxYear}
        readOnly
      />
    );
  }
}

FormVehicleGridContainer.propTypes = {
  formId: PropTypes.string.isRequired, // eslint-disable-line
  taxYear: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  changeCurrentPage: PropTypes.func.isRequired,
  changePageSize: PropTypes.func.isRequired
};

FormVehicleGridContainer.defaultProps = {
  taxYear: null,
  data: []
};

const mapStateToProps = (state, props) => {
  const { formId } = props;
  const formEntity = getFormEntities(state)[formId];
  const vehicleEntities = getVehicleEntities(state);

  const data = sortVehicles(vehicleEntities)
    .filter(
      id =>
        formEntity.Vehicles.includes(id) &&
        !vehicleEntities[id].IsTaxable &&
        !validate(vehicleEntities[id])
    )
    .map(id => ({
      ...vehicleEntities[id],
      CreditEventDate: vehicleEntities[id].CreditEventDate
        ? new Date(vehicleEntities[id].CreditEventDate)
        : null
    }));

  return {
    data,
    taxYear: getTaxYear(state),
    currentPage: getCreditVehiclesGridCurrentPageHof(formId)(state),
    pageSize: getCreditVehiclesGridPageSizeHof(formId)(state)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  changeCurrentPage: pageNum =>
    dispatch(
      changeCurrentPageCreator(
        props.formId,
        pageNum,
        CREDIT_VEHICLES_SLICE_NAME
      )
    ),
  changePageSize: pageSize =>
    dispatch(
      changePageSizeCreator(props.formId, pageSize, CREDIT_VEHICLES_SLICE_NAME)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormVehicleGridContainer);
