import React, { Fragment } from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import modal from "../../modal";
import utilities from "../../utilities";
import { BANK, CC, EFTPS, MAIL, PAYMENT_AGREEMENT } from "../../constants";
import "./paymentMethodWidget.css";

const {
  components: { AutoHeightAnimator, Chevron }
} = controls;

const {
  math: { fastFormatMoney }
} = utilities;

const {
  components: { Acknowledger }
} = modal;

const PaymentMethodWidget = ({
  isExpanded,
  toggleIsExpanded,
  netTax,
  paymentMethod,
  onClosePaymentAgreement,
  paymentAgreementIsVisible
}) => (
  <div className="summary-widget rad-corners payment-method-widget">
    <Acknowledger
      prompt={PAYMENT_AGREEMENT}
      onClose={onClosePaymentAgreement}
      isVisible={paymentAgreementIsVisible}
    />
    <div className="summary-header brand-header payment-method-widget-header">
      <div className="row">
        <div className="d-flex flex-grow-1">
          <div className="flex-grow-1">
            <div className="col-12 d-flex justify-content-between">
              <span>Total Tax Due:</span>
              <strong>
                {netTax !== null ? fastFormatMoney(netTax) : "N/A"}
              </strong>
            </div>
            <div className="col-12 d-flex justify-content-between">
              <span>IRS Payment Method:</span>
              {netTax !== null && netTax > 0 ? (
                <Fragment>
                  {(() => {
                    switch (paymentMethod) {
                      case BANK:
                        return (
                          <strong className="text-right">
                            Checking account direct debit (recommended)
                          </strong>
                        );
                      case CC:
                        return (
                          <strong className="text-right">Credit Card</strong>
                        );
                      case EFTPS:
                        return <strong className="text-right">EFTPS</strong>;
                      case MAIL:
                        return (
                          <strong className="text-right">
                            Check/Money Order
                          </strong>
                        );
                      default:
                        return (
                          <strong className="text-right">Loading...</strong>
                        );
                    }
                  })()}
                </Fragment>
              ) : (
                <strong style={{ marginRight: 0 }}>N/A</strong>
              )}
            </div>
          </div>
          {netTax !== null && netTax > 0 && (
            <div className="my-auto mr-gutter">
              <Chevron
                className="payment-widget-chevron"
                onClick={toggleIsExpanded}
                isUp={isExpanded}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    <AutoHeightAnimator isExpanded={isExpanded}>
      <div className="widget-body payment-method-widget-body">
        {/*<PaymentMethodForm />*/}
      </div>
    </AutoHeightAnimator>
  </div>
);

PaymentMethodWidget.propTypes = {
  paymentMethod: PropTypes.string,
  netTax: PropTypes.number,
  isExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  onClosePaymentAgreement: PropTypes.func.isRequired,
  paymentAgreementIsVisible: PropTypes.bool.isRequired
};

PaymentMethodWidget.defaultProps = {
  paymentMethod: null,
  netTax: null
};

export default PaymentMethodWidget;
