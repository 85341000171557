import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, FieldArray } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";
import "./vinCorrectionForm.css";
import { OTHER_REASON_NAME } from "../supportCenter.constants";

const {
  components: { Button, TextField, CorrectionDropdown },
  validations: { validateRequired, validateDropdown }
} = controls;

const RenderCorrections = ({ fields }) => {
  const [explanationCache, setExplanationCache] = useState([]);
  return (
    <ul className="vin-corrections">
      {fields.map((correction, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className="vin-correction row">
          <div className="col-12 col-lg-3">
            <TextField
              name={`${correction}.OldVin`}
              type="text"
              label="Mistyped VIN"
              validations={[validateRequired]}
            />
          </div>
          <div className="col-12 col-lg-3">
            <TextField
              name={`${correction}.NewVin`}
              type="text"
              label="Update this VIN to"
              validations={[validateRequired]}
            />
          </div>
          <div className="col-12 col-lg-3">
            <CorrectionDropdown
              name={`${correction}.CorrectionExplanation`}
              label="Explanation of Correction"
              validations={[validateDropdown]}
              onChange={x => {
                const newCache = [...explanationCache];
                newCache[index] = x.target.value;
                setExplanationCache(newCache);
                // setExplanationMap(newMap);
              }}
            />
          </div>
          {explanationCache[index] === OTHER_REASON_NAME && (
            <div className="col-12 col-lg-3">
              <TextField
                name={`${correction}.OtherExplanation`}
                type="text"
                label="Please explain"
                validations={[validateRequired]}
              />
            </div>
          )}

          <div className="col-12 col-lg-3 my-auto">
            <Button
              width="100%"
              className="widget-button"
              onClick={() => {
                fields.remove(index);
                const newCache = [...explanationCache];
                newCache.splice(index, 1);
                setExplanationCache(newCache);
              }}
              secondary
              text="Remove"
              disabled={false}
            />
          </div>
        </li>
      ))}
      <li className="row">
        <div className="col text-center">
          <Button
            width="100%"
            className="add-vin-btn"
            onClick={() =>
              fields.push({
                OldVin: "",
                NewVin: "",
                CorrectionExplanation: "",
                OtherExplanation: ""
              })
            }
            secondary
            text="Include Another Set of VINs"
            disabled={false}
          />
        </div>
      </li>
    </ul>
  );
};

const VinCorrectionForm = ({ onSubmit, onClickCancel }) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">VIN Corrections</h2>
    <FieldArray name="corrections" component={RenderCorrections} />
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

VinCorrectionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
};

export default VinCorrectionForm;
