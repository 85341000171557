/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";
import FormWidget from "./FormWidgetContainer";
import isArray from "lodash";
import FormWidgetEmptyBodyContainer from "./FormWidgetEmptyBodyContainer";

const FormWidgetRepeater = ({ formIds, noCredits }) =>
  isArray(formIds) && formIds.length === 0 ? (
    <FormWidgetEmptyBodyContainer
      noCredits={noCredits}
      noVehicles={true}
    ></FormWidgetEmptyBodyContainer>
  ) : (
    <>
      {formIds.map((x, key) => (
        <FormWidget key={key} formId={x} />
      ))}
      {noCredits && (
        <FormWidgetEmptyBodyContainer
          noCredits={true}
          noVehicles={false}
        ></FormWidgetEmptyBodyContainer>
      )}
    </>
  );

FormWidgetRepeater.propTypes = {
  formIds: PropTypes.array.isRequired
};

export default FormWidgetRepeater;
