import { GridDetailRow } from "@progress/kendo-react-grid";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "./TextField";
import DropdownField from "./DropdownField";
import controls from "../../controls";

const {
  data: { countries, states }
} = controls;

class DetailRow extends GridDetailRow {
  render() {
    const { dataItem, onChange } = this.props;
    return (
      <section className="detail-row" {...this.props}>
        <div className="detail-row-ctnr">
          {dataItem.inEdit && (
            <div className="row">
              <div className="col">
                <RadioGroup
                  className="buyer-info-switch"
                  value={
                    dataItem.IsBuyerDataIncluded === null
                      ? null
                      : dataItem.IsBuyerDataIncluded.toString()
                  }
                  name="IsBuyerDataIncluded"
                  onChange={(e, v) =>
                    onChange({
                      field: "IsBuyerDataIncluded",
                      value: v === "true",
                      dataItem
                    })
                  }
                >
                  <FormControlLabel
                    className="material-label-override grid-font"
                    value="true"
                    control={<Radio />}
                    label="Add now"
                  />
                  <FormControlLabel
                    className="material-label-override grid-font"
                    value="false"
                    control={<Radio />}
                    label="Mail seperately"
                  />
                </RadioGroup>
              </div>
            </div>
          )}
          {dataItem.IsBuyerDataIncluded ? (
            <React.Fragment>
              <div className="row">
                <div className="col">
                  {dataItem.inEdit ? (
                    <FormControlLabel
                      className="grid-font"
                      control={
                        <Checkbox
                          checked={dataItem.IsBuyerBusiness}
                          onChange={(e, v) =>
                            onChange({
                              field: "IsBuyerBusiness",
                              value: v,
                              dataItem
                            })
                          }
                          value="checkedB"
                          color="primary"
                        />
                      }
                      label="Sold to business?"
                    />
                  ) : (
                    <span>
                      {`Sold to business: ${
                        dataItem.IsBuyerBusiness ? "Yes" : "No"
                      }`}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <TextField
                    dataItem={dataItem}
                    field="BuyerName"
                    label="Buyer Name"
                    onChange={onChange}
                  />
                </div>
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <TextField
                    dataItem={dataItem}
                    field="BuyerAddress1"
                    label="Address 1"
                    onChange={onChange}
                  />
                </div>
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <TextField
                    dataItem={dataItem}
                    field="BuyerAddress2"
                    label="Address 2"
                    onChange={onChange}
                  />
                </div>
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <DropdownField
                    dataItem={dataItem}
                    field="BuyerCountry"
                    label="Country"
                    onChange={onChange}
                    localizedData={countries}
                  />
                </div>
              </div>
              <div className="row">
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <TextField
                    dataItem={dataItem}
                    field="BuyerCity"
                    label="City"
                    onChange={onChange}
                  />
                </div>
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <DropdownField
                    dataItem={dataItem}
                    field="BuyerState"
                    label="State"
                    onChange={onChange}
                    localizedData={states[dataItem.BuyerCountry || "US"]}
                  />
                </div>
                <div className={dataItem.inEdit ? "col-12 col-lg" : "col-12"}>
                  <TextField
                    dataItem={dataItem}
                    field="BuyerZip"
                    label="Zip"
                    onChange={onChange}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="row">
              <div className="col mail-buyer-info">
                <h1>Send information via mail to:</h1>
                <p>Internal Revenue Service</p>
                <p>201 West Rivercenter</p>
                <p>Stop 5701G</p>
                <p>Covington, KY 41011</p>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default DetailRow;
