import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs";

const Modal = ({
  isVisible,
  prompt,
  subPrompt,
  title,
  onClose,
  actions,
  showClose
}) => (
  <React.Fragment>
    {isVisible && (
      <Dialog title={title} onClose={onClose} closeIcon={showClose !== false}>
        <p style={{ margin: "25px", textAlign: "center" }}>{prompt}</p>
        {subPrompt && (
          <p style={{ margin: "25px", textAlign: "center" }}>{subPrompt}</p>
        )}
        <div className="row">{actions}</div>
      </Dialog>
    )}
  </React.Fragment>
);

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.node.isRequired
};

export default Modal;

//
//
// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       visible: true
//     };
//     this.toggleDialog = this.toggleDialog.bind(this);
//   }
//
//   toggleDialog() {
//     this.setState({
//       visible: !this.state.visible
//     });
//   }
//
//   render() {
//     return (
//       <div>
//         <button className="k-button" onClick={this.toggleDialog}>
//           Open Dialog
//         </button>
//         {this.state.visible && (
//           <Dialog title="Please confirm" onClose={this.toggleDialog}>
//             <p style={{ margin: "25px", textAlign: "center" }}>
//               Are you sure you want to continue?
//             </p>
//             <DialogActionsBar>
//               <button className="k-button" onClick={this.toggleDialog}>
//                 No
//               </button>
//               <button className="k-button" onClick={this.toggleDialog}>
//                 Yes
//               </button>
//             </DialogActionsBar>
//           </Dialog>
//         )}
//       </div>
//     );
//   }
// }
// ReactDOM.render(<App />, document.querySelector("my-app"));
