
import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { NonFormTextField } from "modules/controls/components/TextField";
import Button from "./Button";

export const TextEntryPrompt = (
    {
      label,
      onOk,
      title,
      isVisible, 
      setIsVisible,
      children,
      autoFocus = undefined,
      optional = false,
      error = undefined,
      multiline = undefined,    
      rows = undefined,
      minWidth ="200px",
      minHeight ="200px",
      onCancel = undefined,
      onClose = undefined,
      onChange = undefined,
      isValid = undefined,
      initialValue = '',
      okLabel = 'Submit',
      cancelLabel = 'Cancel',
      className = '',
      submitOnEnter = true,
      ...rest
    }) => {

      const [textValue, setTextValue] = useState(initialValue);
      const [validationError, setValidationError] = useState(undefined);
      const [validate, setValidate] = useState(false);

      const hasErrors = (e) => {
        if (!isValid) return true; // function not set
        setValidate(true);

        const isMissingError = textValue.replace(' ','') === '' && !optional ? 'Nothing entered' : undefined;
        if (isMissingError) {
          setValidationError(isMissingError);
          return true;
        }
        const err = isValid(textValue);
        setValidationError(err);
        return !!err;
      }

      const clearAndClose = () => { setIsVisible(false); setTextValue(initialValue); setValidationError(undefined); setValidate(false); }

      const _onCancel = (e) => {onCancel && onCancel(e); clearAndClose();}
      const _onClose = (e) => {onClose && onClose(e); clearAndClose();}
      const _onOk = (value, e) => {if (hasErrors(value)) return; onOk(value, e); clearAndClose();}
      const _onChange = (e) => {setTextValue(e.target.value); validate && hasErrors(e); onChange && onChange(e.target.value)}
      const _multiline = multiline || (typeof rows === 'number' ? rows > 1 : undefined);
      const _error = validationError || error; // possibly both (and)???
      const _submitOnEnter = submitOnEnter || !_multiline;
      const _autoFocus = autoFocus || _submitOnEnter;

      return (
          <React.Fragment>
            {isVisible && (
              <Dialog className={"payment-modal no-scroll "+ className} title={title} onClose={_onClose} {...rest}>
                {children}
                <div className="receipt-modal-body" style={{minHeight, minWidth}}>
                  <NonFormTextField
                    placeholder=""
                    name="single-entry"
                    variant="outlined"
                    label={label}
                    value={textValue}
                    onChange={_onChange}
                    needsTouch={false}
                    multiline={_multiline}
                    rows={rows}
                    autoFocus={_autoFocus}
                    onKeyPress={e => (_submitOnEnter && e.key === "Enter" ? _onOk(textValue, e) : false)}
                  />
                  {_error && <span className="i2290-inline-error">{_error}</span>}
                </div>
                <div className="row">
                  <div className="col clean-gutter-right">
                    <Button
                      secondary
                      disabled={false}
                      text={cancelLabel}
                      onClick={_onCancel}
                      width="100%"
                    />
                  </div>
                  <div className="col clean-gutter-left">
                    <Button
                      disabled={!!_error}
                      primary
                      onClick={(e) => _onOk(textValue, e)}
                      text={okLabel}
                      width="100%"
                    />
                  </div>
                </div>
              </Dialog>
            )}
          </React.Fragment>
      );
};