import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ForgotPin from "./ForgotPin";
import { getForgotPinFormValues, getSuccess } from "../forgotPin.selectors";
import {
  forgotPin as forgotPinCreator,
  resetError as resetErrorCreator
} from "../forgotPin.actions";
import busyIndicator from "../../../modules/busyIndicator";
import { SUPPORT_URI } from "../../../modules/constants";

const {
  selectors: { isBusy: isBusySelector }
} = busyIndicator;

class ForgotPinContainer extends React.Component {
  handleReduxFormSubmit = values => {
    const { forgotPin, isBusy } = this.props;
    if (isBusy) return;
    const { email, ein } = values;
    forgotPin(email, ein);
  };

  handleSignInClick = () => {
    this.props.history.push("/sign-in");
  };

  handleClickCustomerSupport = () => window.open(SUPPORT_URI, "_blank");

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { submitSuccess, formValues, resetError } = this.props;
    return (
      <ForgotPin
        onClickCustomerSupport={this.handleClickCustomerSupport}
        handleSignInClick={this.handleSignInClick}
        handleReduxFormSubmit={this.handleReduxFormSubmit}
        submitSuccess={submitSuccess}
        formValues={formValues}
        from={from}
        resetError={resetError}
      />
    );
  }
}

ForgotPinContainer.propTypes = {
  submitSuccess: PropTypes.bool,
  formValues: PropTypes.object,
  forgotPin: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired
};

ForgotPinContainer.defaultProps = {
  submitSuccess: false,
  formValues: null
};

const mapDispatchToProps = {
  forgotPin: forgotPinCreator,
  resetError: resetErrorCreator
};

const mapStateToProps = state => ({
  formValues: getForgotPinFormValues(state),
  submitSuccess: getSuccess(state),
  isBusy: isBusySelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPinContainer);
