import React, { Component } from "react";
import MaterialSelect from "@material-ui/core/NativeSelect";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import ReactDOM from "react-dom";
import { Field } from "redux-form";
import "./select.css";
import PropTypes from "prop-types";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    this.setState({
      // new way of doing refs has a bug with material ui, have to do old way
      labelWidth: ReactDOM.findDOMNode(this.inputLabel).offsetWidth // eslint-disable-line react/no-find-dom-node
    });
  }

  renderSelect = ({
    input,
    label,
    meta: { touched, error, active },
    children,
    name,
    labelWidth,
    needsTouch,
    ...custom
  }) => {
    const shouldShrink =
      active ||
      (input.value !== "" && input.value !== undefined && input.value !== null);
    return (
      <div className="i2290-control">
        <FormControl fullWidth variant="outlined">
          <InputLabel
            shrink={shouldShrink}
            ref={ref => {
              this.inputLabel = ref;
            }}
          >
            {label}
          </InputLabel>
          <MaterialSelect
            error={needsTouch ? touched && !!error : !!error}
            input={
              <OutlinedInput
                className="i2290-select-input"
                {...input}
                labelWidth={labelWidth}
                notched={shouldShrink}
              />
            }
            {...input}
            {...custom}
          >
            {children}
          </MaterialSelect>
        </FormControl>
        {needsTouch
          ? touched &&
            error && (
              <FormHelperText htmlFor="render-select" error>
                {error}
              </FormHelperText>
            )
          : error && (
              <FormHelperText htmlFor="render-select" error>
                {error}
              </FormHelperText>
            )}
      </div>
    );
  };

  render() {
    const {
      placeholder,
      label,
      name,
      children,
      validations,
      normalize,
      format,
      className,
      needsTouch,
      ...custom
    } = this.props;
    return (
      <Field
        name={name}
        className={
          className ? `i2290-select-input ${className}` : "i2290-select-input"
        }
        component={this.renderSelect}
        label={label}
        placeholder={placeholder}
        validate={validations}
        normalize={normalize}
        format={format}
        labelWidth={this.state.labelWidth}
        needsTouch={needsTouch}
        {...custom}
      >
        {children}
      </Field>
    );
  }
}

Select.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func,
  className: PropTypes.string,
  needsTouch: PropTypes.bool
};

Select.defaultProps = {
  normalize: null,
  format: null,
  placeholder: "",
  validations: null,
  label: null,
  className: null,
  needsTouch: true
};

export default Select;
