import WeightIncreaseScreen from "./WeightIncreaseScreen";
import SupportFooter from "./SupportFooter";
import SupportFooterContainer from "./SupportFooterContainer";
import WeightIncreaseScreenContainer from "./WeightIncreaseScreenContainer";

export default {
  WeightIncreaseScreen,
  WeightIncreaseScreenContainer,
  SupportFooterContainer,
  SupportFooter
};
