import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords
} from "@progress/kendo-react-grid";
import VehicleGridReadOnlyCell from "./VehicleGridReadOnlyCell";

const WeightIncreaseGrid = ({ data, width, skip, take, onPageChange }) => (
  <React.Fragment>
    <Grid
      data={data.slice(skip, skip + take)}
      editField="inEdit"
      skip={skip}
      take={take}
      total={data.length}
      pageable={
        data.length > 10
          ? { type: "input", buttonCount: 5, pageSizes: [10, 20, 50, 100] }
          : false
      }
      scrollable="none"
      onPageChange={onPageChange}
    >
      <GridNoRecords>No vehicle found</GridNoRecords>
      <Column
        width={width}
        field="Vin"
        title="VIN"
        cell={VehicleGridReadOnlyCell}
      />
      <Column
        width={width}
        field="IncreasedWeightCategory"
        title="Weight Increase"
        cell={VehicleGridReadOnlyCell}
      />
      <Column
        width={width}
        field="DateCategory"
        title="Beginning"
        cell={VehicleGridReadOnlyCell}
      />
      <Column
        width={width}
        field="TaxValue"
        title="Previous Tax"
        cell={VehicleGridReadOnlyCell}
      />
      <Column
        width={width}
        field="IncreasedTaxValue"
        title="Increased Tax"
        cell={VehicleGridReadOnlyCell}
      />
      <Column
        width={width}
        field="WeightIncreaseTaxDifference"
        title="Difference"
        cell={VehicleGridReadOnlyCell}
      />
    </Grid>
  </React.Fragment>
);

WeightIncreaseGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  width: PropTypes.string.isRequired,
  skip: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

WeightIncreaseGrid.defaultProps = {
  // maxHeight: "400px"
};

export default WeightIncreaseGrid;
