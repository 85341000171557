import { lookupDateCategoryByJavascriptDateMonth } from "./lookups";
import { CREDIT_REASON_SUSPENDED } from "../constants";

export const computeTax = (data, taxTable) => {
  const { WeightCategory, DateCategory, Suspend, Logging } = data;
  if (WeightCategory === null || DateCategory === null || Logging === null)
    return null;
  if (Suspend) {
    return 0;
  }
  // return Math.floor(
  //   taxTable[`${WeightCategory}.${DateCategory}.${Logging ? "1" : "0"}`]
  // );
  return taxTable[`${WeightCategory}.${DateCategory}.${Logging ? "1" : "0"}`];
};

export const computeCredit = (data, taxTable) => {
  const { WeightCategory, CreditEventDate, Logging } = data;

  if (WeightCategory === null || CreditEventDate === null || Logging === null)
    return null;

  const dateCategory = lookupDateCategoryByJavascriptDateMonth(
    CreditEventDate.getMonth()
  );

  if (dateCategory === 1) {
    return 0;
  }

  // return Math.floor(
  //   taxTable[`${WeightCategory}.${dateCategory - 1}.${Logging ? "1" : "0"}`]
  // );
  return taxTable[
    `${WeightCategory}.${dateCategory - 1}.${Logging ? "1" : "0"}`
  ];
};

export const computeMaxCredit = (weightCategory, logging, taxTable) =>
  taxTable[`${weightCategory}.12.${logging ? "1" : "0"}`].toFixed(2);

export const overrideCreditAmount = (
  newCreditAmount,
  creditReason,
  weightCategory,
  logging,
  taxTable
) => {
  if (creditReason !== CREDIT_REASON_SUSPENDED) return newCreditAmount;
  const maxCreditAmount = taxTable[
    `${weightCategory}.12.${logging ? "1" : "0"}`
  ].toFixed(2);
  return +newCreditAmount > +maxCreditAmount
    ? maxCreditAmount
    : newCreditAmount;
};
