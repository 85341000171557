import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FormWidgetBody from "./FormWidgetBody";
import utilities from "../../utilities";

const { getVehiclesUrlFromId, getCreditsUrlFromId } = utilities;

class FormWidgetEmptyBodyContainer extends Component {
  handleClickEditCredits = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(getCreditsUrlFromId());
  };

  handleClickEditVehicles = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(getVehiclesUrlFromId());
  };

  render() {
    const {
      handleClickEditCredits,
      handleClickEditVehicles,
      props: { noCredits, noVehicles }
    } = this;

    return (
      <FormWidgetBody
        isWeightIncrease={false}
        isExpanded={false}
        onClickEditCredits={handleClickEditCredits}
        onClickEditVehicles={handleClickEditVehicles}
        formId={0}
        month={""}
        totalTax={0}
        totalCredit={0}
        numOfCredits={noCredits ? 0 : 1}
        numOfVehicles={noVehicles ? 0 : 1}
      />
    );
  }
}

export default withRouter(FormWidgetEmptyBodyContainer);
