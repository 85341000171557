import { SET_ACTIVE_ACCOUNT_FORM_PANEL } from "./account.actionTypes";

const initialState = {
  activeAccountFormPanel: "Account"
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_ACCOUNT_FORM_PANEL:
      return {
        ...state,
        activeAccountFormPanel: action.payload
      };
    default:
      return state;
  }
}
