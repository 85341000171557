import * as actionTypes from "./weightIncrease.actionTypes";

const initialState = {
  entities: {},
  ui: {
    redirect: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_WEIGHT_INCREASE_VEHICLES.RECEIVED:
      return {
        ...state,
        entities: action.payload
      };
    case actionTypes.POST_WEIGHT_INCREASE.RECEIVED:
      return {
        ...state,
        ui: {
          redirect: action.payload
        }
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        ui: {
          redirect: action.payload
        }
      };
    default:
      return state;
  }
}
