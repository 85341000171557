import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Home from "./Home";
import { isAuthenticated } from "../../../modules/userContext/userContext.selectors";

class HomeContainer extends React.Component {
  render() {
    const { isAuthenticated, history } = this.props;
    return <Home isAuthenticated={isAuthenticated} history={history} />;
  }
}

HomeContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: !!isAuthenticated(state)
});

export default connect(mapStateToProps)(HomeContainer);
