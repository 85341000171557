import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";

import { STATE_NAME } from "./form.constants";

export const FETCH_FORMS = buildAsyncActionType(STATE_NAME, "FETCH_FORMS");

export const TOGGLE_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "TOGGLE_IS_EXPANDED"
);

export const CHANGE_CURRENT_PAGE = buildActionType(
  STATE_NAME,
  "CHANGE_CURRENT_PAGE"
);

export const CHANGE_PAGE_SIZE = buildActionType(STATE_NAME, "CHANGE_PAGE_SIZE");
