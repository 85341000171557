import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import userContext from "../../userContext";
import CompanyInfoForm from "./CompanyInfoForm";
import { SUBMISSION_FORM_NAME } from "../companyInfo.constants";
import controls from "../../controls";
import utilities from "../../utilities";
import { getUserHasSubmittedOrder } from "../../order/order.selectors";
import { fetchOrders as fetchOrdersCreator } from "../../order/order.actions";

const {
  validations: {
    validateRequired,
    validateEIN,
    validateDropdown,
    validateZipByCountry,
    validateAddress,
    validateCity,
    validateBusinessName
  }
} = controls;

const {
  transform: { sanitizeValue }
} = utilities;

const {
  actions: { fetchUser: fetchUserCreator, patchUser: patchUserCreator },
  selectors: {
    getUserContext,
    getRedirect,
    getUserContextExists,
    getUserContextIsBusy
  },
  actions: { logout: logoutCreator }
} = userContext;

class CompanyInfoFormContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchOrders }
    } = this;
    fetchOrders();
  }

  componentDidUpdate() {
    const { initialValues, touch } = this.props;
    touch(...Object.keys(initialValues));
  }

  handleBlur = e => {
    const {
      initialValues,
      formSyncErrors,
      patchUser,
      initialize,
      formValues
    } = this.props;

    if (!e.target.value && !initialValues[e.target.name]) {
      return;
    }

    if (e.target.value === initialValues[e.target.name]) {
      return;
    }

    if (
      formSyncErrors &&
      (formSyncErrors[e.target.name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    }

    initialize(formValues);

    if (e.target.name === "Country") {
      patchUser({
        [e.target.name]: sanitizeValue(e.target.value),
        State: null,
        Zip: null
      });
    } else {
      patchUser({
        [e.target.name]: sanitizeValue(e.target.value)
      });
    }
  };

  render() {
    const {
      props: { userHasSubmittedOrder }
    } = this;

    return (
      <CompanyInfoForm
        einIsDisabled={userHasSubmittedOrder}
        formName={SUBMISSION_FORM_NAME}
        onBlur={this.handleBlur}
        businessNameValidations={[validateRequired, validateBusinessName]}
        einValidations={[validateRequired, validateEIN]}
        businessStructureValidations={[validateDropdown]}
        countryValidations={[validateDropdown]}
        address1Validations={[validateRequired, validateAddress]}
        cityValidations={[validateRequired, validateCity]}
        stateValidations={[validateDropdown]}
        zipValidations={[validateRequired, validateZipByCountry]}
      />
    );
  }
}

CompanyInfoFormContainer.propTypes = {
  patchUser: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object
};

const mapStateToProps = state => ({
  userHasSubmittedOrder: getUserHasSubmittedOrder(state),
  formValues: getFormValues(SUBMISSION_FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(SUBMISSION_FORM_NAME)(state),
  initialValues: getUserContext(state),
  redirect: getRedirect(state),
  userContextExists: getUserContextExists(state),
  userContextIsBusy: getUserContextIsBusy(state)
});

const mapDispatchToProps = dispatch => ({
  fetchOrders: () => dispatch(fetchOrdersCreator()),
  fetchUser: () => dispatch(fetchUserCreator()),
  patchUser: payload => dispatch(patchUserCreator(payload)),
  logout: () => dispatch(logoutCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SUBMISSION_FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false
  })(CompanyInfoFormContainer)
);
