import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import FileByPhoneForm from "./FileByPhoneFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 className="support-page-header">File By Phone</h2>
    <p style={{ marginBottom: "30px" }}>
      Submit a request to file your 2290 over the phone.
    </p>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <FileByPhoneForm />
  </div>
);

const FileByPhoneFormScreen = withChildren(children, SupportFormScreen);

export default FileByPhoneFormScreen;
