import validator from "validator";
import { NO_SELECTION_DROPDOWN_VALUE } from "./constants";
import {
  CREDIT_REASON_SUSPENDED,
  ROUTING_NUM_INVALID_MSG,
  BANK,
  EFTPS,
  CREDIT_REASON_SOLD
} from "../constants";
import { isArray, isUndefined, omitBy, updateWith } from "lodash";

// INTERNAL ----------------------- *
const validateTruthy = v => !!v;

const validateBoolean = v => (typeof v !== "boolean" ? "Required" : undefined);

const validateNotEmpty = v => v !== "" && v !== undefined && v !== null;

const validateOptional = (v, f) => (v ? f(v) : undefined);

const validateName = v => /^(?!-)[A-Z0-9\s-]+$/i.test(v);

const validateAlpha = v => validator.isAlpha(v);

const validateNumeric = v => validator.isNumeric(v);

const validateMaxLength = (v, l) => v.length <= l;

const validateZipHOF = locale => v => {
  if (!v) return "Invalid zip code";
  return validator.isPostalCode(v, locale) ? undefined : "Invalid zip code";
};

export const hasErrors = e => {
  return (
    Object.values(e).filter(a =>
      isArray(a) ? a.length > 0 : a !== undefined && a !== null
    ).length > 0
  );
};

// SIMPLE ----------------------- *
export const validateBuyerName = v =>
  validateNotEmpty(v) &&
  validateMaxLength(v, 75) &&
  /^(?!-)[A-Z0-9#\s-()&']+$/i.test(v)
    ? undefined
    : "Invalid buyer name";

export const validateBuyerAddress = v => validateAddress(v);

export const validateCreditAmount = v =>
  validateNotEmpty(v) ? undefined : "Required";

export const validateBuyerCity = v =>
  validateNotEmpty(v) && validateAlpha(v.replace(/\s/g, ""))
    ? undefined
    : "Invalid city";

export const validateRequired = v => (v ? undefined : "Required");

export const validateEmails = v =>
  v && v.replaceAll(' ,',',').replaceAll(', ',',').split(',').find(validateEmail)
    ? "Invalid e-mail" : undefined;

export const validateEmail = v =>
  v && validator.isEmail(v) && v.length <= 75 ? undefined : "Invalid e-mail";

export const validateEIN = v =>
  v && /^\d{9}$/g.test(v) ? undefined : "Invalid EIN";

export const validatePassword = v =>
  v && /^\d{5}$/g.test(v) && v !== "00000"
    ? undefined
    : "Invalid PIN (must be any 5-digit number other than 00000)";

export const validateBusinessName = v =>
  validateNotEmpty(v) &&
  validateMaxLength(v, 60) &&
  /^[A-Z0-9#\-()&'\s]+$/i.test(v)
    ? undefined
    : "No symbols or punctuation";

export const validateTitle = v =>
  v && v.length <= 30 ? undefined : "Invalid title";

export const validateDesigneeName = v =>
  validateNotEmpty(v) && validateName(v)
    ? undefined
    : "Please use letters (no numbers or special characters)";

export const validateDesigneePin = v =>
  validateNotEmpty(v) && validateNumeric(v)
    ? undefined
    : "Please use 5 numbers (no letters or special characters)";

export const validateFirstName = v =>
  validateNotEmpty(v) && validateName(v) ? undefined : "Invalid first name";

export const validateLastName = v =>
  validateNotEmpty(v) && validateName(v) ? undefined : "Invalid last name";

export const validateMiddleInitial = v =>
  v && /^[A-Z]$/i.test(v) ? undefined : "Invalid middle initial";

export const validateDescription = v =>
  validateTruthy(v) && validateMaxLength(v, 50)
    ? undefined
    : "Exceeds 50 character max";

export const validateDropdown = v =>
  validateNotEmpty(v) && v !== NO_SELECTION_DROPDOWN_VALUE
    ? undefined
    : "Must select";

export const validateDateCategory = v =>
  validateNotEmpty(v) && v !== NO_SELECTION_DROPDOWN_VALUE
    ? undefined
    : "Invalid -or- Outside tax year range (select)";

export const validateYN = v =>
  validateNotEmpty(v) && v !== NO_SELECTION_DROPDOWN_VALUE
    ? undefined
    : "Valid values: Y or N (select)";

export const validateGrossWeight = v =>
  validateNotEmpty(v) && v !== NO_SELECTION_DROPDOWN_VALUE
    ? undefined
    : "Valid values: Letters A-W (select)";

export const validateAddress = v =>
  validateNotEmpty(v) && validateMaxLength(v, 35) && validateName(v)
    ? undefined
    : "No symbols or punctuation";

export const validateCity = v =>
  validateNotEmpty(v) && validateMaxLength(v, 50) && validateName(v)
    ? undefined
    : "Invalid city";

export const validateVIN = v =>
  validateNotEmpty(v) && /^([A-Z0-9]{1,17}|[A-Z0-9]{19})$/.test(v)
    ? undefined
    : "Required -or- IRS Valid VIN is 1-17 (or 19) alphanumeric characters";

export const validateRoutingNumber = v => {
  if (!v) return ROUTING_NUM_INVALID_MSG;
  const firstTwo = parseInt(v.slice(0, 2), 10);
  return ((firstTwo >= 1 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32)) &&
    v.length === 9
    ? undefined
    : ROUTING_NUM_INVALID_MSG;
};

export const validatePhone = v =>
  v && validator.isMobilePhone(v, "en-US") ? undefined : "Invalid phone number";

export const validateUSZip = validateZipHOF("US");

export const validateMXZip = validateZipHOF("MX");

export const validateCAZip = validateZipHOF("CA");

export const validateMustConfirm = v => (v ? undefined : "Must check box");

// COMPLEX ----------------------- *
export const validateEmailOrEIN = v =>
  /(?![a-zA-Z])[0-9-]+$/.test(v) ? validateEIN(v) : validateEmail(v);

export const validateMustHaveEmailOrEIN = (v, a) =>
  a.Email || a.Ein ? undefined : "Must have Email or EIN";

export const validateMustHaveTel1OrTel2 = (v, a) =>
  a.Tel1 || a.Tel2 ? undefined : "Must have at least one phone number";

export const validateConfirmPassword = (v, a) =>
  v && v === a.pin ? undefined : "PINs do not match";

// OPTIONALS ----------------------- *
export const validateOptionalBuyerAddress = v =>
  validateOptional(v, validateBuyerAddress);

export const validateOptionalBuyerCity = v =>
  validateOptional(v, validateBuyerCity);

export const validateOptionalBuyerName = v =>
  validateOptional(v, validateBuyerName);

export const validateOptionalPhone = v => validateOptional(v, validatePhone);

export const validateOptionalFirstName = v =>
  validateOptional(v, validateFirstName);

export const validateOptionalLastName = v =>
  validateOptional(v, validateLastName);

export const validateOptionalTitle = v => validateOptional(v, validateTitle);

export const validateOptionalEIN = v => validateOptional(v, validateEIN);

export const validateOptionalEmail = v => validateOptional(v, validateEmail);

export const validateOptionalBusinessName = v =>
  validateOptional(v, validateBusinessName);

export const validateOptionalDescription = v =>
  validateOptional(v, validateDescription);

export const validateOptionalCity = v => validateOptional(v, validateCity);

export const validateOptionalAddress = v =>
  validateOptional(v, validateAddress);

export const validateOptionalRoutingNumber = v =>
  validateOptional(v, validateRoutingNumber);

export const validateOptionalZipHOF = locale => v =>
  validateOptional(v, validateZipHOF(locale));

export const validateOptionalUSZip = validateOptionalZipHOF("US");
export const validateOptionalMXZip = validateOptionalZipHOF("MX");
export const validateOptionalCAZip = validateOptionalZipHOF("CA");

export const validateOptionalMiddleInitial = v =>
  validateOptional(v, validateMiddleInitial);

export const validateOptionalZipByCountry = (v, a) => {
  switch (a.Country) {
    case "US":
      return validateOptionalUSZip(v);
    case "MX":
      return validateOptionalMXZip(v);
    case "CA":
      return validateOptionalCAZip(v);
    default:
      return validateOptionalUSZip(v);
  }
};

// FORM-BASED ----------------------- *
export const validateZipByCountryField = field => (v, a) => {
  switch (a[field]) {
    case "US":
      return validateUSZip(v);
    case "MX":
      return validateMXZip(v);
    case "CA":
      return validateCAZip(v);
    default:
      return validateUSZip(v);
  }
};

export const validateZipByCountry = validateZipByCountryField("Country");
export const validateZipByCcHolderCountry = validateZipByCountryField(
  "CcHolderCountry"
);

const businessFormValidations = {
  BusinessName: [validateRequired, validateBusinessName],
  Ein: [validateRequired, validateEIN],
  SoleProprietor: validateDropdown,
  Address1: [validateRequired, validateAddress],
  City: [validateRequired, validateCity],
  State: validateDropdown,
  Country: validateRequired,
  Zip: [validateRequired, validateZipByCountry]
};

const validateValue = (value, validations, values) =>
  (isArray(validations) ? validations : [validations])
    .map(validation => validation(value, values))
    .filter(r => !!r)[0];

const validateObject = validations => values =>
  !values
    ? null
    : omitBy(
        Object.entries(validations).reduce((acc, [key, validation]) => {
          acc[key] = validateValue(values[key], validation, values);
          return acc;
        }, {}),
        v => isUndefined(v) || (isArray(v) && v.length === 0)
      );

export const validateBusinessForm = validateObject(businessFormValidations);

export const validateIRSPayment = formValues => {
  if (!formValues || !formValues.PaymentAccount) return null;
  if (formValues.PaymentAccount.PaymentMethod === EFTPS) {
    return {
      "registered-eftps-agreement": validateMustConfirm(
        formValues["registered-eftps-agreement"]
      ),
      "cannot-use-credit-card-agreement": validateMustConfirm(
        formValues["cannot-use-credit-card-agreement"]
      ),
      "i2290-access-eftps-agreement": validateMustConfirm(
        formValues["i2290-access-eftps-agreement"]
      ),
      "amount-shown-agreement": validateMustConfirm(
        formValues["amount-shown-agreement"]
      )
    };
  }
  if (formValues.PaymentAccount.PaymentMethod === BANK) {
    return {
      PaymentAccount: {
        Rtn: validateOptionalRoutingNumber(formValues.PaymentAccount.Rtn)
      }
    };
  }
  return {};
};

const validateEFTPS = formValues => {
  if (!formValues || !formValues.PaymentAccount) return null;

  if (formValues.PaymentAccount.PaymentMethod === "EFTPS") {
    const result =
      formValues["registered-eftps-agreement"] === undefined
        ? formValues.PaymentAccount &&
          formValues.PaymentAccount.AgreeEftpsTerms === false
          ? { AgreeEftpsTerms: "Needs to agree to EFTP terms" }
          : {}
        : {
            "registered-eftps-agreement": validateMustConfirm(
              formValues["registered-eftps-agreement"]
            ),
            "cannot-use-credit-card-agreement": validateMustConfirm(
              formValues["cannot-use-credit-card-agreement"]
            ),
            "i2290-access-eftps-agreement": validateMustConfirm(
              formValues["i2290-access-eftps-agreement"]
            ),
            "amount-shown-agreement": validateMustConfirm(
              formValues["amount-shown-agreement"]
            )
          };
    const hasError = !!Object.keys(result).filter(k => result[k] !== undefined)
      .length;
    return hasError ? result : null;
  }
  return null;
};

const validateNonFTPS = formValues => {
  if (!formValues || !formValues.PaymentAccount) return null;

  if (formValues.PaymentAccount.PaymentMethod === "BANK") {
    const result = {
      PaymentAccount: {
        Rtn:
          validateRequired(formValues.PaymentAccount.Rtn) ||
          validateRoutingNumber(formValues.PaymentAccount.Rtn),
        AccountNumber: validateRequired(formValues.PaymentAccount.AccountNumber)
      }
    };
    const hasError = !!Object.keys(result.PaymentAccount).filter(
      k => result.PaymentAccount[k] !== undefined
    ).length;
    return hasError ? result : null;
  }
  return null;
};

export const validatePaymentMethod = formValues => {
  const NonFTPS = validateNonFTPS(formValues);
  const ETFPS = validateEFTPS(formValues);
  return NonFTPS === null && EFTPS === null
    ? null
    : { ...(NonFTPS || {}), ...(ETFPS || {}) };
};

const contactFormValidations = {
  Email: [validateRequired, validateEmail, validateMustHaveEmailOrEIN],
  Password: [validateRequired, validatePassword],
  Tel1: [validateOptionalPhone, validateMustHaveTel1OrTel2],
  Tel2: [validateOptionalPhone]
};

export const validateAccountForm = validateObject(contactFormValidations);

export const validateSignerOnly = formValues => ({
  FirstName:
    validateRequired(formValues.FirstName) ||
    validateFirstName(formValues.FirstName),
  LastName:
    validateRequired(formValues.LastName) ||
    validateLastName(formValues.LastName),
  Mi: validateOptionalMiddleInitial(formValues.Mi),
  OfficerTitle:
    validateRequired(formValues.OfficerTitle) ||
    validateTitle(formValues.OfficerTitle)
});

export const validateSignerFrom = values =>
  omitBy(
    {
      ...validateDesigneeForm(values),
      ...validateSignerOnly(values),
      ...validateAccountForm(values)
    },
    v => isUndefined(v) || (isArray(v) && v.length === 0)
  );

export const validateRegisterForm = v => ({
  firstName: validateRequired(v.firstName) || validateFirstName(v.firstName),
  lastName: validateRequired(v.lastName) || validateLastName(v.lastName),
  phoneNumber: v.phoneNumber ? validatePhone(v.phoneNumber) : undefined,
  username:
    validateRequired(v.username) || validateEmailOrEIN(v.username.trim()),
  pin: validateRequired(v.pin) || validatePassword(v.pin),
  confirmPin:
    validateRequired(v.confirmPin) ||
    validateConfirmPassword(v.confirmPin, { pin: v.pin })
});

export const validateSigninForm = v => ({
  username:
    validateRequired(v.username) || validateEmailOrEIN(v.username.trim()),
  pin: validateRequired(v.pin) || validatePassword(v.pin)
});

export const validateForgotPINForm = v => ({
  ein: validateRequired(v.ein) || validateEIN(v.ein),
  email: validateRequired(v.email) || validateEmail(v.email)
});

export const validateDesigneeForm = f => {
  if (!f.ThirdPyesNo) {
    return {};
  }
  return {
    ThirdPname:
      validateRequired(f.ThirdPname) || validateDesigneeName(f.ThirdPname),
    ThirdPphone:
      validateRequired(f.ThirdPphone) || validatePhone(f.ThirdPphone),
    ThirdPpin: validateRequired(f.ThirdPpin) || validatePassword(f.ThirdPpin)
  };
};

// validateVehicleForm,  validateCreditVehicleForm

export const validateCreditOrVehicleForm = value =>
  value.IsTaxable
    ? validateVehicleForm(value)
    : validateCreditVehicleForm(value);

export const validateVehicleForm = value => ({
  Vin: validateRequired(value.Vin) || validateVIN(value.Vin),
  Description: validateOptionalDescription(value.Description),
  WeightCategory: validateGrossWeight(value.WeightCategory),
  DateCategory: validateDateCategory(value.DateCategory),
  Logging: validateYN(value.Logging),
  Agricultural: validateYN(value.Agricultural),
  Suspend: validateYN(value.Suspend)
});

export const validateCreditVehicleForm = values => {
  if (values.CreditReason === CREDIT_REASON_SUSPENDED) {
    return {
      Vin: validateRequired(values.Vin) || validateVIN(values.Vin),
      CreditReason: validateDropdown(values.CreditReason),
      CreditAmount: validateCreditAmount(values.CreditAmount),
      WeightCategory: validateGrossWeight(values.WeightCategory),
      CreditEventDate: validateRequired(values.CreditEventDate)
    };
  }
  if (values.CreditReason === CREDIT_REASON_SOLD) {
    return {
      Vin: validateRequired(values.Vin) || validateVIN(values.Vin),
      CreditReason: validateDropdown(values.CreditReason),
      WeightCategory: validateGrossWeight(values.WeightCategory),
      CreditEventDate: validateRequired(values.CreditEventDate),
      BuyerAddress1:
        validateRequired(values.BuyerAddress1) ||
        validateBuyerAddress(values.BuyerAddress1),
      BuyerName:
        validateRequired(values.BuyerName) ||
        validateBuyerName(values.BuyerName),
      BuyerCity:
        validateRequired(values.BuyerCity) ||
        validateBuyerCity(values.BuyerCity),
      BuyerState: validateDropdown(values.BuyerState),
      BuyerZip:
        validateRequired(values.BuyerZip) ||
        validateOptionalZipByCountry(values.BuyerZip, {
          Country: values.BuyerCountry
        })
    };
  }
  return {
    Vin: validateRequired(values.Vin) || validateVIN(values.Vin),
    CreditReason: validateDropdown(values.CreditReason),
    WeightCategory: validateGrossWeight(values.WeightCategory),
    CreditEventDate: validateRequired(values.CreditEventDate)
  };
};

export const validateVehicleFieldArray = values => {
  const errors = { Vehicles: [] };
  if (!values.Vehicles || !values.Vehicles.length) {
    return errors;
  }
  const VehiclesArrayErrors = [];
  values.Vehicles.forEach((Vehicle, VehicleIndex) => {
    const VehicleErrors = validateVehicleForm(Vehicle);
    if (Object.keys(VehicleErrors).filter(k => !!VehicleErrors[k]).length) {
      VehiclesArrayErrors[VehicleIndex] = VehicleErrors;
    }
  });
  if (VehiclesArrayErrors.length) {
    errors.Vehicles = VehiclesArrayErrors;
  }

  return errors;
};

export const returnNullIfValid = func => (...args) => {
  const validationResult = func(...args);

  if (validationResult === undefined) return null;

  const hasError = !!Object.keys(validationResult).filter(
    k => validationResult[k] !== undefined
  ).length;

  return hasError ? validationResult : null;
};

export const validateZipByCountryWithNullValid = returnNullIfValid(
  validateZipByCountry
);

export const validateVehicleFormWithNullValid = returnNullIfValid(
  validateVehicleForm
);

export const validateCreditVehicleFormWithNullValid = returnNullIfValid(
  validateCreditVehicleForm
);
