import {
  buildActionType,
  buildAsyncActionType
} from "../../modules/utilities/reduxUtilities";
import { STATE_NAME } from "./register.constants";

export const REGISTER_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "REGISTER_ASYNC"
);

export const RESET = buildActionType(STATE_NAME, "RESET");
