import React from "react";
import PropTypes from "prop-types";
import Select, { components, createFilter } from "react-select";

// NOTE THIS IS NOT A GENERIC COMPONENT AND SHOULDN'T BE CALLED COMBOBOX

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  const value = getValue()[0];
  const { Vin, TaxYear, WeightCategory } = value;
  return (
    <components.ValueContainer {...props}>
      <div className="row w-100">
        <div className="col-12 col-md clean-gutter-md-right ellipsis">
          <strong className="vin-value">{Vin}</strong>
        </div>
        <div className="col-auto clean-gutter-md-left clean-gutter-md-right text-md-right my-auto">
          <span className="no-break">Filed: {TaxYear} </span>
        </div>
        <div className="col-12 col-md-auto clean-gutter-md-left pr-0 my-auto">
          <span className="no-break">Weight Class: {WeightCategory}</span>
        </div>
      </div>
    </components.ValueContainer>
  );
};

const Option = ({ data: { Vin, TaxYear, WeightCategory }, ...props }) => (
  <components.Option {...props}>
    <div className="row w-100">
      <div className="col-12 col-md clean-gutter-md-right">
        <strong>{Vin}</strong>
      </div>
      <div className="col-12 col-md clean-gutter-md-left clean-gutter-md-right text-md-right">
        <span className="no-break">Filed: {TaxYear} </span>
      </div>
      <div className="col-12 col-md-auto clean-gutter-md-left clean-gutter-md-right">
        <span className="no-break">Weight Class: {WeightCategory}</span>
      </div>
    </div>
  </components.Option>
);

const ComboBox = ({
  input,
  data,
  meta: { touched, error },
  className,
  placeholder
}) => (
  <div className={className}>
    <Select
      placeholder={placeholder}
      styles={{
        menu: styles => ({ ...styles, zIndex: 999 }),
        control: styles => ({ ...styles, minHeight: "40px" })
      }}
      filterOption={createFilter({ ignoreAccents: false })}
      components={{ ValueContainer, Option }}
      value={input.value}
      options={data}
      onChange={x => {
        input.onChange(x);
      }}
      isMulti={false}
      isClearable
    />
    {touched && error && <span className="i2290-inline-error">{error}</span>}
  </div>
);

ComboBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

ComboBox.defaultProps = {};

export default ComboBox;
