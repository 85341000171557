import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";

const {
  components: {
    Button,
    TextField,
    LanguageDropdown
  }
} = controls;

const FileByPhoneForm = ({
  onSubmit,
  onClickCancel,
  bodyValidations,
}) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">Tell us about your Form 2290 needs</h2>
    <section>
      <div className="row">
        <div className="col-9">
          <TextField
            name="Body"
            label="Details"
            type="text"
            multiline
            rows={8}
            validations={bodyValidations}
          />
        </div>
        <div className="col-3">
            <LanguageDropdown
              name="Language"
              label=""
            />
          </div>
      </div>
    </section>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right"> 
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

FileByPhoneForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  bodyValidations: PropTypes.arrayOf(PropTypes.func)
};

FileByPhoneForm.defaultProps = {
  bodyValidations: []
};

export default FileByPhoneForm;
