import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const CountryDropdown = ({
  name,
  label,
  onCountryChange,
  validations,
  onBlur
}) => (
  <Select
    name={name}
    label={label}
    onChange={onCountryChange}
    onBlur={onBlur}
    validations={validations}
  >
    <option value="US">United States</option>
    <option value="MX">Mexico</option>
    <option value="CA">Canada</option>
  </Select>
);

CountryDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  onBlur: PropTypes.func
};

CountryDropdown.defaultProps = {
  validations: null,
  onBlur: null
};

export default CountryDropdown;
