import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isInvalid } from "redux-form";
import SubmissionWidget from "./SubmissionWidget";
import userContext from "../../userContext";
import { toggleDialog as toggleDialogCreator } from "../../paymentModal/paymentModal.actions";
import utilities from "../../utilities";
import { TERMS_AND_CONDITIONS_URI, PRIVACY_POLICY_URI } from "../../constants";
import { getPaymentInfo } from "../../cart/cart.selectors";
import controls from "../../controls";
import {
  getFormEntities,
  getOrderHOF,
  getVehicleEntities,
  getUserIsCoveredByUnlimited,
  getDisclaimerIsVisible
} from "../order.selectors";
import {
  submitOrder as submitOrderCreator,
  toggleDisclaimerIsVisible as toggleDisclaimerIsVisibleCreator
} from "../order.actions";
import { getIsNoCharge } from "../order.utilities";

const {
  selectors: { getUserContext }
} = userContext;

const { getOrderIdFromUrl } = utilities;

const {
  validations: {
    validateVehicleFormWithNullValid: validateTaxable,
    validateCreditVehicleFormWithNullValid: validateCredit
  }
} = controls;

class SubmissionWidgetContainer extends Component {
  handleClickSubmission = () => {
    const {
      props: {
        toggleDialog,
        isNoCharge,
        isWeightIncrease,
        userIsCoveredByUnlimited,
        submitOrder,
        total
      }
    } = this;
    if (
      isWeightIncrease ||
      userIsCoveredByUnlimited ||
      isNoCharge ||
      total <= 0
    ) {
      submitOrder(getOrderIdFromUrl());
    } else {
      toggleDialog();
    }
  };

  handleClickDisclaimer = () => {
    const {
      props: { toggleDisclaimerIsVisible }
    } = this;
    toggleDisclaimerIsVisible();
  };

  handleClickTerms = () => window.open(TERMS_AND_CONDITIONS_URI, "_blank");

  handleClickPrivacy = () => window.open(PRIVACY_POLICY_URI, "_blank");

  render() {
    const {
      handleClickSubmission,
      handleClickDisclaimer,
      handleClickPrivacy,
      handleClickTerms,
      props: {
        userContext,
        companyFormIsInvalid,
        signerFormIsInvalid,
        contactFormIsInvalid,
        designeeFormIsInvalid,
        paymentMethodFormIsInvalid,
        numberOfTaxableVehicles,
        numberOfInvalidCreditVehicles,
        numberOfInvalidTaxableVehicles,
        isWeightIncrease,
        disclaimerIsVisible
      }
    } = this;
    const isSubmissionButtonDisabled =
      companyFormIsInvalid ||
      signerFormIsInvalid ||
      contactFormIsInvalid ||
      designeeFormIsInvalid ||
      paymentMethodFormIsInvalid ||
      numberOfTaxableVehicles === 0 ||
      numberOfInvalidTaxableVehicles > 0 ||
      numberOfInvalidCreditVehicles > 0;

    const buttonText = isSubmissionButtonDisabled
      ? "Form is incomplete. Unable to Submit"
      : "AGREE and Submit to IRS";

    return (
      <SubmissionWidget
        onClickDisclaimer={handleClickDisclaimer}
        onClickPrivacy={handleClickPrivacy}
        onClickTerms={handleClickTerms}
        disclaimerIsVisible={disclaimerIsVisible}
        buttonText={buttonText}
        isWeightIncrease={isWeightIncrease}
        pin={userContext && userContext.Password ? userContext.Password : "N/A"}
        onClickSubmission={handleClickSubmission}
        isSubmissionButtonDisabled={isSubmissionButtonDisabled}
        companyFormIsInvalid={companyFormIsInvalid}
        signerFormIsInvalid={signerFormIsInvalid}
        contactFormIsInvalid={contactFormIsInvalid}
        paymentMethodFormIsInvalid={paymentMethodFormIsInvalid}
        designeeFormIsInvalid={designeeFormIsInvalid}
        numberOfInvalidTaxableVehicles={numberOfInvalidTaxableVehicles}
        numberOfInvalidCreditVehicles={numberOfInvalidCreditVehicles}
      />
    );
  }
}

SubmissionWidgetContainer.propTypes = {
  userContext: PropTypes.object,
  toggleDialog: PropTypes.func,
  companyFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfInvalidCreditVehicles: PropTypes.number,
  numberOfInvalidTaxableVehicles: PropTypes.number,
  isWeightIncrease: PropTypes.bool.isRequired,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  submitOrder: PropTypes.func.isRequired,
  isNoCharge: PropTypes.bool.isRequired,
  total: PropTypes.string,
  disclaimerIsVisible: PropTypes.bool.isRequired,
  toggleDisclaimerIsVisible: PropTypes.func.isRequired
};

SubmissionWidgetContainer.defaultProps = {
  userContext: {},
  companyFormIsInvalid: false,
  signerFormIsInvalid: false,
  contactFormIsInvalid: false,
  designeeFormIsInvalid: false,
  paymentMethodFormIsInvalid: false,
  numberOfTaxableVehicles: 0,
  numberOfInvalidCreditVehicles: 0,
  numberOfInvalidTaxableVehicles: 0,
  total: null
};

// const mapStateToProps = state => ({
//   userContext: getUserContext(state)
// });

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const userContext = getUserContext(state);

  let numberOfTaxableVehicles = 0;
  let numberOfInvalidTaxableVehicles = 0;
  let numberOfInvalidCreditVehicles = 0;
  let isWeightIncrease = false;
  let isNoCharge = false;
  let total = null;

  const order = getOrderHOF(orderId)(state);
  if (order) {
    isWeightIncrease = order.WeightIncrease;
    isNoCharge = getIsNoCharge(order.PaymentTranNum);
  }
  if (order && order.Forms.length) {
    ({ total } = getPaymentInfo(state));
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    const orderVehicles = vehicleIds.map(id => vehicles[id]);
    orderVehicles.forEach(x => {
      if (x.IsTaxable) {
        numberOfTaxableVehicles += 1;
        if (validateTaxable(x)) {
          numberOfInvalidTaxableVehicles += 1;
        }
        return;
      }
      if (validateCredit(x)) {
        numberOfInvalidCreditVehicles += 1;
      }
    });
  }

  return {
    total,
    isNoCharge,
    isWeightIncrease,
    disclaimerIsVisible: getDisclaimerIsVisible(state),
    userContext,
    numberOfTaxableVehicles,
    numberOfInvalidTaxableVehicles,
    numberOfInvalidCreditVehicles,
    signerFormIsInvalid: order ? isInvalid("signerSubmission")(state) : false,
    contactFormIsInvalid: order ? isInvalid("contactSubmission")(state) : false,
    designeeFormIsInvalid: order ? isInvalid("designee")(state) : false,
    companyFormIsInvalid: order
      ? isInvalid("companyInfoSubmission")(state)
      : false,
    paymentMethodFormIsInvalid: order
      ? isInvalid("paymentMethod")(state)
      : false,
    userIsCoveredByUnlimited: getUserIsCoveredByUnlimited(orderId)(state)
  };
};

const mapDispatchToProps = dispatch => ({
  toggleDialog: () => dispatch(toggleDialogCreator()),
  submitOrder: orderId => dispatch(submitOrderCreator(orderId)),
  toggleDisclaimerIsVisible: () => dispatch(toggleDisclaimerIsVisibleCreator())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionWidgetContainer)
);
