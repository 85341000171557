import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import WizardController from "./WizardController";
import vehicles from "../../../modules/vehicles";
import utilities from "../../../modules/utilities";
import userContext from "../../../modules/userContext";
import { stageVehicle as stageVehicleCreator } from "../../../modules/vehicles/vehicles.actions";
import { getOrderAlt } from "../../../modules/order/order.selectorsv2";

const {
  getOrderIdFromUrl,
  transform: { mapTaxableVehicleToPayload }
} = utilities;

const {
  selectors: {
    getTaxableVehiclesInEdit,
    getRedirect,
    getWorkingCopies,
    getTaxableVehiclesStagedForCreation,
    getTaxableVehiclesGridOrder,
    getTaxableVehiclesShowImporter
  },
  actions: {
    setRedirect: setRedirectCreator,
    putVehicle: putVehicleCreator,
    postVehicle: postVehicleCreator,
    setShowImporter: setShowImporterCreator
  },
  constants: { TAXABLE_VEHICLES_SLICE_NAME }
} = vehicles;

const {
  actions: { setRedirect: setUserRedirectCreator, fetchUser: fetchUserCreator }
} = userContext;

class VehiclesStepWizardControllerContainer extends Component {
  componentDidMount() {
    const { setUserRedirect, isOrderLoaded, isWeightIncrease } = this.props;
    if (!isOrderLoaded) return;
    if (!isWeightIncrease) return;

    setUserRedirect(`/wizard/3?order=${getOrderIdFromUrl()}`);
  }

  componentWillUnmount() {
    const { setRedirect } = this.props;
    setRedirect(null);
  }

  handleNextClick = () => {
    const {
      props: {
        vehiclesInEdit,
        history,
        fetchUser,
        putVehicle,
        postVehicle,
        setUserRedirect
      }
    } = this;

    const orderId = getOrderIdFromUrl();

    vehiclesInEdit
      .map(x => mapTaxableVehicleToPayload(x))
      .forEach(x =>
        x.Id ? putVehicle(orderId, x.Id, x) : postVehicle(orderId, x)
      );

    fetchUser();

    setUserRedirect(`/wizard/3?order=${getOrderIdFromUrl()}`);
  };

  handleBackClick = () => {
    const {
      showImporter,
      setShowImporter,
      vehicleStaging,
      stageVehicle,
      setUserRedirect
    } = this.props;

    if (vehicleStaging) {
      stageVehicle(null);
    } else if (showImporter) {
      setShowImporter(false);
    } else {
      setUserRedirect(`/wizard/1?order=${getOrderIdFromUrl()}`);
    }
  };

  render() {
    const { redirect } = this.props;
    return (
      <React.Fragment>
        {redirect !== null && <Redirect to={redirect} />}
        <WizardController
          onNextClick={this.handleNextClick}
          onBackClick={this.handleBackClick}
          backButtonIsDisabled={false}
        />
      </React.Fragment>
    );
  }
}

VehiclesStepWizardControllerContainer.propTypes = {
  redirect: PropTypes.string,
  setRedirect: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  vehiclesInEdit: PropTypes.arrayOf(PropTypes.object),
  putVehicle: PropTypes.func.isRequired,
  postVehicle: PropTypes.func.isRequired
};

VehiclesStepWizardControllerContainer.defaultProps = {
  redirect: null,
  vehiclesInEdit: []
};

const mapStateToProps = state => {
  const order = getOrderAlt(state) || { WeightIncrease: false };
  const workingCopies = getWorkingCopies(state);
  const gridOrder = getTaxableVehiclesGridOrder(state);
  const vehicleStaging = getTaxableVehiclesStagedForCreation(state);
  return {
    vehicleStaging,
    workingCopies: gridOrder.map(id => workingCopies[id]),
    redirect: getRedirect(state),
    stagedForCreation: getTaxableVehiclesStagedForCreation(state),
    vehiclesInEdit: getTaxableVehiclesInEdit(state),
    showImporter: getTaxableVehiclesShowImporter(state),
    isOrderLoaded: typeof order !== "undefined",
    isWeightIncrease: !!order.WeightIncrease
  };
};

const mapDispatchToProps = dispatch => ({
  setUserRedirect: redirect => dispatch(setUserRedirectCreator(redirect)),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect)),
  fetchUser: () => dispatch(fetchUserCreator()),
  postVehicle: (orderId, payload) =>
    dispatch(postVehicleCreator(orderId, payload, TAXABLE_VEHICLES_SLICE_NAME)),
  putVehicle: (orderId, vehicleId, data) =>
    dispatch(putVehicleCreator(orderId, vehicleId, data)),
  setShowImporter: showImporter =>
    dispatch(setShowImporterCreator(TAXABLE_VEHICLES_SLICE_NAME, showImporter)),
  stageVehicle: payload =>
    dispatch(stageVehicleCreator(payload, TAXABLE_VEHICLES_SLICE_NAME))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VehiclesStepWizardControllerContainer)
);
