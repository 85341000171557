import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";
import { MessageScreen } from "modules/controls/components/MessageScreen";
import { fetchUser as fetchUserCreator } from "modules/userContext/userContext.actions";


const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator())
});

const ThankYouMessage =
window.i2290settings && window.i2290settings.DefaultSupportSubmissionMessage ;

const ThankYouScreen = ({history, fetchUser}) => {

  useEffect(() => {
    fetchUser();
  // only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const handleClickOK = () => {
    history.push(ORDER_HISTORY_ROUTE);
  };

  return <MessageScreen onClickOK={handleClickOK}>      
      <div className="mb-gutter" dangerouslySetInnerHTML={{ __html: ThankYouMessage }} />    
  </MessageScreen>; 
};


export const ThankYouScreenContainer = withRouter(connect(null, mapDispatchToProps)(ThankYouScreen))


const ThankYouVinMessage =
window.i2290settings && window.i2290settings.SuccessfulVINCorrectionSubmissionMessage ;

/** @param {{history: any, fetchUser: () => any}} [props] @return JSX.Element*/
const ThankYouVinChangeScreen = ({ history, fetchUser }) => {

  useEffect(() => {
    fetchUser();
    // only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleClickOK = () => {
    history.push(ORDER_HISTORY_ROUTE);
  };

  return <MessageScreen onClickOK={handleClickOK} text="Got it">
    <div className="mb-gutter" dangerouslySetInnerHTML={{ __html: ThankYouVinMessage }} />    
  </MessageScreen>;
};

export const ThankYouVinChangeScreenContainer = withRouter(connect(null, mapDispatchToProps)(ThankYouVinChangeScreen));
