import React from "react";
import { Switch, Route } from "react-router-dom";
import CompanyInfo from "./CompanyInfoContainer";
import Signer from "./SignerContainer";
import VehiclesStep from "./VehiclesStepContainer";
import IRSPayment from "./IRSPaymentContainer";
import withNewUserWizardFrame from "../hoc/withNewUserWizardFrame";
import {
  BUSINESS_STEP,
  VEHICLES_STEP,
  SIGNER_STEP,
  PAYMENT_STEP
} from "../../../../src/modules/constants";
import hoc from "../../hoc";

const {
  hof: { withAccountFrame }
} = hoc;

const NewUserWizard = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/${BUSINESS_STEP}`}
      component={withAccountFrame(withNewUserWizardFrame(CompanyInfo))}
    />
    <Route
      exact
      path={`${match.path}/${VEHICLES_STEP}`}
      component={withAccountFrame(withNewUserWizardFrame(VehiclesStep))}
    />
    <Route
      exact
      path={`${match.path}/${SIGNER_STEP}`}
      component={withAccountFrame(withNewUserWizardFrame(Signer))}
    />
    <Route
      exact
      path={`${match.path}/${PAYMENT_STEP}`}
      component={withAccountFrame(withNewUserWizardFrame(IRSPayment))}
    />
  </Switch>
);

export default NewUserWizard;
