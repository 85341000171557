import React from "react";
import PropTypes from "prop-types";
import "./slideDownAnimator.css";

const withSlideDownAnimator = (Slide1, Slide2) => {
  const WithSlideDownAnimator = ({ showSlide2, toggleShowing, ...rest }) => (
    <div className="slide-down-animator">
      {!showSlide2 ? (
        <Slide1 {...rest} toggleShowing={toggleShowing} />
      ) : (
        <Slide2 {...rest} toggleShowing={toggleShowing} />
      )}
    </div>
  );

  WithSlideDownAnimator.propTypes = {
    showSlide2: PropTypes.bool.isRequired,
    fireAnimation: PropTypes.func.isRequired
  };

  return WithSlideDownAnimator;
};

export default withSlideDownAnimator;
