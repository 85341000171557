import React from "react";
import PropTypes from "prop-types";
import "./ackCopiedVehiclesModal.css";

const AckCopiedVehiclesModal = ({ isVisible, onClickIUnderstand }) => (
  <React.Fragment>
    {isVisible && (
      <div className="ack-copied alert-warning">
        <p className="my-auto clean-gutter-right">
          Your previously filed vehicles were automatically imported for your
          convenience. Please review and confirm before submitting.
        </p>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
        <span
          style={{ marginRight: "8px" }}
          className="k-icon k-i-close k-i-x my-auto clean-gutter-left"
          onClick={onClickIUnderstand}
        />
      </div>
    )}
  </React.Fragment>
);

AckCopiedVehiclesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClickIUnderstand: PropTypes.func.isRequired
};

export default AckCopiedVehiclesModal;
