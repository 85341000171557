import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import userContext from "../../userContext";
import SignerForm from "./SignerForm";
import { FORM_NAME } from "../signer.constants";
import controls from "../../controls";
import utilities from "../../utilities";
import { saveOnBlur } from "../../userContext/userContext.actions";
import { getFieldOnTab } from "../../userContext/userContext.selectors";

const {
  validations: { validateSignerOnly: validate }
} = controls;

const {
  actions: { patchUser: patchUserCreator },
  selectors: { getUserContext, getRedirect },
  actions: { logout: logoutCreator }
} = userContext;

const {
  transform: { sanitizeValue }
} = utilities;

class SignerFormContainer extends Component {
  componentDidUpdate(prevProps) {
    const {
      initialValues,
      formSyncErrors,
      touch,
      untouch,
      formValues
    } = this.props;
    // We are updating fields on blur. With invalid values.
    /* const {
      initialValues: prevInitialValues,
      formSyncErrors: prevFormSyncErrors
    } = prevProps;
    if (
      initialValues === prevInitialValues ||
      formSyncErrors === prevFormSyncErrors
    )
      return; */

    if (this.props.fieldOnTab !== "") {
      // this would be better made into a ref
      const ref = document.getElementsByClassName("SignerForm")[0];
      if (ref !== undefined) {
        const fieldOnTab = ref.querySelector(
          `[name="${this.props.fieldOnTab}"]`
        );
        fieldOnTab && fieldOnTab.focus();
      }
    }

    if (formSyncErrors && formValues) {
      const latestErrors = validate(formValues);
      touch(
        ...Object.keys(formSyncErrors).filter(k =>
          initialValues.hasOwnProperty(k)
        )
      );
      untouch(
        ...Object.keys(latestErrors).filter(k => latestErrors[k] === undefined)
      );
    }
  }

  handleBlur = e => {
    const { initialValues, saveOnBlur, patchUser } = this.props;
    if (e.nativeEvent && e.nativeEvent.relatedTarget)
      saveOnBlur(e.nativeEvent.relatedTarget.getAttribute("name"));

    if (!e.target.value && !initialValues[e.target.name]) {
      return;
    }

    if (e.target.value === initialValues[e.target.name]) {
      return;
    }

    // We are updating fields on blur. With invalid values.
    /* if (
      formSyncErrors &&
      (formSyncErrors[e.target.name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    } */

    patchUser({
      [e.target.name]: sanitizeValue(e.target.value)
    });
  };

  render() {
    const { initialValues, formSyncErrors, touch, untouch } = this.props;
    return (
      <SignerForm
        onBlur={this.handleBlur}
        initialValues={initialValues}
        formSyncErrors={formSyncErrors}
        touch={touch}
        untouch={untouch}
      />
    );
  }
}

SignerFormContainer.propTypes = {
  patchUser: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  initialValues: getUserContext(state),
  redirect: getRedirect(state),
  fieldOnTab: getFieldOnTab(state)
});

const mapDispatchToProps = dispatch => ({
  patchUser: payload => dispatch(patchUserCreator(payload)),
  logout: () => dispatch(logoutCreator()),
  saveOnBlur: fieldOnTab => dispatch(saveOnBlur(fieldOnTab))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    validate
  })(SignerFormContainer)
);
