import * as actionTypes from "./error.actionTypes";

const ignoreActions = ["vbp/paymentModal/STRIPE_PROCESSING_ASYNC_ERROR_ASYNC"];

export default function reducer(state = {}, action) {
  if (
    action.type.includes("_ERROR") &&
    !action.type.includes("RESET_ERROR") &&
    !ignoreActions.includes(action.type)
  ) {
    return {
      ...state,
      ...action.payload
    };
  }

  if (action.type.includes("_RECEIVED_ASYNC")) {
    return {
      ...state,
      Error: null,
      Message: null,
      Stack: null
    };
  }

  switch (action.type) {
    case actionTypes.RESET: {
      return {
        ...state,
        Error: null,
        Message: null,
        Stack: null
      };
    }
    default: {
      return state;
    }
  }
}
