import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./taxYearErrorModal.css";
import controls from "../../controls";

const {
  components: { Button }
} = controls;

const TaxYearErrorModal = ({ isVisible, onClose, onClickOK, error }) => (
  <React.Fragment>
    {isVisible && (
      <Dialog title="Error" onClose={onClose}>
        <div className="tax-year-error-modal-body">
          <span className="i2290-inline-error">{error}</span>
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              disabled={false}
              text="OK"
              secondary
              onClick={onClickOK}
              width="100%"
            />
          </div>
        </div>
      </Dialog>
    )}
  </React.Fragment>
);

TaxYearErrorModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickOK: PropTypes.func.isRequired,
  error: PropTypes.string
};

TaxYearErrorModal.defaultProps = {
  error: null
};

export default TaxYearErrorModal;
