import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import controls from "../../controls";
import {
  UNLIMITED_CART_MESSAGE,
  WEIGHT_INCREASE_CART_MESSAGE,
  WHITE_LOGO_URI
} from "../../constants";
import UnlimitedCheckbox from "./UnlimitedCheckbox";
import utilities from "../../utilities";

const {
  math: { fastFormatMoney }
} = utilities;

const {
  components: { AutoHeightAnimator, Button, Chevron, Link }
} = controls;

const CartWidget = ({
  total,
  // subTotal,
  productPrice,
  // taxAmount,
  // taxPercentage,
  product,
  promoCode,
  isUnlimited,
  onChangePromoCode,
  onChangeUnlimited,
  onClickClearPromo,
  onClickApply,
  promoValueCode,
  promoValueAmount,
  error,
  productDescription,
  isWeightIncrease,
  userIsCoveredByUnlimited,
  isNoCharge,
  noChargeString,
  isExpanded,
  toggleIsExpanded,
  unlimitedPrice,
  buttonText,
  isSubmissionButtonDisabled,
  onClickSubmission,
  onClickDisclaimer,
  onClickTerms,
  onClickPrivacy,
  isPaid
}) => {
  let bodyMarkup = null;
  if (
    !isWeightIncrease &&
    !userIsCoveredByUnlimited &&
    !isNoCharge &&
    !isPaid
  ) {
    bodyMarkup = (
      <div className="widget-body cart-widget-body soft-border-bottom">
        {total !== null ? (
          <Fragment>
            <div className="row">
              <div className="col">
                <span className="sub-line-parent">{`${product} (${productDescription})`}</span>
              </div>
              <div className="col-auto text-right">
                <span>{`${fastFormatMoney(productPrice)}`}</span>
              </div>
            </div>
            {promoValueCode && promoValueAmount && (
              <Fragment>
                <div className="row">
                  <div className="col">
                    <span>{`Promo (${promoValueCode})`}</span>
                  </div>
                  <div className="col-auto text-right">
                    {/* eslint-disable-next-line max-len */}
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                    <span
                      className="k-icon k-i-close-outline k-i-x-outline mr-1"
                      onClick={onClickClearPromo}
                    />
                    <span>{`-${fastFormatMoney(promoValueAmount)}`}</span>
                  </div>
                </div>
                <div className="hard-border-bottom my-gutter" />
                <div className="row">
                  <div className="col">
                    <span>Total</span>
                  </div>
                  <div className="col-auto text-right">
                    <span>{`${fastFormatMoney(total)}`}</span>
                  </div>
                </div>
              </Fragment>
            )}
            {/* {taxPercentage && taxAmount && ( */}
            {/*  <div className="row"> */}
            {/*    <div className="col"> */}
            {/*      <span>{`State Tax (${taxPercentage}%)`}</span> */}
            {/*    </div> */}
            {/*    <div className="col-auto text-right"> */}
            {/*      <span>{`${fastFormatMoney(taxAmount)}`}</span> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* )} */}
            <div className="row my-gutter">
              <div className="col col-sm-8 clean-gutter-right">
                <TextField
                  className="promo-code-field"
                  fullWidth
                  label="Promo Code"
                  value={promoCode}
                  onChange={onChangePromoCode}
                  variant="outlined"
                />
              </div>
              <div className="col-auto col-sm clean-gutter-left">
                <Button
                  primary
                  text="Apply"
                  disabled={false}
                  onClick={onClickApply}
                />
              </div>
            </div>
            {error && (
              <div className="row">
                <div className="col">
                  <span className="i2290-inline-error">{error}</span>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="row">
            <div className="col">
              <span>Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  } else if (isPaid) {
    bodyMarkup = null;
  } else {
    let message = null;

    if (userIsCoveredByUnlimited) {
      message = UNLIMITED_CART_MESSAGE;
    } else if (isNoCharge) {
      message = noChargeString;
    } else {
      message = WEIGHT_INCREASE_CART_MESSAGE;
    }

    bodyMarkup = (
      <div className="widget-body cart-widget-body soft-border-bottom">
        <p className="mb-0">{message}</p>
      </div>
    );
  }

  return (
    <div className="summary-widget rad-corners cart-widget">
      <div className="summary-header brand-header pr-0 cart-widget-header">
        <div className="row">
          {productPrice === null ? (
            <React.Fragment>
              <div className="col-auto clean-gutter-right my-auto">
                <img
                  className="cart-logo"
                  src={WHITE_LOGO_URI}
                  alt="i2290 logo"
                />
              </div>
              <div className="col my-auto clean-gutter-left">
                <strong>Loading...</strong>
              </div>
            </React.Fragment>
          ) : (
            <div className="col">
              <div className="row">
                <div className="col-auto clean-gutter-right my-auto">
                  <img
                    className="cart-logo"
                    src={WHITE_LOGO_URI}
                    alt="i2290 logo"
                  />
                </div>
                {!isWeightIncrease &&
                !userIsCoveredByUnlimited &&
                !isNoCharge &&
                !isPaid ? (
                  <React.Fragment>
                    <div className="col clean-gutter-left">
                      <h2
                        style={{
                          marginBottom: "5px",
                          textTransform: "uppercase"
                        }}
                      >
                        <em>{product}</em>
                      </h2>
                      <span className="prod-descrip">{productDescription}</span>
                    </div>
                    <div className="total-col col-12 col-sm-auto text-left text-sm-right pl-0 my-auto">
                      <h2
                        style={{
                          marginBottom: "5px",
                          marginRight: "0px",
                          textTransform: "uppercase"
                        }}
                      >
                        {fastFormatMoney(productPrice)}
                      </h2>
                      {/* {taxPercentage && ( */}
                      {/* eslint-disable-next-line max-len */}
                      {/*  <span className="prod-descrip">Includes Sales Tax</span> */}
                      {/* )} */}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {isPaid ? (
                      <React.Fragment>
                        <div className="col clean-gutter-left">
                          <h2
                            style={{
                              marginBottom: "5px",
                              textTransform: "uppercase"
                            }}
                          >
                            <em>{product}</em>
                          </h2>
                          <span className="prod-descrip">
                            {productDescription}
                          </span>
                        </div>
                        <div className="total-col col-12 col-sm-auto text-left text-sm-right pl-0 my-auto">
                          <h2
                            style={{
                              marginBottom: "5px",
                              marginRight: "15px",
                              textTransform: "uppercase"
                            }}
                          >
                            PAID
                          </h2>
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="col-12">
                        <h2 style={{ marginBottom: "5px" }}>No Charge</h2>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        {!isPaid && (
          <div className="col-auto my-auto text-right" style={{ flex: "none" }}>
            <Chevron
              className="payment-widget-chevron"
              onClick={toggleIsExpanded}
              isUp={isExpanded}
            />
          </div>
        )}
      </div>
      {!isWeightIncrease &&
        !userIsCoveredByUnlimited &&
        !isNoCharge &&
        !isPaid && (
          <div className="total-ctnr cart-widget-body widget-body soft-border-bottom">
            <UnlimitedCheckbox
              productPrice={unlimitedPrice}
              isUnlimited={isUnlimited}
              onChangeUnlimited={onChangeUnlimited}
            />
          </div>
        )}
      <AutoHeightAnimator isExpanded={isPaid || isExpanded}>
        {bodyMarkup}
      </AutoHeightAnimator>
      <div className="widget-body cart-widget-body submission-btn-ctnr">
        <div className="text-center">
          <p className="mb-gutter disclaimer-text">
            <Link clickHandler={onClickDisclaimer} text="IRS e-file Terms" />
            {" and "}
            <Link clickHandler={onClickTerms} text="i2290.com Terms" />
            {" and "}
            <Link clickHandler={onClickPrivacy} text="Privacy Policy" />
          </p>
          <Button
            className="submission-button"
            width="100%"
            secondary
            text={buttonText}
            disabled={isSubmissionButtonDisabled}
            onClick={onClickSubmission}
          />
        </div>
      </div>
    </div>
  );
};

CartWidget.propTypes = {
  total: PropTypes.string,
  // subTotal: PropTypes.number,
  productPrice: PropTypes.number,
  // taxAmount: PropTypes.number,
  // taxPercentage: PropTypes.number,
  product: PropTypes.string,
  productDescription: PropTypes.string,
  promoCode: PropTypes.string,
  isUnlimited: PropTypes.bool.isRequired,
  onChangePromoCode: PropTypes.func.isRequired,
  onChangeUnlimited: PropTypes.func.isRequired,
  onClickClearPromo: PropTypes.func.isRequired,
  onClickApply: PropTypes.func.isRequired,
  promoValueCode: PropTypes.string,
  promoValueAmount: PropTypes.number,
  error: PropTypes.string,
  isWeightIncrease: PropTypes.bool,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  isNoCharge: PropTypes.bool.isRequired,
  noChargeString: PropTypes.string,
  unlimitedPrice: PropTypes.number,
  buttonText: PropTypes.string.isRequired,
  isSubmissionButtonDisabled: PropTypes.bool.isRequired,
  onClickSubmission: PropTypes.func.isRequired,
  onClickTerms: PropTypes.func.isRequired,
  onClickDisclaimer: PropTypes.func.isRequired,
  onClickPrivacy: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired
};

CartWidget.defaultProps = {
  total: null,
  // subTotal: null,
  productPrice: null,
  // taxAmount: null,
  // taxPercentage: null,
  product: null,
  productDescription: null,
  promoCode: "",
  promoValueCode: null,
  promoValueAmount: null,
  error: null,
  noChargeString: null,
  unlimitedPrice: null
};

export default CartWidget;
