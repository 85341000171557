import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FormSummaryPanelRepeater from "./FormSummaryPanelRepeater";
import order from "../../../modules/order";
import { FORM_TYPE_8849 } from "../../../modules/constants";

const {
  selectors: { getHistoryOrderHOF, getFormHistory }
} = order;

class FormSummaryPanelRepeaterContainer extends Component {
  handleClickEdit = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/form?order=${orderId}`);
  };

  render() {
    const {
      handleClickEdit,
      props: { formIds, orderId }
    } = this;
    return (
      <FormSummaryPanelRepeater
        onClickEdit={handleClickEdit}
        formIds={formIds}
        orderId={orderId}
      />
    );
  }
}

FormSummaryPanelRepeaterContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
  formIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

const mapStateToProps = (state, props) => {
  const order = getHistoryOrderHOF(props.orderId)(state);
  const forms = getFormHistory(state);
  return {
    formIds:
      order && order.Forms
        ? order.Forms.filter(
            id =>
              forms[id].DateCategory !== 0 &&
              forms[id].DateCategory !== null &&
              forms[id].FormType !== FORM_TYPE_8849
          )
        : []
  };
};

export default withRouter(
  connect(mapStateToProps)(FormSummaryPanelRepeaterContainer)
);
