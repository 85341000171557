import React from "react";
import PropTypes from "prop-types";
import MaterialSwitch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import "./switch.css";

const styles = {
  checked: {
    color: "#9B9B9C !important"
  }
};

const renderSwitch = ({ classes, input, ...custom }) => (
  <MaterialSwitch
    {...input}
    {...custom}
    onClick={() => input.onChange(!input.checked)}
    classes={classes}
    color="primary"
  />
);

const Switch = ({
  name,
  validations,
  normalize,
  format,
  className,
  ...custom
}) => (
  <div className={className ? `i2290-switch ${className}` : "i2290-switch"}>
    <span className="i2290-switch-label">No</span>
    <Field
      {...custom}
      name={name}
      component={renderSwitch}
      validate={validations}
      normalize={normalize}
      format={format}
      type="checkbox"
    />
    <span className="i2290-switch-label">Yes</span>
  </div>
);

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func
};

Switch.defaultProps = {
  normalize: null,
  format: null,
  validations: null
};

export default withStyles(styles)(Switch);
