import {
  lookupDateCategoryByJavascriptDateMonth,
  lookupMonthCSV
} from "../utilities/lookups";
import { CREDIT_REASON_SOLD, CREDIT_REASON_SUSPENDED } from "../constants";
import { validateZipByCountryWithNullValid } from "./validation";
import { normalizeZipByCountry } from "./normalization";
import { states, countries as countryMap } from "./data";
import { overrideCreditAmount } from "../utilities/taxYear";

const countries = countryMap.map(x => x.real);
const usStates = states.US.map(x => x.real);
const mxStates = states.MX.map(x => x.real);
const caStates = states.CA.map(x => x.real);

const transformState = (country, state) => {
  if (state === undefined) return null;
  const normalized = state.toUpperCase();
  switch (country) {
    case "US":
      return usStates.includes(normalized) ? normalized : null;
    case "MX":
      return mxStates.includes(normalized) ? normalized : null;
    case "CA":
      return caStates.includes(normalized) ? normalized : null;
    default:
      return null;
  }
};
const transformYesNo = val => {
  if (val === undefined) return null;
  const normalized = val.toUpperCase();
  return normalized === "Y" || normalized === "N" ? normalized === "Y" : null;
};

const transformCountry = val => {
  if (val === undefined) return null;
  const normalized = val.toUpperCase();
  return countries.includes(normalized) ? normalized : null;
};

const transformCreditDate = (val, currentTaxYear) => {
  if (val === undefined || !/^\d{8}$/.test(val)) return null;
  const date = new Date(val.replace(/(\d{4})(\d{2})(\d{2})/, "$2/$3/$1"));
  if (date instanceof Date && Number.isNaN(date)) {
    return null;
  }

  const min = new Date(currentTaxYear - 110, 6, 1);
  const max = new Date(currentTaxYear + 1, 5, 30);
  return date >= min && date <= max ? date : null;
};

const transformWeightCategory = val =>
  /^[a-v]$/i.test(val) ? val.toUpperCase() : null;

const transformCreditReason = val => {
  if (val === undefined) return null;
  const normalized = val.toUpperCase();
  if (
    normalized === "SOLD" ||
    normalized === "STOLEN" ||
    normalized === "DESTROYED" ||
    normalized === "SUSPENDED"
  ) {
    return (
      normalized.charAt(0).toUpperCase() + normalized.slice(1).toLowerCase()
    );
  }
  return null;
};

const setCreditDateCategory = creditEventDate => {
  if (creditEventDate === null) return null;
  return (
    lookupDateCategoryByJavascriptDateMonth(creditEventDate.getMonth()) - 1
  );
};

const transformZip = (country, zip) => {
  if (zip === undefined || country === undefined) return null;
  const validationResult = validateZipByCountryWithNullValid(zip, {
    Country: country
  });
  return validationResult === null
    ? normalizeZipByCountry(zip, null, {
        Country: country
      })
    : null;
};

export const transformCSV = (data, taxYear) =>
  data.map(x => {
    if (x.hasOwnProperty("")) delete x[""];
    if (!/^\d{6}$/.test(x.VehicleDate)) {
      x.DateCategory = null;
    } else {
      const year = x.VehicleDate.slice(0, 4);
      const month = x.VehicleDate.slice(4);
      const numYear = parseInt(year, 10);
      const orderTaxYear = parseInt(taxYear, 10);
      const dateCategory = lookupMonthCSV(month);

      if (!dateCategory || !numYear) {
        x.DateCategory = null;
      } else if (numYear === orderTaxYear) {
        if (dateCategory < 7) {
          x.DateCategory = null;
        } else {
          x.DateCategory = dateCategory;
        }
      } else if (numYear === orderTaxYear + 1) {
        if (dateCategory > 6) {
          x.DateCategory = null;
        } else {
          x.DateCategory = dateCategory;
        }
      } else {
        x.DateCategory = null;
      }
    }

    if (x.Description) {
      x.Description = x.Description.slice(0, 100);
    }

    delete x.VehicleDate;

    x.WeightCategory = /[a-vA-V]/.test(x.WeightCategory)
      ? x.WeightCategory.toUpperCase()
      : null;

    x.Suspend = transformYesNo(x.Suspend);

    if (x.WeightCategory === "W") {
      x.Suspend = true;
    } else if (x.Suspend) {
      x.WeightCategory = "W";
    }

    x.Logging = transformYesNo(x.Logging);
    x.Agricultural = transformYesNo(x.Agricultural);

    return x;
  });

export const transformCreditsCSV = (data, currentTaxYear, taxTable) =>
  data.map(x => {
    if (x.hasOwnProperty("")) delete x[""];
    x.CreditEventDate = transformCreditDate(
      x.VehicleCreditDate,
      currentTaxYear
    );
    x.CreditReason = transformCreditReason(x.CreditReason);
    x.WeightCategory = transformWeightCategory(x.WeightCategory);
    x.Logging = transformYesNo(x.Logging);
    x.Agricultural = false;
    x.Suspend = false;
    x.BuyerCountry = transformCountry(x.BuyerCountry);
    x.BuyerState = transformState(x.BuyerCountry, x.BuyerState);
    x.BuyerZip = transformZip(x.BuyerCountry, x.BuyerZip);
    x.IsBuyerBusiness = transformYesNo(x.IsBuyerBusiness);
    x.DateCategory = setCreditDateCategory(x.CreditEventDate);
    x.TaxPaidPriorPeriod = x.TaxPaidPriorPeriod;
    if (Number.isNaN(x.TaxPaidPriorPeriod)) {
      x.TaxPaidPriorPeriod = null;
    }

    // might not need this?
    if (x.WeightCategory === "W") {
      x.Suspend = true;
    }

    if (x.CreditReason === CREDIT_REASON_SUSPENDED) {
      x.CreditAmount = x.TaxPaidPriorPeriod;
    }

    x.CreditAmount = overrideCreditAmount(
      x.CreditAmount,
      x.CreditReason,
      x.WeightCategory,
      x.Logging,
      taxTable
    );

    x.IsBuyerDataIncluded = !!(
      x.CreditReason === CREDIT_REASON_SOLD &&
      (x.BuyerName ||
        x.BuyerAddress1 ||
        x.BuyerAddress2 ||
        x.BuyerState ||
        x.BuyerCity ||
        x.BuyerZip ||
        x.BuyerCountry ||
        x.IsBuyerBusiness)
    );

    if (x.CreditReason !== CREDIT_REASON_SOLD) {
      x.BuyerName = null;
      x.BuyerAddress1 = null;
      x.BuyerAddress2 = null;
      x.BuyerState = null;
      x.BuyerCity = null;
      x.BuyerZip = null;
      x.BuyerCountry = null;
      x.IsBuyerBusiness = null;
      x.IsBuyerDataIncluded = null;
    }

    return x;
  });
