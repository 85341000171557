import React from "react";
import PropTypes from "prop-types";
import TextField from "./TextField";
import { normalizePin } from "../normalization";

const PasswordField = ({ name, label, onBlur, validations, textIsHidden }) => (
  <TextField
    name={name}
    label={label}
    type={textIsHidden ? "password" : "text"}
    onBlur={onBlur}
    validate={validations}
    normalize={normalizePin}
    autoComplete="no"
  />
);

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  validations: PropTypes.arrayOf(PropTypes.func),
  textIsHidden: PropTypes.bool
};

PasswordField.defaultProps = {
  name: "Pin",
  label: "5-digit PIN",
  validations: null,
  onBlur: null,
  textIsHidden: true
};

export default PasswordField;
