import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import Filer from "./Filer";
import order from "../../../modules/order";
import {
  WEIGHT_INCREASE_DD_VAL,
  VIN_CORRECTION_DD_VAL,
  WEIGHT_INCREASE_ROUTE,
  VIN_CORRECTION_ROUTE,
  FORM_ROUTE
} from "../../../modules/constants";
import { checkLastYearVehicles } from "../../../modules/order/order.actions";

const {
  selectors: { getRedirect, getActiveOrders, getTaxYears },
  actions: { postOrder: postOrderCreator }
} = order;

class FilerContainer extends Component {
  state = {
    dropdownValue: "",
    showImportModal: false
  };

  handleChangeDropdown = async ({ target: { value } }) => {
    this.setState({ dropdownValue: "" });
    const {
      props: {
        postOrder,
        activeOrders,
        history: { push }
      }
    } = this;
    if (value === null) return;
    if (value === VIN_CORRECTION_DD_VAL) return push(VIN_CORRECTION_ROUTE);
    if (value === WEIGHT_INCREASE_DD_VAL) return push(WEIGHT_INCREASE_ROUTE);

    if (activeOrders[value]) {
      push(`${FORM_ROUTE}?order=${activeOrders[value]}`);
    } else {
      const vehicleCount = await checkLastYearVehicles(value);

      if (vehicleCount > 0) {
        this.setState({ showImportModal: true, dropdownValue: value });
      } else {
        postOrder(value, "/form", false);
      }
    }
  };

  handleClickNo = () => {
    const {
      props: {
        postOrder,
        activeOrders,
        history: { push }
      },
      state: { dropdownValue }
    } = this;

    if (!dropdownValue) return;

    const taxYear = dropdownValue;

    this.setState({ dropdownValue: "" });
    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      postOrder(taxYear, "/form", false);
    }
  };

  handleClickYes = () => {
    const {
      props: {
        postOrder,
        activeOrders,
        history: { push }
      },
      state: { dropdownValue }
    } = this;

    if (!dropdownValue) return;

    const taxYear = dropdownValue;

    this.setState({ dropdownValue: "" });
    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      postOrder(taxYear, "/form", true);
    }
  };

  render() {
    const {
      handleChangeDropdown,
      handleClickNo,
      handleClickYes,
      state: { dropdownValue },
      props: {
        taxYears,
        redirect,
        location: { pathname }
      },
      state: { showImportModal }
    } = this;

    return (
      <Fragment>
        {redirect && redirect !== pathname && <Redirect to={redirect} />}
        <Filer
          value={dropdownValue}
          onChange={handleChangeDropdown}
          options={taxYears}
          showImportModal={showImportModal}
          handleClickNo={handleClickNo}
          handleClickYes={handleClickYes}
        />
      </Fragment>
    );
  }
}

FilerContainer.propTypes = {
  postOrder: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  activeOrders: PropTypes.object.isRequired
};

FilerContainer.defaultProps = {
  redirect: null
};

const mapStateToProps = state => ({
  redirect: getRedirect(state),
  activeOrders: getActiveOrders(state),
  taxYears: getTaxYears(state)
});

const mapDispatchToProps = dispatch => ({
  postOrder: (taxYear, redirect, fromHistory) =>
    dispatch(postOrderCreator(taxYear, redirect, fromHistory))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilerContainer)
);
