import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import {
  normalizeBooleanDropdown,
  formatBooleanDropdown
} from "../normalization";

const BusinessStructureDropdown = ({ name, validations, label, onBlur }) => (
  <Select
    name={name}
    label={label}
    validations={validations}
    format={formatBooleanDropdown}
    normalize={normalizeBooleanDropdown}
    onBlur={onBlur}
  >
    <option value="true">Sole Proprietor</option>
    <option value="false">Inc, LLC, LP</option>
  </Select>
);

BusinessStructureDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.array,
  label: PropTypes.string,
  onBlur: PropTypes.func
};

BusinessStructureDropdown.defaultProps = {
  validations: [],
  label: "Structure",
  onBlur: null
};

export default BusinessStructureDropdown;
