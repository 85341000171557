import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import {
  normalizeBooleanDropdown,
  formatBooleanDropdown
} from "../normalization";

const BinaryDropdown = ({
  name,
  label,
  onBlur,
  onChange,
  validations,
  trueText,
  falseText,
  ...rest
}) => (
  <Select
    name={name}
    label={label}
    onBlur={onBlur}
    onChange={onChange}
    validations={validations}
    format={formatBooleanDropdown}
    normalize={normalizeBooleanDropdown}
    {...rest}
  >
    <option value="true">{trueText}</option>
    <option value="false">{falseText}</option>
  </Select>
);

BinaryDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validations: PropTypes.arrayOf(PropTypes.func),
  trueText: PropTypes.string,
  falseText: PropTypes.string
};

BinaryDropdown.defaultProps = {
  label: null,
  validations: null,
  onBlur: null,
  onChange: null,
  trueText: "Yes",
  falseText: "No"
};

export default BinaryDropdown;
