import * as actions from "./taxYear.actions";
import * as constants from "./taxYear.constants";
import * as selectors from "./taxYear.selectors";
import reducer from "./taxYear.reducer";

export default {
  actions,
  constants,
  reducer,
  selectors
};
