import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import VinCorrectionForm from "./VinCorrectionFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 className="support-page-header">
      Do you have a Schedule 1 with a mistyped VIN?
    </h2>
    <p style={{ marginBottom: "30px" }}>
      No matter how many you filed, we can help you get a corrected Schedule 1.
    </p>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <VinCorrectionForm />
  </div>
);

const VinCorrectionFormScreen = withChildren(children, SupportFormScreen);

export default VinCorrectionFormScreen;
