import React from "react";
import "./busyIndicator.css";

const BusyIndicator = ({ isBusy, size }) => (
  <div>
    {isBusy && (
      <div className="spinner" style={{ width: size, height: size }} />
    )}
  </div>
);

export default BusyIndicator;
