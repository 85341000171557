import DesigneeForm from "./DesigneeForm";
import DesigneeFormWidget from "./DesigneeFormWidget";
import DesigneeFormWidgetContainer from "./DesigneeFormWidgetContainer";
import DesigneeView from "./DesigneeView";
import DesigneeViewContainer from "./DesigneeViewContainer";
import SignerViewContainer from "./SignerViewContainer";

export default {
  DesigneeForm,
  DesigneeFormWidget,
  DesigneeFormWidgetContainer,
  DesigneeView,
  DesigneeViewContainer,
  SignerViewContainer
};
