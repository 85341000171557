import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TotalTaxIndicator from "./TotalTaxIndicator";
import { getEntities } from "../form.selectors";
import order from "../../order";
import utilities from "../../utilities";
import { FORM_TYPE_8849 } from "../../constants";

const {
  selectors: { getOrderHOF }
} = order;

const { getOrderIdFromUrl } = utilities;

class TotalTaxIndicatorContainer extends Component {
  render() {
    const {
      props: { totalTax }
    } = this;
    return <TotalTaxIndicator totalTax={totalTax} />;
  }
}

TotalTaxIndicatorContainer.propTypes = {
  totalTax: PropTypes.number
};

TotalTaxIndicatorContainer.defaultProps = {
  totalTax: null
};

const mapStateToProps = state => {
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  if (order && order.Forms) {
    const forms = getEntities(state);
    return {
      totalTax: order.Forms.map(id => forms[id])
        .filter(x => x.FormType !== FORM_TYPE_8849)
        .reduce((a, v) => a + v.TotalTax - v.TotalCredit, 0)
    };
  }
  return {
    totalTax: null
  };
};

export default connect(mapStateToProps)(TotalTaxIndicatorContainer);
