import React from "react";
import { TextEntryPrompt } from "modules/controls/components/TextEntryPrompt";
import { validateEmails} from "modules/controls/validation"

export const SendEmailDialog = ({
  title,
  isVisible,
  setIsVisible,
  onOk,
  children,
  ...rest
}) => {

  const _props = {title, isVisible, setIsVisible, onOk, ...rest};

  return <TextEntryPrompt className="text-left"
    label="Separate with commas when entering more than one email address"
    minWidth="300px"
    isValid={validateEmails}
    rows={3}
    okLabel="Send"
    {..._props}
  >
    {children}
  </TextEntryPrompt>;  
}