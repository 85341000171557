import React from "react";
import "./companyInfoWidgetBody.css";
import CompanyInfoForm from "./CompanyInfoFormSubmissionContainer";
import signer from "../../signer";
import contact from "../../contact";

const {
  components: { SignerFormSubmissionContainer: SignerForm }
} = signer;

const {
  components: { ContactFormSubmissionContainer: ContactForm }
} = contact;

const CompanyInfoWidgetBody = () => (
  <div className="company-info-form widget-body">
    <div className="row">
      <div className="col-12 col-lg-6 clean-gutter-lg-right mb-gutter mb-lg-0">
        <div className="company-form-col-head">Company Information</div>
        <CompanyInfoForm />
      </div>
      <div className="col-12 col-lg-6 mb-0 clean-gutter-lg-left">
        <div className="company-form-col-head">E-file Signed By/Contact</div>
        <div className="row">
          <div className="col-12 col-md-6 clean-gutter-md-right">
            <SignerForm />
          </div>
          <div className="col-12 col-md-6 clean-gutter-md-left">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CompanyInfoWidgetBody;
