import React, { Fragment } from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import {
  UNLIMITED_CART_MESSAGE,
  WEIGHT_INCREASE_CART_MESSAGE
} from "../../constants";
import utilities from "../../utilities";
import DisclaimerModal from "../../../modules/order/components/DisclaimerModal";

const {
  math: { fastFormatMoney }
} = utilities;

const {
  components: { AutoHeightAnimator, Button, Chevron, Link }
} = controls;

const PaySubmit = ({
  // subTotal,
  //totalCredit,
  //totalTax,
  // taxPercentage,
  //isUnlimited,
  //onClickSubmission,
  total,
  netTax,
  pin,
  disclaimerIsVisible,
  error,
  isWeightIncrease,
  userIsCoveredByUnlimited,
  isNoCharge,
  noChargeString,
  buttonText,
  isSubmissionButtonDisabled,
  onClickDisclaimer,
  onClickTerms,
  onClickPrivacy,
  isPaid,
  paymentAccountShortDesc,
  onClickEdit,
  onClickModal
}) => {
  let bodyMarkup = null;
  if (
    !isWeightIncrease &&
    !userIsCoveredByUnlimited &&
    !isNoCharge &&
    !isPaid
  ) {
    bodyMarkup = (
      <div className="widget-body cart-widget-body soft-border-bottom">
        {total !== null ? (
          <Fragment>
            <div className="row">
              <div className="col">
                <span className="sub-line-parent">productDescription</span>
              </div>
              <div className="col-auto text-right">
                <span>{`${fastFormatMoney(netTax)}`}</span>
              </div>
            </div>
            {/* {taxPercentage && taxAmount && ( */}
            {/*  <div className="row"> */}
            {/*    <div className="col"> */}
            {/*      <span>{`State Tax (${taxPercentage}%)`}</span> */}
            {/*    </div> */}
            {/*    <div className="col-auto text-right"> */}
            {/*      <span>{`${fastFormatMoney(taxAmount)}`}</span> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* )} */}
            {error && (
              <div className="row">
                <div className="col">
                  <span className="i2290-inline-error">{error}</span>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="row">
            <div className="col">
              <span>Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  } else if (isPaid) {
    bodyMarkup = null;
  } else {
    let message = null;

    if (userIsCoveredByUnlimited) {
      message = UNLIMITED_CART_MESSAGE;
    } else if (isNoCharge) {
      message = noChargeString;
    } else {
      message = WEIGHT_INCREASE_CART_MESSAGE;
    }

    bodyMarkup = (
      <div className="widget-body cart-widget-body soft-border-bottom">
        <p className="mb-0">{message}</p>
      </div>
    );
  }

  return (
    <div>
      <DisclaimerModal
        pin={pin}
        onClose={onClickDisclaimer}
        isVisible={disclaimerIsVisible}
      />
      <div className="summary-widget">
        <div className="summary-header bg-white">
          <div className="form-widget-header row">
            <div className="col-12 col-md my-auto">Pay the IRS</div>
            <div className="col text-right">
              <Button
                className="i2290-btn i2290-btn-primary"
                text="Edit"
                disabled={false}
                onClick={onClickEdit}
                primary={false}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="widget-body row align-items-end">
        <div className="col-8">What you owe the IRS:</div>
        <div className="col-4 text-right">{fastFormatMoney(netTax)}</div>
        <div className="col-7">How are you paying the IRS:</div>
        <div className="col-5 text-right">
          {netTax !== 0 ? paymentAccountShortDesc : "No payment required"}
        </div>
        <div className="col-12 cart-widget-body submission-btn-ctnr mt-4">
          <div className="text-center">
            <p className="mb-gutter disclaimer-text">
              <Link clickHandler={onClickDisclaimer} text="IRS e-file Terms" />
              {" and "}
              <Link clickHandler={onClickTerms} text="i2290.com Terms" />
              {" and "}
              <Link clickHandler={onClickPrivacy} text="Privacy Policy" />
            </p>
            <Button
              className="submission-button"
              width="100%"
              primary
              text={buttonText}
              disabled={isSubmissionButtonDisabled}
              onClick={onClickModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PaySubmit.propTypes = {
  total: PropTypes.string,
  netTax: PropTypes.number,
  // subTotal: PropTypes.number,
  // taxAmount: PropTypes.number,
  // taxPercentage: PropTypes.number,
  isUnlimited: PropTypes.bool.isRequired,
  error: PropTypes.string,
  isWeightIncrease: PropTypes.bool,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  isNoCharge: PropTypes.bool.isRequired,
  noChargeString: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  isSubmissionButtonDisabled: PropTypes.bool.isRequired,
  onClickSubmission: PropTypes.func.isRequired,
  onClickTerms: PropTypes.func.isRequired,
  onClickDisclaimer: PropTypes.func.isRequired,
  onClickPrivacy: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
  paymentAccountShortDesc: PropTypes.string,
  onClickEdit: PropTypes.func
};

PaySubmit.defaultProps = {
  total: null,
  // subTotal: null,
  // taxAmount: null,
  // taxPercentage: null,
  error: null,
  noChargeString: null
};

export default PaySubmit;
