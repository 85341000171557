import React from "react";
import PropTypes from "prop-types";
import { Input } from "@progress/kendo-react-inputs";

class TextField extends React.Component {
  handleChange = e => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  };

  render() {
    const dataValue = this.props.dataItem[this.props.field];

    let error = null;
    if (
      this.props.dataItem.errors &&
      this.props.dataItem.errors[this.props.field]
    ) {
      error = this.props.dataItem.errors[this.props.field];
    }

    if (!this.props.dataItem.inEdit) {
      return (
        <div>
          {dataValue === null || dataValue === undefined
            ? `${this.props.label}: `
            : `${this.props.label}: ${this.props.dataItem[
                this.props.field
              ].toString()}`}
        </div>
      );
    }

    return (
      <div>
        <Input
          style={{ width: "100%" }}
          name={this.props.field}
          label={this.props.label}
          onChange={this.handleChange}
          value={dataValue}
        />
        {error && <span className="i2290-inline-error">{error}</span>}
      </div>
    );
  }
}

TextField.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default TextField;
