import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";
import { STATE_NAME } from "./taxYear.constants";

export const SET_TAX_YEAR = buildActionType(STATE_NAME, "SET_TAX_YEAR");

export const FETCH_TAX_YEAR_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_TAX_YEAR_ASYNC"
);

export const PATCH_TAX_YEAR_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "PATCH_TAX_YEAR_ASYNC"
);

export const FETCH_TAX_TABLE_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_TAX_TABLE_ASYNC"
);
