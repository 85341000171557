import React from "react";
import {
  NO_VEHICLES_ERROR_MESSAGE,
  INVALID_VEHICLE_NUMBER_ERROR_MESSAGE,
  INVALID_CREDITS_NUMBER_ERROR_MESSAGE
} from "../../../modules/constants";
import controls from "../../../modules/controls";
import order from "../../../modules/order";
import vehicles from "../../../modules/vehicles";
import WizardController from "./VehiclesStepWizardControllerContainer";

const {
  components: { CreditVehiclesAnimatorContainer: CreditVehiclesAnimator }
} = vehicles;

const {
  components: { VehiclesAnimatorContainer: VehiclesAnimator }
} = vehicles;

const {
  components: { TaxYearDropdownContainer: TaxYearDropdown }
} = order;

const {
  components: { Button }
} = controls;

const VehiclesStep = ({
  creditsOrVehiclesStaged,
  vehiclesEntered,
  vehicleErrorsExist,
  creditVehicleErrorCount,
  vehicleErrorCount,
  invalidVehiclesExist,
  creditsOrVehiclesFromUrl,
  setRedirect,
  pathName,
  orderId
}) => {
  const showCredits = () => setRedirect(`${pathName}?order=${orderId}&Credits`);
  const showVehicles = () => setRedirect(`${pathName}?order=${orderId}`);

  return (
    <div className="nu-vehicles-screen widget-background drop-shadow p-gutter mb-gutter rad-corners">
      <div className="new-user-header-cntr mb-gutter">
        <h1 className="header-size">
          What {creditsOrVehiclesFromUrl ? "credit" : "vehicle"}/s do you want
          to file?
        </h1>
        {!creditsOrVehiclesStaged && (
          <>
            <div className="row">
              <div className="col-12 mt-gutter">
                {!creditsOrVehiclesFromUrl && (
                  <Button
                    className="grid-button grid-button-spaced"
                    onClick={showCredits}
                    disabled={false}
                    secondary
                    width="170px"
                  >
                    Switch to Credits
                    {creditVehicleErrorCount > 0 && (
                      <i
                        className="fa fa-exclamation-circle icon-red fa-lg ml-gutter"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                )}
                {creditsOrVehiclesFromUrl && (
                  <Button
                    className="grid-button grid-button-spaced"
                    onClick={showVehicles}
                    disabled={false}
                    secondary
                    width="170px"
                  >
                    Switch to Vehicles
                    {(vehicleErrorCount > 0 || vehiclesEntered === 0) && (
                      <i
                        className="fa fa-exclamation-circle icon-red fa-lg ml-gutter"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-gutter">
                {!creditsOrVehiclesFromUrl && (
                  <TaxYearDropdown label="Tax Period" width="300px" />
                )}
              </div>
            </div>
            {(invalidVehiclesExist || vehiclesEntered === 0) && (
              <div className="text-center mt-gutter mb-gutter">
                <span className="i2290-inline-error">
                  {creditsOrVehiclesFromUrl
                    ? creditVehicleErrorCount === 0
                      ? ""
                      : INVALID_CREDITS_NUMBER_ERROR_MESSAGE(
                          creditVehicleErrorCount
                        )
                    : vehicleErrorCount === 0
                    ? vehiclesEntered == 0
                      ? NO_VEHICLES_ERROR_MESSAGE
                      : ""
                    : INVALID_VEHICLE_NUMBER_ERROR_MESSAGE(vehicleErrorCount)}
                </span>
              </div>
            )}
          </>
        )}
      </div>

      {!creditsOrVehiclesFromUrl && <VehiclesAnimator />}
      {creditsOrVehiclesFromUrl && <CreditVehiclesAnimator />}
      {!creditsOrVehiclesStaged && (
        <div className="new-user-ctrl-area new-user-inner-content">
          <WizardController />
        </div>
      )}
    </div>
  );
};

export default VehiclesStep;
