import React from "react";
import PropTypes from "prop-types";
import FormWidgetHeaderContainer from "./FormWidgetHeaderContainer";
import FormWidgetBodyContainer from "./FormWidgetBodyContainer";
// import controls from "../../controls";

// const {
//   components: { AutoHeightAnimator }
// } = controls;

const Summary = ({ isExpanded, formId }) => (
  <div className="summary-widget">
    {/*    Removed header...
    <div className="summary-header bg-white">
      <FormWidgetHeaderContainer formId={formId} />
    </div>
    */}
    <FormWidgetBodyContainer formId={formId} isExpanded={isExpanded} />
  </div>
);

Summary.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  formId: PropTypes.number.isRequired
};

export default Summary;
