import SupportCenterScreen from "./SupportCenterScreen";
import SupportCenterScreenContainer from "./SupportCenterScreenContainer";
import SupportFormScreen from "./SupportFormScreen";
import SupportFormScreenContainer from "./SupportFormScreenContainer";
import ContactSupportFormScreen from "./ContactSupportFormScreen";
import UnlockRequestFormScreen from "./UnlockRequestFormScreen";
import IrsRejectedFormScreen from "./IrsRejectedFormScreen";
import NoticeOrLetterFormScreen from "./NoticeOrLetterFormScreen";
import ScheduleOneSupportFormScreen from "./ScheduleOneSupportFormScreen";
import BulkPricingFormScreen from "./BulkPricingFormScreen";
import VinCorrectionFormScreen from "./VinCorrectionFormScreen";
import FileByPhoneFormScreen from "./FileByPhoneFormScreen";


export default {
  SupportCenterScreen,
  SupportCenterScreenContainer,
  SupportFormScreen,
  SupportFormScreenContainer,
  ContactSupportFormScreen,
  UnlockRequestFormScreen,
  IrsRejectedFormScreen,
  NoticeOrLetterFormScreen,
  ScheduleOneSupportFormScreen,
  BulkPricingFormScreen,
  VinCorrectionFormScreen,
  FileByPhoneFormScreen
};
