import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Uploader from "./Uploader";

class UploaderContainer extends Component {
  componentWillUnmount() {
    const {
      props: { setFiles }
    } = this;
    setFiles([]);
  }

  render() {
    const {
      props: { files, setFiles }
    } = this;
    return <Uploader files={files} setFiles={setFiles} />;
  }
}

UploaderContainer.propTypes = {
  files: PropTypes.array,
  setFiles: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  files: props.getFiles(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  setFiles: files => dispatch(props.setFiles(files))
});

const ConnectedUploaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploaderContainer);

ConnectedUploaderContainer.propTypes = {
  getFiles: PropTypes.func.isRequired,
  setFiles: PropTypes.func.isRequired,
  fileLimit: PropTypes.number
};

ConnectedUploaderContainer.defaultProps = {
  fileLimit: null
};

export default ConnectedUploaderContainer;
