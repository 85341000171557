import React, { Component } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormScreen from "./FormScreen";
import order from "../../../modules/order";
import cart from "../../../modules/cart";
import utilities from "../../../modules/utilities";
import {
  getFormEntities,
  getOrderHOF,
  getVehicleEntities,
  getRedirect,
  getCanBeEdited
} from "../../../modules/order/order.selectors";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";

const { getOrderIdFromUrl } = utilities;

const {
  actions: {
    fetchOrder: fetchOrderCreator,
    fetchActiveOrders: fetchActiveOrdersCreator
  }
} = order;

const {
  actions: {
    fetchProducts: fetchProductsCreator,
    changePromoCode: changePromoCodeCreator,
    clearPromo: clearPromoCreator
  }
} = cart;

class FormScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchOrder, fetchProducts, fetchActiveOrders, orderId }
    } = this;
    fetchActiveOrders();
    fetchOrder(orderId);
    fetchProducts();
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        fetchOrder,
        fetchProducts,
        fetchActiveOrders,
        orderId,
        changePromoCode,
        clearPromo
      }
    } = this;
    if (orderId !== prevProps.orderId) {
      fetchActiveOrders();
      fetchOrder(orderId);
      fetchProducts();
      clearPromo();
      changePromoCode("");
    }
  }

  handleClickEditVehicles = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/edit-vehicles?order=${orderId}`);
  };

  handleClickEditCompany = () => {
    const {
      props: {
        history: { push }
      }
    } = this;

    push(`/wizard/1?order=${getOrderIdFromUrl(this)}`);
  };

  handleClickEditSigner = () => {
    const {
      props: {
        history: { push }
      }
    } = this;

    push(`/wizard/3?order=${getOrderIdFromUrl(this)}`);
  };

  handleClickEditPayment = () => {
    const {
      props: {
        history: { push }
      }
    } = this;

    push(`/wizard/4?order=${getOrderIdFromUrl(this)}`);
  };

  handleClickEditCreditVehicles = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/edit-credits?order=${orderId}`);
  };

  render() {
    const {
      handleClickEditVehicles,
      handleClickEditCreditVehicles,
      handleClickEditCompany,
      handleClickEditSigner,
      handleClickEditPayment,
      props: {
        orderId,
        numberOfCreditVehicles,
        numberOfTaxableVehicles,
        isWeightIncrease,
        redirect
      }
    } = this;
    return (
      <FormScreen
        orderId={orderId}
        redirect={redirect}
        isWeightIncrease={isWeightIncrease}
        numberOfCreditVehicles={numberOfCreditVehicles}
        numberOfTaxableVehicles={numberOfTaxableVehicles}
        onClickEditVehicles={handleClickEditVehicles}
        onClickEditCompany={handleClickEditCompany}
        onClickEditSigner={handleClickEditSigner}
        onClickEditPayment={handleClickEditPayment}
        onClickEditCreditVehicles={handleClickEditCreditVehicles}
      />
    );
  }
}

FormScreenContainer.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  fetchActiveOrders: PropTypes.func.isRequired,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfCreditVehicles: PropTypes.number,
  isWeightIncrease: PropTypes.bool,
  redirect: PropTypes.string,
  orderId: PropTypes.string.isRequired
};

FormScreenContainer.defaultProps = {
  numberOfTaxableVehicles: 0,
  numberOfCreditVehicles: 0,
  redirect: null
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl(this);
  let numberOfTaxableVehicles = 0;
  let numberOfCreditVehicles = 0;
  let isWeightIncrease = null;

  const order = getOrderHOF(orderId)(state);
  if (order && order.Forms.length) {
    isWeightIncrease = order.WeightIncrease;
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    const orderVehicles = vehicleIds.map(id => vehicles[id]);
    orderVehicles.forEach(x => {
      if (x.IsTaxable) {
        numberOfTaxableVehicles += 1;
        return;
      }
      numberOfCreditVehicles += 1;
    });
  }
  return {
    orderId,
    numberOfTaxableVehicles,
    numberOfCreditVehicles,
    isWeightIncrease,/*ssss */
    redirect: getCanBeEdited(order) ? ORDER_HISTORY_ROUTE : getRedirect(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true)),
  fetchProducts: () => dispatch(fetchProductsCreator()),
  fetchActiveOrders: () => dispatch(fetchActiveOrdersCreator()),
  changePromoCode: val => dispatch(changePromoCodeCreator(val)),
  clearPromo: () => dispatch(clearPromoCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormScreenContainer);

/*
 */
