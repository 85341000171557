import React from "react";
import AccountFrameContainer from "../components/AccountFrameContainer";

const withAccountFrame = WrappedComponent => ({ ...rest }) => (
  <AccountFrameContainer>
    <WrappedComponent {...rest} />
  </AccountFrameContainer>
);

export default withAccountFrame;
