import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormSyncErrors, getFormValues, reduxForm } from "redux-form";
import {
  TAXABLE_VEHICLES_SLICE_NAME,
  VEHICLE_EDITOR_FORM_NAME
} from "../vehicles.constants";
import VehicleEditor from "./VehicleEditor";
import {
  postVehicle as postVehicleCreator,
  putVehicle as putVehicleCreator,
  stageVehicle as stageVehicleCreator,
  stageForDeletion as stageForDeletionCreator,
  toggleShowImporter as toggleShowImporterCreator,
  updateTaxableVehicleStaging as updateTaxableVehicleStagingCreator
} from "../vehicles.actions";
import { resetError as resetErrorCreator } from "../../error/error.actions";
import utilities from "../../utilities";
import order from "../../order";
import { getTaxableVehiclesStagedForCreation } from "../vehicles.selectors";
import { validateVehicleForm } from "../../controls/validation";
import {
  normalizeTaxableVehicleDescription,
  normalizeVIN
} from "../../controls/normalization";
import { computeTax } from "../../utilities/taxYear";
import { getCurrentTaxYear } from "../../order/order.selectors";

const {
  getOrderIdFromUrl,
  transform: { mapTaxableVehicleToPayload }
} = utilities;

const {
  selectors: { getOrder },
  actions: { fetchOrder: fetchOrderCreator }
} = order;

// Used to call getOrder after postVehicle. This seems to be the clearest way to do this..
// without circular relations.
export const postVehicleAndFetchOrder = (orderId, vehiclePayload) => {
  return dispatch => {
    return dispatch(
      postVehicleCreator(orderId, vehiclePayload, TAXABLE_VEHICLES_SLICE_NAME)
    ).then(_ => dispatch(fetchOrderCreator(orderId, true)));
  };
};

class VehicleEditorContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = props.handleSubmit(this.handleSubmitSuccess);
    this.state = {
      cancelModalIsOpen: false
    };
  }

  componentDidMount() {
    const {
      props: { data, touch }
    } = this;

    if (data.errors) {
      touch(...Object.keys(data.errors).filter(k => data.hasOwnProperty(k)));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      initialValues,
      formSyncErrors,
      touch,
      untouch,
      anyTouched
    } = this.props;

    if (!anyTouched) return;

    const {
      initialValues: prevInitialValues
      // formSyncErrors: prevFormSyncErrors
    } = prevProps;
    if (
      initialValues === prevInitialValues
      // formSyncErrors === prevFormSyncErrors
    )
      return;

    if (formSyncErrors) {
      const latestErrors = validateVehicleForm(initialValues);
      touch(
        ...Object.keys(formSyncErrors).filter(k =>
          initialValues.hasOwnProperty(k)
        )
      );
      untouch(
        ...Object.keys(latestErrors).filter(k => latestErrors[k] === undefined)
      );
    }
  }

  componentWillUnmount() {
    const {
      props: { data, stageVehicle, resetError }
    } = this;
    if (data) {
      stageVehicle(null);
    }
    resetError();
  }

  toggleCancelModalIsOpen = () => {
    this.setState(s => ({ ...s, cancelModalIsOpen: !s.cancelModalIsOpen }));
  };

  handleConfirmCancelModal = () => {
    const {
      props: { stageVehicle }
    } = this;
    this.setState({ cancelModalIsOpen: false }, () => stageVehicle(null));
  };

  handleChange = (event, value) => {
    const { updateVehicleStaging, data, taxTable } = this.props;

    const {
      target: { name: field }
    } = event;

    if (!field) return;

    const update = { [field]: value };

    if (field === "Vin") {
      update.Vin = normalizeVIN(value);
    }

    if (field === "Description") {
      update.Description = normalizeTaxableVehicleDescription(value);
    }

    if (field === "WeightCategory") {
      update.Suspend = value === "W";
    }

    if (field === "Suspend") {
      update.WeightCategory = value ? "W" : "V";
    }

    update.TaxValue = computeTax({ ...data, ...update }, taxTable);

    updateVehicleStaging(update);
  };

  handleClickUpload = () => {
    const {
      props: { toggleShowImporter }
    } = this;
    toggleShowImporter();
  };

  handleSubmitSuccess = () => {
    const {
      props: { postVehicle, putVehicle, data, orderId }
    } = this;
    return data.Id
      ? putVehicle(orderId, data.Id, mapTaxableVehicleToPayload(data))
      : postVehicle(orderId, mapTaxableVehicleToPayload(data));
  };

  handleClickDelete = () => {
    const {
      props: { stageForDeletion, data }
    } = this;

    stageForDeletion(data.Id);
  };

  render() {
    const {
      handleSubmit,
      handleClickDelete,
      handleClickUpload,
      handleChange,
      handleConfirmCancelModal,
      toggleCancelModalIsOpen,
      props: { taxYear, data },
      state: { cancelModalIsOpen }
    } = this;

    const taxAmount = data.TaxValue;
    const isDeletable = !!data.Id;

    return (
      <VehicleEditor
        isDeletable={isDeletable}
        onChange={handleChange}
        onSubmit={handleSubmit}
        taxYear={taxYear}
        onClickCancel={toggleCancelModalIsOpen}
        onClickDelete={handleClickDelete}
        onClickUpload={handleClickUpload}
        taxAmount={taxAmount}
        cancelModalIsOpen={cancelModalIsOpen}
        onCloseCancelModal={toggleCancelModalIsOpen}
        onConfirmCancelModal={handleConfirmCancelModal}
      />
    );
  }
}

VehicleEditorContainer.propTypes = {
  stageVehicle: PropTypes.func.isRequired,
  stageForDeletion: PropTypes.func.isRequired,
  postVehicle: PropTypes.func.isRequired,
  putVehicle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleShowImporter: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  data: PropTypes.object
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const currentTaxYear = getCurrentTaxYear(state);
  const order = getOrder(orderId, state);
  const data = getTaxableVehiclesStagedForCreation(state);
  return {
    data,
    orderId,
    taxYear: order ? order.TaxYear : currentTaxYear,
    initialValues: data,
    formValues: getFormValues(VEHICLE_EDITOR_FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(VEHICLE_EDITOR_FORM_NAME)(state)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  stageVehicle: payload =>
    dispatch(stageVehicleCreator(payload, TAXABLE_VEHICLES_SLICE_NAME)),
  updateVehicleStaging: payload =>
    dispatch(updateTaxableVehicleStagingCreator(payload, props.taxTable)),
  stageForDeletion: id =>
    dispatch(stageForDeletionCreator(id, TAXABLE_VEHICLES_SLICE_NAME)),
  postVehicle: (orderId, payload) =>
    dispatch(postVehicleAndFetchOrder(orderId, payload)),
  putVehicle: (orderId, vehicleId, data) =>
    dispatch(putVehicleCreator(orderId, vehicleId, data)),
  toggleShowImporter: () =>
    dispatch(toggleShowImporterCreator(TAXABLE_VEHICLES_SLICE_NAME)),
  resetError: () => dispatch(resetErrorCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: VEHICLE_EDITOR_FORM_NAME,
    destroyOnUnmount: true,
    touchOnBlur: false,
    enableReinitialize: true,
    validate: validateVehicleForm
  })(VehicleEditorContainer)
);
