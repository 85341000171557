import React, { useEffect } from "react";
import PropTypes from "prop-types";
import busyIndicator from "../../../modules/busyIndicator";
import "./accountFrame.css";
import Navbar from "./NavbarContainer";
import userContext from "../../../modules/userContext";

const {
  components: { GlobalBusyIndicator }
} = busyIndicator;

const {
  components: { WithRestrictedAccess }
} = userContext;

const AccountFrame = ({ children, redirect, push, location, setRedirect }) => {
  useEffect(() => {
    if (redirect) {
      push(redirect);
      setRedirect(null);
    }
  }, [redirect, location.pathname]);

  return (
    <div className="d-flex site-wrapper" id="wrapper">
      <GlobalBusyIndicator />
      <div className="page-content-wrapper">
        <Navbar />
        <div id="content-area" className="container-fluid">
          {children}
        </div>
      </div>
    </div>
  );
};

AccountFrame.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  redirect: PropTypes.string,
  push: PropTypes.func.isRequired
};

AccountFrame.defaultProps = {
  redirect: null
};

export default WithRestrictedAccess(AccountFrame);
