import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import Button from "../../controls/components/Button";
import { getDialogStep } from "../paymentModal.selectors";
import {
  startPayment as startPaymentCreator,
  setPaymentDialog as setPaymentDialogCreator,
} from "../paymentModal.actions";
import { connect } from "react-redux";
import { notStartedStep, previousYearStep } from "../paymentModal.constants";

const NotCurrentYearDialog = ({
  startPayment,
  cancelPayment,
  warningDialogOpen
}) => {

  if (!warningDialogOpen) return <></>; 

  return (
    <Dialog
      className="payment-modal no-scroll"
      title="Confirm Prior Tax Year"
      closeIcon={false}
    >
      <div className="row">
        <div className="col">
          <p
            style={{
              margin: "25px",
              textAlign: "left",
              fontSize: "medium"
            }}
          >
            You are about to e-file a 2290 return from a previous tax year. This
            action cannot be undone.
            <br />
            Do you want to proceed?
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col clean-gutter-right">
          <Button
            primary={false}
            disabled={false}
            text="Cancel"
            onClick={cancelPayment}
            width="100%"
          />
        </div>
        <div className="col clean-gutter-left">
          <Button
            type="submit"
            primary
            disabled={false}
            text="Confirm"
            onClick={startPayment}
            width="100%"
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    warningDialogOpen: getDialogStep(state) === previousYearStep
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startPayment: (next) => dispatch(startPaymentCreator()).then(() => typeof next === "function" ? next() : undefined),
  cancelPayment: (next) => dispatch(setPaymentDialogCreator(notStartedStep)).then(() => typeof next === "function" ? next() : undefined),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotCurrentYearDialog);
