import React from "react";
import BusyIndicator from "./BusyIndicatorContainer";
import "./globalBusyIndicator.css";
import controls from "../../controls";

const {
  components: { Link }
} = controls;

const InlineBusyIndicator = ({
  spinnerSize,
  error,
  isBusy,
  successMessage,
  handleForgotPinClick
}) => (
  <div className="i2290-busy-container">
    <BusyIndicator size={spinnerSize} />
    {error && error.Error && error.Error !== "EinFound" && !isBusy && (
      <span className="i2290-inline-error">{error.Message}</span>
    )}
    {error && error.Error && error.Error === "EinFound" && !isBusy && (
      <span className="i2290-inline-error">
        The entered User ID is already registered in the site. Please enter PIN
        to login. If you forgot your PIN, click{" "}
        <Link text="here" clickHandler={handleForgotPinClick} /> to get it.
      </span>
    )}
    {successMessage && !error && !isBusy && (
      <span className="i2290-inline-success">{successMessage}</span>
    )}
  </div>
);

export default InlineBusyIndicator;
