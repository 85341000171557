import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  currentUserHasPermissions,
  getErrorType
} from "../userContext.selectors";
import { constants as httpConstants } from "../../http";

const { UNAUTHORIZED_NAME } = httpConstants;

const WithRestrictedAccess = (WrappedComponent, requiredPermissions = []) => {
  const curriedCurrentUserHasPermissions = currentUserHasPermissions.bind(
    null,
    requiredPermissions
  );

  const ProtectedContainer = ({
    isAuthenticated,
    location,
    hasPermissions,
    ...rest
  }) => (
    <div>
      {isAuthenticated ? (
        <WrappedComponent {...{ ...rest, location }} />
      ) : (
        <Redirect
          to={{
            // Not authenticated so redirect to /sign-in
            pathname: "/sign-in",
            state: { from: location }
          }}
        />
      )}
    </div>
  );

  ProtectedContainer.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    hasPermissions: PropTypes.bool.isRequired
  };

  const mapStateToProps = state => ({
    isAuthenticated: getErrorType(state) !== UNAUTHORIZED_NAME,
    hasPermissions: curriedCurrentUserHasPermissions(state)
  });

  return connect(mapStateToProps)(ProtectedContainer);
};

export default WithRestrictedAccess;
