import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";

const {
  components: {
    Button,
    TextField,
    UploaderContainer: Uploader,
    AutoHeightAnimator
  }
} = controls;

const IrsRejectedForm = ({
  onSubmit,
  onClickCancel,
  bodyValidations,
  attachmentsIsExpanded,
  getAttachments,
  setAttachments,
  onClickAttach
}) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">IRS Rejected Order Support Request</h2>
    <section>
      <div className="row">
        <div className="col">
          <TextField
            name="Body"
            label="Additional Information"
            type="text"
            multiline
            rows={8}
            validations={bodyValidations}
          />
        </div>
      </div>
    </section>
    <AutoHeightAnimator isExpanded={attachmentsIsExpanded}>
      <h2>If you want to send us related documents</h2>
      <p>
        <strong>Attach:</strong> Scan or take a photo of all pages (front and
        back). Click Attach below to submit your file(s).
      </p>
      <p>
        <strong>Fax:</strong> Please include a cover sheet with your information
        and fax all pages (front and back) to 480-393-5776
      </p>
      <Uploader
        getFiles={getAttachments}
        setFiles={setAttachments}
        fileLimit={5}
      />
    </AutoHeightAnimator>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Attach Files"
            secondary
            onClick={onClickAttach}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

IrsRejectedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  getAttachments: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired,
  onClickAttach: PropTypes.func.isRequired,
  attachmentsIsExpanded: PropTypes.bool.isRequired,
  bodyValidations: PropTypes.arrayOf(PropTypes.func)
};

IrsRejectedForm.defaultProps = {
  bodyValidations: []
};

export default IrsRejectedForm;
