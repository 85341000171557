import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import controls from "../../../modules/controls";
import { LOGO_URI } from "../../../modules/constants";
import "./accountFrame.css";
import NavMenu from "./NavMenuContainer";
import "./navbar.css";

const {
  components: { AutoHeightAnimator, ClickContainer },
  normalizations: { formatEIN }
} = controls;

const Navbar = ({
  // firstName,
  // lastName,
  ein,
  loading,
  menuIsOpen,
  toggleMenuIsOpen,
  onClickLogo,
  companyName,
  onClickLogout
}) => (
  <nav className="i2290-navbar px-gutter">
    <div className="row">
      <ClickContainer className="col logo-container" onClick={onClickLogo}>
        <img src={LOGO_URI} alt="INSTANT i2290" />
      </ClickContainer>
      <MediaQuery maxWidth={768}>
        {matches => {
          if (matches) {
            return (
              <React.Fragment>
                <div className="col text-right d-flex justify-content-end align-items-center">
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <i
                    className="fas fa-bars mr-gutter"
                    onClick={toggleMenuIsOpen}
                  />
                </div>
                <div className="col-12">
                  <AutoHeightAnimator isExpanded={menuIsOpen}>
                    <NavMenu />
                  </AutoHeightAnimator>
                </div>
                {/* <div className="w-100" /> */}
                <div className="col brand-header p-gutter">
                  {loading ? (
                    <strong className="d-block">Loading...</strong>
                  ) : (
                    <React.Fragment>
                      <strong className="d-block">{companyName}</strong>
                      <span>{formatEIN(ein)}</span>
                    </React.Fragment>
                  )}
                </div>
                <ClickContainer
                  className="col-auto logout-ctnr p-gutter text-center"
                  onClick={onClickLogout}
                >
                  <i className="fas fa-power-off d-block" />
                  <span>Logout</span>
                </ClickContainer>
              </React.Fragment>
            );
          }
          return (
            <React.Fragment>
              <div className="col my-auto">
                <NavMenu />
              </div>
              <MediaQuery minWidth={1042}>
                {matches => {
                  if (matches) {
                    return (
                      <React.Fragment>
                        <div className="col-auto brand-header p-gutter text-right">
                          {loading ? (
                            <strong className="d-block">Loading...</strong>
                          ) : (
                            <React.Fragment>
                              <strong className="d-block">{companyName}</strong>
                              <span>{formatEIN(ein)}</span>
                            </React.Fragment>
                          )}
                        </div>
                        <ClickContainer
                          className="col-auto logout-ctnr p-gutter text-center"
                          onClick={onClickLogout}
                        >
                          <i className="fas fa-power-off d-block" />
                          <span>Logout</span>
                        </ClickContainer>
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment>
                      <div className="w-100" />
                      <div className="col brand-header p-gutter">
                        {loading ? (
                          <strong className="d-block">Loading...</strong>
                        ) : (
                          <React.Fragment>
                            <strong className="d-block">{companyName}</strong>
                            <span>{formatEIN(ein)}</span>
                          </React.Fragment>
                        )}
                      </div>
                      <ClickContainer
                        className="col-auto logout-ctnr p-gutter text-center"
                        onClick={onClickLogout}
                      >
                        <i className="fas fa-power-off d-block" />
                        <span>Logout</span>
                      </ClickContainer>
                    </React.Fragment>
                  );
                }}
              </MediaQuery>
            </React.Fragment>
          );
        }}
      </MediaQuery>
    </div>
    {/* <div className="sub-nav row"> */}
    {/*  <div className="col-12 col-md"> */}
    {/*    {loading ? ( */}
    {/*      <span>Loading...</span> */}
    {/*    ) : ( */}
    {/*      <React.Fragment> */}
    {/*        <span className="no-break"> */}
    {/*          <strong>{companyName} </strong> {ein} */}
    {/*        </span> */}
    {/*      </React.Fragment> */}
    {/*    )} */}
    {/*  </div> */}
    {/* eslint-disable-next-line max-len */}
    {/*  <div className="col-12 col-md text-left text-md-right my-auto signed-in-as"> */}
    {/*    {loading ? ( */}
    {/*      <span>Loading...</span> */}
    {/*    ) : ( */}
    {/*      <React.Fragment> */}
    {/*        <span className="no-break"> */}
    {/*          Logged in as: <strong>{nameLine}</strong> */}
    {/*        </span> */}
    {/*      </React.Fragment> */}
    {/*    )} */}
    {/*    <a className="logout-link" href="/sign-in"> */}
    {/*      Logout */}
    {/*    </a> */}
    {/*  </div> */}
    {/* </div> */}
  </nav>
);

Navbar.propTypes = {
  // firstName: PropTypes.string,
  // lastName: PropTypes.string,
  ein: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  toggleMenuIsOpen: PropTypes.func.isRequired,
  onClickLogo: PropTypes.func.isRequired,
  onClickLogout: PropTypes.func.isRequired,
  companyName: PropTypes.string
};

Navbar.defaultProps = {
  // firstName: null,
  // lastName: null,
  ein: null,
  companyName: null
};

export default Navbar;
