import components from "./components";
import * as actions from "./weightIncrease.actions";
import * as actionTypes from "./weightIncrease.actionTypes";
import * as constants from "./weightIncrease.constants";
import * as selectors from "./weightIncrease.selectors";
import reducer from "./weightIncrease.reducer";

export default {
  components,
  actions,
  actionTypes,
  constants,
  reducer,
  selectors
};
