import React from "react";
import PropTypes from "prop-types";
import FormVehicleGridContainer from "./FormVehicleGridContainer";
import FormCreditVehicleGridContainer from "./FormCreditVehicleGridContainer";
import utilities from "../../utilities";
import controls from "../../controls";
import "./formWidgetBody.css";

const {
  math: { fastFormatMoney }
} = utilities;

const {
  components: { Button, AutoHeightAnimator }
} = controls;

const FormWidgetBody = ({
  month,
  formId,
  totalTax,
  totalCredit,
  onClickEditVehicles,
  onClickEditCredits,
  noCredits,
  numOfCredits,
  numOfVehicles,
  isExpanded,
  isWeightIncrease
}) => (
  <div className="form-widget-body">
    {((numOfVehicles > 0 && formId !== 0) ||
      (formId === 0 && numOfVehicles === 0)) && (
      <React.Fragment>
        <div className="widget-body form-widget-vehicle-summ row">
          <span className="col my-auto text-lg">
            {`${numOfVehicles} ${month} Vehicle${
              numOfVehicles !== 1 ? "s" : ""
            }`}
          </span>
          {!isWeightIncrease && (
            <div className="col d-flex justify-content-end align-items-center">
              <Button
                primary={false}
                className="d-inline"
                onClick={onClickEditVehicles}
                text="Edit"
                disabled={false}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    )}
    {formId !== 0 && (
      <AutoHeightAnimator isExpanded={isExpanded}>
        <FormVehicleGridContainer formId={formId} />
      </AutoHeightAnimator>
    )}
    {((numOfCredits > 0 && formId !== 0) ||
      (formId === 0 && numOfCredits === 0)) && (
      <React.Fragment>
        <div className="widget-body form-widget-vehicle-summ row">
          <span className="col my-auto text-lg">
            {`${numOfCredits} Credit${numOfCredits !== 1 ? "s" : ""}`}
          </span>
          <div className="col d-flex justify-content-end align-items-center">
            <Button
              className="d-inline"
              onClick={onClickEditCredits}
              text="Edit"
              disabled={false}
              primary={false}
            />
          </div>
        </div>
        {formId !== 0 && (
          <AutoHeightAnimator isExpanded={isExpanded}>
            <FormCreditVehicleGridContainer formId={formId} />
          </AutoHeightAnimator>
        )}
      </React.Fragment>
    )}
    {formId !== 0 && (
      <div className="widget-body row no-gutters totals-border">
        <span className="col-10 my-auto text-lg">Net Tax {month}:</span>
        <span className="col-2 text-lg text-right">
          {fastFormatMoney(totalTax - totalCredit)}
        </span>
      </div>
    )}
  </div>
);

FormWidgetBody.propTypes = {
  formId: PropTypes.number.isRequired,
  month: PropTypes.string,
  totalTax: PropTypes.number,
  totalCredit: PropTypes.number,
  onClickEditVehicles: PropTypes.func,
  onClickEditCredits: PropTypes.func,
  numOfCredits: PropTypes.number,
  numOfVehicles: PropTypes.number,
  isExpanded: PropTypes.bool,
  noCredits: PropTypes.bool
};

export default FormWidgetBody;
