import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import Button from "../../controls/components/Button";
import { getThankYouOpen } from "../paymentModal.selectors";
import { toggleThankYouDialog as toggleThankYouDialogCreator } from "../paymentModal.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const ThankYouDialog = ({ toggleThankYou, thankYouDialogOpen, history }) => {
  const handleSubmit = () => {
    toggleThankYou();
    history.push("/order-history");
  };

  if (!thankYouDialogOpen) return <></>;

  const windowMessage =
    window.i2290settings && window.i2290settings.SuccessfulIRSSubmissionMessage;

  return (
    <Dialog className="payment-modal no-scroll" closeIcon={false}>
      <div className="row">
        <div className="col">
          <p
            style={{
              margin: "25px",
              textAlign: "left",
              fontSize: "medium"
            }}
          >
            {windowMessage || ""}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col clean-gutter-left">
          <Button
            type="submit"
            disabled={false}
            primary
            text="Ok"
            onClick={handleSubmit}
            width="100%"
          />
        </div>
      </div>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    thankYouDialogOpen: getThankYouOpen(state)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  toggleThankYou: () => dispatch(toggleThankYouDialogCreator())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ThankYouDialog)
);
