import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import "./companyInfoWidgetHeader.css";

const {
  components: { Button },
  normalizations: { formatEIN }
} = controls;

const CompanyInfoWidgetHeader = ({
  loading,
  companyName,
  ein,
  onClick,
  isExpanded
}) => (
  <div className="company-info-widget-header row">
    <div className="col my-auto">
      {loading ? (
        <span>Loading...</span>
      ) : (
        <React.Fragment>
          {companyName && ein && (
            <span>
              <strong>{`${companyName}: `}</strong>
              {formatEIN(ein)}
            </span>
          )}
          {companyName && !ein && <strong>{companyName}</strong>}
          {!companyName && ein && <strong>{formatEIN(ein)}</strong>}
        </React.Fragment>
      )}
    </div>
    <div className="col-auto text-right my-auto">
      <Button
        className="widget-button"
        secondary
        text={isExpanded ? "Hide" : "Edit"}
        disabled={false}
        onClick={onClick}
      />
    </div>
  </div>
);

CompanyInfoWidgetHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  companyName: PropTypes.string,
  ein: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired
};

CompanyInfoWidgetHeader.defaultProps = {
  companyName: null,
  ein: null
};

export default CompanyInfoWidgetHeader;
