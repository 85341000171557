import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import { Redirect } from "react-router-dom";
import controls from "../../../modules/controls";
import utilities from "../../../modules/utilities";
import { weightData, monthData } from "../../../modules/vehicles/data";
import "./weightIncreaseForm.css";
import { FORM_NAME } from "../weightIncrease.constants";

const {
  components: { ComboBoxFieldContainer: ComboBoxField, Select, Button },
  validations: { validateDropdown }
} = controls;

const {
  lookups: { lookupWeight },
  math: { fastFormatMoney },
  transform: { formatDateDescription }
} = utilities;

const WeightIncreaseForm = ({
  onSubmit,
  currentTaxYear,
  selectedVehicle,
  weightIncreaseVehicles,
  redirect,
  onClickCancel
}) => (
  <div>
    <Form onSubmit={onSubmit}>
      {redirect && <Redirect to={redirect} />}
      <div className="row">
        <div className="col-12">
          <div className="widget-body rad-corners-loose mb-gutter">
            <div className="row">
              <div className="col-12 col-sm mb-gutter my-sm-auto col-lg-6 clean-gutter-sm-right">
                <ComboBoxField
                  formName={FORM_NAME}
                  name="Vehicle"
                  data={weightIncreaseVehicles}
                  placeholder="Weight Increase Transaction"
                />
              </div>
              <div className="col-auto my-auto clean-gutter-sm-left">
                <span>{`${weightIncreaseVehicles.length} vehicles`}</span>
              </div>
              <div className="col-12 col-lg mt-gutter my-lg-auto text-right">
                <div className="row">
                  <div
                    className={`${
                      selectedVehicle
                        ? "col my-auto text-right clean-gutter-right"
                        : "col my-auto text-right"
                    }`}
                  >
                    <Button
                      className="weight-increase-button"
                      disabled={false}
                      text="Cancel"
                      secondary
                      onClick={onClickCancel}
                    />
                  </div>
                  {selectedVehicle && selectedVehicle.WeightCategory !== "V" && (
                    <div className="col-auto my-auto text-right clean-gutter-left">
                      <Button
                        className="weight-increase-button"
                        disabled={false}
                        text="Update"
                        primary
                        type="submit"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedVehicle && (
        <section className="selected-form-section">
          <div className="row">
            <div className="col-12 col-lg-6 clean-gutter-lg-right">
              <div className="summary-widget rad-corners mb-gutter mb-lg-0">
                <div className="summary-header">
                  <h2>Previously Filed as:</h2>
                </div>
                <div className="widget-body" style={{ minHeight: "175px" }}>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>VIN:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>{selectedVehicle.Vin || "N/A"}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>Description:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>{selectedVehicle.Description || "N/A"}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>Weight:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>
                        {lookupWeight(selectedVehicle.WeightCategory) || "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>Logging:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>{selectedVehicle.Logging ? "Yes" : "No"}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>First Used:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>
                        {formatDateDescription(
                          selectedVehicle.DateCategory,
                          selectedVehicle.TaxYear
                        ) || "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-left text-lg-right">
                      <span>Tax Amount:</span>
                    </div>
                    <div className="col text-right text-lg-left">
                      <span>
                        {fastFormatMoney(selectedVehicle.TaxValue) || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-gutter mb-lg-0 clean-gutter-lg-left">
              {selectedVehicle.TaxYear >= currentTaxYear - 2 ? (
                <React.Fragment>
                  {selectedVehicle.WeightCategory !== "V" ? (
                    <div className="summary-widget rad-corners">
                      <div className="summary-header">
                        <h2>Increase Vehicle Weight As:</h2>
                      </div>
                      <div
                        className="widget-body"
                        style={{ minHeight: "175px" }}
                      >
                        <div className="row">
                          <div className="col-12">
                            <Select
                              name="IncreasedWeightCategory"
                              label="Increased Weight"
                              validations={[validateDropdown]}
                            >
                              <option value={null} />
                              {weightData
                                .filter(
                                  x =>
                                    x.real > selectedVehicle.WeightCategory &&
                                    x.real !== "W"
                                )
                                .map(x => (
                                  <option value={x.real}>{x.display}</option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-12">
                            <Select
                              name="IncreasedDateCategory"
                              label="Increase Effective"
                              validations={[validateDropdown]}
                            >
                              <option value={null} />
                              {monthData
                                .filter(
                                  x =>
                                    selectedVehicle.DateCategory >=
                                    parseInt(x.real, 10)
                                )
                                .map(x => (
                                  <option value={x.real}>{`${x.display} ${
                                    x.real < 7
                                      ? parseInt(selectedVehicle.TaxYear, 10) +
                                        1
                                      : selectedVehicle.TaxYear
                                  }`}</option>
                                ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="summary-widget rad-corners">
                      <div className="summary-header">
                        <h2 className="i2290-inline-error">
                          Weight Increase Unavailable
                        </h2>
                      </div>
                      <div
                        className="widget-body"
                        style={{ minHeight: "175px" }}
                      >
                        <p>Vehicle already filed at maximum weight.</p>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <div className="summary-widget rad-corners">
                  <div className="summary-header">
                    <h2 className="i2290-inline-error">
                      Weight Increase Unavailable
                    </h2>
                  </div>
                  <div className="widget-body" style={{ minHeight: "175px" }}>
                    <p>IRS does not support e-file for this tax year.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </Form>
  </div>
);

WeightIncreaseForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  weightIncreaseVehicles: PropTypes.arrayOf(PropTypes.object),
  redirect: PropTypes.string,
  onClickCancel: PropTypes.func.isRequired
};

WeightIncreaseForm.defaultProps = {
  weightIncreaseVehicles: [],
  redirect: null
};

export default WeightIncreaseForm;
