import { getUserContext } from "../userContext/userContext.selectors";
import { getPaymentInfo } from "../cart/cart.selectors";
import {
  getCurrentTaxYear,
  getUserIsCoveredByUnlimited,
  getNetTax,
  getOrderHOF
} from "../order/order.selectors";
import { getIsNoCharge } from "../order/order.utilities";
import { getOrderIdFromUrl } from "../../modules/utilities/url.js";

export const noCartBalance = state => {
  //const userContext = getUserContext(state);
  //const currentTaxYear = getCurrentTaxYear(state);
  //let noChargeString = null;noChargeString = order.PaymentTranNum;
  //let netTax = getNetTax(orderId)(state);
  const orderId = getOrderIdFromUrl();
  const order = getOrderHOF(orderId)(state);
  const userIsCoveredByUnlimited = getUserIsCoveredByUnlimited(orderId)(state);
  const paymentInfo = getPaymentInfo(state);

  let isWeightIncrease = null;
  let isNoCharge = false;
  let isPaid = false;
  let total = 0;

  if (order) {
    total = paymentInfo.total;
    isWeightIncrease = order.WeightIncrease;
    isNoCharge = getIsNoCharge(order.PaymentTranNum);
    isPaid = order.PaymentInError === false && order.PaymentTranNum !== null;
  }

  var result =
    isPaid ||
    isNoCharge ||
    isWeightIncrease ||
    userIsCoveredByUnlimited ||
    total <= 0;

  return order ? result : undefined;
};

/*isPaid: order.PaymentInError == false && order.PaymentTranNum !== null
isNoCharge: getIsNoCharge (check for "NO CHARGE" in paymentTranNum)
isWeightIncrease: order.WeightIncrease bool
userIsCoveredByUnlimited: getUserIsCoveredByUnlimited (order.TaxYear <= user.Unlimited)
total <= 0: promo code applied */
