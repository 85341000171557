import React, { Component } from "react";
import { Upload } from "@progress/kendo-react-upload";
import PropTypes from "prop-types";
import Papa from "papaparse";
import { Dialog } from "@progress/kendo-react-dialogs";
import utilities from "../../utilities";
import { IMPORT_VEHICLE_HEADER, IMPORT_CREDIT_HEADER } from "../../constants";
import Button from "../../controls/components/Button";
import BusyIndicatorContainer from "../../busyIndicator/components/BusyIndicatorContainer";

const {
  lookups: { lookupImportName }
} = utilities;

const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing"
];

class Dropzone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      events: [],
      filePreviews: {},
      error: null,
      staging: null
    };
  }

  onAdd = event => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter(file => !file.validationErrors)
        .forEach(file => {
          const reader = new FileReader();

          reader.onloadend = ev => {
            this.setState(prevState => ({
              filePreviews: {
                ...prevState.filePreviews,
                [file.uid]: ev.target.result
              }
            }));
          };

          reader.readAsDataURL(file.getRawFile());
        });
    };

    this.setState(
      prevState => ({
        error:
          event.affectedFiles[0].extension.toUpperCase() !== ".CSV"
            ? "File type must be .csv"
            : null,
        files: event.newState,
        events: [
          ...prevState.events,
          `File selected: ${event.affectedFiles[0].name}`
        ]
      }),
      afterStateChange
    );
  };

  onRemove = event => {
    const filePreviews = {
      ...this.state.filePreviews
    };

    event.affectedFiles.forEach(file => {
      delete filePreviews[file.uid];
    });

    this.setState(prevState => ({
      files: event.newState,
      events: [
        ...prevState.events,
        `File removed: ${event.affectedFiles[0].name}`
      ],
      filePreviews
    }));
  };

  onProgress = event => {
    this.setState(prevState => ({
      files: event.newState,
      events: [
        ...prevState.events,
        `On Progress: ${event.affectedFiles[0].progress} %`
      ]
    }));
  };

  onStatusChange = event => {
    const { IsTaxable } = this.props;
    const file = event.affectedFiles[0];
    const reader = new FileReader();
    reader.readAsText(file.getRawFile());
    reader.onload = fileEvent =>
      this.setState(
        prevState => ({
          files: event.newState,
          events: [
            ...prevState.events,
            `File '${file.name}' status changed to: ${
              fileStatuses[file.status]
            }`
          ]
        }),
        () => {
          const importFile = fileEvent.target.result;
          const firstColIndex = importFile.indexOf(",");
          const firstVal = importFile.substr(0, firstColIndex).trim();
          let updatedFile;
          if (firstVal.toUpperCase() !== "VIN") {
            const addedHeaderRow = IsTaxable
              ? IMPORT_VEHICLE_HEADER
              : IMPORT_CREDIT_HEADER;

            updatedFile = addedHeaderRow + "\n" + importFile;
          } else {
            updatedFile = importFile;
          }

          return fileStatuses[file.status] === "Uploaded"
            ? this.setState({ staging: updatedFile })
            : false;
        }
      );
  };

  validate = file => {
    Papa.parse(file, {
      error: this.handleParseError,
      complete: this.handleParseSuccess,
      header: true,
      skipEmptyLines: true,
      transform(value) {
        return value.toUpperCase().trim();
      },
      beforeFirstChunk(chunk) {
        const rows = chunk.split(/\r\n|\r|\n/);
        const correctHeaderRow = rows[0].includes("VehicleCreditDate")
          ? IMPORT_CREDIT_HEADER
          : IMPORT_VEHICLE_HEADER;
        rows[0] = correctHeaderRow;
      }
    });
  };

  handleParseSuccess = ({ data }) => {
    const { onUploadSuccess } = this.props;

    const iData = data.map(x =>
      Object.keys(x).reduce(
        (a, b) => ({ ...a, [lookupImportName(b.toUpperCase())]: x[b] }),
        {}
      )
    );

    const duplicates = {};

    iData.forEach(x => {
      if (x.Vin !== "") {
        duplicates[x.Vin] = duplicates[x.Vin] !== undefined;
      }
    });

    let hasDuplicates = false;

    Object.keys(duplicates).forEach(k => {
      if (duplicates[k]) hasDuplicates = true;
    });

    if (hasDuplicates) {
      this.setState({
        files: [],
        error: "Rejected: Cannot have duplicate VINs."
      });
    } else {
      onUploadSuccess(iData);
    }
  };

  handleParseError = error => {
    this.setState({ error });
  };

  handleCloseModal = () => {
    const {
      props: { isBusy }
    } = this;
    if (isBusy) return;
    this.setState({
      files: [],
      events: [],
      filePreviews: {},
      error: null,
      staging: null
    });
  };

  handleConfirmModal = () => {
    this.validate(this.state.staging);
  };

  render() {
    const {
      handleCloseModal,
      handleConfirmModal,
      state: { staging },
      props: { IsTaxable, isBusy }
    } = this;
    return (
      <div className="dropzone-container">
        {!!staging && (
          <Dialog
            height="250px"
            width="320px"
            title={isBusy ? undefined : "Please confirm"}
            onClose={handleCloseModal}
          >
            {isBusy ? (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <h2>Uploading...</h2>
                <BusyIndicatorContainer />
              </div>
            ) : (
              <React.Fragment>
                <p
                  style={{
                    margin: "25px",
                    textAlign: "center"
                  }}
                >
                  {IsTaxable ? (
                    <React.Fragment>
                      <p>
                        <strong>Warning: </strong>
                        importing vehicles will remove and replace your current
                        vehicles.{" "}
                      </p>
                      <p className="mb-0">Do you want to continue?</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p>
                        <strong>Warning: </strong>
                        importing credits will remove and replace your current
                        credits.{" "}
                      </p>
                      <p className="mb-0">Do you want to continue?</p>
                    </React.Fragment>
                  )}
                </p>
                <div className="row">
                  <div className="col clean-gutter-right">
                    <Button
                      disabled={isBusy}
                      text="No"
                      secondary
                      onClick={handleCloseModal}
                      width="100%"
                    />
                  </div>
                  <div className="col clean-gutter-left">
                    <Button
                      disabled={isBusy}
                      text="Yes"
                      primary
                      onClick={handleConfirmModal}
                      width="100%"
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </Dialog>
        )}
        <Upload
          batch={false}
          multiple={false}
          files={this.state.files}
          onAdd={this.onAdd}
          onProgress={this.onProgress}
          onStatusChange={this.onStatusChange}
          restrictions={{
            allowedExtensions: [".csv"]
          }}
          showFileList={false}
          withCredentials={false}
          saveUrl="https://demos.telerik.com/kendo-ui/service-v4/upload/save"
          removeUrl="https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
        />
        {this.state.error && (
          <span className="i2290-inline-error">{this.state.error}</span>
        )}
      </div>
    );
  }
}

Dropzone.propTypes = {
  onUploadSuccess: PropTypes.func.isRequired,
  IsTaxable: PropTypes.bool.isRequired,
  isBusy: PropTypes.bool.isRequired
};

export default Dropzone;
