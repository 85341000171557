import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import qs from "qs";
import TaxYearDropdown from "./TaxYearDropdown";
import TaxYearErrorModal from "./TaxYearErrorModalContainer";
import {
  getOrder,
  getActiveOrders,
  getCurrentTaxYear,
  getTaxYears
} from "../order.selectors";
import {
  patchOrder as patchOrderCreator,
  setTaxYearError as setTaxYearErrorCreator
} from "../order.actions";
import utilities from "../../utilities";

const { getOrderIdFromUrl } = utilities;

class TaxYearDropdownContainer extends Component {
  handleChange = ({ target: { value } }) => {
    const {
      props: { patchOrder, activeOrders, taxYear, setTaxYearError }
    } = this;
    if (value.toString() === taxYear.toString()) {
      return;
    }
    if (activeOrders[value]) {
      setTaxYearError(
        `You already have an active Order for ${value}. Please contact support if you need help.`
      );
    } else {
      patchOrder(getOrderIdFromUrl(), { TaxYear: value });
    }
  };

  render() {
    const {
      handleChange,
      props: { taxYear, width, label, currentTaxYear, taxYears }
    } = this;
    return (
      <React.Fragment>
        <TaxYearErrorModal />
        <TaxYearDropdown
          taxYears={taxYears}
          currentTaxYear={currentTaxYear}
          value={taxYear}
          onChange={handleChange}
          width={width}
          label={label}
        />
      </React.Fragment>
    );
  }
}

TaxYearDropdownContainer.propTypes = {
  taxYear: PropTypes.string,
  patchOrder: PropTypes.func.isRequired,
  setTaxYearError: PropTypes.func.isRequired,
  activeOrders: PropTypes.object.isRequired,
  width: PropTypes.string,
  label: PropTypes.string.isRequired
};

TaxYearDropdownContainer.defaultProps = {
  width: "300px"
};

const mapStateToProps = state => {
  const currentTaxYear = getCurrentTaxYear(state) || "";
  const taxYears = getTaxYears(state);
  const order = getOrder(getOrderIdFromUrl(), state);
  return {
    currentTaxYear,
    taxYears,
    taxYear: order ? order.TaxYear : currentTaxYear,
    activeOrders: getActiveOrders(state)
  };
};

const mapDispatchToProps = dispatch => ({
  patchOrder: (id, update) => dispatch(patchOrderCreator(id, update)),
  setTaxYearError: error => dispatch(setTaxYearErrorCreator(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxYearDropdownContainer);
