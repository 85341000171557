import React from "react";
import PropTypes from "prop-types";
import "./link.css";

const Link = ({ className, clickHandler, text, style }) => (
  <span
    style={style}
    className={className ? `i2290-link ${className}` : "i2290-link"}
    onClick={clickHandler}
    onKeyPress={e => (e.key === "Enter" ? clickHandler() : false)}
    role="button"
    tabIndex="0"
  >
    {text}
  </span>
);

Link.propTypes = {
  text: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

Link.defaultProps = {
  className: "",
  style: null
};

export default Link;
