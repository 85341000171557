import React from "react";
import PropTypes from "prop-types";
import Dropzone from "./Dropzone";
import controls from "../../controls";
import "./vehicleImporter.css";

const {
  components: { Button }
} = controls;

const VehicleImporter = ({
  onDrop,
  onClickDownload,
  onClickCancel,
  onClickDownloadPriorYear,
  isTaxable,
  isBusy
}) => (
  <div className="vehicle-importer row">
    <div className="col-12 col-md-6 clean-gutter-md-right first-vehicle-col mb-gutter">
      <div className="summary-widget rad-corners">
        <div className="summary-header">
          <h2>Upload {isTaxable ? "Vehicles" : "Credits"}</h2>
        </div>
        <div className="widget-body">
          <p>File must be i2290 CSV template. Do not change header row.</p>
          <p>
            <b className="i2290-inline-error">
              Importing {isTaxable ? "vehicles" : "credits"} will remove and
              replace your current {isTaxable ? "vehicles" : "credits"}
            </b>
          </p>
          <Dropzone
            onUploadSuccess={onDrop}
            IsTaxable={isTaxable}
            isBusy={isBusy}
          />
        </div>
      </div>
    </div>
    <div className="col-12 col-md-6 clean-gutter-md-left download-col">
      <div className="summary-widget rad-corners">
        <div className="summary-header">
          <h2>Download Template</h2>
        </div>
        <div className="widget-body">
          <div className="row">
            <div
              className={`${
                isTaxable
                  ? "col-12 text-center mb-gutter"
                  : "col-12 text-center"
              }`}
            >
              <Button
                className="import-button"
                onClick={onClickDownload}
                secondary
                text="Download Blank Template"
                disabled={false}
              />
            </div>
            {isTaxable && (
              <div className="col-12 text-center">
                <Button
                  className="import-button"
                  onClick={onClickDownloadPriorYear}
                  secondary
                  text="Download with Prior Year Vehicles"
                  disabled={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className="col">
      <Button
        icon="arrow-chevron-left"
        onClick={onClickCancel}
        secondary
        text="Cancel"
        type="button"
        width="100px"
      />
    </div>
  </div>
);

VehicleImporter.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onClickDownload: PropTypes.func.isRequired,
  onClickDownloadPriorYear: PropTypes.func,
  isTaxable: PropTypes.bool,
  isBusy: PropTypes.bool.isRequired
};

VehicleImporter.defaultProps = {
  isTaxable: true,
  onClickDownloadPriorYear: null
};

export default VehicleImporter;
