import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import controls from "../../../modules/controls";
import utilities from "../../../modules/utilities";

import "./wizardStep.css";
import {
  STEP_IS_VALID,
  STEP_IS_INVALID,
  STEP_IS_DISABLED
} from "../../../modules/constants";

const {
  components: { ClickContainer }
} = controls;

const { getOrderIdFromUrl, getWizardStepFromUrl } = utilities;

const WizardStep = ({
  stepNumber,
  stepTitle,
  valid,
  isFormScreen,
  inProcess,
  history: { push }
}) => {
  const currentStep = isFormScreen ? 5 : getWizardStepFromUrl();

  const active = currentStep === stepNumber;
  const past = currentStep > stepNumber;

  let className = "";
  if (active) {
    className = " wiz-active";
  } else if (past) {
    className = " wiz-past";
  }
  /* could also be :

    : inProcess && STEP_IS_VALID === valid 
    ? "fa-check-circle icon-????????????????"
    : inProcess && STEP_IS_INVALID === valid
    ? "fa-exclamation-circle icon-?????????????"
 */

  const icon = inProcess
    ? "fa-exclamation-circle icon-orange"
    : STEP_IS_VALID === valid
    ? "fa-check-circle icon-green"
    : STEP_IS_INVALID === valid
    ? "fa-exclamation-circle icon-red"
    : "";

  const showGreenIcon = isFormScreen ? [] : ["icon-green"];

  return (
    <ClickContainer
      onClick={() =>
        stepNumber !== 5
          ? push(`/wizard/${stepNumber}?order=${getOrderIdFromUrl()}`)
          : push(`/form?order=${getOrderIdFromUrl()}`)
      }
      disabled={valid === STEP_IS_DISABLED}
      className={
        isFormScreen
          ? `wizard-step${className} form-screen-wiz-step${
              stepNumber === 5 ? " review-step" : ""
            }`
          : `review-step wizard-step${className}`
      }
    >
      <div className="wizard-step-number">
        {/* eslint-disable-next-line max-len */}
        {/* {past ? <i className="fa fa-check" aria-hidden="true" /> : stepNumber} */}
        {stepTitle}
        <span className={["ml-auto", ...showGreenIcon].join(" ")}>
          <i className={`fa ${icon} fa-lg`} aria-hidden="true" />
        </span>
      </div>
    </ClickContainer>
  );
};

WizardStep.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  valid: PropTypes.string.isRequired,
  stepTitle: PropTypes.string.isRequired,
  isFormScreen: PropTypes.bool
};

WizardStep.defaultProps = {
  isFormScreen: false
};

export default withRouter(WizardStep);
