import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import InlineBusyIndicator from "./InlineBusyIndicator";
import { isBusy as isBusySelector } from "../busyIndicator.selector";

class InlineBusyIndicatorContainer extends Component {
  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  handleForgotPinClick = () => {
    const { history } = this.props;
    history.push("/forgot-pin");
  };

  render() {
    const { spinnerSize, error, isBusy, successMessage } = this.props;
    return (
      <InlineBusyIndicator
        spinnerSize={spinnerSize}
        error={error}
        isBusy={isBusy}
        successMessage={successMessage}
        handleForgotPinClick={this.handleForgotPinClick}
      />
    );
  }
}

InlineBusyIndicatorContainer.propTypes = {
  spinnerSize: PropTypes.number,
  error: PropTypes.object,
  errorSelector: PropTypes.func.isRequired, // eslint-disable-line
  isBusy: PropTypes.bool.isRequired,
  successMessage: PropTypes.string,
  reset: PropTypes.func.isRequired
};

InlineBusyIndicatorContainer.defaultProps = {
  spinnerSize: 25,
  error: null,
  successMessage: null
};

const mapStateToProps = (state, props) => ({
  error: props.errorSelector(state),
  isBusy: isBusySelector(state)
});

export default withRouter(
  connect(mapStateToProps)(InlineBusyIndicatorContainer)
);
