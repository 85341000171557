import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import FormSummaryPanelRepeater from "./FormSummaryPanelRepeaterContainer";
import "./orderSummaryPanel.css";

const {
  components: { Button }
} = controls;

const OrderSummaryPanel = ({
  orderId,
  taxYear,
  onClickReceipt,
  onClickFinish,
  isSubmitted,
  submissionDate,
  summaryPrefix,
  paymentSummary,
  showReceiptButton
}) => (
  <div className="order-summary-panel rad-corners">
    <div className="summary-header order-summary-header bg-white">
      <div className="row">
        <div className="col-12 col-sm mb-gutter my-sm-0 d-flex flex-column justify-content-center">
          {summaryPrefix ? (
            <span className="order-summary-header-text">
              <strong>{summaryPrefix}: </strong>
              {`${taxYear} - ${parseInt(taxYear, 10) + 1} HVUT Return`}
            </span>
          ) : (
            <h2 className="order-summary-header-text">{`${taxYear} - ${parseInt(
              taxYear,
              10
            ) + 1} HVUT Return`}</h2>
          )}
          <p>{`#${orderId}${
            isSubmitted && submissionDate
              ? ` - E-filed: ${submissionDate.toLocaleString()}`
              : ""
          }`}</p>
          {paymentSummary && <p>{paymentSummary}</p>}
        </div>
        <div className="col-12 col-sm-auto d-flex align-items-center text-left text-sm-right">
          {isSubmitted ? (
            <React.Fragment>
              {showReceiptButton && (
                <Button
                  secondary
                  text="CC Receipt"
                  disabled={false}
                  onClick={onClickReceipt}
                />
              )}
            </React.Fragment>
          ) : (
            <Button
              primary
              text="Finish"
              disabled={false}
              onClick={onClickFinish}
            />
          )}
        </div>
      </div>
    </div>
    <div className="summary-body pt-0">
      <FormSummaryPanelRepeater orderId={orderId} />
    </div>
  </div>
);

OrderSummaryPanel.propTypes = {
  orderId: PropTypes.number.isRequired,
  taxYear: PropTypes.number.isRequired,
  onClickReceipt: PropTypes.func.isRequired,
  onClickFinish: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  submissionDate: PropTypes.object,
  summaryPrefix: PropTypes.string
};

OrderSummaryPanel.defaultProps = {
  submissionDate: null
};

export default OrderSummaryPanel;
