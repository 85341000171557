import {
  buildActionType,
  buildAsyncActionType
} from "../../modules/utilities/reduxUtilities";
import { STATE_NAME } from "./weightIncrease.constants";

export const FETCH_WEIGHT_INCREASE_VEHICLES = buildAsyncActionType(
  STATE_NAME,
  "FETCH_WEIGHT_INCREASE_VEHICLES"
);

export const POST_WEIGHT_INCREASE = buildAsyncActionType(
  STATE_NAME,
  "POST_WEIGHT_INCREASE"
);

export const SET_REDIRECT = buildActionType(STATE_NAME, "SET_REDIRECT");
