import React, { Component } from "react";
import { connect } from "react-redux";
import EditVehiclesScreen from "./EditCreditVehiclesScreen";
import utilities from "../../../modules/utilities";
import order from "../../../modules/order";
import taxYear from "../../../modules/taxYear";
import vehicles from "../../../modules/vehicles";
import userContext from "../../../modules/userContext";
import { FORM_ROUTE, ORDER_HISTORY_ROUTE } from "../../../modules/constants";
import {
  getCanBeEdited,
  getFormEntities,
  getOrderHOF,
  getVehicleEntities
} from "../../../modules/order/order.selectors";
import { getCreditVehiclesStagedForCreation } from "../../../modules/vehicles/vehicles.selectors";
import { Redirect } from "react-router-dom";

const {
  selectors: {
    getCreditVehiclesErrorsExist,
    getCreditVehiclesShowImporter,
    getCreditVehiclesInEdit
  },
  actions: {
    toggleShowImporter: toggleShowImporterCreator,
    putVehicle: putVehicleCreator,
    postVehicle: postVehicleCreator
  },
  constants: { CREDIT_VEHICLES_SLICE_NAME }
} = vehicles;

const {
  actions: { fetchOrder: fetchOrderCreator }
} = order;

const {
  selectors: { getTaxTable },
  actions: { fetchTaxTable: fetchTaxTableCreator }
} = taxYear;

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

const {
  getOrderIdFromUrl,
  transform: { mapCreditVehicleToPayload }
} = utilities;

class EditCreditVehiclesScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { taxTable, fetchTaxTable, fetchOrder, fetchUser }
    } = this;
    fetchUser();
    fetchOrder(getOrderIdFromUrl());
    if (!taxTable) {
      fetchTaxTable();
    }
  }

  handleClickEditTaxableVehicles = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/edit-vehicles?order=${orderId}`);
  };

  handleClickBack = () => {
    const {
      props: {
        vehiclesInEdit,
        putVehicle,
        postVehicle,
        history: { push }
      }
    } = this;

    const orderId = getOrderIdFromUrl();

    vehiclesInEdit
      .map(x => mapCreditVehicleToPayload(x))
      .forEach(x =>
        x.Id ? putVehicle(orderId, x.Id, x) : postVehicle(orderId, x)
      );

    push(`${FORM_ROUTE}?order=${orderId}`);
  };

  render() {
    const {
      handleClickBack,
      handleClickEditTaxableVehicles,
      props: {
        invalidCreditsExist,
        showImporter,
        toggleShowImporter,
        numberOfTaxableVehicles,
        vehicleStaging
      }
    } = this;
    return (
      <>
        {this.props.redirect && <Redirect to={this.props.redirect} />}
        <EditVehiclesScreen
          vehicleStaging={vehicleStaging}
          showImporter={showImporter}
          toggleShowImporter={toggleShowImporter}
          invalidCreditsExist={invalidCreditsExist}
          onClickBack={handleClickBack}
          numberOfTaxableVehicles={numberOfTaxableVehicles}
          onClickEditVehicles={handleClickEditTaxableVehicles}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const vehicleStaging = getCreditVehiclesStagedForCreation(state);
  let numberOfTaxableVehicles = 0;

  const order = getOrderHOF(orderId)(state);
  if (order && order.Forms.length) {
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    numberOfTaxableVehicles = vehicleIds.reduce(
      (a, id) => (vehicles[id].IsTaxable ? a + 1 : a),
      0
    );
  }
  return {
    orderId,
    vehicleStaging,
    taxTable: getTaxTable(state),
    invalidCreditsExist: getCreditVehiclesErrorsExist(state),
    showImporter: getCreditVehiclesShowImporter(state),
    vehiclesInEdit: getCreditVehiclesInEdit(state),
    numberOfTaxableVehicles,
    redirect: getCanBeEdited(order) ? ORDER_HISTORY_ROUTE : undefined
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true)),
  fetchTaxTable: () => dispatch(fetchTaxTableCreator()),
  toggleShowImporter: () =>
    dispatch(toggleShowImporterCreator(CREDIT_VEHICLES_SLICE_NAME)),
  putVehicle: (orderId, vehicleId, payload) =>
    dispatch(putVehicleCreator(orderId, vehicleId, payload)),
  postVehicle: (orderId, payload) =>
    dispatch(postVehicleCreator(orderId, payload, CREDIT_VEHICLES_SLICE_NAME)),
  fetchUser: () => dispatch(fetchUserCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCreditVehiclesScreenContainer);
