import React, { Fragment } from "react";
import PropTypes from "prop-types";
import OrderSummaryPanel from "./OrderSummaryPanelContainer";

const OrderSummaryPanelRepeater = ({ orderIds, isActive }) => (
  <Fragment>
    {orderIds.length !== 0 && (
      <h2 className="bg-header pl-gutter">
        {isActive ? "Active Orders" : "Order History"}
      </h2>
    )}
    {orderIds.map(orderId => (
      <OrderSummaryPanel orderId={orderId} isActive={isActive} />
    ))}
  </Fragment>
);

OrderSummaryPanelRepeater.propTypes = {
  orderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isActive: PropTypes.bool.isRequired
};

export default OrderSummaryPanelRepeater;
