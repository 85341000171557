import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import { FORM_NAME } from "../account.constants";
import AccountForm from "./AccountForm";
import userContext from "../../../modules/userContext";
import { getActiveAccountFormPanel } from "../account.selectors";
import { setActiveAccountFormPanel as setActiveAccountFormPanelCreator } from "../account.actions";
import "./account.css";
import utilities from "../../../modules/utilities";

const {
  transform: { sanitizeValue }
} = utilities;

const {
  actions: { fetchUser: fetchUserCreator, patchUser: patchUserCreator },
  selectors: { getUserContext, getRedirect },
  actions: { logout: logoutCreator }
} = userContext;

class AccountFormContainer extends Component {
  constructor(props) {
    super(props);
    const { handleSubmit } = props;
    this.handleSubmit = handleSubmit(this.handleSubmit);
  }

  componentDidMount() {
    const { fetchUser } = this.props;
    fetchUser();
  }

  handleCountryChange = () => {
    const { change } = this.props;
    change("State", "");
  };

  handleBlur = e => {
    const { initialValues, formSyncErrors, patchUser } = this.props;

    if (!e.target.value && !initialValues[e.target.name]) {
      return;
    }

    if (e.target.value === initialValues[e.target.name]) {
      return;
    }

    if (
      formSyncErrors &&
      (formSyncErrors[e.target.name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    }

    if (e.target.name === "Country") {
      patchUser({
        [e.target.name]: sanitizeValue(e.target.value),
        State: null
      });
    } else {
      patchUser({
        [e.target.name]: sanitizeValue(e.target.value)
      });
    }
  };

  handleClickWeCanHelp = () => {
    const { history } = this.props;
    history.push("/contact");
  };

  handleDriverAuthorityBlur = e => {
    const name = e.target.name.split(".")[1];
    const { initialValues, formSyncErrors, patchUser } = this.props;

    if (!e.target.value && !initialValues.DriverAuthority[name]) {
      return;
    }

    if (e.target.value === initialValues.DriverAuthority[name]) {
      return;
    }

    if (
      formSyncErrors &&
      formSyncErrors.DriverAuthority &&
      (formSyncErrors.DriverAuthority[name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    }

    patchUser({
      DriverAuthority: {
        [name]: sanitizeValue(e.target.value)
      }
    });
  };

  handleDriverAuthoritySwitchChange = (name, val) => {
    const { patchUser } = this.props;
    patchUser({
      DriverAuthority: {
        [name.split(".")[1]]: val
      }
    });
  };

  handleLogoutClick = e => {
    e.preventDefault();
    const { logout } = this.props;
    logout();
  };

  handleSubmit = formValues => {
    console.log(formValues);
  };

  render() {
    const {
      formValues,
      setActiveAccountFormPanel,
      fetchUser,
      initialValues,
      formSyncErrors,
      touch,
      untouch,
      redirect,
      history,
      ...props
    } = this.props;

    return (
      <AccountForm
        onSubmit={this.handleSubmit}
        {...props}
        formValues={formValues}
        onClickWeCanHelp={this.handleClickWeCanHelp}
        setActiveAccountFormPanel={setActiveAccountFormPanel}
        fetchUser={fetchUser}
        handleBlur={this.handleBlur}
        handleDriverAuthorityBlur={this.handleDriverAuthorityBlur}
        handleDriverAuthoritySwitchChange={
          this.handleDriverAuthoritySwitchChange
        }
        handleCountryChange={this.handleCountryChange}
        handleLogoutClick={this.handleLogoutClick}
        initialValues={initialValues}
        formSyncErrors={formSyncErrors}
        touch={touch}
        untouch={untouch}
        redirect={redirect}
        history={history}
      />
    );
  }
}

AccountFormContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  formValues: PropTypes.object,
  formSyncErrors: PropTypes.object,
  activeAccountFormPanel: PropTypes.string,
  setActiveAccountFormPanel: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  formValues: getFormValues(FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
  activeAccountFormPanel: getActiveAccountFormPanel(state),
  initialValues: getUserContext(state),
  redirect: getRedirect(state)
});

const mapDispatchToProps = dispatch => ({
  setActiveAccountFormPanel: payload =>
    dispatch(setActiveAccountFormPanelCreator(payload)),
  fetchUser: () => dispatch(fetchUserCreator()),
  patchUser: payload => dispatch(patchUserCreator(payload)),
  logout: () => dispatch(logoutCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false
  })(AccountFormContainer)
);
