import * as actionTypes from "./taxYear.actionTypes";

const initialState = {
  taxYear: null,
  taxTable: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PATCH_TAX_YEAR_ASYNC.RECEIVED:
    case actionTypes.FETCH_TAX_YEAR_ASYNC.RECEIVED:
      return {
        ...state,
        taxYear: action.payload
      };
    case actionTypes.FETCH_TAX_TABLE_ASYNC.RECEIVED:
      return {
        ...state,
        taxTable: action.payload
      };
    case actionTypes.SET_TAX_YEAR:
      return {
        ...state,
        taxYear: action.taxYear
      };
    default:
      return state;
  }
}
