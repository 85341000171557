import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TaxYearErrorModal from "./TaxYearErrorModal";
import { getTaxYearError } from "../order.selectors";
import { setTaxYearError as setTaxYearErrorCreator } from "../order.actions";

class TaxYearErrorModalContainer extends Component {
  handleClose = () => {
    const {
      props: { setTaxYearError }
    } = this;
    setTaxYearError(null);
  };

  handleClickOK = () => {
    const {
      props: { setTaxYearError }
    } = this;
    setTaxYearError(null);
  };

  render() {
    const {
      handleClose,
      handleClickOK,
      props: { taxYearError }
    } = this;
    return (
      <TaxYearErrorModal
        error={taxYearError}
        onClose={handleClose}
        onClickOK={handleClickOK}
        isVisible={taxYearError !== null}
      />
    );
  }
}

TaxYearErrorModalContainer.propTypes = {
  setTaxYearError: PropTypes.func.isRequired,
  taxYearError: PropTypes.string
};

TaxYearErrorModalContainer.defaultProps = {
  taxYearError: null
};

const mapStateToProps = state => ({
  taxYearError: getTaxYearError(state)
});

const mapDispatchToProps = dispatch => ({
  setTaxYearError: error => dispatch(setTaxYearErrorCreator(error))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxYearErrorModalContainer);
