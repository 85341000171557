import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { importVehicles as importVehiclesCreator } from "../vehicles.actions";
import VehicleImporter from "./VehicleImporter";
import utilities from "../../utilities";
import controls from "../../controls";
import taxYear from "../../taxYear";
import { CREDIT_REASON_SUSPENDED, CREDIT_TEMPLATE_URI } from "../../constants";
import { getCurrentTaxYear } from "../../order/order.selectors";
import { isBusy as isBusySelector } from "../../busyIndicator/busyIndicator.selector";

const {
  utilities: { transformCreditsCSV }
} = controls;

const {
  selectors: { getTaxTable }
} = taxYear;

const { computeCredit, getOrderIdFromUrl } = utilities;

class CreditVehicleImporterContainer extends Component {
  handleDrop = data => {
    const { importVehicles, taxTable, currentTaxYear } = this.props;
    const transform = transformCreditsCSV(
      data,
      parseInt(currentTaxYear, 10),
      taxTable
    );
    let LineNumber = 1;
    transform.forEach(x => {
      if (!(x.CreditReason === CREDIT_REASON_SUSPENDED)) {
        x.CreditAmount = computeCredit(x, taxTable);
      }
      x.IsTaxable = false;
      x.LineNumber = LineNumber;
      LineNumber += 1;
    });
    importVehicles(getOrderIdFromUrl(), data);
  };

  handleClickDownload = () => {
    window.location.href = CREDIT_TEMPLATE_URI;
  };

  handleClickCancel = () => {
    const {
      props: { toggleShowing }
    } = this;
    toggleShowing();
  };

  render() {
    const {
      handleClickDownload,
      handleDrop,
      handleClickCancel,
      props: { isBusy }
    } = this;
    return (
      <VehicleImporter
        onClickDownload={handleClickDownload}
        onClickCancel={handleClickCancel}
        onDrop={handleDrop}
        isBusy={isBusy}
        isTaxable={false}
      />
    );
  }
}

CreditVehicleImporterContainer.propTypes = {
  toggleShowing: PropTypes.func.isRequired
  // importVehicles: PropTypes.func.isRequired,
  // taxTable: PropTypes.object
};

// CreditVehicleImporterContainer.defaultProps = {
//   taxTable: null
// };

const mapStateToProps = state => ({
  taxTable: getTaxTable(state),
  currentTaxYear: getCurrentTaxYear(state),
  isBusy: isBusySelector(state)
});

const mapDispatchToProps = dispatch => ({
  importVehicles: (orderId, vehicles) =>
    dispatch(importVehiclesCreator(orderId, vehicles))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditVehicleImporterContainer)
);
