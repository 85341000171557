import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { BULK_PRICING_FORM_NAME } from "../supportCenter.constants";
import userContext from "../../../modules/userContext";
import controls from "../../../modules/controls";
import BulkPricingForm from "./BulkPricingForm";
import {
  mapSupportFieldsToPayload,
  createDescription
} from "../supportCenter.utilities";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import { BULK_PRICING_SUBJECT } from "../../../modules/constants";

const {
  selectors: { getUserContext }
} = userContext;

const {
  validations: { validateRequired, validateBusinessName }
} = controls;

class BulkPricingFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { postSupportRequest }
    } = this;

    const payload = mapSupportFieldsToPayload(formValues);
    payload.Subject = BULK_PRICING_SUBJECT;
    payload.Description = createDescription({
      "Company Name": formValues.BusinessName,
      Position: formValues.CompanyPosition,
      "Company Type": formValues.CompanyType,
      History: formValues.CompanyEverFiled,
      Quantity: formValues.CompanyFormsFiled,
      Fleets: formValues.CompanyFleetsFiled
    });
    postSupportRequest(payload);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const { handleSubmit, handleClickCancel } = this;
    return (
      <BulkPricingForm
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
        companyEverFiledValidations={[validateRequired]}
        companyFleetsFiledValidations={[validateRequired]}
        companyFormsFiledValidations={[validateRequired]}
        companyNameValidations={[validateRequired, validateBusinessName]}
        companyPositionValidations={[validateRequired]}
        companyTypeValidations={[validateRequired]}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValues: getUserContext(state)
});

const mapDispatchToProps = dispatch => ({
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: BULK_PRICING_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(BulkPricingFormContainer)
  )
);
