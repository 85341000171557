import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import userContext from "../../../modules/userContext";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";

const {
  actions: { logout: logoutCreator },
  selectors: { getRedirect, getUserContext }
} = userContext;

class NavbarContainer extends Component {
  state = {
    menuIsOpen: false
  };

  toggleMenuIsOpen = () => {
    this.setState(prevState => ({
      menuIsOpen: !prevState.menuIsOpen
    }));
  };

  handleClickLogo = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(ORDER_HISTORY_ROUTE);
  };

  handleClickLogout = () => {
    const {
      props: { logout }
    } = this;
    logout();
  };

  render() {
    const {
      toggleMenuIsOpen,
      handleClickLogo,
      handleClickLogout,
      props: { children, user },
      state: { menuIsOpen }
    } = this;
    const firstName = user.FirstName || null;
    const lastName = user.LastName || null;
    const ein = user.Ein || null;
    const companyName = user.BusinessName || null;

    let loading = true;

    if (user.Id) loading = false;

    return (
      <Navbar
        onClickLogout={handleClickLogout}
        companyName={companyName}
        onClickLogo={handleClickLogo}
        menuIsOpen={menuIsOpen}
        toggleMenuIsOpen={toggleMenuIsOpen}
        firstName={firstName}
        lastName={lastName}
        ein={ein}
        loading={loading}
      >
        {children}
      </Navbar>
    );
  }
}

NavbarContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  user: PropTypes.object,
  logout: PropTypes.func.isRequired
};

NavbarContainer.defaultProps = {
  user: {}
};

const mapStateToProps = state => ({
  redirect: getRedirect(state),
  user: getUserContext(state)
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutCreator())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarContainer)
);
