import React, { Component } from "react";
import NewUserScreen from "./NewUserScreen";

class NewUserScreenContainer extends Component {
  render() {
    return <NewUserScreen />;
  }
}

// ForgotPinContainer.propTypes = {
//   submitSuccess: PropTypes.bool,
//   formValues: PropTypes.object,
//   forgotPin: PropTypes.func.isRequired,
//   resetError: PropTypes.func.isRequired
// };
//
// ForgotPinContainer.defaultProps = {
//   submitSuccess: false,
//   formValues: null
// };
//
// const mapDispatchToProps = {
//   forgotPin: forgotPinCreator,
//   resetError: resetErrorCreator
// };
//
// const mapStateToProps = state => ({
//   formValues: getForgotPinFormValues(state),
//   submitSuccess: getSuccess(state),
//   isBusy: isBusySelector(state)
// });

export default NewUserScreenContainer;
