import {
  STANDARD_SKU,
  STATE_NAME,
  TEXAS_STATE_ABBREVIATION,
  TEXAS_STATE_SALES_TAX_DECIMAL,
  TEXAS_STATE_TAXABLE_PORTION,
  UNLIMITED_SKU
} from "./cart.constants";
import { getUserContext } from "../userContext/userContext.selectors";
import utilities from "../utilities";
import {
  getDefaultSku,
  getOrderHOF,
  getUserIsCoveredByUnlimited
} from "../order/order.selectors";
import { FORM_NAME } from "../paymentModal/paymentModal.constants";

const {
  getOrderIdFromUrl,
  math: { roundTo }
} = utilities;

export const getState = state => state[STATE_NAME];

export const getBillingForm = state => {
  if (!state || !state.form) return {};
  const form = state.form[FORM_NAME];
  if (!form) return {};
  return form.values || {};
};

export const getEntities = state => state[STATE_NAME].entities;

export const getUnlimitedPrice = state =>
  state[STATE_NAME].entities[UNLIMITED_SKU]
    ? state[STATE_NAME].entities[UNLIMITED_SKU].Price
    : null;

export const getWidgetIsExpanded = state =>
  state[STATE_NAME].ui.widgetIsExpanded;

export const getPromoCode = state => state[STATE_NAME].ui.promoCode;

export const getPromoValue = state => state[STATE_NAME].ui.promoValue;

export const getError = state => state[STATE_NAME].ui.error;

export const getIsUnlimitedSelected = state => state[STATE_NAME].ui.isUnlimited;

export const getSku = defaultSku => state =>
  getIsUnlimitedSelected(state) ? UNLIMITED_SKU : defaultSku;

export const getPaymentInfo = state => {
  const coveredByUnlimited = getUserIsCoveredByUnlimited(getOrderIdFromUrl())(
    state
  );

  const products = getEntities(state);
  const userContext = getUserContext(state);
  const promoValue = getPromoValue(state);
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  const billingFormValues = getBillingForm(state);

  let productPrice = null;
  let productName = null;
  let productDescription = null;
  let subTotal = null;
  let total = null;
  let taxPercentage = null;
  let taxAmount = null;
  let promoValueCode = null;
  let promoValueAmount = null;

  if (order && products[STANDARD_SKU] && userContext) {
    const defaultSku = getDefaultSku(order.Id, userContext.Unlimited)(state);
    const Sku = getSku(defaultSku)(state);
    const product = products[Sku];

    productName = product.Name;
    productDescription = product.Description;
    productPrice = product.Price;

    subTotal = productPrice;

    if (promoValue) {
      promoValueCode = promoValue.code;
      promoValueAmount = promoValue.amount;
      subTotal -= promoValueAmount;
      if (subTotal < 0) subTotal = 0;
    }

    if (billingFormValues.CcHolderState === TEXAS_STATE_ABBREVIATION) {
      taxPercentage =
        TEXAS_STATE_SALES_TAX_DECIMAL * TEXAS_STATE_TAXABLE_PORTION;
      taxAmount = subTotal * taxPercentage;
      total = subTotal + taxAmount;
    } else {
      total = subTotal;
    }

    subTotal = roundTo(subTotal, 2);
    total = roundTo(total, 2);
    taxAmount = roundTo(taxAmount, 2);
  }
  productName =
    productName +
    (order && userContext && coveredByUnlimited
      ? ` (${userContext.Unlimited})`
      : "");
  return {
    productPrice,
    productName,
    productDescription,
    subTotal,
    total,
    taxPercentage,
    taxAmount,
    promoValueCode,
    promoValueAmount
  };
};
