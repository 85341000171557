import React from "react";
import PropTypes from "prop-types";
import MaterialRadioGroup from "@material-ui/core/RadioGroup";
import { Field } from "redux-form";
import "./radioGroup.css";

const renderRadioGroup = ({ input, ...custom }) => (
  <MaterialRadioGroup
    {...input}
    {...custom}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
  />
);

const RadioGroup = ({ name, validations, normalize, format, ...custom }) => (
  <Field
    {...custom}
    name={name}
    className="i2290-radio-group"
    component={renderRadioGroup}
    validate={validations}
    normalize={normalize}
    format={format}
  />
);

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func
};

RadioGroup.defaultProps = {
  normalize: null,
  format: null,
  validations: null
};

export default RadioGroup;
