import _ from "lodash";
import { STATE_NAME } from "./userContext.constants";
import { UNAUTHORIZED_NAME } from "../http/http.constants";

export const getUserContext = state => state[STATE_NAME].entity;

export const getUserAddress = state => {
  const {Email, Country, State, City, Zip, Address1: Address, Address2} = state[STATE_NAME].entity;
  return {Email, Country, State, City, Zip, Address};
}

export const getUserEmail = state => state[STATE_NAME].entity?.Email;

export const isAuthenticated = state => {
  const userContext = getUserContext(state);
  return _.isEmpty(userContext)
    ? false
    : userContext.hasOwnProperty("Email") &&
        state.error.Error !== UNAUTHORIZED_NAME;
};

export const getError = state => state[STATE_NAME].ui.error;

export const getRedirect = state => state[STATE_NAME].ui.redirect;

export const getErrorType = state =>
  state[STATE_NAME].ui.error && state[STATE_NAME].ui.error.Error
    ? state[STATE_NAME].ui.error.Error
    : null;

export const getSuccess = state => state[STATE_NAME].ui.success || false;

export const getUserContextExists = state => !!state[STATE_NAME].entity.Id;

export const getCompanyInfoWidgetIsExpanded = state =>
  state[STATE_NAME].ui.companyInfoWidgetIsExpanded;

export const getUserContextIsBusy = state => !!state[STATE_NAME].ui.isBusy;

export const getFieldOnTab = state => state[STATE_NAME].ui.fieldOnTab;

// TODO: is this being used? if so refactor, if not remove
export const authenticationError = state => {
  const userContext = getUserContext(state);
  if (!userContext || !userContext.Error) return "";

  return userContext.Error;
};

export const currentUserHasPermissions = (permissions, state) =>
  userHasPermissions(permissions, state);

function userHasPermissions(permissions, state) {
  if (!permissions || !permissions.length) {
    return true;
  }

  const userContext = getUserContext(state);

  if (
    !userContext ||
    !userContext.permissions ||
    !userContext.permissions.length
  ) {
    return false;
  }

  return !!userContext.permissions.find(userPermission =>
    permissions.find(
      requiredPermission => requiredPermission === userPermission
    )
  );
}
