import React from "react";
import PropTypes from "prop-types";
import "./companyInfoForm.css";

const CompanyInfoView = ({
  Address1,
  BusinessName,
  City,
  Country,
  Ein,
  FriendlyStructureName,
  State,
  Zip
}) => (
  <div className="row">
    {Ein !== "" ? <div className="col-12 col-md-12">{Ein}</div> : <></>}
    {BusinessName + FriendlyStructureName !== "" ? (
      <div className="col-12 col-md-12">{BusinessName}</div>
    ) : (
      <></>
    )}
    {Address1 + Zip + State + City + Country !== "" ? (
      <div className="col-12 col-md-12">
        {Address1} {City}, {State} {Zip} {Country}
      </div>
    ) : (
      <></>
    )}
  </div>
);

CompanyInfoView.propTypes = {
  Address1: PropTypes.string,
  BusinessName: PropTypes.string,
  City: PropTypes.string,
  Country: PropTypes.string,
  Ein: PropTypes.string,
  FriendlyStructureName: PropTypes.string,
  State: PropTypes.string,
  Zip: PropTypes.string
};

CompanyInfoView.defaultProps = {
  Address1: "",
  BusinessName: "",
  City: "",
  Country: "",
  Ein: "",
  FriendlyStructureName: "",
  State: "",
  Zip: ""
};

export default CompanyInfoView;
