import components from "./components";
import * as actions from "./account.actions";
import reducer from "./account.reducer";
import * as constants from "./account.constants";
import * as selectors from "./account.selectors";

export default {
  components,
  actions,
  reducer,
  constants,
  selectors
};
