import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { reduxForm, Form } from "redux-form";
import controls from "../../../modules/controls";
import busyIndicator from "../../../modules/busyIndicator";
import "./register.css";
import { FORM_NAME } from "../register.constants";
import { getError } from "../register.selectors";
import yellowLogo from "../../../modules/assets/logo.icon.min.png";
import { getState } from "../../../modules/store";
import { fetchOrders } from "../../../modules/order/order.actions";
import { getOrderHistory } from "../../../modules/order/order.selectors";

const {
  components: { InlineBusyIndicator }
} = busyIndicator;

const {
  components: { TextField, Select, Button, Link },
  normalizations: {
    normalizePhone,
    normalizeEmailOrEIN,
    normalizePin,
    formatPhone,
    formatEmailOrEIN
  },
  validations: { validateRegisterForm: validate }
} = controls;

const Register = ({
  handleSubmit,
  handleReduxFormSubmit,
  handleExistingEINError,
  submitting,
  submitError,
  submitSuccess,
  userContextError,
  handleLogin,
  isAuthenticated,
  history,
  resetError,
  onClickSignIn,
  onClickTermsConditions,
  onClickPrivacyPolicy,
  formValues,
  dispatch
}) => {
  useEffect(() => {
    if (submitSuccess && !isAuthenticated) {
      handleLogin();
    } else if (isAuthenticated) {
      dispatch(fetchOrders()).then(_ => {
        const state = getState();
        const orderId = Object.keys(getOrderHistory(state))[0] || "notfound";
        return dispatch(history.push(`/wizard/1?order=${orderId}`));
      });
    }
  }, [submitSuccess, isAuthenticated]);
  useEffect(() => {
    if (userContextError && userContextError.Error === "EinFound") {
      history.push("/sign-in");
    } else if (submitError && submitError.Error === "EinFound") {
      handleExistingEINError();
    }
  }, [submitError, userContextError]);
  return (
    <Form className="add" onSubmit={handleSubmit(handleReduxFormSubmit)}>
      <div className="row" id="wrapper">
        <div className="col-md-6 iconCol">
          <div className="imgContainer">
            <img src={yellowLogo} alt="large logo" />
          </div>
        </div>
        <div className="col-md-6 col-12 formCol">
          <div className="form-scroller">
            <div className="formContainer" style={{ marginTop: "30px" }}>
              <div className="smallLogoContainer">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/914629/logo.final.png"
                  id="smallLogo"
                  width="300px"
                />
                <h2 className="welcomeText">Welcome.</h2>
                <h2 className="welcomeText">Please create a new account.</h2>
                <p className="signUpLinkCtn text-center">
                  <span className="info text-center">
                    {"Already have an account? "}
                    <Link clickHandler={onClickSignIn} text="Sign in." />
                  </span>
                </p>
              </div>
              <div className="inputContainer">
                <div className="row">
                  <div className="col-12 col-md-6 clean-gutter-md-right">
                    <TextField
                      label="First Name"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className="col-12 col-md-6 clean-gutter-md-left">
                    <TextField label="Last Name" name="lastName" type="text" />
                  </div>
                </div>
              </div>
              <div className="inputContainer">
                <TextField
                  label="Phone Number (optional)"
                  name="phoneNumber"
                  type="text"
                  normalize={normalizePhone}
                  format={formatPhone}
                />
              </div>
              <div className="inputContainer">
                <TextField
                  label="Email or EIN"
                  name="username"
                  type="text"
                  normalize={normalizeEmailOrEIN}
                  format={formatEmailOrEIN}
                />
              </div>

              <div className="inputContainer">
                <TextField
                  label="5-digit PIN"
                  name="pin"
                  type="password"
                  normalize={normalizePin}
                />
              </div>
              <div className="inputContainer">
                <TextField
                  label="Confirm 5-digit PIN"
                  name="confirmPin"
                  type="password"
                  normalize={normalizePin}
                />
              </div>
              <div className="inputContainer">
                <Select name="foundUsOption" label="How did you hear about us?">
                  <option value={null} />
                  <option value="irsWebsite">IRS Website</option>
                  <option value="webSearch">Web Search</option>
                  <option value="friendOrFamily">Friend/Family</option>
                  <option value="employerOrCoworker">Employer/Coworker</option>
                  <option value="other">Other</option>
                </Select>
              </div>
              {formValues.foundUsOption === "other" && (
                <div className="inputContainer">
                  <TextField label="Details" name="details" type="text" />
                </div>
              )}
              <InlineBusyIndicator
                errorSelector={getError}
                reset={resetError}
              />
              <div className="registrationContainer">
                <p className="text-center">
                  <span className="info text-center">
                    {"By creating a new account with INSTANT 2290, you are agreeing\n" +
                      "to i2290.com's "}
                    <Link
                      clickHandler={onClickTermsConditions}
                      text="Terms & Conditions"
                    />
                    {" and "}
                    <Link
                      clickHandler={onClickPrivacyPolicy}
                      text="Privacy Policy"
                    />
                  </span>
                </p>
                <p className="text-center">
                  <Button
                    type="submit"
                    disabled={submitting}
                    primary
                    text="Sign Up"
                    width="250px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

Register.propTypes = {
  onClickTermsConditions: PropTypes.func.isRequired,
  onClickPrivacyPolicy: PropTypes.func.isRequired,
  onClickSignIn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_NAME,
  validate,
  touchOnBlur: false,
  initialValues: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    username: "",
    pin: "",
    confirmPin: ""
  }
})(Register);
