import * as actionTypes from "./taxYear.actionTypes";
import doAsync from "../doAsync";

export const setTaxYear = taxYear => ({
  type: actionTypes.SET_TAX_YEAR,
  taxYear
});

export const fetchTaxYear = () =>
  doAsync({
    actionType: actionTypes.FETCH_TAX_YEAR_ASYNC,
    url: "order",
    httpMethod: "get",
    mapResponseToPayload: r => r.TaxYear || "2020"
  });

export const fetchTaxTable = () =>
  doAsync({
    actionType: actionTypes.FETCH_TAX_TABLE_ASYNC,
    url: "taxtable",
    httpMethod: "get",
    mapResponseToPayload: r => {
      const hashMap = {};
      r.forEach(x => {
        hashMap[`${x.WeightCategory}.${x.Month}.${x.LoggingFlag}`] = x.Tax;
      });
      return hashMap;
    }
  });

export const patchTaxYear = taxYear =>
  doAsync({
    actionType: actionTypes.PATCH_TAX_YEAR_ASYNC,
    url: "order",
    httpConfig: {
      body: JSON.stringify({ TaxYear: taxYear }),
      headers: {
        ['wait-lock-key']: taxYear
      }
    },
    httpMethod: "patch",
    mapResponseToPayload: r => r.TaxYear
  });
