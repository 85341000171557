import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import CurrentTaxYearFiler from "./CurrentTaxYearFiler";
import userContext from "../../../modules/userContext";
import order from "../../../modules/order";
import { getHeroTaxYearObject } from "../../../modules/order/order.selectors";

const {
  selectors: { getUserContext }
} = userContext;

const {
  selectors: { getRedirect, getActiveOrders },
  actions: { postOrder: postOrderCreator, checkLastYearVehicles }
} = order;

class CurrentTaxYearFilerContainer extends Component {
  state = {
    showImportModal: false
  };

  handleClickFileReturn = async () => {
    const {
      props: {
        heroTaxYear,
        postOrder,
        activeOrders,
        history: { push }
      }
    } = this;

    if (!heroTaxYear) return;

    const taxYear = heroTaxYear.TaxYear;

    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      const vehicleCount = await checkLastYearVehicles(heroTaxYear.TaxYear);

      if (vehicleCount > 0) {
        this.setState({ showImportModal: true });
      } else {
        postOrder(taxYear, "/form", false);
      }
    }
  };

  /* Replace code below with:
  handleClickNo = this.handleClick("/edit-vehicles", false);
  handleClickYes = this.handleClick("/form", true);
  handleClick = (path, selected) => {
    const {
      props: {
        heroTaxYear,
        postOrder,
        activeOrders,
        history: { push }
      }
    } = this;

    if (!heroTaxYear) return;

    const taxYear = heroTaxYear.TaxYear;

    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      postOrder(taxYear, path, selected);
    }
  };
  */

  handleClickNo = () => {
    const {
      props: {
        heroTaxYear,
        postOrder,
        activeOrders,
        history: { push }
      }
    } = this;

    if (!heroTaxYear) return;

    const taxYear = heroTaxYear.TaxYear;

    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      postOrder(taxYear, "/form", false);
    }
  };

  handleClickYes = () => {
    const {
      props: {
        heroTaxYear,
        postOrder,
        activeOrders,
        history: { push }
      }
    } = this;

    if (!heroTaxYear) return;

    const taxYear = heroTaxYear.TaxYear;

    if (activeOrders[taxYear]) {
      push(`/form?order=${activeOrders[taxYear]}`);
    } else {
      postOrder(taxYear, "/form", true);
    }
  };

  render() {
    const {
      currentTaxYear,
      handleClickFileReturn,
      handleClickNo,
      handleClickYes,
      props: {
        heroTaxYear,
        userContext,
        redirect,
        location: { pathname }
      },
      state: { showImportModal }
    } = this;

    let firstName = null;
    let lastName = null;
    let loading = true;
    if (userContext.Id) loading = false;
    if (userContext.FirstName) firstName = userContext.FirstName;
    if (userContext.LastName) lastName = userContext.LastName;

    return (
      <Fragment>
        {redirect && redirect !== pathname && <Redirect to={redirect} />}
        <CurrentTaxYearFiler
          showImportModal={showImportModal}
          heroTaxYear={heroTaxYear}
          loading={loading}
          taxYear={currentTaxYear}
          firstName={firstName}
          lastName={lastName}
          onClickFileReturn={handleClickFileReturn}
          handleClickNo={handleClickNo}
          handleClickYes={handleClickYes}
        />
      </Fragment>
    );
  }
}

CurrentTaxYearFilerContainer.propTypes = {
  userContext: PropTypes.object,
  postOrder: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  activeOrders: PropTypes.object.isRequired
};

CurrentTaxYearFilerContainer.defaultProps = {
  userContext: {},
  redirect: null
};

const mapStateToProps = state => ({
  userContext: getUserContext(state),
  heroTaxYear: getHeroTaxYearObject(state),
  redirect: getRedirect(state),
  activeOrders: getActiveOrders(state)
});

const mapDispatchToProps = dispatch => ({
  postOrder: (taxYear, redirect, fromHistory) =>
    dispatch(postOrderCreator(taxYear, redirect, fromHistory))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CurrentTaxYearFilerContainer)
);
