const values = [
  {
    display: "July",
    real: "12"
  },
  {
    display: "August",
    real: "11"
  },
  {
    display: "September",
    real: "10"
  },
  {
    display: "October",
    real: "9"
  },
  {
    display: "November",
    real: "8"
  },
  {
    display: "December",
    real: "7"
  },
  {
    display: "January",
    real: "6"
  },
  {
    display: "February",
    real: "5"
  },
  {
    display: "March",
    real: "4"
  },
  {
    display: "April",
    real: "3"
  },
  {
    display: "May",
    real: "2"
  },
  {
    display: "June",
    real: "1"
  }
];

export default values;
