import React from "react";
import PropTypes from "prop-types";
import "./linkWidget.css";
import controls from "../../../modules/controls";

const {
  components: { ClickContainer }
} = controls;

const LinkWidget = ({ iconClassName, linkText, bodyText, onClickLink }) => (
  <ClickContainer className="link-widget drop-shadow" onClick={onClickLink}>
    <i className={`${iconClassName} link-widget-icon`} />
    <span className="link-widget-link">{linkText}</span>
    <p>{bodyText}</p>
  </ClickContainer>
);

LinkWidget.propTypes = {
  iconClassName: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired
};

export default LinkWidget;
