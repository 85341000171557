import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import BulkPricingForm from "./BulkPricingFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 className="support-page-header" style={{ marginBottom: "30px" }}>
      Do you file Form 2290 for many companies / truckers?
    </h2>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <BulkPricingForm />
  </div>
);

const BulkPricingFormScreen = withChildren(children, SupportFormScreen);

export default BulkPricingFormScreen;
