import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import VehiclesStep from "./VehiclesStep";
import order from "../../../modules/order";
import utilities from "../../../modules/utilities";
import vehicles from "../../../modules/vehicles";
import { getCreditsOrVehiclesFromUrl } from "../../../modules/utilities/url";
import { withRouter } from "react-router-dom";
import userContext from "../../../modules/userContext";
import { getVehicleValidationCounts } from "../../../modules/order/order.selectorsv2";

const { getOrderIdFromUrl } = utilities;

const {
  selectors: {
    getTaxableVehiclesErrorsExist,
    getCreditsOrVehiclesStaged,
    getCreditVehiclesErrorsExist
  }
} = vehicles;

const {
  actions: { fetchOrder: fetchOrderCreator }
} = order;

const {
  actions: { setRedirect: setRedirectCreator }
} = userContext;

class VehiclesStepContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchOrder }
    } = this;

    fetchOrder(getOrderIdFromUrl());
  }

  render() {
    const {
      props: {
        creditsOrVehiclesStaged,
        vehiclesEntered,
        vehicleErrorsExist,
        invalidVehiclesExist,
        setRedirect,
        creditVehicleErrorCount,
        vehicleErrorCount,
        creditsOrVehiclesFromUrl,
        history: { location }
      }
    } = this;

    return (
      <VehiclesStep
        creditsOrVehiclesStaged={creditsOrVehiclesStaged}
        creditVehicleErrorCount={creditVehicleErrorCount}
        vehicleErrorCount={vehicleErrorCount}
        vehiclesEntered={vehiclesEntered}
        vehicleErrorsExist={vehicleErrorsExist}
        invalidVehiclesExist={invalidVehiclesExist}
        setRedirect={setRedirect}
        pathName={location.pathname}
        orderId={getOrderIdFromUrl()}
        creditsOrVehiclesFromUrl={creditsOrVehiclesFromUrl}
      />
    );
  }
}

VehiclesStepContainer.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  vehiclesEntered: PropTypes.number.isRequired,
  vehicleErrorsExist: PropTypes.bool.isRequired,
  invalidVehiclesExist: PropTypes.bool.isRequired,
  setRedirect: PropTypes.func.isRequired,
  getCreditsOrVehiclesFromUrl: PropTypes.func.isRequired,
  creditsOrVehiclesStaged: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    vehiclesEntered,
    vehicleErrorCount,
    creditVehicleErrorCount
  } = getVehicleValidationCounts(state);

  return {
    vehiclesEntered,
    vehicleErrorsExist:
      getTaxableVehiclesErrorsExist(state) ||
      getCreditVehiclesErrorsExist(state),
    vehicleErrorCount,
    creditVehicleErrorCount,
    invalidVehiclesExist: vehicleErrorCount + creditVehicleErrorCount > 0,
    creditsOrVehiclesFromUrl: getCreditsOrVehiclesFromUrl(state),
    creditsOrVehiclesStaged: getCreditsOrVehiclesStaged(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchOrder: id => dispatch(fetchOrderCreator(id, true)),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VehiclesStepContainer)
);
