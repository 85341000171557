import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";
import { STATE_NAME } from "./order.constants";

export const PATCH_ORDER_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "PATCH_ORDER_ASYNC"
);

export const POST_ORDER_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "POST_ORDER_ASYNC"
);

export const FETCH_ORDER_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_ORDER_ASYNC"
);

export const FETCH_TAX_YEARS_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_TAX_YEARS_ASYNC"
);

export const FETCH_HISTORY_ORDER_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_HISTORY_ORDER_ASYNC"
);

export const SEND_RECEIPT_EMAIL = buildAsyncActionType(
  STATE_NAME,
  "SEND_RECEIPT_EMAIL"
);

export const SET_TAX_YEAR_ERROR = buildActionType(
  STATE_NAME,
  "SET_TAX_YEAR_ERR"
);

export const SET_REDIRECT = buildActionType(STATE_NAME, "SET_REDIRECT");

export const SET_IRS_NOT_RESP_ACKOWLEDGEMENT = buildActionType(
  STATE_NAME,
  "SET_IRS_NOT_RESP_ACKOWLEDGEMENT"
);

export const SET_ACK_COPIED_VEHICLES_MODAL_IS_DISMISSED = buildActionType(
  STATE_NAME,
  "SET_ACK_COPIED_VEHICLES_MODAL_IS_DISMISSED"
);

export const FETCH_ORDERS_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_ORDERS_ASYNC"
);

export const FETCH_SCHEDULE_ONE_URI = buildAsyncActionType(
  STATE_NAME,
  "FETCH_SCHEDULE_ONE_URI"
);

export const FETCH_ACTIVE_ORDERS_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_ACTIVE_ORDERS_ASYNC"
);

export const SUBMIT_ORDER = buildAsyncActionType(STATE_NAME, "SUBMIT_ORDER");

export const SEND_SCHEDULE_ONE_EMAIL_ASYNC = buildAsyncActionType(STATE_NAME, "SEND_SCHEDULE_ONE_EMAIL_ASYNC");

export const DELETE_VEHICLES_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "DELETE_VEHICLES_ASYNC"
);

export const TOGGLE_PAYMENT_METHOD_WIDGET_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "TOGGLE_PAYMENT_METHOD_WIDGET_IS_EXPANDED"
);

export const SET_PAYMENT_METHOD_WIDGET_IS_EXPANDED = buildActionType(
  STATE_NAME,
  "SET_PAYMENT_METHOD_WIDGET_IS_EXPANDED"
);

export const SET_STAGED_SCHEDULE_ONE = buildActionType(
  STATE_NAME,
  "SET_STAGED_SCHEDULE_ONE"
);

export const SET_ACTIVE_RECEIPT_MODAL = buildActionType(
  STATE_NAME,
  "SET_ACTIVE_RECEIPT_MODAL"
);

export const TOGGLE_DISCLAIMER_IS_VISIBLE = buildActionType(
  STATE_NAME,
  "TOGGLE_DISCLAIMER_IS_VISIBLE"
);

export const SET_DISCLAIMER_IS_VISIBLE = buildActionType(
  STATE_NAME,
  "SET_DISCLAIMER_IS_VISIBLE"
);

export const CHANGE_RECEIPT_MODAL_EMAIL = buildActionType(
  STATE_NAME,
  "CHANGE_RECEIPT_MODAL_EMAIL"
);

export const TOGGLE_PAYMENT_AGREEMENT_IS_OPEN = buildActionType(
  STATE_NAME,
  "TOGGLE_PAYMENT_AGREEMENT_IS_OPEN"
);

export const SET_PAYMENT_AGREEMENT_IS_OPEN = buildActionType(
  STATE_NAME,
  "SET_PAYMENT_AGREEMENT_IS_OPEN"
);
