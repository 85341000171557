import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import userContext from "../../../modules/userContext";
import NavMenu from "./NavMenu";
import utilities from "../../../modules/utilities";
import { setActiveAccountFormPanel as setActiveAccountFormPanelCreator } from "../../account/account.actions";

const { getOrderIdFromUrl } = utilities;

const {
  actions: { logout: logoutCreator }
} = userContext;

class NavMenuContainer extends Component {
  handleClickOrderHistory = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/order-history");
  };

  handleClickProfile = () => {
    const {
      props: {
        setActiveAccountFormPanel,
        history: { push }
      }
    } = this;
    const orderId = getOrderIdFromUrl();
    setActiveAccountFormPanel("Account");
    push(orderId ? `/account?order=${orderId}` : "/account");
  };

  handleClickSupportCenter = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    const orderId = getOrderIdFromUrl();
    push(orderId ? `/support-center?order=${orderId}` : "/support-center");
  };

  handleClickLogout = () => {
    const {
      props: { logout }
    } = this;
    logout();
  };

  render() {
    const {
      handleClickOrderHistory,
      handleClickProfile,
      handleClickSupportCenter,
      handleClickLogout,
      props: {
        location: { pathname }
      }
    } = this;
    return (
      <NavMenu
        onClickOrderHistory={handleClickOrderHistory}
        onClickProfile={handleClickProfile}
        onClickSupportCenter={handleClickSupportCenter}
        onClickLogout={handleClickLogout}
        path={pathname}
      />
    );
  }
}

NavMenuContainer.propTypes = {
  logout: PropTypes.func.isRequired,
  setActiveAccountFormPanel: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutCreator()),
  setActiveAccountFormPanel: activePanel =>
    dispatch(setActiveAccountFormPanelCreator(activePanel))
});

export default withRouter(
  connect(undefined, mapDispatchToProps)(NavMenuContainer)
);
