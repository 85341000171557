import { normalize, schema } from "normalizr";
import controls from "../controls";
import utilities from "../utilities";
import { NO_CHARGE_STRING_CAPS, WAIVE_FEE } from "../constants";

const {
  normalizations: { normalizePhone }
} = controls;

const {
  transform: { cleanVehicle }
} = utilities;

const vehicle = new schema.Entity("Vehicles", {}, { idAttribute: "Id" });

const form = new schema.Entity(
  "Forms",
  {
    Vehicles: [vehicle]
  },
  { idAttribute: "Id" }
);

const order = new schema.Entity(
  "Orders",
  {
    Forms: [form]
  },
  { idAttribute: "Id" }
);

export const normalizeOrder = o => normalize(o, order);
export const normalizeOrders = o => normalize(o, [order]);

export const cleanOrder = order => {
  if (order.ThirdPphone) {
    order.ThirdPphone = normalizePhone(order.ThirdPphone);
  }

  if (order.Forms) {
    order.Forms.forEach(x => {
      if (x.Vehicles) {
        x.Vehicles = x.Vehicles.map(y => cleanVehicle(y));
      }
    });
  }

  return order;
};

export const getIsNoCharge = paymentTranNum =>
  typeof paymentTranNum === "string"
    ? paymentTranNum.toUpperCase().indexOf(NO_CHARGE_STRING_CAPS) !== -1 || 
      paymentTranNum.toUpperCase().indexOf(WAIVE_FEE) !== -1
    : false;
