import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";

const {
  components: { Link }
} = controls;

class CommandCell extends React.Component {
  render() {
    const { dataItem, editHOF, deleteHOF } = this.props;

    const handleEdit = editHOF(dataItem);
    const handleRemove = deleteHOF(dataItem);

    return (
      <td className="k-grid-content-sticky command-cell" {...this.props}>
        <Link clickHandler={handleEdit} text="Edit" />
        <Link
          className="grid-button-spaced"
          clickHandler={handleRemove}
          text="Delete"
          secondary
        />
      </td>
    );
  }
}

CommandCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  editHOF: PropTypes.func.isRequired,
  deleteHOF: PropTypes.func.isRequired
};

export default CommandCell;
