import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";

const {
  components: { Button }
} = controls;

const UnlockRequestForm = ({ onSubmit, onClickCancel }) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">
      We will unlock your most recent Form 2290 for editing
    </h2>
    <p>Unlocking is usually done within 3 hours on business days.</p>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right">
          <Button
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button disabled={false} text="Submit" primary type="submit" />
        </div>
      </div>
    </section>
  </Form>
);

UnlockRequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired
};

export default UnlockRequestForm;
