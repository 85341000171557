import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import IRSPayment from "./IRSPayment";
import { FORM_NAME } from "../newUser.constants";
import controls from "../../../modules/controls";
import userContext from "../../../modules/userContext";
import utilities from "../../../modules/utilities";
import order from "../../../modules/order";
import {
  IRS_CC_SITE_URI,
  IRS_EFTPS_SITE_URI
} from "../../../modules/constants";
import { get } from "lodash";
import { getOrder } from "modules/order/order.selectors";

const {
  getOrderIdFromUrl,
  transform: { mapPaymentFormValuesToPayload }
} = utilities;

const {
  actions: {  setRedirect: setRedirectCreator },
  selectors: { getRedirect }
} = userContext;

const {
  validations: { validatePaymentMethod: validate }
} = controls;

const {
  actions: { patchOrder: patchOrderCreator, fetchOrder: fetchOrderCreator },
  selectors: { getNetTax }
} = order;

class IRSPaymentContainer extends Component {
  constructor(props) {
    super(props);

    const {
      props: { fetchOrder }
    } = this;
    
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
    fetchOrder(getOrderIdFromUrl(this));
  }

  componentDidMount() {
    const {
      props: { redirect, setRedirect }
    } = this;

    if (redirect !== null) {
      setRedirect(null);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      formSyncErrors,
      touch,
      untouch,
      formValues,
      initialValues
    } = this.props;

    if (formSyncErrors) {
      const latestErrors = validate(formValues);
      formSyncErrors.PaymentAccount &&
        initialValues.PaymentAccount &&
        initialValues.PaymentAccount.hasOwnProperty &&
        touch(
          ...Object.keys(formSyncErrors.PaymentAccount)
            .filter(k => initialValues.PaymentAccount.hasOwnProperty(k))
            .map(k => `PaymentAccount.${k}`)
        );
      latestErrors.PaymentAccount &&
        untouch(
          ...Object.keys(latestErrors.PaymentAccount)
            .filter(k => latestErrors.PaymentAccount[k] === undefined)
            .map(k => `PaymentAccount.${k}`)
        );
      touch(
        ...Object.keys(formSyncErrors).filter(k =>
          initialValues.hasOwnProperty(k)
        )
      );
      untouch(
        ...Object.keys(latestErrors).filter(k => latestErrors[k] === undefined)
      );
    }
  }

  patch = formValues => {
    const { patchOrder } = this.props;
    const prepped = mapPaymentFormValuesToPayload(formValues);
    prepped && patchOrder(prepped);
  };

  onSuccess = formValues => {
    const { patchOrder, setRedirect } = this.props;
    const prepped = mapPaymentFormValuesToPayload(formValues);
    return prepped
      ? patchOrder(
          mapPaymentFormValuesToPayload(formValues),
          `/form?order=${getOrderIdFromUrl(this)}`
        )
      : setRedirect(`/form?order=${getOrderIdFromUrl(this)}`);
  };

  handleClickSkip = () => {
    const { history } = this.props;
    history.push(`/form?order=${getOrderIdFromUrl(this)}`);
  };

  handleClickIRSWebsite = () => window.open(IRS_CC_SITE_URI, "_blank");

  handleClickEFTPSWebsite = () => window.open(IRS_EFTPS_SITE_URI, "_blank");

  handleClickWeCanHelp = () => {
    const { history } = this.props;
    history.push("/contact");
  };

  handleOnBlur = e => {
    const { initialValues, formValues } = this.props;

    if (e && e.target) {
      if (!e.target.value && !get(initialValues, e.target.name)) {
        return;
      }

      if (e.target.value === get(initialValues, e.target.name)) {
        return;
      }
    }

    this.patch(formValues);
  };

  render() {
    const {
      handleClickWeCanHelp,
      handleClickSkip,
      handleClickIRSWebsite,
      handleClickEFTPSWebsite,
      handleSubmit,
      handleOnBlur,
      props: {
        netTax,
        formValues,
        change,
        redirect,
        location: { pathname }
      }
    } = this;

    return (
      <React.Fragment>
        {redirect && redirect !== pathname && <Redirect to={redirect} />}
        <IRSPayment
          onClickIRSWebsite={handleClickIRSWebsite}
          onClickEFTPSWebsite={handleClickEFTPSWebsite}
          netTax={netTax}
          onClickWeCanHelp={handleClickWeCanHelp}
          onClickSkip={handleClickSkip}
          change={change}
          onSubmit={handleSubmit}
          onBlur={handleOnBlur}
          formValues={formValues}
        />
      </React.Fragment>
    );
  }
}

IRSPaymentContainer.propTypes = {
  formValues: PropTypes.object,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  patchOrder: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  setRedirect: PropTypes.func.isRequired,
  netTax: PropTypes.number,
  fetchOrder: PropTypes.func.isRequired,
  initialValues: PropTypes.object // eslint-disable-line
};

IRSPaymentContainer.defaultProps = {
  netTax: null,
  formValues: {},
  redirect: null,
  initialValues: {}
};

const mapStateToProps = state => {
  const order = getOrder(getOrderIdFromUrl(), state);

  const isEFTPS =
    order &&
    order.PaymentAccount &&
    order.PaymentAccount.AgreeEftpsTerms === true;

  const prev = getFormValues(FORM_NAME)(state);

  const initialChecks = {
    "registered-eftps-agreement":
      (prev && prev["registered-eftps-agreement"]) || isEFTPS,
    "cannot-use-credit-card-agreement":
      (prev && prev["cannot-use-credit-card-agreement"]) || isEFTPS,
    "i2290-access-eftps-agreement":
      (prev && prev["i2290-access-eftps-agreement"]) || isEFTPS,
    "amount-shown-agreement":
      (prev && prev["amount-shown-agreement"]) || isEFTPS
  };

  return {
    netTax: getNetTax(getOrderIdFromUrl(this))(state),
    redirect: getRedirect(state),
    formValues: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    initialValues: {
      ...({PaymentAccount: order?.PaymentAccount}),
      ...initialChecks
    }
  };
};

const mapDispatchToProps = dispatch => ({
  patchOrder: (order, redirect) => dispatch(patchOrderCreator(getOrderIdFromUrl(this), order)),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect)),
  fetchOrder: id => dispatch(fetchOrderCreator(id, true))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: FORM_NAME,
      validate,
      destroyOnUnmount: false,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true
      //forceUnregisterOnUnmount: true,
      //touchOnBlur: false
    })(IRSPaymentContainer)
  )
);
