import NewUserScreen from "./NewUserScreenContainer";
import WizardStepper from "./WizardStepper";
import WizardStepperContainer from "./WizardStepperContainer";
import WizardStep from "./WizardStep";
import CompanyInfo from "./CompanyInfoContainer";
import Signer from "./SignerContainer";
import VehiclesStep from "./VehiclesStep";
import VehiclesStepContainer from "./VehiclesStepContainer";
import WizardController from "./WizardControllerContainer";
import NewUserWizard from "./NewUserWizardContainer";
import "./index.css";

export default {
  NewUserScreen,
  WizardStepper,
  WizardStepperContainer,
  WizardStep,
  CompanyInfo,
  Signer,
  VehiclesStep,
  VehiclesStepContainer,
  WizardController,
  NewUserWizard
};
