import * as actions from "./vehicles.actions";
import * as actionTypes from "./vehicles.actionTypes";
import * as constants from "./vehicles.constants";
import * as selectors from "./vehicles.selectors";
import * as utilities from "./vehicles.utilities";
import components from "./components";
import reducer from "./vehicles.reducer";

export default {
  actions,
  components,
  reducer,
  constants,
  selectors,
  utilities,
  actionTypes
};
