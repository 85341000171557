export default [
  {
    display: "Alberta",
    real: "AB"
  },
  {
    display: "British Columbia",
    real: "BC"
  },
  {
    display: "Manitoba",
    real: "MB"
  },
  {
    display: "New Brunswick",
    real: "NB"
  },
  {
    display: "New Foundland",
    real: "NF"
  },
  {
    display: "Northwest Territories",
    real: "NT"
  },
  {
    display: "Nova Scotia",
    real: "NS"
  },
  {
    display: "Nunavut",
    real: "NU"
  },
  {
    display: "Ontario",
    real: "ON"
  },
  {
    display: "Prince Edward Island",
    real: "PE"
  },
  {
    display: "Quebec",
    real: "QC"
  },
  {
    display: "Sakatchewan",
    real: "SK"
  },
  {
    display: "Yukon Territory",
    real: "YK"
  }
];
