import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import hoc from "../../hoc";
import "./formReport.css";
import controls from "../../../modules/controls";
import utilities from "../../../modules/utilities";
import { BANK, CC, EFTPS, MAIL } from "../../../modules/constants";
import { SendSchedule1EmailDialog } from "screens/orderHistory/components/SendSchedule1EmailDialog";

const {
  hof: { withAccountFrame }
} = hoc;

const {
  components: { Button, Link },
  normalizations: { formatPhone, formatEIN }
} = controls;

const {
  math: { fastFormatMoney }
} = utilities;

const FormReport = ({
  taxYear,
  orderCreateDate,
  eFileSubmissionDate,
  firstUsedDate,
  pin,
  acknowledgementTimestamp,
  stampedScheduleOne,
  statusDescription,
  ein,
  signerName,
  address,
  city,
  state,
  zip,
  country,
  email,
  businessName,
  signerTitle,
  phone,
  thirdPartyName,
  thirdPartyPhone,
  thirdPartyPin,
  isThirdParty,
  soleProprietor,
  taxableVehicles,
  creditVehicles,
  loading,
  totalTax,
  totalCredit,
  netTax,
  accountNumber,
  routingNumber,
  onClickPrint,
  onClickScheduleOne,
  onClickBackToOrders,
  isVinCorrection,
  paymentMethod,
  formId
}) => {
  let paymentMethodString;
  switch (paymentMethod) {
    case CC:
      paymentMethodString = "Credit Card";
      break;
    case EFTPS:
      paymentMethodString = "EFTPS";
      break;
    case MAIL:
      paymentMethodString = "Mail a Check";
      break;
    case BANK:
      paymentMethodString = `Checking Account Direct Debit - ${routingNumber} / ******${accountNumber.slice(
        -4
      )}`;
      break;
    default:
      paymentMethodString = "";
  }

  const [isEmailPromptVisible, setEmailPromptVisible] = useState(false);

  const onOkEmailSchedule1 = console.log;
  return (
    <Fragment>
      {loading ? (
        <span>Compiling...</span>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <div className="form-report widget-background drop-shadow mb-gutter rad-corners">
              <section className="order-details">
                <div className="form-report-header">
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 my-auto">
                      <Button
                        onClick={onClickBackToOrders}
                        className="widget-button"
                        icon="arrow-chevron-left"
                        disabled={false}
                        text="Back to Orders"
                        primary
                      />
                    </div>
                    <div className="col-lg-4 order-lg-1 col-6 text-left text-sm-right my-auto print-button-ctnr">
                    {stampedScheduleOne && (<>
                      <SendSchedule1EmailDialog 
                        isVisible={isEmailPromptVisible} 
                        setIsVisible={setEmailPromptVisible} 
                        onOk={() => setEmailPromptVisible(false)} 
                        formId={formId}/>
                      <div className="mb-gutter">
                          <Button
                            onClick={onClickScheduleOne}
                            className="widget-button mr-sm-3"
                            disabled={false}
                            text="Download Schedule 1"
                            secondary
                          />
                          <Button
                            onClick={() => setEmailPromptVisible(true)}
                            className="widget-button"
                            disabled={false}
                            text="Email Schedule 1"
                            secondary
                          />
                      </div>
                      </>
                    )}
                        <Button
                          onClick={onClickPrint}
                          className="widget-button"
                          icon="print"
                          disabled={false}
                          text="Print"
                          secondary
                        />
                    </div>
                    <div className="col-lg-5 col-12 my-auto">
                      <h1 className="mb-gutter mb-md-0 text-center">
                        <span>{`${taxYear} - Form 2290 `}</span>
                        <span style={{ whiteSpace: "nowrap" }}>{`${
                          isVinCorrection ? "VIN Correction" : "E-file"
                        } Summary`}</span>
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Ordered:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{orderCreateDate}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">First Used:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{firstUsedDate}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">IRS Response:</span>
                      </div>
                      <div className="col text-right text-lg-left irs-info">
                        {statusDescription && <span>{statusDescription}</span>}
                        {acknowledgementTimestamp && (
                          <span>{acknowledgementTimestamp}</span>
                        )}
                        {stampedScheduleOne && (
                          <Link
                            className="schedule-1-link"
                            text="Schedule 1 Available"
                            clickHandler={onClickScheduleOne}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">E-filed:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{eFileSubmissionDate}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Signed Using PIN:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{pin}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="filer-information">
                <div className="form-report-header">
                  <h2>
                    <span>Filer Information: </span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">EIN:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{formatEIN(ein)}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Signer's Name:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{signerName}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Address:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{`${address} ${city}, ${state} ${zip}`}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Email:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Business Name:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{businessName}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Signer's Title:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{signerTitle}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Telephone:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{formatPhone(phone)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Structure:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{soleProprietor}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left text-lg-right">
                        <span className="inline-head">Country:</span>
                      </div>
                      <div className="col text-right text-lg-left">
                        <span>{country}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {taxableVehicles.length > 0 && (
                <section className="taxable-vehicles">
                  <div className="form-report-header">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h2>
                          {`Vehicles: ${taxableVehicles.length} Vehicle${
                            taxableVehicles.length !== 1 ? "s" : ""
                          }`}
                        </h2>
                      </div>
                      <div className="col text-left text-md-right">
                        <h2>{`Total: ${fastFormatMoney(totalTax)}`}</h2>
                      </div>
                    </div>
                  </div>
                  <MediaQuery minWidth={830}>
                    {matches => {
                      if (matches) {
                        return (
                          <ul className="vehicle-list">
                            <li className="row">
                              <div className="col inline-head">VIN</div>
                              <div className="col inline-head">Description</div>
                              <div className="col inline-head">Weight</div>
                              <div className="col inline-head">Tax</div>
                            </li>
                            {taxableVehicles.map(x => (
                              <li className="row">
                                <div className="col">{x.Vin}</div>
                                <div className="col">{x.Description}</div>
                                <div className="col">{x.WeightCategory}</div>
                                <div className="col">
                                  {fastFormatMoney(x.TaxValue)}
                                </div>
                              </li>
                            ))}
                          </ul>
                        );
                      }
                      return (
                        <ul className="vehicle-list">
                          {taxableVehicles.map(x => (
                            <li className="row">
                              <div className="col p-0">
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">VIN: </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.Vin}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">
                                      Description:{" "}
                                    </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.Description}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">
                                      Weight:{" "}
                                    </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.WeightCategory}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">Tax: </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{fastFormatMoney(x.TaxValue)}</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      );
                    }}
                  </MediaQuery>
                </section>
              )}
              {creditVehicles.length > 0 && (
                <section className="credit-vehicles">
                  <div className="form-report-header">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <h2>
                          {`Credits: ${creditVehicles.length} Credit${
                            creditVehicles.length !== 1 ? "s" : ""
                          }`}
                        </h2>
                      </div>
                      <div className="col text-left text-md-right">
                        <h2>{`Total: -${fastFormatMoney(totalCredit)}`}</h2>
                      </div>
                    </div>
                  </div>
                  <MediaQuery minWidth={830}>
                    {matches => {
                      if (matches) {
                        return (
                          <ul className="vehicle-list">
                            <li className="row">
                              <div className="col inline-head">VIN</div>
                              <div className="col inline-head">
                                Credit Reason
                              </div>
                              <div className="col inline-head">Weight</div>
                              <div className="col inline-head">Credit</div>
                            </li>
                            {creditVehicles.map(x => (
                              <li className="row">
                                <div className="col">{x.Vin}</div>
                                <div className="col">{x.CreditReason}</div>
                                <div className="col">{x.WeightCategory}</div>
                                <div className="col">
                                  -{fastFormatMoney(x.CreditAmount)}
                                </div>
                              </li>
                            ))}
                          </ul>
                        );
                      }
                      return (
                        <ul className="vehicle-list">
                          {creditVehicles.map(x => (
                            <li className="row">
                              <div className="col p-0">
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">VIN: </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.Vin}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">
                                      Credit Reason:{" "}
                                    </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.CreditReason}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">
                                      Weight:{" "}
                                    </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>{x.WeightCategory}</span>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col text-left">
                                    <span className="inline-head">
                                      Credit:{" "}
                                    </span>
                                  </div>
                                  <div className="col text-right">
                                    <span>
                                      -{fastFormatMoney(x.CreditAmount)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      );
                    }}
                  </MediaQuery>
                </section>
              )}
              <section className="totals mt-4">
                <div className="line-item" />
                <div className="row">
                  <div className="col col-md-9 text-left text-md-right">
                    <span className="inline-head">Total Taxes:</span>
                  </div>
                  <div className="col text-right text-md-left">
                    <span>{fastFormatMoney(totalTax)}</span>
                  </div>
                </div>
                <div className="line-item" />
                <div className="row">
                  <div className="col col-md-9 text-left text-md-right">
                    <span className="inline-head">Total Credits:</span>
                  </div>
                  <div className="col text-right text-md-left">
                    <span>-{fastFormatMoney(totalCredit)}</span>
                  </div>
                </div>
                <div className="line-item" />
                <div className="row">
                  <div className="col-6 col-md-3 text-left text-md-right">
                    <span className="inline-head">Payment Method:</span>{" "}
                  </div>
                  <div className="col-6 col-md-4 text-right text-md-left">
                    <span>{paymentMethodString}</span>
                  </div>
                  <div className="col col-md-2 text-left text-md-right">
                    <span className="inline-head">Net Tax:</span>
                  </div>
                  <div className="col text-right text-md-left">
                    <span>{fastFormatMoney(netTax)}</span>
                  </div>
                </div>
              </section>
              <section className="third-party">
                <div className="form-report-header">
                  <h2>
                    <span>Third-party Designee:</span>
                  </h2>
                </div>
                <div className="form-report-subhead">
                  <p>Authorized to Represent Company for this Form 2290</p>
                </div>
                <div className="row">
                  <div className="col d-flex">
                    <span className="inline-head mr-gutter">Filed: </span>
                    <div className="form-report-third-party">
                      {!isThirdParty ? (
                        <span>N/A</span>
                      ) : (
                        <React.Fragment>
                          <span>{thirdPartyName}</span>
                          <span>{formatPhone(thirdPartyPhone)}</span>
                          <span>{`PIN: ${thirdPartyPin}`}</span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="col-12 col-lg-10 offset-lg-1 mb-gutter" />
        </div>
      )}
    </Fragment>
  );
};

const FormReportWithAccountFrame = withAccountFrame(FormReport);

FormReportWithAccountFrame.propTypes = {
  isVinCorrection: PropTypes.bool.isRequired,
  ein: PropTypes.string.isRequired,
  taxYear: PropTypes.number.isRequired,
  orderCreateDate: PropTypes.string.isRequired,
  eFileSubmissionDate: PropTypes.string.isRequired,
  firstUsedDate: PropTypes.string.isRequired,
  signerName: PropTypes.string.isRequired,
  pin: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  businessName: PropTypes.string.isRequired,
  signerTitle: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  thirdPartyName: PropTypes.string.isRequired,
  thirdPartyPhone: PropTypes.string.isRequired,
  thirdPartyPin: PropTypes.string.isRequired,
  isThirdParty: PropTypes.bool.isRequired,
  soleProprietor: PropTypes.string.isRequired,
  stampedScheduleOne: PropTypes.bool.isRequired,
  acknowledgementTimestamp: PropTypes.string.isRequired,
  taxableVehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  creditVehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  totalTax: PropTypes.number.isRequired,
  totalCredit: PropTypes.number.isRequired,
  netTax: PropTypes.number.isRequired,
  accountNumber: PropTypes.string.isRequired,
  routingNumber: PropTypes.string.isRequired,
  statusDescription: PropTypes.string.isRequired,
  isEftps: PropTypes.bool.isRequired,
  onClickPrint: PropTypes.func.isRequired,
  onClickBackToOrders: PropTypes.func.isRequired,
  onClickScheduleOne: PropTypes.func.isRequired
};

// FormReportWithAccountFrame.defaultProps = {
// };

export default FormReportWithAccountFrame;
