import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import controls from "../../../modules/controls";

const {
  components: {
    Button,
    TextField,
    ZipFieldContainer: ZipField,
    UnitedStatesDropdown
  },
  normalizations: { normalizeAddress, normalizeCity }
} = controls;

const ScheduleOneSupportForm = ({
  onSubmit,
  onClickCancel,
  mailNameValidations,
  mailAttnValidations,
  mailAddressValidations,
  mailCityValidations,
  mailStateValidations,
  mailZipValidations
}) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">
      Where would you like to mail your Schedule 1?
    </h2>
    <section>
      <div className="row">
        <div className="col-12">
          <TextField
            name="MailName"
            label="Name"
            type="text"
            validations={mailNameValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="MailAttn"
            type="text"
            label="ATTN"
            validations={mailAttnValidations}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            name="Address1"
            label="Address"
            type="text"
            validations={mailAddressValidations}
            normalize={normalizeAddress}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <TextField
            name="City"
            label="City"
            type="text"
            validations={mailCityValidations}
            normalize={normalizeCity}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <UnitedStatesDropdown
            name="State"
            label="State"
            validations={mailStateValidations}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <ZipField name="Zip" label="Zip" validations={mailZipValidations} />
        </div>
      </div>
    </section>
    <p>Copies are normally mailed within 3 business days</p>

    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 text-left text-sm-right">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

ScheduleOneSupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  mailNameValidations: PropTypes.arrayOf(PropTypes.func),
  mailAttnValidations: PropTypes.arrayOf(PropTypes.func),
  mailAddressValidations: PropTypes.arrayOf(PropTypes.func),
  mailCityValidations: PropTypes.arrayOf(PropTypes.func),
  mailStateValidations: PropTypes.arrayOf(PropTypes.func),
  mailZipValidations: PropTypes.arrayOf(PropTypes.func)
};

ScheduleOneSupportForm.defaultProps = {
  mailNameValidations: [],
  mailAttnValidations: [],
  mailAddressValidations: [],
  mailCityValidations: [],
  mailStateValidations: [],
  mailZipValidations: []
};

export default ScheduleOneSupportForm;
