import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";
import "./companyInfoForm.css";

const {
  components: {
    TextField,
    CountryDropdown,
    StateDropdown,
    ZipFieldContainer: ZipField,
    BusinessStructureDropdown
  },
  normalizations: {
    normalizeEIN,
    formatEIN,
    normalizeAddress,
    normalizeCity,
    normalizeBusinessName
  }
} = controls;

const CompanyInfoForm = ({ einIsDisabled, formName, onBlur, onChange }) => (
  <div className="row CompanyInfoForm">
    <div className="col-12 col-md-6 clean-gutter-md-right">
      <TextField
        label="Business Name"
        name="BusinessName"
        type="text"
        normalize={normalizeBusinessName}
        onBlur={onBlur}
        value=""
      />
      <TextField
        label="EIN"
        name="Ein"
        type="text"
        normalize={normalizeEIN}
        format={formatEIN}
        onBlur={onBlur}
        onChange={onChange}
        value=""
        disabled={einIsDisabled}
      />
      <BusinessStructureDropdown name="SoleProprietor" onBlur={onBlur} />
      <CountryDropdown
        name="Country"
        label="Country"
        formName={formName}
        stateFieldName="State"
        zipFieldName="Zip"
        onBlur={onBlur}
      />
    </div>
    <div className="col-12 col-md-6 clean-gutter-md-left">
      <TextField
        label="Address"
        name="Address1"
        type="text"
        onBlur={onBlur}
        normalize={normalizeAddress}
      />
      <TextField
        label="City"
        name="City"
        type="text"
        onBlur={onBlur}
        normalize={normalizeCity}
      />
      <StateDropdown
        name="State"
        label="State"
        formName={formName}
        countryFieldName="Country"
        onBlur={onBlur}
      />
      <ZipField formName={formName} onBlur={onBlur} />
    </div>
  </div>
);

CompanyInfoForm.propTypes = {
  businessNameValidations: PropTypes.arrayOf(PropTypes.func),
  einValidations: PropTypes.arrayOf(PropTypes.func),
  businessStructureValidations: PropTypes.arrayOf(PropTypes.func),
  countryValidations: PropTypes.arrayOf(PropTypes.func),
  address1Validations: PropTypes.arrayOf(PropTypes.func),
  cityValidations: PropTypes.arrayOf(PropTypes.func),
  stateValidations: PropTypes.arrayOf(PropTypes.func),
  zipValidations: PropTypes.arrayOf(PropTypes.func),
  formName: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

CompanyInfoForm.defaultProps = {
  onBlur: null,
  onChange: null,
  businessNameValidations: [],
  einValidations: [],
  businessStructureValidations: [],
  countryValidations: [],
  address1Validations: [],
  cityValidations: [],
  stateValidations: [],
  zipValidations: []
};

export default CompanyInfoForm;
