import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { FILE_BY_PHONE_FORM_NAME } from "../supportCenter.constants";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import { mapSupportFieldsToPayload } from "../supportCenter.utilities";
import userContext from "../../../modules/userContext";
import utilities from "../../../modules/utilities";
import {FILE_BY_PHONE_SUBJECT } from "../../../modules/constants";
import FileByPhoneForm from "./FileByPhoneForm";

const {
  selectors: { getUserContext }
} = userContext;

const { getOrderIdFromUrl } = utilities;

class FileByPhoneFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { postSupportRequest }
    } = this;
    const orderId = getOrderIdFromUrl();
    const payload = mapSupportFieldsToPayload(formValues);

    payload.Subject = FILE_BY_PHONE_SUBJECT;
    payload.Description = formValues.Body || "";
    payload.Language = formValues.Language || "";
    if (orderId) payload.OrderId = orderId;

    postSupportRequest(payload);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const {
      handleSubmit,
      handleClickCancel,
    } = this;
    return (
      <FileByPhoneForm
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
      />
    );
  }
}

FileByPhoneFormContainer.propTypes = {
  postSupportRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  initialValues: {...getUserContext(state),
    Body:"I would like to be contacted by phone to e-file my Form 2290. I will have my credit card, vehicle information, and IRS payment information ready. The best time to reach me is:"
  }
});

const mapDispatchToProps = dispatch => ({
    postSupportRequest: (fields = []) =>
    dispatch(postSupportRequestCreator(fields))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: FILE_BY_PHONE_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(FileByPhoneFormContainer)
  )
);
