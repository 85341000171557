import CA from "./ca-states";
import US from "./us-states";
import MX from "./mx-states";
import countries from "./countries";

const states = {
  CA,
  US,
  MX
};

export { states, countries };
