import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HideIfUnauthenticated from "./HideIfUnauthenticated";
import { getUserContext } from "../userContext.selectors";

class HideIfUnauthenticatedContainer extends Component {
  render() {
    const {
      props: { children, user }
    } = this;

    let isAuthenticated = false;

    if (user.Id) isAuthenticated = true;

    return (
      <HideIfUnauthenticated isAuthenticated={isAuthenticated}>
        {children}
      </HideIfUnauthenticated>
    );
  }
}

HideIfUnauthenticatedContainer.propTypes = {
  user: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

HideIfUnauthenticatedContainer.defaultProps = {
  user: {}
};

const mapStateToProps = state => ({
  user: getUserContext(state)
});

export default connect(mapStateToProps)(HideIfUnauthenticatedContainer);
