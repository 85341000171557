import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const LanguageDropdown = ({ name, validations, label, onBlur, onChange }) => (
  <Select
    name={name}
    label={label}
    validations={validations}
    onBlur={onBlur}
    onChange={onChange}
  >
    <option value="English">
      English    
    </option>
    <option value="Spanish">
      Spanish    
    </option>
  </Select>
);

LanguageDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.array,
  label: PropTypes.string,
  onBlur: PropTypes.func
};

LanguageDropdown.defaultProps = {
  validations: [],
  label: "Language",
  onBlur: null
};

export default LanguageDropdown;
