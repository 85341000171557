import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isInvalid } from "redux-form";
import CartContents from "./CartContentsWidget";
import {
  getPromoCode,
  getError,
  getIsUnlimitedSelected,
  getWidgetIsExpanded,
  getPaymentInfo,
  getUnlimitedPrice
} from "../cart.selectors";
import {
  changePromoCode as changePromoCodeCreator,
  changeIsUnlimited as changeIsUnlimitedCreator,
  fetchPromo as fetchPromoCreator,
  clearPromo as clearPromoCreator,
  toggleIsExpanded as toggleIsExpandedCreator
} from "../cart.actions";
import order from "../../order";
import utilities from "../../utilities";
import {
  getCurrentTaxYear,
  getFormEntities,
  getVehicleEntities
} from "../../order/order.selectors";
import controls from "../../controls";
import { TERMS_AND_CONDITIONS_URI, PRIVACY_POLICY_URI } from "../../constants";
import { toggleDialog as toggleDialogCreator } from "../../paymentModal/paymentModal.actions";

const {
  selectors: { getOrderHOF, getUserIsCoveredByUnlimited },
  utilities: { getIsNoCharge },
  actions: {
    toggleDisclaimerIsVisible: toggleDisclaimerIsVisibleCreator,
    submitOrder: submitOrderCreator
  }
} = order;

const {
  validations: {
    validateVehicleFormWithNullValid: validateTaxable,
    validateCreditVehicleFormWithNullValid: validateCredit
  }
} = controls;

const {
  getOrderIdFromUrl,
  transform: { trim }
} = utilities;

class CartContentsBodyContainer extends Component {
  handleClickSubmission = () => {
    const {
      props: {
        isPaid,
        toggleDialog,
        isNoCharge,
        isWeightIncrease,
        userIsCoveredByUnlimited,
        submitOrder,
        total
      }
    } = this;
    if (
      isPaid ||
      isWeightIncrease ||
      userIsCoveredByUnlimited ||
      isNoCharge ||
      total <= 0
    ) {
      submitOrder(getOrderIdFromUrl());
    } else {
      toggleDialog();
    }
  };

  handleClickDisclaimer = () => {
    const {
      props: { toggleDisclaimerIsVisible }
    } = this;
    toggleDisclaimerIsVisible();
  };

  handleClickTerms = () => window.open(TERMS_AND_CONDITIONS_URI, "_blank");

  handleClickPrivacy = () => window.open(PRIVACY_POLICY_URI, "_blank");

  handleChangeIsUnlimited = (e, next) => {
    const {
      props: { changeIsUnlimited }
    } = this;
    changeIsUnlimited(next);
  };

  handleChangePromoCode = ({ target: { value } }) => {
    const {
      props: { changePromoCode }
    } = this;
    changePromoCode(value.toUpperCase());
  };

  handleClickApply = () => {
    const {
      props: { promoCode, fetchPromo, changePromoCode }
    } = this;
    const trimmed = trim(promoCode);
    changePromoCode(trimmed);
    fetchPromo(trimmed);
  };

  handleClickClearPromo = () => {
    const {
      props: { clearPromo }
    } = this;
    clearPromo();
  };

  render() {
    const {
      handleChangeIsUnlimited,
      handleChangePromoCode,
      handleClickApply,
      handleClickClearPromo,
      props: {
        productPrice,
        promoCode,
        promoValueCode,
        promoValueAmount,
        productName,
        productDescription,
        isUnlimited,
        subTotal,
        taxPercentage,
        taxAmount,
        total,
        error,
        isWeightIncrease,
        userIsCoveredByUnlimited,
        isNoCharge,
        noChargeString,
        widgetIsExpanded,
        toggleIsExpanded,
        unlimitedPrice,
        companyFormIsInvalid,
        signerFormIsInvalid,
        contactFormIsInvalid,
        designeeFormIsInvalid,
        paymentMethodFormIsInvalid,
        numberOfTaxableVehicles,
        numberOfInvalidTaxableVehicles,
        numberOfInvalidCreditVehicles,
        isPaid
      },
      handleClickDisclaimer,
      handleClickPrivacy,
      handleClickTerms,
      handleClickSubmission
    } = this;

    const isSubmissionButtonDisabled =
      companyFormIsInvalid ||
      signerFormIsInvalid ||
      contactFormIsInvalid ||
      designeeFormIsInvalid ||
      paymentMethodFormIsInvalid ||
      numberOfTaxableVehicles === 0 ||
      numberOfInvalidTaxableVehicles > 0 ||
      numberOfInvalidCreditVehicles > 0;

    const buttonText = isSubmissionButtonDisabled
      ? "Form is incomplete. Unable to Submit"
      : "AGREE and Submit to IRS";

    return (
      <CartContents
        isPaid={isPaid}
        onClickSubmission={handleClickSubmission}
        onClickDisclaimer={handleClickDisclaimer}
        onClickPrivacy={handleClickPrivacy}
        onClickTerms={handleClickTerms}
        buttonText={buttonText}
        isSubmissionButtonDisabled={isSubmissionButtonDisabled}
        unlimitedPrice={unlimitedPrice}
        isExpanded={widgetIsExpanded}
        toggleIsExpanded={toggleIsExpanded}
        isNoCharge={isNoCharge}
        noChargeString={noChargeString}
        isWeightIncrease={isWeightIncrease}
        userIsCoveredByUnlimited={userIsCoveredByUnlimited}
        productDescription={productDescription}
        onClickClearPromo={handleClickClearPromo}
        productPrice={productPrice}
        subTotal={subTotal}
        taxPercentage={taxPercentage}
        taxAmount={taxAmount}
        total={total}
        isUnlimited={isUnlimited}
        onChangeUnlimited={handleChangeIsUnlimited}
        product={productName}
        promoCode={promoCode}
        promoValueCode={promoValueCode}
        promoValueAmount={promoValueAmount}
        onChangePromoCode={handleChangePromoCode}
        onClickApply={handleClickApply}
        error={error}
      />
    );
  }
}

CartContentsBodyContainer.propTypes = {
  isPaid: PropTypes.bool.isRequired,
  promoCode: PropTypes.string.isRequired,
  changePromoCode: PropTypes.func.isRequired,
  changeIsUnlimited: PropTypes.func.isRequired,
  clearPromo: PropTypes.func.isRequired,
  fetchPromo: PropTypes.func.isRequired,
  productName: PropTypes.string,
  productDescription: PropTypes.string,
  subTotal: PropTypes.string,
  productPrice: PropTypes.number,
  taxPercentage: PropTypes.number,
  taxAmount: PropTypes.string,
  total: PropTypes.string,
  promoValueCode: PropTypes.number,
  promoValueAmount: PropTypes.number,
  error: PropTypes.string,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  isWeightIncrease: PropTypes.bool,
  isNoCharge: PropTypes.bool.isRequired,
  noChargeString: PropTypes.string,
  widgetIsExpanded: PropTypes.bool.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  unlimitedPrice: PropTypes.number,
  companyFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  numberOfTaxableVehicles: PropTypes.number,
  numberOfInvalidCreditVehicles: PropTypes.number,
  numberOfInvalidTaxableVehicles: PropTypes.number,
  toggleDisclaimerIsVisible: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired
};

CartContentsBodyContainer.defaultProps = {
  productName: null,
  subTotal: null,
  productPrice: null,
  productDescription: null,
  taxPercentage: null,
  taxAmount: null,
  total: null,
  promoValueCode: null,
  promoValueAmount: null,
  error: null,
  noChargeString: null,
  unlimitedPrice: null
};

const mapStateToProps = state => {
  const orderId = getOrderIdFromUrl();
  const currentTaxYear = getCurrentTaxYear(state);
  const order = getOrderHOF(orderId)(state);
  const userIsCoveredByUnlimited = getUserIsCoveredByUnlimited(orderId)(state);
  const paymentInfo = getPaymentInfo(state);

  let isWeightIncrease = null;
  let isNoCharge = false;
  let isPaid = false;
  let noChargeString = null;
  let numberOfTaxableVehicles = 0;
  let numberOfInvalidTaxableVehicles = 0;
  let numberOfInvalidCreditVehicles = 0;

  if (order) {
    isWeightIncrease = order.WeightIncrease;
    isNoCharge = getIsNoCharge(order.PaymentTranNum);
    noChargeString = order.PaymentTranNum;
    isPaid = order.PaymentInError === false && order.PaymentTranNum !== null;
  }

  if (order && order.Forms.length) {
    const forms = getFormEntities(state);
    const vehicles = getVehicleEntities(state);
    const vehicleIds = [].concat(...order.Forms.map(id => forms[id].Vehicles));
    const orderVehicles = vehicleIds.map(id => vehicles[id]);
    orderVehicles.forEach(x => {
      if (x.IsTaxable) {
        numberOfTaxableVehicles += 1;
        if (validateTaxable(x)) {
          numberOfInvalidTaxableVehicles += 1;
        }
        return;
      }
      if (validateCredit(x)) {
        numberOfInvalidCreditVehicles += 1;
      }
    });
  }

  const {
    productPrice,
    productName,
    productDescription,
    subTotal,
    total,
    taxPercentage,
    taxAmount,
    promoValueCode,
    promoValueAmount
  } = paymentInfo;

  return {
    currentTaxYear,
    isPaid,
    isNoCharge,
    noChargeString,
    productPrice,
    promoValueCode,
    promoValueAmount,
    subTotal,
    taxPercentage,
    taxAmount,
    total,
    isUnlimited: getIsUnlimitedSelected(state),
    userIsCoveredByUnlimited,
    isWeightIncrease,
    productName,
    productDescription,
    promoCode: getPromoCode(state),
    error: getError(state),
    widgetIsExpanded: getWidgetIsExpanded(state),
    unlimitedPrice: getUnlimitedPrice(state),
    signerFormIsInvalid: order ? isInvalid("signerSubmission")(state) : false,
    contactFormIsInvalid: order ? isInvalid("contactSubmission")(state) : false,
    designeeFormIsInvalid: order ? isInvalid("designee")(state) : false,
    companyFormIsInvalid: order
      ? isInvalid("companyInfoSubmission")(state)
      : false,
    paymentMethodFormIsInvalid: order
      ? isInvalid("paymentMethod")(state)
      : false,
    numberOfInvalidCreditVehicles,
    numberOfInvalidTaxableVehicles,
    numberOfTaxableVehicles
  };
};

const mapDispatchToProps = dispatch => ({
  changePromoCode: val => dispatch(changePromoCodeCreator(val)),
  changeIsUnlimited: val => dispatch(changeIsUnlimitedCreator(val)),
  fetchPromo: code => dispatch(fetchPromoCreator(code)),
  clearPromo: () => dispatch(clearPromoCreator()),
  toggleIsExpanded: () => dispatch(toggleIsExpandedCreator()),
  toggleDisclaimerIsVisible: () => dispatch(toggleDisclaimerIsVisibleCreator()),
  toggleDialog: () => dispatch(toggleDialogCreator()),
  submitOrder: orderId => dispatch(submitOrderCreator(orderId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartContentsBodyContainer);
