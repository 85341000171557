import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import ComboBox from "./ComboBox";
import "./textField.css";

const ComboBoxField = ({
  placeholder,
  label,
  name,
  type,
  validations,
  normalize,
  format,
  className,
  data,
  onChange
}) => (
  <Field
    name={name}
    component={ComboBox}
    label={label}
    placeholder={placeholder}
    type={type}
    validate={validations}
    normalize={normalize}
    format={format}
    className={className}
    data={data}
    onChange={onChange}
  />
);

ComboBoxField.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object)
};

ComboBoxField.defaultProps = {
  normalize: null,
  format: null,
  placeholder: "",
  validations: null,
  label: null,
  className: null
};

export default ComboBoxField;
