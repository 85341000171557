/* eslint-disable quotes */
// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import "./index.css";
import cssVars from "css-vars-ponyfill";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import rootReducer from "./modules/rootReducer";
import unregister from "./registerServiceWorker";
import { createStore } from "./modules/store";
import app from "./modules/app";
import reportWebVitals from './reportWebVitals';

console.log("i2290 client version " + process.env.REACT_APP_VERSION);

cssVars();

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      track: {
        "$checked$checked + &": {
          opacity: 1.0,
          backgroundColor: "var(--primary-green)"
        }
      }
    }
  }
});

const { App } = app.components;

const history = createBrowserHistory();

const store = createStore(rootReducer, history);

Number.isInteger =
  Number.isInteger ||
  function(value) {
    return (
      typeof value === "number" &&
      Number.isFinite(value) &&
      Math.floor(value) === value
    );
  };

ReactDOM.render( //<React.StrictMode>
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App history={history} />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
unregister();
