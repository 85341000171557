const initialState = {
  busyCount: 0,
  stepsInProcess: []
};

export default function reducer(state = initialState, action) {
  if (action.type.includes("_REQUESTED_ASYNC")) {
    const stepsInProcess = window.location.pathname.startsWith("/wizard")
      ? [...state.stepsInProcess, window.location.pathname]
      : state.stepsInProcess;
    return {
      stepsInProcess,
      busyCount: state.busyCount + 1
    };
  }

  if (
    action.type.indexOf("_RECEIVED_ASYNC") !== -1 ||
    action.type.indexOf("_ERROR_ASYNC") !== -1
  ) {
    const busyCount = state.busyCount > 0 ? state.busyCount - 1 : 0;
    return {
      stepsInProcess: busyCount === 0 ? [] : state.stepsInProcess,
      busyCount
    };
  }

  return state;
}
