import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import userContext from "../../userContext";
import SignerForm from "./SignerForm";
import { SUBMISSION_FORM_NAME } from "../signer.constants";
import controls from "../../controls";
import utilities from "../../utilities";

const {
  validations: {
    validateRequired,
    validateOptionalMiddleInitial,
    validateFirstName,
    validateLastName,
    validateTitle
  }
} = controls;

const {
  actions: { patchUser: patchUserCreator },
  selectors: { getUserContext, getRedirect },
  actions: { logout: logoutCreator }
} = userContext;

const {
  transform: { sanitizeValue }
} = utilities;

class SignerFormContainer extends Component {
  componentDidUpdate() {
    const { initialValues, touch } = this.props;
    touch(...Object.keys(initialValues));
  }

  handleBlur = e => {
    const {
      initialValues,
      formSyncErrors,
      patchUser,
      initialize,
      formValues
    } = this.props;

    if (!e.target.value && !initialValues[e.target.name]) {
      return;
    }

    if (e.target.value === initialValues[e.target.name]) {
      return;
    }

    if (
      formSyncErrors &&
      (formSyncErrors[e.target.name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    }

    initialize(formValues);

    patchUser({
      [e.target.name]: sanitizeValue(e.target.value)
    });
  };

  render() {
    const { initialValues, formSyncErrors, touch, untouch } = this.props;
    return (
      <SignerForm
        onBlur={this.handleBlur}
        initialValues={initialValues}
        formSyncErrors={formSyncErrors}
        touch={touch}
        untouch={untouch}
        firstNameValidations={[validateRequired, validateFirstName]}
        middleInitialValidations={[validateOptionalMiddleInitial]}
        lastNameValidations={[validateRequired, validateLastName]}
        titleValidations={[validateRequired, validateTitle]}
      />
    );
  }
}

SignerFormContainer.propTypes = {
  patchUser: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object
};

const mapStateToProps = state => ({
  formValues: getFormValues(SUBMISSION_FORM_NAME)(state),
  formSyncErrors: getFormSyncErrors(SUBMISSION_FORM_NAME)(state),
  initialValues: getUserContext(state),
  redirect: getRedirect(state)
});

const mapDispatchToProps = dispatch => ({
  patchUser: payload => dispatch(patchUserCreator(payload)),
  logout: () => dispatch(logoutCreator())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SUBMISSION_FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false
  })(SignerFormContainer)
);
