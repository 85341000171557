import React from "react";
import "./MessageScreen.css";
import Button from "./Button";
import withAccountFrame from "screens/hoc/hof/withAccountFrame";


export const MessageScreen = withAccountFrame(({ 
    onClickOK, 
    children,
    text = 'Ok'
}) => (
  <div className="thank-you-screen">
    <div className="text-center widget-background drop-shadow p-gutter mb-gutter">
      {children}
      <Button
        primary
        text={text}
        disabled={false}
        onClick={onClickOK}
        width="200px"
      />
    </div>
  </div>
));