import components from "./components";
import * as actions from "./form.actions";
import * as constants from "./form.constants";
import * as selectors from "./form.selectors";
import * as utilities from "./form.utilities";
import reducer from "./form.reducer";

export default {
  actions,
  components,
  constants,
  reducer,
  selectors,
  utilities
};
