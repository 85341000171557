import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Form } from "redux-form";
import { Redirect, withRouter } from "react-router-dom";
import controls from "../../../modules/controls";
import { FORM_NAME } from "../forgotPin.constants";
import "./ForgotPin.css";
import busyIndicator from "../../../modules/busyIndicator";
import { getError } from "../forgotPin.selectors";
import yellowLogo from "../../../modules/assets/logo.icon.min.png";

const { InlineBusyIndicator } = busyIndicator.components;
const { TextField, Link, Button } = controls.components;
const { validateForgotPINForm: validate } = controls.validations;
const { normalizeEIN, formatEIN, normalizeEmail } = controls.normalizations;

const ForgotPin = ({
  handleSubmit,
  handleReduxFormSubmit,
  submitting,
  isAuthenticated,
  submitSuccess,
  from,
  resetError,
  handleSignInClick,
  onClickCustomerSupport
}) => (
  <Form onSubmit={handleSubmit(handleReduxFormSubmit)}>
    {isAuthenticated ? (
      <Redirect to={from} />
    ) : (
      <div className="row" id="wrapper">
        <div className="col-md-6 iconCol">
          <div className="imgContainer">
            <img src={yellowLogo} alt="yellow logo" />
          </div>
        </div>
        <div className="col-md-6 col-12 formCol d-flex justify-content-center align-items-center">
          <div className="formContainer">
            <div className="smallLogoContainer">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/914629/logo.final.png"
                id="smallLogo"
                width="300px"
              />
              <h6 className="welcomeText">Reset Your PIN</h6>
            </div>
            <div className="inputContainer">
              <TextField
                label="EIN"
                name="ein"
                type="text"
                normalize={normalizeEIN}
                format={formatEIN}
              />
            </div>
            <div className="inputContainer">
              <TextField
                label="Email"
                name="email"
                type="text"
                normalize={normalizeEmail}
              />
            </div>
            <InlineBusyIndicator
              successMessage={
                submitSuccess ? "Success! Please check your email." : null
              }
              errorSelector={getError}
              reset={resetError}
            />
            <div className="sendPinContainer">
              <div className="row section">
                <div className="col-12">
                  <Button
                    type="submit"
                    disabled={submitting}
                    primary
                    text="Send My PIN"
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 text-center">
              <Link
                text="Return to Sign In"
                className="sign-in-link"
                clickHandler={handleSignInClick}
              />
            </div>
            <div className="additionalHelpCtn text-center">
              If you need additional help, please contact{" "}
              <Link
                clickHandler={onClickCustomerSupport}
                text="Customer Support"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </Form>
);

ForgotPin.propTypes = {
  onClickCustomerSupport: PropTypes.func.isRequired
};

export default withRouter(
  reduxForm({
    form: FORM_NAME,
    validate,
    touchOnBlur: false,
    initialValues: {
      email: "",
      ein: ""
    }
  })(ForgotPin)
);
