import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormWidgetBody from "./FormWidgetBody";
import { getEntities } from "../form.selectors";
import utilities from "../../utilities";
import vehicles from "../../vehicles";
import controls from "../../controls";
import { getOrderHOF } from "../../order/order.selectors";

const {
  getVehiclesUrlFromId,
  getCreditsUrlFromId,
  getOrderIdFromUrl,
  lookups: { lookupMonth }
} = utilities;

const {
  selectors: { getEntities: getVehicleEntities }
} = vehicles;

const {
  validations: {
    validateCreditVehicleFormWithNullValid: validateCredit,
    validateVehicleFormWithNullValid: validateVehicle
  }
} = controls;

class FormWidgetBodyContainer extends Component {
  handleClickEditCredits = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(getCreditsUrlFromId());
  };

  handleClickEditVehicles = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(getVehiclesUrlFromId());
  };

  render() {
    const {
      handleClickEditCredits,
      handleClickEditVehicles,
      props: {
        formId,
        form,
        isWeightIncrease,
        numOfCredits,
        numOfVehicles,
        isExpanded
      }
    } = this;

    if (form) {
      const month = lookupMonth(form.DateCategory);
      const { TotalTax: totalTax, TotalCredit: totalCredit } = form;
      return (
        <FormWidgetBody
          isWeightIncrease={isWeightIncrease}
          isExpanded={isExpanded}
          onClickEditCredits={handleClickEditCredits}
          onClickEditVehicles={handleClickEditVehicles}
          formId={formId}
          month={month}
          totalTax={totalTax}
          totalCredit={totalCredit}
          numOfCredits={numOfCredits}
          numOfVehicles={numOfVehicles}
        />
      );
    }
  }
}

FormWidgetBodyContainer.propTypes = {
  formId: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  isWeightIncrease: PropTypes.bool.isRequired,
  numOfCredits: PropTypes.number,
  numOfVehicles: PropTypes.number
};

FormWidgetBodyContainer.defaultProps = {
  numOfCredits: 0,
  numOfVehicles: 0
};

const mapStateToProps = (state, props) => {
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  const form = getEntities(state)[props.formId];
  const vehicleEntities = getVehicleEntities(state);
  if (form) {
    const credits = form.Vehicles.map(id => vehicleEntities[id]).filter(
      x => !x.IsTaxable && !validateCredit(x)
    );
    const vehicles = form.Vehicles.map(id => vehicleEntities[id]).filter(
      x => x.IsTaxable && !validateVehicle(x)
    );
    return {
      form,
      isWeightIncrease: order ? order.WeightIncrease : undefined,
      numOfCredits: credits.length,
      numOfVehicles: vehicles.length
    };
  }
  return {
    form: getEntities(state)[props.formId]
  };
};

export default withRouter(connect(mapStateToProps)(FormWidgetBodyContainer));
