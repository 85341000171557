import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import UnlockRequestForm from "./UnlockRequestFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 style={{ marginBottom: "30px" }} className="support-page-header">
      Do you need to unlock your unfiled Form 2290?
    </h2>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <UnlockRequestForm />
  </div>
);

const UnlockRequestFormScreen = withChildren(children, SupportFormScreen);

export default UnlockRequestFormScreen;
