import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import controls from "../../../modules/controls";
import vehicles from "../../../modules/vehicles";
import order from "../../../modules/order";
import WizardStepper from "./WizardStepper";
import userContext from "../../../modules/userContext";
import {
  getVehicleValidationCounts,
  getOrderAlt
} from "../../../modules/order/order.selectorsv2";
import { getOrderIdFromUrl } from "../../../modules/utilities/url";
import { getIsInProcess } from "../../../modules/busyIndicator/busyIndicator.selector";
import { getOrderHOF } from "modules/order/order.selectors";

const {
  selectors: { getUserContext }
} = userContext;

const {
  validations: {
    validateBusinessForm,
    validateSignerFrom,
    validatePaymentMethod
  }
} = controls;

const {
  selectors: { getTaxableVehiclesErrorsExist, getCreditVehiclesErrorsExist }
} = vehicles;

const {
  selectors: { getNetTax }
} = order;

class WizardStepperContainer extends Component {
  render() {
    return <WizardStepper {...this.props} />;
  }
}

WizardStepperContainer.propTypes = {
  companyFormIsInvalid: PropTypes.bool,
  contactFormIsInvalid: PropTypes.bool,
  designeeFormIsInvalid: PropTypes.bool,
  signerFormIsInvalid: PropTypes.bool,
  paymentMethodFormIsInvalid: PropTypes.bool,
  vehiclesIsInvalid: PropTypes.bool
};

WizardStepperContainer.defaultProps = {
  companyFormIsInvalid: false,
  signerFormIsInvalid: false,
  contactFormIsInvalid: false,
  designeeFormIsInvalid: false,
  paymentMethodFormIsInvalid: false,
  vehiclesIsInvalid: false
};

const mapStateToProps = state => {
  const { ThirdPname, ThirdPphone, ThirdPpin, ThirdPyesNo, WeightIncrease } =
    getOrderAlt(state) || {};
  const orderId = getOrderIdFromUrl();
  const order = getOrderHOF(orderId)(state);

  const user = getUserContext(state);
  const {
    vehiclesEntered,
    vehicleErrorCount,
    creditVehicleErrorCount
  } = getVehicleValidationCounts(state);

  const hasErrors = e => {
    return Object.keys(e).length > 0;
  };

  // ATTENTION: validation should match src\modules\cart\components\PaySubmitContainer.js
  return {
    vehiclesIsInvalid:
      vehiclesEntered === 0 ||
      vehicleErrorCount + creditVehicleErrorCount > 0 ||
      getTaxableVehiclesErrorsExist(state) ||
      getCreditVehiclesErrorsExist(state),
    vehiclesIsDisabled: !!WeightIncrease,
    signerFormIsInvalid: hasErrors(
      validateSignerFrom({
        ...user,
        ThirdPname,
        ThirdPphone,
        ThirdPpin,
        ThirdPyesNo:
          ThirdPyesNo === null || ThirdPyesNo === undefined
            ? false
            : ThirdPyesNo
      })
    ),
    companyFormIsInvalid: hasErrors(validateBusinessForm(user)),
    paymentMethodFormIsInvalid:
      getNetTax(getOrderIdFromUrl())(state) !== 0 &&
      hasErrors(validatePaymentMethod(order) || {}),
    getIsInProcess: getIsInProcess(state)
  };
};

export default connect(mapStateToProps, null)(WizardStepperContainer);
