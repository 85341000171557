import React from "react";
import * as PropTypes from "prop-types";
import { Form } from "redux-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import controls from "../../controls";
import { monthData, weightData } from "../data";
import { fastFormatMoney } from "../../utilities/math";
import { isEmpty } from "../../utilities/generic";

const {
  components: { TextField, Select, Checkbox, Button },
  normalizations: { normalizeVIN, normalizeTaxableVehicleDescription }
} = controls;

const VehicleEditor = ({
  onSubmit,
  taxYear,
  onClickCancel,
  cancelModalIsOpen,
  onCloseCancelModal,
  onConfirmCancelModal,
  onClickDelete,
  onChange,
  taxAmount,
  isDeletable
}) => (
  <div className="vehicle-editor rad-corners p-gutter pb-0">
    {cancelModalIsOpen && (
      <Dialog width="320px" title="Please confirm" onClose={onCloseCancelModal}>
        <React.Fragment>
          <p
            style={{
              margin: "25px",
              textAlign: "center"
            }}
          >
            Are you sure you want to discard the changes?
          </p>
          <div className="row">
            <div className="col clean-gutter-right">
              <Button
                disabled={false}
                text="No"
                secondary
                onClick={onCloseCancelModal}
                width="100%"
              />
            </div>
            <div className="col clean-gutter-left">
              <Button
                disabled={false}
                text="Yes"
                primary
                onClick={onConfirmCancelModal}
                width="100%"
              />
            </div>
          </div>
        </React.Fragment>
      </Dialog>
    )}
    <Form onSubmit={onSubmit} className="row">
      <div className="col-12 col-sm-6">
        <TextField
          label="VIN"
          name="Vin"
          type="text"
          // validations={[validateRequired, validateVIN]}
          normalize={normalizeVIN}
          onChange={onChange}
        />
        <Select
          name="WeightCategory"
          label="Gross Weight"
          // validations={[validateDropdown]}
          onChange={onChange}
        >
          {[<option value={null} key="none-state" />].concat(
            weightData.map(x => (
              <option value={x.real} key={x.real}>
                {x.display}
              </option>
            ))
          )}
        </Select>
        <Select
          name="DateCategory"
          label="First Used Date"
          // validations={[validateDropdown]}
          onChange={onChange}
        >
          {[<option value={null} key="none-state" />].concat(
            monthData.map((x, i) => {
              const display =
                i < monthData.length / 2
                  ? `${x.display} ${taxYear}`
                  : `${x.display} ${parseInt(taxYear, 10) + 1}`;
              return (
                <option value={x.real} key={x.real}>
                  {display}
                </option>
              );
            })
          )}
        </Select>
      </div>
      <div className="col-12 col-sm-6">
        <div
          className="mb-gutter p-gutter pb-0 vehicle-editor-checks"
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px"
          }}
        >
          <h5 className="vehicle-editor-checks-header">Check all that apply</h5>
          <Checkbox name="Logging" label="Forestry" onChange={onChange} />
          <Checkbox name="Agricultural" label="Farming" onChange={onChange} />
          <Checkbox name="Suspend" label="Suspended" onChange={onChange} />
        </div>
      </div>
      <div className="col-12">
        <TextField
          label="Description"
          name="Description"
          type="text"
          normalize={normalizeTaxableVehicleDescription}
          onChange={onChange}
        />
      </div>
      <div className="col-12 mb-gutter">
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>
          Tax: {!isEmpty(taxAmount) ? fastFormatMoney(taxAmount) : "$-"}
        </span>
      </div>
      <div className="col">
        <Button
          primary
          text="Save"
          onClick={onSubmit}
          disabled={false}
          className="mb-gutter mr-gutter"
        />
        <Button
          secondary
          text="Cancel"
          disabled={false}
          onClick={onClickCancel}
          className="mb-gutter"
        />
      </div>
      {isDeletable && (
        <div className="col-auto">
          <Button
            secondary
            text="Delete"
            disabled={false}
            onClick={onClickDelete}
            className="mb-gutter"
          />
        </div>
      )}
    </Form>
  </div>
);

VehicleEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  taxYear: PropTypes.string.isRequired,
  taxAmount: PropTypes.number.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  cancelModalIsOpen: PropTypes.bool.isRequired,
  onConfirmCancelModal: PropTypes.func.isRequired,
  onCloseCancelModal: PropTypes.func.isRequired
};

export default VehicleEditor;
