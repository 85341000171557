import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import Select from "@material-ui/core/NativeSelect";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import "./taxYearDropdown.css";

class TaxYearDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelWidth: 0
    };
  }

  componentDidMount() {
    this.setState({
      // new way of doing refs has a bug with material ui, have to do old way
      labelWidth: ReactDOM.findDOMNode(this.inputLabel).offsetWidth // eslint-disable-line react/no-find-dom-node
    });
  }

  render() {
    const { labelWidth } = this.state;
    const { onChange, value, label, disabled } = this.props;
    return (
      <FormControl variant="outlined">
        <InputLabel
          shrink={value !== null}
          ref={ref => {
            this.inputLabel = ref;
          }}
        >
          {label}
        </InputLabel>
        <Select
          className="tax-year-dropdown"
          onChange={onChange}
          value={value}
          input={
            <OutlinedInput
              disabled={disabled}
              labelWidth={labelWidth}
              notched={value !== null}
            />
          }
        >
          {this.props.taxYears &&
            this.props.taxYears.map(x => (
              <option key={x.TaxYear} value={x.TaxYear}>
                {`July ${x.TaxYear} - June ${parseInt(x.TaxYear, 10) + 1}`}
              </option>
            ))}
        </Select>
      </FormControl>
    );
  }
}

TaxYearDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

TaxYearDropdown.defaultProps = {
  value: "",
  width: "300px"
};

export default TaxYearDropdown;
