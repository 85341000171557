import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OrderHistoryScreen from "./OrderHistoryScreen";
import userContext from "../../../modules/userContext";
import order from "../../../modules/order";
import busyIndicator from "../../../modules/busyIndicator";
import { getHeroTaxYearObject } from "../../../modules/order/order.selectors";

const {
  selectors: { isBusy }
} = busyIndicator;

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

const {
  actions: {
    fetchOrders: fetchOrdersCreator,
    fetchActiveOrders: fetchActiveOrdersCreator,
    setRedirect: setRedirectCreator,
    setStagedScheduleOne: setStagedScheduleOneCreator
  },
  selectors: {
    getActiveReceiptModal,
    getOrderHistoryByIsActive,
    getScheduleOneStagedForDownload
  }
} = order;

class OrderHistoryScreenContainer extends Component {
  constructor(props) {
    super(props);
    this.scheduleOneForm = React.createRef();
  }

  componentDidMount() {
    const {
      props: { fetchUser, fetchOrders, fetchActiveOrders, setRedirect }
    } = this;
    fetchUser();
    fetchOrders();
    fetchActiveOrders();
    setRedirect(null);
    this.intervalId = setInterval(this.refetch, 60 * 1000);
  }

  componentDidUpdate(prevProps) {
    const {
      props: { scheduleOneStagedForDownload, setStagedScheduleOne }
    } = this;
    if (
      !prevProps.scheduleOneStagedForDownload &&
      scheduleOneStagedForDownload
    ) {
      this.scheduleOneForm.current.submit();
      setStagedScheduleOne(null);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  refetch = () => {
    const {
      props: { fetchOrders, fetchActiveOrders }
    } = this;
    fetchOrders();
    fetchActiveOrders();
  };

  render() {
    const {
      props: {
        activeReceiptModal,
        orderHistory,
        activeOrders,
        loading,
        scheduleOneStagedForDownload,
        heroTaxYear
      }
    } = this;

    return (
      <React.Fragment>
        <form
          action={scheduleOneStagedForDownload}
          method="GET"
          ref={this.scheduleOneForm}
          style={{ display: "none" }}
        />
        <OrderHistoryScreen
          loading={loading}
          heroTaxYear={heroTaxYear}
          hasActiveOrders={!!activeOrders.length}
          hasOrderHistory={!!orderHistory.length}
          activeReceiptModal={activeReceiptModal}
        />
      </React.Fragment>
    );
  }
}

OrderHistoryScreenContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  activeReceiptModal: PropTypes.number,
  fetchActiveOrders: PropTypes.func.isRequired,
  setRedirect: PropTypes.func.isRequired,
  setStagedScheduleOne: PropTypes.func.isRequired,
  scheduleOneStagedForDownload: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  activeOrders: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderHistory: PropTypes.arrayOf(PropTypes.number).isRequired
};

OrderHistoryScreenContainer.defaultProps = {
  activeReceiptModal: null,
  scheduleOneStagedForDownload: null
};

const mapStateToProps = state => ({
  loading: isBusy(state),
  activeReceiptModal: getActiveReceiptModal(state),
  heroTaxYear: getHeroTaxYearObject(state),
  activeOrders: getOrderHistoryByIsActive(true)(state) || [],
  orderHistory: getOrderHistoryByIsActive(false)(state) || [],
  scheduleOneStagedForDownload: getScheduleOneStagedForDownload(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator()),
  fetchOrders: () => dispatch(fetchOrdersCreator()),
  fetchActiveOrders: () => dispatch(fetchActiveOrdersCreator()),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect)),
  setStagedScheduleOne: val => dispatch(setStagedScheduleOneCreator(val))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHistoryScreenContainer);
