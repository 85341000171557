export default [
  {
    display: "Alabama",
    real: "AL"
  },
  {
    display: "Alaska",
    real: "AK"
  },
  {
    display: "Arizona",
    real: "AZ"
  },
  {
    display: "Arkansas",
    real: "AR"
  },
  {
    display: "California",
    real: "CA"
  },
  {
    display: "Colorado",
    real: "CO"
  },
  {
    display: "Connecticut",
    real: "CT"
  },
  {
    display: "Delaware",
    real: "DE"
  },
  {
    display: "District Of Columbia",
    real: "DC"
  },
  {
    display: "Florida",
    real: "FL"
  },
  {
    display: "Georgia",
    real: "GA"
  },
  {
    display: "Hawaii",
    real: "HI"
  },
  {
    display: "Idaho",
    real: "ID"
  },
  {
    display: "Illinois",
    real: "IL"
  },
  {
    display: "Indiana",
    real: "IN"
  },
  {
    display: "Iowa",
    real: "IA"
  },
  {
    display: "Kansas",
    real: "KS"
  },
  {
    display: "Kentucky",
    real: "KY"
  },
  {
    display: "Louisiana",
    real: "LA"
  },
  {
    display: "Maine",
    real: "ME"
  },
  {
    display: "Maryland",
    real: "MD"
  },
  {
    display: "Massachusetts",
    real: "MA"
  },
  {
    display: "Michigan",
    real: "MI"
  },
  {
    display: "Minnesota",
    real: "MN"
  },
  {
    display: "Mississippi",
    real: "MS"
  },
  {
    display: "Missouri",
    real: "MO"
  },
  {
    display: "Montana",
    real: "MT"
  },
  {
    display: "Nebraska",
    real: "NE"
  },
  {
    display: "Nevada",
    real: "NV"
  },
  {
    display: "New Hampshire",
    real: "NH"
  },
  {
    display: "New Jersey",
    real: "NJ"
  },
  {
    display: "New Mexico",
    real: "NM"
  },
  {
    display: "New York",
    real: "NY"
  },
  {
    display: "North Carolina",
    real: "NC"
  },
  {
    display: "North Dakota",
    real: "ND"
  },
  {
    display: "Ohio",
    real: "OH"
  },
  {
    display: "Oklahoma",
    real: "OK"
  },
  {
    display: "Oregon",
    real: "OR"
  },
  {
    display: "Pennsylvania",
    real: "PA"
  },
  {
    display: "Rhode Island",
    real: "RI"
  },
  {
    display: "South Carolina",
    real: "SC"
  },
  {
    display: "South Dakota",
    real: "SD"
  },
  {
    display: "Tennessee",
    real: "TN"
  },
  {
    display: "Texas",
    real: "TX"
  },
  {
    display: "Utah",
    real: "UT"
  },
  {
    display: "Vermont",
    real: "VT"
  },
  {
    display: "Virginia",
    real: "VA"
  },
  {
    display: "Washington",
    real: "WA"
  },
  {
    display: "West Virginia",
    real: "WV"
  },
  {
    display: "Wisconsin",
    real: "WI"
  },
  {
    display: "Wyoming",
    real: "WY"
  }
];
