import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";

const {
  components: { TextField }
} = controls;

const SupportRequestForm = ({ subjectValidations, bodyValidations }) => (
  <section>
    <div className="row">
      <div className="col-12 col-sm-6">
        <TextField
          name="Subject"
          label="Support Request Subject"
          type="text"
          validations={subjectValidations}
        />
      </div>
      <div className="col-12">
        <TextField
          name="Body"
          label="Tell us how we can help"
          type="text"
          multiline
          rows={8}
          validations={bodyValidations}
        />
      </div>
    </div>
  </section>
);

SupportRequestForm.propTypes = {
  subjectValidations: PropTypes.arrayOf(PropTypes.func),
  bodyValidations: PropTypes.arrayOf(PropTypes.func)
};

export default SupportRequestForm;
