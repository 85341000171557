import components from "./components";
import * as actions from "./forgotPin.actions";
import reducer from "./forgotPin.reducer";
import * as constants from "./forgotPin.constants";
import * as selectors from "./forgotPin.selectors";

export default {
  components,
  actions,
  reducer,
  constants,
  selectors
};
