import components from "./components";
import * as actions from "./order.actions";
import * as actionTypes from "./order.actionTypes";
import * as constants from "./order.constants";
import * as selectors from "./order.selectors";
import * as utilities from "./order.utilities";
import reducer from "./order.reducer";

export default {
  components,
  actions,
  actionTypes,
  constants,
  reducer,
  selectors,
  utilities
};
