import Footer from "./Footer";
import FooterContainer from "./FooterContainer";
import FormReportScreen from "./FormReportScreen";
import FormReportScreenContainer from "./FormReportScreenContainer";

export default {
  Footer,
  FooterContainer,
  FormReportScreen,
  FormReportScreenContainer
};
