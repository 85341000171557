import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { NOTICE_OR_LETTER_FORM_NAME } from "../supportCenter.constants";
import { getAttachments } from "../supportCenter.selectors";
import { mapSupportFieldsToPayload } from "../supportCenter.utilities";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import userContext from "../../../modules/userContext";
import { NOTICE_OR_LETTER_SUBJECT } from "../../../modules/constants";
import NoticeOrLetterForm from "./NoticeOrLetterForm";

const {
  selectors: { getUserContext }
} = userContext;

class NoticeOrLetterFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { attachments, postSupportRequest }
    } = this;
    const payload = mapSupportFieldsToPayload(formValues);
    payload.Subject = NOTICE_OR_LETTER_SUBJECT;
    payload.Description = formValues.Body || "";
    postSupportRequest(payload, attachments);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const { handleSubmit, handleClickCancel } = this;
    return (
      <NoticeOrLetterForm
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
      />
    );
  }
}

NoticeOrLetterFormContainer.propTypes = {
  attachments: PropTypes.array.isRequired,
  postSupportRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  initialValues: getUserContext(state),
  attachments: getAttachments(state)
});

const mapDispatchToProps = dispatch => ({
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: NOTICE_OR_LETTER_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(NoticeOrLetterFormContainer)
  )
);
