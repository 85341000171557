import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import utilities from "../../../modules/utilities";
import {
  STATUS_ACCEPTED,
  STATUS_NOT_SUBMITTED,
  STATUS_REJECTED,
  NOT_FILED_YET_STATUS_LABEL,
} from "../../../modules/constants";
import "./formSummaryPanel.css";
import { LineDescription, SendSchedule1EmailDialog } from "./SendSchedule1EmailDialog";

const {
  components: { Button, Link },
} = controls;

const {
  math: { fastFormatMoney },
  lookups: { lookupMonthAbbreviation },
} = utilities;

const FormSummaryPanel = (
    {
      status,
      hasSchedule1,
      statusLabel,
      irsNotRespondingMessage,
      onClickMore,
      onClickView,
      onClickSchedule1,
      onClickSubmitTicket,
      formId
    }
) => {
  
    const [isEmailPromptVisible, setEmailPromptVisible] = useState(false);
          

    return (
        <div className="form-summary-panel row status-container">
          <div className="col-12 col-sm py-gutter d-flex align-items-center form-info">
            <LineDescription formId={formId}/>
          </div>
          <div
            className={`col-12 col-sm text-left text-sm-right${
              status !== STATUS_NOT_SUBMITTED
                ? " clean-gutter-sm-right my-auto"
                : " mb-gutter mb-sm-0 d-flex justify-content-sm-end align-items-center"
            }`}
          >
            {statusLabel && irsNotRespondingMessage ? (
              <span>
                {`${statusLabel} `}
                <Link clickHandler={onClickMore} text="More..." />
              </span>
            ) : (
              <Fragment>
                {!hasSchedule1 && (
                  <span
                    className={
                      status === STATUS_NOT_SUBMITTED ? "i2290-inline-error" : null
                    }
                  >
                    {statusLabel || NOT_FILED_YET_STATUS_LABEL}
                  </span>
                )}
              </Fragment>
            )}
          </div>
          {status !== STATUS_NOT_SUBMITTED && (
            <div className="col-12 col-sm-auto pr-0 py-gutter d-flex align-items-center justify-content-end text-right clean-gutter-sm-left">
              {(() => {
                switch (status) {
                  case STATUS_ACCEPTED:
                    return (
                      <Fragment>
                        <Button
                          secondary
                          text="Summary"
                          disabled={false}
                          onClick={onClickView}
                        />
                        {hasSchedule1 && ( <>
                          <SendSchedule1EmailDialog 
                            isVisible={isEmailPromptVisible} 
                            setIsVisible={setEmailPromptVisible} 
                            onOk={() => setEmailPromptVisible(false)} 
                            formId={formId}/>
                          <Button
                            secondary
                            text={"Download Schedule 1"}
                            disabled={false}
                            onClick={onClickSchedule1}
                          />
                          <Button
                            secondary
                            text="Email Schedule 1"
                            disabled={false}
                            onClick={() => setEmailPromptVisible(true)}
                          />
                        </>
                        )}
                      </Fragment>
                    );
                  case STATUS_REJECTED:
                    return (
                      <Fragment>
                        <Button
                          secondary
                          text="Submit Ticket"
                          disabled={false}
                          onClick={onClickSubmitTicket}
                        />
                        <Button
                          secondary
                          text="Summary"
                          disabled={false}
                          onClick={onClickView}
                        />
                      </Fragment>
                    );
                  default:
                    return (
                      <Button
                        secondary
                        text="Summary"
                        disabled={false}
                        onClick={onClickView}
                      />
                    );
                }
              })()}
            </div>
          )}
        </div>
    );
};

FormSummaryPanel.propTypes = {
  dateCategory: PropTypes.number.isRequired,
  numberOfVins: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
  hasSchedule1: PropTypes.bool.isRequired,
  netTax: PropTypes.number.isRequired,
  hasCredits: PropTypes.bool.isRequired,
  irsNotRespondingMessage: PropTypes.string,
  onClickMore: PropTypes.func.isRequired,
};

FormSummaryPanel.defaultProps = {
  irsNotRespondingMessage: null,
};

export default FormSummaryPanel;

