const values = [
  {
    display: "A - 55,000 lbs.",
    real: "A"
  },
  {
    display: "B - 55,001 - 56,000 lbs.",
    real: "B"
  },
  {
    display: "C - 56,001 - 57,000 lbs.",
    real: "C"
  },
  {
    display: "D - 57,001 - 58,000 lbs.",
    real: "D"
  },
  {
    display: "E - 58,001 - 59,000 lbs.",
    real: "E"
  },
  {
    display: "F - 59,001 - 60,000 lbs.",
    real: "F"
  },
  {
    display: "G - 60,001 - 61,000 lbs.",
    real: "G"
  },
  {
    display: "H - 61,001 - 62,000 lbs.",
    real: "H"
  },
  {
    display: "I - 62,001 - 63,000 lbs.",
    real: "I"
  },
  {
    display: "J - 63,001 - 64,000 lbs.",
    real: "J"
  },
  {
    display: "K - 64,001 - 65,000 lbs.",
    real: "K"
  },
  {
    display: "L - 65,001 - 66,000 lbs.",
    real: "L"
  },
  {
    display: "M - 66,001 - 67,000 lbs.",
    real: "M"
  },
  {
    display: "N - 67,001 - 68,000 lbs.",
    real: "N"
  },
  {
    display: "O - 68,001 - 69,000 lbs.",
    real: "O"
  },
  {
    display: "P - 69,001 - 70,000 lbs.",
    real: "P"
  },
  {
    display: "Q - 70,001 - 71,000 lbs.",
    real: "Q"
  },
  {
    display: "R - 71,001 - 72,000 lbs.",
    real: "R"
  },
  {
    display: "S - 72,001 - 73,000 lbs.",
    real: "S"
  },
  {
    display: "T - 73,001 - 74,000 lbs.",
    real: "T"
  },
  {
    display: "U - 74,001 - 75,000 lbs.",
    real: "U"
  },
  {
    display: "V - Over 75,000 lbs.",
    real: "V"
  },
  {
    display: "W - Suspended",
    real: "W"
  }
];

export default values;
