import AccountForm from "./AccountFormContainer";
import hoc from "../../hoc";

const {
  hof: { withAccountFrame }
} = hoc;

const AccountScreen = withAccountFrame(AccountForm);

export default AccountScreen;
