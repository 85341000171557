import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SupportFooter from "./SupportFooter";

class SupportFooterContainer extends Component {
  handleClickCall = () => {
    window.location.href = "tel:+8664442290";
  };

  handleClickEmail = () => {
    window.location.href = "mailto:support@i2290.com";
  };

  handleClickSupport = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  handleClickFaq = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const {
      handleClickFaq,
      handleClickSupport,
      handleClickCall,
      handleClickEmail
    } = this;
    return (
      <SupportFooter
        onClickEmail={handleClickEmail}
        onClickCall={handleClickCall}
        onClickSupport={handleClickSupport}
        onClickFaq={handleClickFaq}
      />
    );
  }
}

export default withRouter(SupportFooterContainer);
