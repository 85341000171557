// import React from "react";
import CompanyInfoWidgetHeader from "./CompanyInfoWidgetHeaderContainer";
import CompanyInfoWidgetBody from "./CompanyInfoWidgetBodyContainer";
import widgets from "../../widgets";
import userContext from "../../userContext";

const {
  hof: { withSummaryWidget }
} = widgets;

const {
  selectors: { getCompanyInfoWidgetIsExpanded }
} = userContext;

export default withSummaryWidget(
  CompanyInfoWidgetHeader,
  CompanyInfoWidgetBody,
  getCompanyInfoWidgetIsExpanded
);
