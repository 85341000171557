import React from "react";
import PropTypes from "prop-types";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "redux-form";
import "./checkbox.css";

const renderCheckbox = ({
  label,
  input,
  meta: { touched, error },
  ...custom
}) => (
  <React.Fragment>
    <FormControlLabel
      control={
        <MaterialCheckbox
          className="i2290-checkbox-input"
          {...input}
          {...custom}
        />
      }
      label={label}
    />
    {touched && error && (
      <FormHelperText margin="dense" error>
        {error}
      </FormHelperText>
    )}
  </React.Fragment>
);

const Checkbox = ({ name, validations, normalize, format, ...custom }) => (
  <Field
    {...custom}
    name={name}
    component={renderCheckbox}
    validate={validations}
    normalize={normalize}
    format={format}
    type="checkbox"
  />
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func
};

Checkbox.defaultProps = {
  normalize: null,
  format: null,
  validations: null
};

export const NonFormCheckbox = renderCheckbox;

export default Checkbox;
