import components from "./components";
import * as actions from "./supportCenter.actions";
import * as actionTypes from "./supportCenter.actionTypes";
import * as constants from "./supportCenter.constants";
import * as utilities from "./supportCenter.utilities";
import * as selectors from "./supportCenter.selectors";
import reducer from "./supportCenter.reducer";

export default {
  components,
  actions,
  actionTypes,
  constants,
  reducer,
  selectors,
  utilities
};
