import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";

import { STATE_NAME } from "./paymentModal.constants";

export const TOGGLE_WARNING_DIALOG = buildActionType(
  STATE_NAME,
  "TOGGLE_WARNING_DIALOG"
);

export const TOGGLE_THANK_YOU_DIALOG = buildActionType(
  STATE_NAME,
  "TOGGLE_THANK_YOU_DIALOG"
);

export const OPEN_DIALOG_NAME = buildActionType(
  STATE_NAME,
  "OPEN_DIALOG_NAME"
);

export const TOGGLE_DIALOG = buildActionType(STATE_NAME, "TOGGLE_DIALOG");

export const SHOW_ERROR = buildActionType(STATE_NAME, "SHOW_ERROR");

export const SET_REDIRECT = buildActionType(STATE_NAME, "SET_REDIRECT");

export const REQUEST_STRIPE_TOKEN = buildActionType(
  STATE_NAME,
  "REQUEST_STRIPE_TOKEN"
);

export const STRIPE_PROCESSING_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "STRIPE_PROCESSING_ASYNC"
);

export const FETCH_VERIFY_BANK_ASYNC = buildAsyncActionType(
  STATE_NAME,
  "FETCH_VERIFY_BANK_ASYNC"
);
