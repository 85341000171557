import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./acknowledger.css";
import controls from "../../controls";

const {
  components: { Button }
} = controls;

const Acknowledger = ({ isVisible, onClose, prompt }) => (
  <React.Fragment>
    {isVisible && (
      <Dialog onClose={onClose}>
        <div className="irs-not-resp-ack">
          <p>{prompt}</p>
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              disabled={false}
              text="OK"
              secondary
              onClick={onClose}
              width="100%"
            />
          </div>
        </div>
      </Dialog>
    )}
  </React.Fragment>
);

Acknowledger.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  prompt: PropTypes.string.isRequired
};

export default Acknowledger;
