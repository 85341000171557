import React from "react";
import Grid from "../components/Grid";

const withGrid = (GridComponent, ToolbarComponent) => ({ ...rest }) => (
  <Grid
    gridChildren={<GridComponent />}
    toolbarChildren={<ToolbarComponent />}
    {...rest}
  />
);

export default withGrid;
