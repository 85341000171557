import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreditVehiclesGrid from "./CreditVehiclesGrid";
import {
  getCreditVehiclesAllAreStagedForDeletion,
  getCreditVehiclesCurrentPage,
  getCreditVehiclesGridOrder,
  getCreditVehiclesPageSize,
  getCreditVehiclesStagedForCreation,
  getCreditVehiclesStagedForDeletion,
  getWorkingCopies
} from "../vehicles.selectors";
import {
  changeCurrentPage as changeCurrentPageCreator,
  changePageSize as changePageSizeCreator,
  deleteVehicle as deleteVehicleCreator,
  stageVehicle as stageVehicleCreator,
  stageForDeletion as stageForDeletionCreator,
  toggleAllAreStagedForDeletion as toggleAllAreStagedForDeletionCreator
} from "../vehicles.actions";
import { CREDIT_VEHICLES_SLICE_NAME } from "../vehicles.constants";
import controls from "../../controls";
import grid from "../../grid";
import utilities from "../../utilities";
import taxYear from "../../taxYear";
import order from "../../order";
import modal from "../../modal";
import hoc from "../../hoc";
import CreditVehicleEditorContainer from "./CreditVehicleEditorContainer";
import { isEmpty } from "../../utilities/generic";

const {
  components: { Button }
} = controls;

const {
  components: { Modal }
} = modal;

const {
  selectors: { getTaxTable }
} = taxYear;

const {
  hof: { bindComponent }
} = hoc;

const {
  actions: { deleteVehicles: deleteVehiclesCreator }
} = order;

const {
  components: { CommandCell }
} = grid;

const { getOrderIdFromUrl } = utilities;

class CreditVehiclesGridContainer extends Component {
  constructor(props) {
    super(props);
    this.CommandCell = bindComponent(
      { editHOF: this.editHOF, deleteHOF: this.removeHOF },
      CommandCell
    );
  }

  editHOF = dataItem => () => {
    const {
      props: { stageVehicle }
    } = this;
    stageVehicle(dataItem);
  };

  removeHOF = dataItem => () => {
    const { stageForDeletion } = this.props;
    stageForDeletion(dataItem.Id);
  };

  // handleChange = event => {
  //   const { updateStagedForCreation, updateWorkingCopy, taxTable } = this.props;
  //   const { value, field, dataItem } = event;
  //
  //   if (!field) return;
  //
  //   let update;
  //   switch (field) {
  //     case "BuyerZip":
  //       update = {
  //         [field]: normalizeZipByCountry(value, null, {
  //           Country: dataItem.BuyerCountry
  //         })
  //       };
  //       break;
  //     case "BuyerName":
  //       update = { [field]: normalizeBuyerName(value) };
  //       break;
  //     case "BuyerAddress1":
  //       update = { [field]: normalizeAddress(value) };
  //       break;
  //     case "BuyerCity":
  //       update = { [field]: normalizeAddress(value) };
  //       break;
  //     case "Vin":
  //       update = { [field]: normalizeVIN(value) };
  //       break;
  //     case "Description":
  //       update = { [field]: normalizeDescription(value) };
  //       break;
  //     case "BuyerCountry":
  //       update = { [field]: value, BuyerState: "", BuyerZip: "" };
  //       break;
  //     case "IsBuyerDataIncluded": {
  //       if (value) {
  //         update = {
  //           [field]: value,
  //           BuyerName: "",
  //           BuyerAddress1: "",
  //           BuyerAddress2: "",
  //           BuyerCity: "",
  //           BuyerState: "",
  //           BuyerZip: "",
  //           BuyerCountry: "",
  //           IsBuyerBusiness: false
  //         };
  //         break;
  //       } else {
  //         update = {
  //           [field]: value,
  //           BuyerName: null,
  //           BuyerAddress1: null,
  //           BuyerAddress2: null,
  //           BuyerCity: null,
  //           BuyerState: null,
  //           BuyerZip: null,
  //           BuyerCountry: null,
  //           IsBuyerBusiness: null
  //         };
  //         break;
  //       }
  //     }
  //     case "CreditReason":
  //       switch (value) {
  //         case null:
  //           update = {
  //             [field]: value,
  //             CreditEventDate: null,
  //             CreditAmount: null,
  //             isExpanded: false
  //           };
  //           break;
  //         case CREDIT_REASON_SUSPENDED:
  //           update = {
  //             [field]: value,
  //             CreditEventDate: new Date(),
  //             CreditAmount: null,
  //             isExpanded: false
  //           };
  //           break;
  //         case CREDIT_REASON_SOLD:
  //           update = {
  //             [field]: value,
  //             isExpanded: true
  //           };
  //           break;
  //         case CREDIT_REASON_STOLEN:
  //           update = {
  //             [field]: value,
  //             isExpanded: false
  //           };
  //           break;
  //         case CREDIT_REASON_DESTROYED:
  //           update = {
  //             [field]: value,
  //             isExpanded: false
  //           };
  //           break;
  //         default:
  //           throw new Error();
  //       }
  //       if (dataItem.CreditReason === CREDIT_REASON_SUSPENDED) {
  //         update.CreditEventDate = null;
  //         update.CreditAmount = null;
  //       }
  //       break;
  //     case "WeightCategory": {
  //       update = { [field]: value, Suspend: value === "W" };
  //       if (dataItem.CreditReason !== CREDIT_REASON_SUSPENDED) {
  //         update.CreditAmount = computeCredit(
  //           { ...dataItem, ...update },
  //           taxTable
  //         );
  //       }
  //       break;
  //     }
  //     case "CreditEventDate":
  //       update = {
  //         [field]: value,
  //         DateCategory:
  //           lookupDateCategoryByJavascriptDateMonth(value.getMonth()) - 1
  //       };
  //       if (dataItem.CreditReason !== CREDIT_REASON_SUSPENDED) {
  //         update.CreditAmount = computeCredit(
  //           { ...dataItem, ...update },
  //           taxTable
  //         );
  //       }
  //       break;
  //     default:
  //       update = { [field]: value };
  //   }
  //
  //   if (dataItem.errors) {
  //     update.errors = validate({ ...event.dataItem, ...update });
  //   }
  //
  //   if (!event.dataItem.Id) {
  //     return updateStagedForCreation(update);
  //   }
  //
  //   updateWorkingCopy(event.dataItem.Id, update);
  // };

  handleClickAddNew = () => {
    const { stageVehicle } = this.props;

    stageVehicle({
      // errors: null,
      Vin: "",
      Description: "",
      Logging: false,
      Agricultural: false,
      Suspend: false,
      WeightCategory: "V",
      DateCategory: "12",
      IsTaxable: false,
      IsBuyerDataIncluded: null,
      BuyerName: null,
      BuyerAddress1: null,
      BuyerAddress2: null,
      BuyerCity: null,
      BuyerState: null,
      BuyerZip: null,
      BuyerCountry: null,
      IsBuyerBusiness: null,
      CreditEventDate: null,
      CreditReason: null
    });
  };

  handleClickImport = () => {
    const {
      props: { toggleShowing }
    } = this;
    toggleShowing();
  };

  handleClickDeleteAll = () => {
    const {
      props: { toggleAllAreStagedForDeletion }
    } = this;

    toggleAllAreStagedForDeletion();
  };

  deleteStaged = () => {
    const {
      props: { stagedForDeletion, deleteVehicle }
    } = this;

    if (stagedForDeletion === null) return;

    deleteVehicle(getOrderIdFromUrl(), stagedForDeletion);
  };

  unstageForDeletion = () => {
    const { stageForDeletion } = this.props;
    stageForDeletion(null);
  };

  deleteAll = () => {
    const {
      props: { deleteVehicles }
    } = this;
    deleteVehicles(getOrderIdFromUrl());
  };

  handlePageChange = ({ page: { skip, take } }) => {
    const {
      props: { changeCurrentPage, changePageSize }
    } = this;
    changeCurrentPage(skip / take);
    changePageSize(take);
  };

  render() {
    const {
      deleteStaged,
      unstageForDeletion,
      deleteAll,
      handleClickAddNew,
      handleClickDeleteAll,
      handleClickImport,
      CommandCell,
      handlePageChange,
      props: {
        gridData,
        currentPage,
        pageSize,
        stagedForDeletion,
        stagedForCreation,
        allAreStagedForDeletion,
        toggleAllAreStagedForDeletion,
        taxTable,
        totalCredit
      }
    } = this;

    return (
      <React.Fragment>
        <Modal
          isVisible={stagedForDeletion !== null}
          onClose={unstageForDeletion}
          prompt="Are you sure you want to delete this credit?"
          title="Please confirm"
          actions={
            <React.Fragment>
              <div className="col clean-gutter-right">
                <Button
                  disabled={false}
                  text="No"
                  secondary
                  onClick={unstageForDeletion}
                  width="100%"
                />
              </div>
              <div className="col clean-gutter-left">
                <Button
                  disabled={false}
                  text="Yes"
                  primary
                  onClick={deleteStaged}
                  width="100%"
                />
              </div>
            </React.Fragment>
          }
        />
        <Modal
          isVisible={allAreStagedForDeletion}
          onClose={toggleAllAreStagedForDeletion}
          prompt="Are you sure you want to delete all credits?"
          title="Please confirm"
          actions={
            <React.Fragment>
              <div className="col clean-gutter-right">
                <Button
                  disabled={false}
                  text="No"
                  secondary
                  onClick={toggleAllAreStagedForDeletion}
                  width="100%"
                />
              </div>
              <div className="col clean-gutter-left">
                <Button
                  disabled={false}
                  text="Yes"
                  primary
                  onClick={deleteAll}
                  width="100%"
                />
              </div>
            </React.Fragment>
          }
        />
        {stagedForCreation ? (
          <CreditVehicleEditorContainer taxTable={taxTable} />
        ) : (
          <CreditVehiclesGrid
            skip={currentPage * pageSize}
            take={pageSize}
            onPageChange={handlePageChange}
            data={gridData}
            readOnly={false}
            onClickAddNew={handleClickAddNew}
            onClickImport={handleClickImport}
            onClickDeleteAll={handleClickDeleteAll}
            CommandCell={CommandCell}
            totalCredit={totalCredit}
          />
        )}
      </React.Fragment>
    );
  }
}

CreditVehiclesGridContainer.propTypes = {
  gridData: PropTypes.arrayOf(PropTypes.object),
  toggleShowing: PropTypes.func.isRequired,
  totalCredit: PropTypes.number.isRequired
};

CreditVehiclesGridContainer.defaultProps = {
  gridData: []
};

const mapStateToProps = state => {
  const workingCopies = getWorkingCopies(state);
  const gridOrder = getCreditVehiclesGridOrder(state);
  const stagedForCreation = getCreditVehiclesStagedForCreation(state);
  const data = gridOrder.map(id => ({
    ...workingCopies[id],
    CreditEventDate: workingCopies[id].CreditEventDate
      ? new Date(workingCopies[id].CreditEventDate)
      : null
  }));

  const totalCredit = data.reduce(
    (a, x) => (!isEmpty(x.CreditAmount) ? a + x.CreditAmount : a),
    0
  );

  return {
    gridData: data,
    stagedForCreation,
    stagedForDeletion: getCreditVehiclesStagedForDeletion(state),
    allAreStagedForDeletion: getCreditVehiclesAllAreStagedForDeletion(state),
    taxTable: getTaxTable(state),
    currentPage: getCreditVehiclesCurrentPage(state),
    pageSize: getCreditVehiclesPageSize(state),
    totalCredit
  };
};

const mapDispatchToProps = dispatch => ({
  stageForDeletion: id =>
    dispatch(stageForDeletionCreator(id, CREDIT_VEHICLES_SLICE_NAME)),
  stageVehicle: payload =>
    dispatch(stageVehicleCreator(payload, CREDIT_VEHICLES_SLICE_NAME)),
  deleteVehicle: (orderId, vehicleId) =>
    dispatch(
      deleteVehicleCreator(orderId, vehicleId, CREDIT_VEHICLES_SLICE_NAME)
    ),
  deleteVehicles: orderId => dispatch(deleteVehiclesCreator(orderId, false)),
  toggleAllAreStagedForDeletion: () =>
    dispatch(toggleAllAreStagedForDeletionCreator(CREDIT_VEHICLES_SLICE_NAME)),
  changeCurrentPage: pageNum =>
    dispatch(changeCurrentPageCreator(pageNum, CREDIT_VEHICLES_SLICE_NAME)),
  changePageSize: pageSize =>
    dispatch(changePageSizeCreator(pageSize, CREDIT_VEHICLES_SLICE_NAME))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditVehiclesGridContainer);
