import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import {
  OTHER_REASON_NAME,
  VIN_CORRECTION_FORM_NAME
} from "../supportCenter.constants";
import userContext from "../../../modules/userContext";
import VinCorrectionForm from "./VinCorrectionForm";
import {
  createListDescription,
  mapSupportFieldsToPayload
} from "../supportCenter.utilities";
import { postSupportRequest as postSupportRequestCreator } from "../supportCenter.actions";
import { VIN_CORRECTION_SUBJECT } from "../../../modules/constants";

const {
  selectors: { getUserContext }
} = userContext;

class VinCorrectionFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  onSuccess = formValues => {
    const {
      props: { postSupportRequest }
    } = this;
    const payload = mapSupportFieldsToPayload(formValues);
    payload.Subject = VIN_CORRECTION_SUBJECT;
    payload.Description = createListDescription(
      formValues.corrections.map(x => ({
        "Mistyped VIN": x.OldVin,
        "Update this VIN to": x.NewVin,
        Explanation:
          x.CorrectionExplanation === OTHER_REASON_NAME
            ? x.OtherExplanation
            : x.CorrectionExplanation
      }))
    );

    postSupportRequest(payload);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  render() {
    const { handleSubmit, handleClickCancel } = this;

    return (
      <VinCorrectionForm
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
      />
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...getUserContext(state),
    corrections: [
      {
        OldVin: "",
        NewVin: "",
        CorrectionExplanation: ""
      }
    ]
  }
});

const mapDispatchToProps = dispatch => ({
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments, '/thank-you-vin-change'))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: VIN_CORRECTION_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(VinCorrectionFormContainer)
  )
);
