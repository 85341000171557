import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { Field } from "redux-form";
import "./textField.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import EventIcon from "@material-ui/icons/Event";

const renderDatePicker = ({
  value,
  input,
  meta,
  meta: { touched, error, active },
  className,
  needsTouch,
  ...custom
}) => (
  <DatePicker
    autoOk
    error={needsTouch ? touched && !!error : !!error}
    helperText={needsTouch ? touched && error : error}
    format="MM/dd/yyyy"
    inputVariant="outlined"
    InputLabelProps={{
      shrink:
        active ||
        (input.value !== "" &&
          input.value !== undefined &&
          input.value !== null)
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <EventIcon />
        </InputAdornment>
      )
    }}
    className={
      className
        ? `i2290-control i2290-text-input ${className}`
        : "i2290-control i2290-text-input"
    }
    {...input}
    {...custom}
    onBlur={null}
  />
);

const DatePickerWrapper = ({
  placeholder,
  label,
  name,
  validations,
  normalize,
  format,
  needsTouch,
  ...custom
}) => (
  <Field
    name={name}
    component={renderDatePicker}
    label={label}
    placeholder={placeholder}
    validate={validations}
    normalize={normalize}
    format={format}
    needsTouch={needsTouch}
    {...custom}
  />
);

DatePickerWrapper.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func,
  format: PropTypes.func,
  className: PropTypes.string,
  needsTouch: PropTypes.bool
};

DatePickerWrapper.defaultProps = {
  normalize: null,
  format: null,
  placeholder: "",
  validations: null,
  label: null,
  className: null,
  needsTouch: true
};

export default DatePickerWrapper;
