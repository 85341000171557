import React from "react";
import hoc from "../../hoc";
import controls from "../../../modules/controls";
import vehicles from "../../../modules/vehicles";
import { INVALID_CREDITS_ERROR_MESSAGE } from "../../../modules/constants";

const {
  hof: { withAccountFrame }
} = hoc;

const {
  components: { Button }
} = controls;

const {
  components: { CreditVehiclesAnimatorContainer: CreditVehiclesAnimator }
} = vehicles;

const EditCreditVehiclesScreen = ({
  onClickBack,
  invalidCreditsExist,
  showImporter,
  onClickEditVehicles,
  numberOfTaxableVehicles,
  toggleShowImporter,
  vehicleStaging
}) => (
  <div className="edit-credits-screen">
    <div className="row">
      <div className="col-12">
        <div className="mb-gutter widget-body rad-corners p-gutter vehicle-grid-width">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h2 className="mb-gutter mb-md-0">Add any credits to claim</h2>
            </div>
            <div className="col-12 col-md-auto text-nowrap d-flex justify-content-end">
              {!vehicleStaging && (
                <Button
                  className="d-inline"
                  onClick={onClickEditVehicles}
                  text={"Vehicles (" + numberOfTaxableVehicles + ")"}
                  disabled={false}
                  primary
                />
              )}
              {showImporter && (
                <Button
                  className="d-inline grid-button-spaced"
                  onClick={toggleShowImporter}
                  text="Back"
                  disabled={false}
                  secondary
                />
              )}
              {!vehicleStaging && (
                <Button
                  onClick={onClickBack}
                  text="Done"
                  className="d-inline grid-button-spaced"
                  disabled={false}
                  primary
                />
              )}
            </div>
            {invalidCreditsExist && (
              <div className="col-12 text-center mt-gutter">
                <span className="i2290-inline-error">
                  {INVALID_CREDITS_ERROR_MESSAGE}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12">
        <CreditVehiclesAnimator />
      </div>
    </div>
  </div>
);

export default withAccountFrame(EditCreditVehiclesScreen);
