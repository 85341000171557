import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormWidget from "./FormWidget";
import { getWidgetIsExpandedHof } from "../form.selectors";

class FormWidgetContainer extends Component {
  render() {
    const {
      props: { formId, isExpanded }
    } = this;
    return <FormWidget formId={formId} isExpanded={isExpanded} />;
  }
}

FormWidgetContainer.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  formId: PropTypes.number.isRequired
};

const mapStateToProps = (state, props) => ({
  isExpanded: getWidgetIsExpandedHof(props.formId)(state)
});

const ConnectedFormWidgetContainer = connect(mapStateToProps)(
  FormWidgetContainer
);

ConnectedFormWidgetContainer.propTypes = {
  formId: PropTypes.number.isRequired
};

export default ConnectedFormWidgetContainer;
