import React from "react";
import PropTypes from "prop-types";
import "./vehiclesAnimator.css";
import VehicleGrid from "./VehicleGridContainer";
import VehicleImporter from "./VehicleImporterContainer";

// TODO: change name, no longer an "animator"
const VehiclesAnimator = ({ height, showImporter, toggleShowImporter }) => (
  <div className="vehicles-animator vehicle-grid-width">
    {!showImporter && (
      <VehicleGrid onClickImport={toggleShowImporter} height={height} />
    )}
    {showImporter && <VehicleImporter onClickCancel={toggleShowImporter} />}
  </div>
);

VehiclesAnimator.propTypes = {
  showImporter: PropTypes.bool.isRequired,
  toggleShowImporter: PropTypes.func.isRequired,
  creditsOrVehiclesFromUrl: PropTypes.bool,
  setRedirect: PropTypes.func
};

export default VehiclesAnimator;
