import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, getFormSyncErrors } from "redux-form";
import utilities from "../../utilities";
import DesigneeFormWidget from "./DesigneeFormWidget";
import order from "../../order";
import { FORM_NAME } from "../designee.constants";
import controls from "../../controls";
import { saveOnBlur } from "../../userContext/userContext.actions";
import { getFieldOnTab } from "../../userContext/userContext.selectors";

const {
  getOrderIdFromUrl,
  transform: { sanitizeValue }
} = utilities;

const {
  validations: { validateDesigneeForm }
} = controls;

const {
  actions: { patchOrder: patchOrderCreator },
  selectors: { getOrderHOF, getThirdPyesNoHOF }
} = order;

class DesigneeFormWidgetContainer extends Component {
  componentDidUpdate() {
    const { initialValues, touch } = this.props;
    if (initialValues) {
      touch(...Object.keys(initialValues));
    }
  }

  handleChange = (e, newVal) => {
    const {
      props: { patchOrder }
    } = this;
    e.preventDefault();
    patchOrder(getOrderIdFromUrl(), { ThirdPyesNo: newVal });
  };

  handleBlur = e => {
    const { initialValues, saveOnBlur, patchOrder } = this.props;

    if (e.nativeEvent && e.nativeEvent.relatedTarget)
      saveOnBlur(e.nativeEvent.relatedTarget.getAttribute("name"));

    if (!e.target.value && !initialValues[e.target.name]) {
      return;
    }

    if (e.target.value === initialValues[e.target.name]) {
      return;
    }

    // We are updating fields on blur. With invalid values.
    /* if (
      formSyncErrors &&
      (formSyncErrors[e.target.name] !== undefined ||
        formSyncErrors.Email !== undefined)
    ) {
      return;
    } */

    patchOrder(getOrderIdFromUrl(), {
      [e.target.name]: sanitizeValue(e.target.value)
    });
  };

  render() {
    const orderId = getOrderIdFromUrl();
    const {
      handleChange,
      handleBlur,
      props: { isExpanded, radCorners }
    } = this;
    return (
      <DesigneeFormWidget
        onChange={handleChange}
        isExpanded={isExpanded}
        orderId={orderId}
        onBlur={handleBlur}
        radCorners={radCorners}
      />
    );
  }
}

DesigneeFormWidgetContainer.propTypes = {
  patchOrder: PropTypes.func.isRequired,
  formSyncErrors: PropTypes.object,
  isExpanded: PropTypes.bool.isRequired,
  radCorners: PropTypes.bool
};

DesigneeFormWidgetContainer.defaultProps = {
  radCorners: true
};

const mapStateToProps = state => {
  const { ThirdPname, ThirdPphone, ThirdPpin, ThirdPyesNo } = {
    ...(getOrderHOF(getOrderIdFromUrl())(state) || {}),
    ThirdPyesNo: getThirdPyesNoHOF(getOrderIdFromUrl())(state)
  };

  const initialValues = {
    ThirdPname: ThirdPname || "",
    ThirdPphone: ThirdPphone || "",
    ThirdPpin: ThirdPpin || "",
    ThirdPyesNo: ThirdPyesNo ? true : false
  };

  return {
    formValues: getFormValues(FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
    initialValues,
    isExpanded: ThirdPyesNo,
    fieldOnTab: getFieldOnTab(state)
  };
};

const mapDispatchToProps = dispatch => ({
  patchOrder: (id, data) => dispatch(patchOrderCreator(id, data)),
  saveOnBlur: fieldOnTab => dispatch(saveOnBlur(fieldOnTab))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    // forceUnregisterOnUnmount: true,
    keepDirtyOnReinitialize: true,
    // touchOnBlur: false
    validate: validateDesigneeForm
  })(DesigneeFormWidgetContainer)
);
