import * as actionTypes from "./cart.actionTypes";
import doAsync from "../doAsync";
import utilities from "../utilities";

const {
  transform: { arrayToMap },
  math: { roundTo }
} = utilities;

export const toggleIsExpanded = () => ({
  type: actionTypes.TOGGLE_WIDGET_IS_EXPANDED
});

export const changePromoCode = val => ({
  type: actionTypes.CHANGE_PROMO_CODE,
  payload: val
});

export const changeIsUnlimited = val => ({
  type: actionTypes.CHANGE_IS_UNLIMITED,
  payload: val
});

export const toggleIsUnlimited = () => ({
  type: actionTypes.TOGGLE_IS_UNLIMITED
});

export const clearPromo = () => ({
  type: actionTypes.CLEAR_PROMO
});

export const fetchProducts = () =>
  doAsync({
    actionType: actionTypes.FETCH_PRODUCTS_ASYNC,
    url: "products",
    httpMethod: "get",
    mapResponseToPayload: r => arrayToMap(r, "Sku"),
    noBusySpinner: false
  });

export const fetchPromo = code =>
  doAsync({
    actionType: actionTypes.FETCH_PROMO,
    url: `promotion/${code}`,
    httpMethod: "get",
    mapResponseToPayload: r => ({ code, amount: roundTo(r.DollarValue, 2) }),
    noBusySpinner: false
  });
