import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormSummaryPanel from "./FormSummaryPanel";
import order from "../../../modules/order";
import { getHistoryOrderHOF } from "modules/order/order.selectors";
import { postSendScheduleOneEmail as postSendScheduleOneEmailCreator } from "modules/order/order.actions";
import { getUserEmail } from "modules/userContext/userContext.selectors";

const {
  selectors: { getHistoryFormHOF, getScheduleOneStagedForDownload },
  actions: {
    setIrsNotRespAcknowledgement: setIrsNotRespAcknowledgementCreator,
    fetchScheduleOneUri: fetchScheduleOneUriCreator
  }
} = order;

class FormSummaryPanelContainer extends Component {
  handleClickEdit = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/form?order=${orderId}`);
  };

  handleClickView = () => {
    const {
      props: {
        orderId,
        formId,
        history: { push }
      }
    } = this;
    push(`/form-report?order=${orderId}&form=${formId}`);
  };

  handleClickMore = () => {
    const {
      props: { setIrsNotRespAcknowledgement, form }
    } = this;
    setIrsNotRespAcknowledgement(form.NoIrsResponsePageContent);
  };

  handleClickSchedule1 = () => {
    const {
      props: { form, fetchScheduleOneUri }
    } = this;
    fetchScheduleOneUri(form.StampedScheduleOne);
  };

  handleClickSubmitTicket = () => {
    const {
      props: {
        orderId,
        history: { push }
      }
    } = this;
    push(`/irs-rejected?order=${orderId}`);
  };

  handleOkEmailSchedule1 = (emails) => {
    this.props.postSendScheduleOneEmail(this.props.formId, emails.split(","));    
  }

  render() {
    const {
      handleClickEdit,
      handleClickView,
      handleClickSchedule1,
      handleClickSubmitTicket,
      handleClickMore,
      handleScheduleOneStagedForDownload,
      handleOkEmailSchedule1,
      props: { taxYear, formId, orderId, form, scheduleOneStagedForDownload, email }
    } = this;

    let dateCategory = null;
    let numberOfVins = null;
    let submissionStatus = null;
    let submissionStatusLabel = null;
    let hasSchedule1 = false;
    let netTax = null;
    let hasCredits = false;
    let eFileSubmissionDate = null;
    let irsNotRespondingMessage = null;

    if (form) {
      if (form.SubmissionDate) {
        eFileSubmissionDate = new Date(form.SubmissionDate).toLocaleString();
      }
      if (form.DateCategory !== null) dateCategory = form.DateCategory;
      numberOfVins = form.NumberOfVehicles;
      submissionStatus = form.SubmissionStatus || null;
      submissionStatusLabel = form.StatusDescription || null;
      irsNotRespondingMessage = form.NoIrsResponsePageContent || null;
      hasSchedule1 = form.StampedScheduleOne !== null;
      if (form.TotalCredit !== null && form.TotalCredit > 0) {
        hasCredits = true;
      }
      if (form.TotalTax !== null && form.TotalCredit !== null) {
        netTax = form.TotalTax - form.TotalCredit;
      }
    }

    return (
      <FormSummaryPanel
        taxYear={taxYear}
        email={email}
        scheduleOneStagedForDownload={scheduleOneStagedForDownload}
        handleScheduleOneStagedForDownload={handleScheduleOneStagedForDownload}
        irsNotRespondingMessage={irsNotRespondingMessage}
        onClickMore={handleClickMore}
        eFileSubmissionDate={eFileSubmissionDate}
        netTax={netTax}
        hasCredits={hasCredits}
        hasSchedule1={hasSchedule1}
        statusLabel={submissionStatusLabel}
        formId={formId}
        orderId={orderId}
        dateCategory={dateCategory}
        numberOfVins={numberOfVins}
        status={submissionStatus}
        onClickEdit={handleClickEdit}
        onClickView={handleClickView}
        onClickSchedule1={handleClickSchedule1}
        onClickSubmitTicket={handleClickSubmitTicket}
        onOkEmailSchedule1={handleOkEmailSchedule1}
      />
    );
  }
}

FormSummaryPanelContainer.propTypes = {
  orderId: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  form: PropTypes.object,
  setIrsNotRespAcknowledgement: PropTypes.func.isRequired,
  fetchScheduleOneUri: PropTypes.func.isRequired
};

FormSummaryPanelContainer.propTypes = {
  form: null
};

const mapStateToProps = (state, props) => ({
  taxYear: getHistoryOrderHOF(props.orderId)(state)?.TaxYear,
  form: getHistoryFormHOF(props.formId)(state),
  scheduleOneStagedForDownload: getScheduleOneStagedForDownload(state),
  email: getUserEmail(state)
});

const mapDispatchToProps = dispatch => ({
  postSendScheduleOneEmail: (formId, emails) => dispatch(postSendScheduleOneEmailCreator(formId, emails)),
  setIrsNotRespAcknowledgement: prompt =>
    dispatch(setIrsNotRespAcknowledgementCreator(prompt)),
  fetchScheduleOneUri: filename =>
    dispatch(fetchScheduleOneUriCreator(filename))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormSummaryPanelContainer)
);
