import CreditVehiclesGrid from "./CreditVehiclesGridContainer";
import CreditVehiclesImporter from "./CreditVehiclesImporterContainer";
import hoc from "../../hoc";

const {
  hof: { withSlideDownAnimator }
} = hoc;

const CreditVehiclesAnimator = withSlideDownAnimator(
  CreditVehiclesGrid,
  CreditVehiclesImporter
);

export default CreditVehiclesAnimator;
