import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import { CONTACT_SUPPORT_FORM_NAME } from "../supportCenter.constants";
import {
  setAttachments,
  toggleAttachmentsIsExpanded as toggleAttachmentsIsExpandedCreator,
  setAttachmentsIsExpanded as setAttachmentsIsExpandedCreator,
  postSupportRequest as postSupportRequestCreator
} from "../supportCenter.actions";
import {
  getAttachments,
  getAttachmentsIsExpanded
} from "../supportCenter.selectors";
import userContext from "../../../modules/userContext";
import controls from "../../../modules/controls";
import ContactSupportForm from "./ContactSupportForm";
import { mapSupportFieldsToPayload } from "../supportCenter.utilities";

const {
  selectors: { getUserContext }
} = userContext;

const {
  validations: { validateRequired }
} = controls;

class ContactSupportFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  componentWillUnmount() {
    const {
      props: { setAttachmentsIsExpanded }
    } = this;
    setAttachmentsIsExpanded(false);
  }

  onSuccess = formValues => {
    const {
      props: { attachments, postSupportRequest }
    } = this;
    const payload = mapSupportFieldsToPayload(formValues);
    payload.Subject = formValues.Subject || "";
    payload.Description = formValues.Body || "";
    postSupportRequest(payload, attachments);
  };

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/support-center");
  };

  handleClickAttach = () => {
    const {
      props: { toggleAttachmentsIsExpanded }
    } = this;
    toggleAttachmentsIsExpanded();
  };

  render() {
    const {
      handleSubmit,
      handleClickCancel,
      handleClickAttach,
      props: { attachmentsIsExpanded }
    } = this;
    return (
      <ContactSupportForm
        onClickAttach={handleClickAttach}
        attachmentsIsExpanded={attachmentsIsExpanded}
        getAttachments={getAttachments}
        setAttachments={setAttachments}
        onSubmit={handleSubmit}
        onClickCancel={handleClickCancel}
        subjectValidations={[validateRequired]}
      />
    );
  }
}

ContactSupportFormContainer.propTypes = {
  attachments: PropTypes.array.isRequired,
  attachmentsIsExpanded: PropTypes.bool.isRequired,
  toggleAttachmentsIsExpanded: PropTypes.func.isRequired,
  postSupportRequest: PropTypes.func.isRequired,
  setAttachmentsIsExpanded: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  attachments: getAttachments(state),
  attachmentsIsExpanded: getAttachmentsIsExpanded(state),
  initialValues: {
    ...getUserContext(state),
    Mobile: "",
    Fax: "",
    Subject: "",
    Body: ""
  }
});

const mapDispatchToProps = dispatch => ({
  toggleAttachmentsIsExpanded: () =>
    dispatch(toggleAttachmentsIsExpandedCreator()),
  setAttachmentsIsExpanded: isExpanded =>
    dispatch(setAttachmentsIsExpandedCreator(isExpanded)),
  postSupportRequest: (fields = [], attachments = []) =>
    dispatch(postSupportRequestCreator(fields, attachments))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: CONTACT_SUPPORT_FORM_NAME,
      destroyOnUnmount: true,
      keepDirtyOnReinitialize: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(ContactSupportFormContainer)
  )
);
