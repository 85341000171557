import TaxYearDropdown from "./TaxYearDropdown";
import TaxYearDropdownContainer from "./TaxYearDropdownContainer";
import SubmissionWidget from "./SubmissionWidget";
import SubmissionWidgetContainer from "./SubmissionWidgetContainer";
import PaymentMethodForm from "./PaymentMethodForm";
import PaymentMethodWidget from "./PaymentMethodWidget";
import PaymentMethodWidgetContainer from "./PaymentMethodWidgetContainer";
import AckCopiedVehiclesModal from "./AckCopiedVehiclesModal";
import AckCopiedVehiclesModalContainer from "./AckCopiedVehiclesModalContainer";

export default {
  TaxYearDropdown,
  TaxYearDropdownContainer,
  SubmissionWidget,
  SubmissionWidgetContainer,
  PaymentMethodForm,
  PaymentMethodWidget,
  PaymentMethodWidgetContainer,
  AckCopiedVehiclesModal,
  AckCopiedVehiclesModalContainer
};
