import React, { Fragment } from "react";
import PropTypes from "prop-types";
import screenHoc from "../../hoc";
import moduleHoc from "../../../modules/hoc";
import widgets from "../../../modules/widgets";
import controls from "../../../modules/controls";
import LinkWidget from "./LinkWidget";
import SupportHero from "./SupportHeroContainer";
import FaqWidgetHeader from "./FaqWidgetHeaderContainer";
import FaqWidgetBody from "./FaqWidgetBody";
import "./supportCenterScreen.css";
import { getFaqIsExpandedHOF } from "../supportCenter.selectors";

const {
  hof: { withAccountFrame }
} = screenHoc;

const {
  hof: { bindComponent }
} = moduleHoc;

const {
  hof: { withSummaryWidget }
} = widgets;

const {
  components: { Button }
} = controls;

const FaqA = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqAIsExpanded",
      text:
        "If I call INSTANT 2290 Customer Support do I get to talk to a real person?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            "Yes! We are staffed with IRS Form 2290 e-file specialists who will
            answer the phones from 9 a.m. to 6 p.m. Monday through Friday, CT
            except for holidays. If call volumes are heavy, just place your spot
            in the queue and we'll call you back the same day (usually within
            the hour)."
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqAIsExpanded")
);

const FaqB = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqBIsExpanded",
      text: "Which tax year should I select?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            IRS Form 2290 and the Heavy Vehicle Use Tax is unique in two ways:
            <ul>
              <li>It is paid in advance</li>
              <li>The tax year renews every July 1</li>
            </ul>
          </li>
          <li>
            <strong>Pro tip:</strong> Think "I want to be paid through next
            June" and select the tax year accordingly.
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqBIsExpanded")
);

const FaqC = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqCIsExpanded",
      text: "What weight category do I select for my truck?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            <strong>Pro tip:</strong> Think about the maximum weight you are
            allowed on a scale according to your registration. The most common
            truck e-filed at i2290.com is the 18-wheeler, registered for 80,000
            pounds.
          </li>
          <li>
            Thousands of lighter trucks are also e-filed at i2290.com and the
            maximum Gross Vehicle Weight on your registration is usually the
            correct weight. (For IRP, use the state's highest gross weight.)
          </li>
          <li>
            In some cases, determining your GVW can get tricky, so refer to{" "}
            <a
              href="https://www.irs.gov/instructions/i2290/ch02.html#d0e630"
              target="_blank"
              rel="noopener noreferrer"
            >
              these IRS instructions
            </a>
            .{" "}
          </li>
          <li>
            If you are still unsure-or if you filed the wrong weight, contact
            Customer Support for help.
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqCIsExpanded")
);

const FaqD = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqDIsExpanded",
      text: "When is the tax due for my vehicle? When should I file?"
    },
    FaqWidgetHeader
  ),
  ({ currentTaxYear }) => {
    const currentTaxYearPlusOne = parseInt(currentTaxYear, 10) + 1;
    return (
      <FaqWidgetBody>
        <ul>
          <li>For the tax year beginning July {currentTaxYear}:</li>
          <table
            className="table table-bordered"
            style={{ textAlign: "center", maxWidth: "800px" }}
          >
            <tbody>
              <tr>
                <th>If the vehicle is first used:</th>
                <th>Then IRS Form 2290 is due:</th>
              </tr>
              <tr>
                <td>July, {currentTaxYear}</td>
                <td>August 31, {currentTaxYear}</td>
              </tr>
              <tr>
                <td>August, {currentTaxYear}</td>
                <td>October 1, {currentTaxYear}</td>
              </tr>
              <tr>
                <td>September, {currentTaxYear}</td>
                <td>October 31, {currentTaxYear}</td>
              </tr>
              <tr>
                <td>October {currentTaxYear}</td>
                <td>November 30, {currentTaxYear}</td>
              </tr>
              <tr>
                <td>November, {currentTaxYear}</td>
                <td>December 31, {currentTaxYear}</td>
              </tr>
              <tr>
                <td>December, {currentTaxYear}</td>
                <td>January 31, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>January, {currentTaxYearPlusOne}</td>
                <td>February 28, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>February, {currentTaxYearPlusOne}</td>
                <td>April 1, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>March, {currentTaxYearPlusOne}</td>
                <td>April 30, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>April, {currentTaxYearPlusOne}</td>
                <td>May 31, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>May, {currentTaxYearPlusOne}</td>
                <td>July 1, {currentTaxYearPlusOne}</td>
              </tr>
              <tr>
                <td>June, {currentTaxYearPlusOne}</td>
                <td>July 31, {currentTaxYearPlusOne}</td>
              </tr>
            </tbody>
          </table>
          <li>
            To avoid penalties, file by this date regardless of when state
            registration for the vehicle is due.
          </li>
        </ul>
      </FaqWidgetBody>
    );
  },
  getFaqIsExpandedHOF("faqDIsExpanded")
);

const FaqE = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqEIsExpanded",
      text: "Do I have to file using my EIN, or can I use my SSN instead?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            {" "}
            Regardless of how you file your Form 2290, the IRS requires a
            business tax ID called an Employer Identification Number (EIN).
            <ul>
              <li>
                If you do not know your EIN or you are unsure whether you have
                one, call the IRS Business Support line at 800-829-4933.{" "}
              </li>
              <li>
                If you need an EIN, you can{" "}
                <a
                  href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  get an EIN online here
                </a>
                . Pro tip: Print or save the final confirmation page; it is very
                useful and the IRS will not provide a copy later.
              </li>
            </ul>
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqEIsExpanded")
);

const FaqF = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqFIsExpanded",
      text: "What are the different ways I can complete a return?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            <strong>E-file:</strong> E-file online using an IRS-authorized
            provider like i2290.com. This is the most convenient way to file. It
            is faster, simpler, and you can do it from your living room in your
            bathrobe. Good e-file providers save your information from the prior
            year to make it super easy to file.
          </li>
          <li>
            <strong>By mail:</strong> Download and complete the{" "}
            <a
              href="https://www.irs.gov/pub/irs-pdf/f2290.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              IRS Form 2290 Fill-In PDF
            </a>{" "}
            and mail it with a check for payment (if you owe taxes) to the
            address shown on the form
          </li>
          <li>
            <strong>In person:</strong> Download and complete the{" "}
            <a
              href="https://www.irs.gov/pub/irs-pdf/f2290.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              IRS Form 2290 Fill-In PDF
            </a>{" "}
            and take it to an IRS TAC (Taxpayer Assistance Center):
            <ul>
              <li>
                <strong>Pro tip:</strong> Before you drive to the IRS office:
                <ul>
                  <li>Be sure that location provides service for Form 2290</li>
                  <li>
                    Set an appointment in advance (beginning August 2016, the
                    IRS is scheduled to turn away walk-in traffic)
                  </li>
                  <li>Take your checkbook</li>
                  <li>Know your VIN number(s)</li>
                  <li>Know your Taxable Gross Vehicle Weight(s)</li>
                  <li>
                    Know your EIN and Business Name as the IRS has them listed
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqFIsExpanded")
);

const FaqG = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqGIsExpanded",
      text: "Do I still need to file Form 2290 if my vehicles are tax-exempt?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            For exempt filers, the Form 2290 is not only about payment-it is a
            statement that you agree you will not drive your vehicle(s) more
            than 5,000 miles (or 7,500 miles for farming vehicles) during the
            tax year. Your state will still require you to show proof you filed
            the Form 2290, even if your total tax is $0.
          </li>
          <li>
            To e-file Form 2290 for an exempt vehicle, indicate your vehicle is
            "Suspended" in Step 2 of 7 while preparing your return. This applies
            a tax of $0 and shows a weight category of "W" on your Schedule 1.
            Most commercial vehicles drive much more than 5,000/7,500 miles on
            public highways.
          </li>
          <li>
            <strong>Pro tip:</strong> If you think you may qualify for Suspended
            vehicle status, contact the IRS 2290 Assistors at 866-699-4096
            (options 1, 4, 2) to be certain. There may be penalties for filing
            as Suspended if it is later determined you do not qualify.
          </li>
        </ul>
      )
    },
    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqGIsExpanded")
);

const FaqH = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqHIsExpanded",
      text:
        "Can I use the prior tax year's Schedule 1 during July, August, and September?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            <p>Yes. The IRS Form 2290 instructions say:</p>
            <p>
              If the state receives your application for registration of your
              highway motor vehicle during the months of July, August, or
              September, you may provide the immediately previous taxable
              period's approved Schedule 1 that was returned to you by the IRS,
              as proof of payment. Remember to file Form 2290 for the current
              period by the due date of the return...No proof of payment is
              required for a newly purchased vehicle, if you present the state a
              copy of the bill of sale showing that the vehicle was purchased
              within the last 60 days. However, you must file a return and pay
              any tax due.
            </p>
          </li>
        </ul>
      )
    },

    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqHIsExpanded")
);

const FaqI = withSummaryWidget(
  bindComponent(
    {
      faqName: "faqIIsExpanded",
      text: "What is a Third-Party Designee?"
    },
    FaqWidgetHeader
  ),
  bindComponent(
    {
      children: (
        <ul>
          <li>
            <p>
              In order for you to e-file at i2290.com, Step 5 of 7 asks whether
              you would like to "name a person to represent you with the IRS on
              this individual filing." This is what the IRS Form 2290
              instructions say about this option:{" "}
            </p>
            <p>
              If you want to allow an employee of your business, a return
              preparer, or other third party to discuss your Form 2290 with the
              IRS, check the "Yes" box in the Third Party Designee section of
              Form 2290. Also, enter the designee's name, phone number, and any
              five digits that person chooses as his or her personal
              identification number (PIN). The authorization applies only to the
              tax return on which it appears.
            </p>
            <p>
              By checking the "Yes" box, you are authorizing the IRS to speak
              with the designee to answer any questions relating to the
              information reported on Form 2290. You are also authorizing the
              designee to:
              <ul>
                <li>
                  Exchange information concerning Form 2290 with the IRS, and
                </li>
                <li>
                  Request and receive written tax return information relating to
                  Form 2290, including copies of notices, correspondence, and
                  account transcripts.
                </li>
                <li>
                  You are not authorizing the designee to bind you to anything
                  (including additional tax liability) or otherwise represent
                  you before the IRS. If you want to expand the designee's
                  authority, see Pub. 947, Practice Before the IRS and Power of
                  Attorney.
                </li>
                <li>
                  The authorization will automatically expire 1 year from the
                  due date (without regard to extensions) for filing your Form
                  2290. If you or your designee wants to revoke this
                  authorization, send a written statement of revocation to:
                  <ul style={{ listStyle: "none" }}>
                    <li>
                      <p>Department of the Treasury</p>
                      <p>Internal Revenue Service</p>
                      <p>Cincinnati, OH 45999</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </li>
        </ul>
      )
    },

    FaqWidgetBody
  ),
  getFaqIsExpandedHOF("faqIIsExpanded")
);

const SupportCenterScreen = ({
  onClickIrsRejectedReturn,
  onClickNoticeOrLetter,
  onClickMailScheduleOne,
  onClickBulkPricing,
  onClickVinCorrection,
  onClickAbout2290,
  onClickTruckingTaxCenter,
  onClickEinApplication,
  onClickFaqForTruckers,
  onClick2290Instructions,
  onClickFileByPhone,
  onClick2290,
  onClick4900,
  isLoggedIn,
  currentTaxYear
}) => (
  <div className="support-center">
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <SupportHero />
      </div>
    </div>
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <div className="widget-body rad-corners mb-gutter">
          <section className="support-forms">
            <h2 className="mb-gutter">Support Request Forms</h2>
            <div className="support-form-link-menu row">
              {isLoggedIn && (
                <Fragment>
                  <div className="col-12 col-md-6 col-xl-4 clean-gutter-md-right">
                    <LinkWidget
                      linkText="IRS Rejected Return"
                      onClickLink={onClickIrsRejectedReturn}
                      bodyText="Have a Form 2290 Specialist assist you in correcting your return"
                      iconClassName="fa fa-flag"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 clean-gutter-xl-right clean-gutter-md-left">
                    <LinkWidget
                      linkText="IRS Notice or Letter"
                      onClickLink={onClickNoticeOrLetter}
                      bodyText="Have a Form 2290 Specialist review and provide advice on what you should do next"
                      iconClassName="fa fa-file-alt"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 clean-gutter-xl-left clean-gutter-md-right gutter-xl-right">
                    <LinkWidget
                      linkText="Schedule 1 by Mail"
                      onClickLink={onClickMailScheduleOne}
                      bodyText="Have a paper copy of your IRS-stamped Schedule 1 sent to the address of your choice"
                      iconClassName="fa fa-envelope"
                    />
                  </div>
                </Fragment>
              )}
              <div
                className={
                  isLoggedIn
                    ? "col-12 col-md-6 col-xl-4 clean-gutter-md-left clean-gutter-xl-right"
                    : "col-12 col-md-6 clean-gutter-md-right clean-gutter-xl-right"
                }
              >
                <LinkWidget
                  linkText="Bulk Pricing"
                  onClickLink={onClickBulkPricing}
                  bodyText="Submit request for bulk pricing options"
                  iconClassName="fa fa-shopping-cart"
                />
              </div>
              <div
                className={
                  isLoggedIn
                    ? "col-12 col-md-6 col-xl-4 clean-gutter-xl-left clean-gutter-md-right"
                    : "col-12 col-md-6 clean-gutter-xl-left clean-gutter-md-right"
                }
              >
                <LinkWidget
                  linkText="VIN Correction"
                  onClickLink={onClickVinCorrection}
                  bodyText="Submit a VIN correction support request"
                  iconClassName="fa fa-truck"
                />
              </div>
              <div className={
                  isLoggedIn
                    ? "col-12 col-md-6 col-xl-4 clean-gutter-xl-left clean-gutter-md-right"
                    : "col-12 col-md-6 clean-gutter-xl-left clean-gutter-md-right"
                }>
                    <LinkWidget
                      linkText="File By Phone"
                      onClickLink={onClickFileByPhone}
                      bodyText="Submit a request to file your 2290 over the phone"
                      iconClassName="fa fa-phone"
                    />
                  </div>
            </div>
          </section>
          <section className="faq-section">
            <h2 className="mb-gutter">Common Questions</h2>
            <FaqA />
            <FaqB />
            <FaqC />
            <FaqD currentTaxYear={currentTaxYear} />
            <FaqE />
            <FaqF />
            <FaqG />
            <FaqH />
            <FaqI />
          </section>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <section className="irs-links widget-background drop-shadow p-gutter rad-corners">
          <h2>Links to IRS pages</h2>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-3 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS About Form 2290"
                onClick={onClickAbout2290}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS Trucking Tax Center"
                onClick={onClickTruckingTaxCenter}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS EIN Application"
                onClick={onClickEinApplication}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-3 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS FAQ for Truckers who E-file"
                onClick={onClickFaqForTruckers}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="col-md-10 offset-md-1">
        <section className="downloads-section widget-background drop-shadow p-gutter rad-corners">
          <h2>Downloads</h2>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-4 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS Instructions for Form 2290"
                onClick={onClick2290Instructions}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS Form 2290"
                onClick={onClick2290}
              />
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mb-gutter">
              <Button
                width="100%"
                disabled={false}
                secondary
                text="IRS Publication 4900 (HVUT Brochure)"
                onClick={onClick4900}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
);

const SupportCenterScreenWithAccountFrame = withAccountFrame(
  SupportCenterScreen
);

SupportCenterScreenWithAccountFrame.propTypes = {
  onClickIrsRejectedReturn: PropTypes.func.isRequired,
  onClickNoticeOrLetter: PropTypes.func.isRequired,
  onClickMailScheduleOne: PropTypes.func.isRequired,
  onClickBulkPricing: PropTypes.func.isRequired,
  onClickVinCorrection: PropTypes.func.isRequired,
  onClickAbout2290: PropTypes.func.isRequired,
  onClickTruckingTaxCenter: PropTypes.func.isRequired,
  onClickEinApplication: PropTypes.func.isRequired,
  onClickFaqForTruckers: PropTypes.func.isRequired,
  onClick2290Instructions: PropTypes.func.isRequired,
  onClick2290: PropTypes.func.isRequired,
  onClick4900: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

export default SupportCenterScreenWithAccountFrame;
