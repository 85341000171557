import React from "react";
import PropTypes from "prop-types";
import { Button as KendoButton } from "@progress/kendo-react-buttons";
import "./button.css";

const Button = ({
  icon,
  onClick,
  disabled,
  text,
  primary,
  type,
  height,
  width,
  className,
  textClass,
  children
}) => {
  let classNameString = primary
    ? "i2290-btn i2290-btn-primary"
    : "i2290-btn i2290-btn-secondary";
  if (className) {
    classNameString += ` ${className}`;
  }
  return (
    <KendoButton
      icon={icon}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNameString}
      style={{ height, width }}
    >
      {textClass ? <span className={textClass}>{text}</span> : text}
      {children}
    </KendoButton>
  );
};

Button.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
};

Button.defaultProps = {
  icon: null,
  className: null,
  type: "button"
};

export default Button;
