import * as React from "react";
import { LOGO_URI } from "../../constants";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  };

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  handleClickBackToOrders = () => {
    window.location.href = "/order-history";
  };

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    const {
      handleClickBackToOrders,
      state: { hasError },
      props: { children }
    } = this;
    if (hasError) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw"
          }}
        >
          <h1 style={{ textAlign: "center", fontSize: "24px" }}>
            <div style={{ width: "100%" }}>
              <img
                src={LOGO_URI}
                alt="INSTANT i2290"
                style={{ height: "80px" }}
              />
            </div>
            <span style={{ display: "inline-block", marginRight: "10px" }}>
              {"Something went wrong. "}
            </span>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
                whiteSpace: "nowrap",
                display: "inline-block"
              }}
              onClick={handleClickBackToOrders}
            >
              Back to orders
            </span>
          </h1>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
