import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from "redux-form";
import { FORM_NAME } from "../weightIncrease.constants";
import { getEntities, getRedirect } from "../weightIncrease.selectors";
import { postWeightIncrease as postWeightIncreaseCreator } from "../weightIncrease.actions";
import WeightIncreaseForm from "./WeightIncreaseForm";
import { ORDER_HISTORY_ROUTE } from "../../../modules/constants";
import { getCurrentTaxYear } from "../../../modules/order/order.selectors";

class WeightIncreaseFormContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  handleClickCancel = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push(ORDER_HISTORY_ROUTE);
  };

  onSuccess = formValues => {
    const {
      props: { postWeightIncrease }
    } = this;
    const payload = {
      DateCategory: parseInt(formValues.IncreasedDateCategory, 10),
      WeightCategory: formValues.Vehicle.WeightCategory,
      IncreasedWeightCategory: formValues.IncreasedWeightCategory,
      Id: formValues.Vehicle.Id
    };
    postWeightIncrease(payload);
  };

  render() {
    const {
      handleSubmit,
      handleClickCancel,
      props: { currentTaxYear, formValues, weightIncreaseVehicles, redirect }
    } = this;

    let selectedVehicle = null;
    if (formValues.Vehicle) selectedVehicle = formValues.Vehicle;
    return (
      <WeightIncreaseForm
        onClickCancel={handleClickCancel}
        currentTaxYear={currentTaxYear}
        redirect={redirect}
        weightIncreaseVehicles={weightIncreaseVehicles}
        onSubmit={handleSubmit}
        selectedVehicle={selectedVehicle}
      />
    );
  }
}

WeightIncreaseFormContainer.propTypes = {
  formValues: PropTypes.object
};

WeightIncreaseFormContainer.defaultProps = {
  formValues: {}
};

const mapStateToProps = state => {
  const entities = getEntities(state);
  const currentTaxYear = getCurrentTaxYear(state);
  return {
    currentTaxYear: currentTaxYear
      ? parseInt(currentTaxYear, 10)
      : currentTaxYear,
    redirect: getRedirect(state),
    formValues: getFormValues(FORM_NAME)(state),
    weightIncreaseVehicles: Object.keys(entities)
      .map(k => ({
        ...entities[k],
        TaxYear: parseInt(entities[k].TaxYear, 10)
      }))
      .sort((a, b) => {
        if (a.TaxYear === b.TaxYear) return 0;
        return a.TaxYear > b.TaxYear ? -1 : 1;
      })
  };
};

const mapDispatchToProps = dispatch => ({
  postWeightIncrease: weightIncrease =>
    dispatch(postWeightIncreaseCreator(weightIncrease))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: FORM_NAME,
      destroyOnUnmount: false,
      initialValues: {
        Vehicle: null,
        IncreasedWeightCategory: "",
        IncreasedDateCategory: ""
      },
      keepDirtyOnReinitialize: false,
      // forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(WeightIncreaseFormContainer)
  )
);
