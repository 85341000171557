import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import userContext from "../../../modules/userContext";
import SupportFormScreen from "./SupportFormScreen";
import { getRedirect } from "../supportCenter.selectors";
import { setRedirect as setRedirectCreator } from "../supportCenter.actions";

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

class SupportFormScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchUser }
    } = this;
    fetchUser();
  }

  componentWillUnmount() {
    const {
      props: { setRedirect }
    } = this;
    setRedirect(null);
  }

  render() {
    const {
      props: { children, redirect }
    } = this;
    return (
      <React.Fragment>
        {redirect && <Redirect to={redirect} />}
        <SupportFormScreen>{children}</SupportFormScreen>
      </React.Fragment>
    );
  }
}

SupportFormScreenContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  setRedirect: PropTypes.func.isRequired,
  redirect: PropTypes.string
};

SupportFormScreenContainer.defaultProps = {
  redirect: null
};

const mapStateToProps = state => ({
  redirect: getRedirect(state)
});

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator()),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportFormScreenContainer);
