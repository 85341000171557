import React from "react";
import PropTypes from "prop-types";
import "./footer.css";
import controls from "../../../modules/controls";

const {
  components: { Link }
} = controls;

const Footer = ({ onClickTermsConditions, onClickPrivacyPolicy }) => (
  <div className="footer rad-corners">
    <div className="row">
      <div className="col-12 col-md-6">
        <img
          alt="logo1"
          src="https://s3.amazonaws.com/i2290/efile.logo.gry.min.png"
        />
        <img
          alt="logo-2"
          src="https://s3.amazonaws.com/i2290/veri.logo.gry.min.png"
        />
        <img
          src="https://www.i2290.com/Images/texas.png"
          alt="i2290.com Established with Pride, in Texas 2003."
        />
      </div>
      <div className="col-12 col-md-6 footer-link-section text-right">
        <p>Customer Support Hours: Monday through Friday, 9am to 6pm CT</p>
        <p>866.444.2290 · support@i2290.com</p>
        <p>
          <Link
            className="footer-link"
            clickHandler={onClickTermsConditions}
            text="Terms & Conditions"
          />
          <Link
            className="footer-link ml-gutter"
            clickHandler={onClickPrivacyPolicy}
            text="Privacy Policy"
          />
        </p>
        <p>Copyright © 2020 INSTANT 2290 and i2290.com. All rights reserved.</p>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  onClickTermsConditions: PropTypes.func.isRequired,
  onClickPrivacyPolicy: PropTypes.func.isRequired
};

export default Footer;
