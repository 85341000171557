import React from "react";
import SupportFormScreen from "./SupportFormScreenContainer";
import NoticeOrLetterForm from "./NoticeOrLetterFormContainer";
import hoc from "../../../modules/hoc";
import userContext from "../../../modules/userContext";
import ProfileWidget from "./ProfileWidgetContainer";

const {
  hof: { withChildren }
} = hoc;

const {
  components: { HideIfUnauthenticatedContainer: HideIfUnauthenticated }
} = userContext;

const children = (
  <div className="widget-background drop-shadow p-gutter mb-gutter rad-corners">
    <h2 className="support-page-header">
      Have you received a notice from the IRS?
    </h2>
    <p style={{ marginBottom: "30px" }}>
      We can help you. Follow these instructions and we will reply within 3
      business days.
    </p>
    <HideIfUnauthenticated>
      <section className="section-spacer">
        <ProfileWidget />
      </section>
    </HideIfUnauthenticated>
    <NoticeOrLetterForm />
  </div>
);

const NoticeOrLetterFormScreen = withChildren(children, SupportFormScreen);

export default NoticeOrLetterFormScreen;
