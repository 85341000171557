import CompanyInfoForm from "./CompanyInfoForm";
import CompanyInfoFormContainer from "./CompanyInfoFormContainer";
import CompanyInfoWidget from "./CompanyInfoWidget";
import CompanyInfoWidgetBody from "./CompanyInfoWidgetBody";
import BusinessInfoPanel from "./BusinessInfoPanel";
import CompanyInfoWidgetBodyContainer from "./CompanyInfoWidgetBodyContainer";
import CompanyInfoWidgetHeader from "./CompanyInfoWidgetHeader";
import CompanyInfoWidgetHeaderContainer from "./CompanyInfoWidgetHeaderContainer";
import CompanyInfoView from "./CompanyInfoView";
import CompanyInfoViewContainer from "./CompanyInfoViewContainer";

export default {
  CompanyInfoForm,
  CompanyInfoFormContainer,
  CompanyInfoWidget,
  CompanyInfoWidgetBody,
  CompanyInfoWidgetBodyContainer,
  CompanyInfoWidgetHeader,
  CompanyInfoWidgetHeaderContainer,
  BusinessInfoPanel,
  CompanyInfoView,
  CompanyInfoViewContainer
};
