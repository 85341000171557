import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./supportFooter.css";

const {
  components: { Link }
} = controls;

const SupportFooter = ({
  onClickSupport,
  onClickFaq,
  onClickCall,
  onClickEmail
}) => (
  <div className="support-footer drop-shadow widget-background">
    <div className="row">
      <div className="col-3 d-none d-lg-inline">
        <img
          src="https://www.i2290.com/n/Images/i2290-customerservice.png?v=635388214777090163"
          alt="support reps"
        />
      </div>
      <div className="col-12 col-md-6">
        <h2 className="support-footer-subhead">
          Need some help? <em>Se habla espa&#241;ol.</em>
        </h2>
        <p>
          <Link clickHandler={onClickCall} text="866.444.2290" />
          <Link
            clickHandler={onClickEmail}
            text="support@i2290.com"
            className="ml-gutter"
          />
        </p>
        <p>
          Our friendly i2290.com e-file specialists are just a phone call away
          and proudly based in the USA.
        </p>
      </div>
      <div className="col-12 col-md d-flex flex-column align-items-end">
        <h3>Helpful Links</h3>
        <Link clickHandler={onClickSupport} text="Support" />
        <Link clickHandler={onClickFaq} text="Frequently Asked Questions" />
      </div>
    </div>
  </div>
);

SupportFooter.propTypes = {
  onClickSupport: PropTypes.func.isRequired,
  onClickFaq: PropTypes.func.isRequired,
  onClickCall: PropTypes.func.isRequired,
  onClickEmail: PropTypes.func.isRequired
};

export default SupportFooter;
