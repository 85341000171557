import * as actionTypes from "./register.actionTypes";
import doAsync from "../../modules/doAsync";
import utilities from "../../modules/utilities";
import { CallGoogleAnalytics } from "../../modules/paymentModal/paymentModal.actions";

const {
  transform: { isEIN, sanitizeEIN }
} = utilities;

export const register = (
  firstName,
  lastName,
  emailOrEIN,
  pin,
  phoneNumber,
  foundUsOption,
  details
) =>
  doAsync({
    actionType: actionTypes.REGISTER_ASYNC,
    url: "sign-up",
    httpConfig: {
      body: JSON.stringify({
        FirstName: firstName,
        LastName: lastName,
        UserId: isEIN(emailOrEIN) ? sanitizeEIN(emailOrEIN) : emailOrEIN,
        Password: pin,
        Telephone1: phoneNumber.replace(/[- )(]/g, ""),
        FoundUsOption: foundUsOption || undefined,
        FoundUsDescription:
          foundUsOption === "other" ? details || undefined : undefined
      }),
      method: "post"
    },
    httpMethod: "post",
    mapResponseToPayload(r) {
      CallGoogleAnalytics({
        category: "User",
        name: "Registered",
        label: "New",
        value: 1
      });
      return r;
    },
    noBusySpinner: true
  });

export const reset = () => ({
  type: actionTypes.RESET
});
