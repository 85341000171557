import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CompanyInfoView from "./CompanyInfoView";
import userContext from "../../userContext";
import controls from "../../controls";
import utilities from "../../../modules/utilities";

const {
  actions: { fetchUser: fetchUserCreator },
  selectors: { getUserContext, getUserContextExists }
} = userContext;

const {
  normalizations: { formatEIN }
} = controls;

const {
  lookups: { getFriendlyStructureName }
} = utilities;

class CompanyInfoViewContainer extends Component {
  componentDidMount() {
    const { fetchUser, userContextExists } = this.props;
    if (!userContextExists) fetchUser();
  }

  render() {
    return (
      <React.Fragment>
        <CompanyInfoView {...this.props.initialValues} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    Address1,
    BusinessName,
    City,
    Country,
    Ein,
    SoleProprietor,
    State,
    Zip
  } = {
    ...(getUserContext(state) || {})
  };
  const initialValues = {
    Address1: Address1 || "",
    BusinessName: BusinessName || "",
    City: City || "",
    Country: Country || "",
    Ein: formatEIN(Ein) || "",
    FriendlyStructureName: getFriendlyStructureName(SoleProprietor) || "",
    State: State || "",
    Zip: Zip || ""
  };

  return {
    initialValues,
    userContextExists: getUserContextExists(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyInfoViewContainer)
);
