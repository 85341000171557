export const STATE_NAME = "supportCenter";
export const CONTACT_SUPPORT_FORM_NAME = "contactSupport";
export const SUPPORT_REQUEST_FORM_NAME = "supportRequest";
export const UNLOCK_REQUEST_FORM_NAME = "unlockRequest";
export const IRS_REJECTED_FORM_NAME = "irsRejected";
export const NOTICE_OR_LETTER_FORM_NAME = "noticeOrLetter";
export const SCHEDULE_ONE_SUPPORT_FORM_NAME = "scheduleOneSupport";
export const BULK_PRICING_FORM_NAME = "bulkPricing";
export const VIN_CORRECTION_FORM_NAME = "vinCorrection";
export const FILE_BY_PHONE_FORM_NAME = "fileByPhone";
export const OTHER_REASON_NAME = "Other";
