import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GlobalBusyIndicator from "./GlobalBusyIndicator";
import error from "../../error";

const {
  selectors: { getErrorMessage }
} = error;

const GlobalBusyIndicatorContainer = ({ spinnerSize, errorText }) => (
  <GlobalBusyIndicator spinnerSize={spinnerSize} errorText={errorText} />
);

GlobalBusyIndicatorContainer.propTypes = {
  spinnerSize: PropTypes.number,
  errorText: PropTypes.string
};

GlobalBusyIndicatorContainer.defaultProps = {
  spinnerSize: 40,
  errorText: null
};

const mapStateToProps = state => ({
  errorText: getErrorMessage(state)
});

export default connect(mapStateToProps)(GlobalBusyIndicatorContainer);
