import React from "react";
import PropTypes from "prop-types";
import { Form } from "redux-form";
import ContactInfoSupportForm from "./ContactInfoSupportFormContainer";
import SupportRequestForm from "./SupportRequestForm";
import controls from "../../../modules/controls";

const {
  components: { Button, AutoHeightAnimator, UploaderContainer: Uploader }
} = controls;

const ContactSupportForm = ({
  subjectValidations,
  bodyValidations,
  onSubmit,
  onClickCancel,
  onClickAttach,
  attachmentsIsExpanded,
  getAttachments,
  setAttachments
}) => (
  <Form onSubmit={onSubmit}>
    <h2 className="underline-head">Tell us how to contact you</h2>
    <ContactInfoSupportForm />
    <h2 className="underline-head">Support Request</h2>
    <SupportRequestForm
      subjectValidations={subjectValidations}
      bodyValidations={bodyValidations}
    />
    <AutoHeightAnimator isExpanded={attachmentsIsExpanded}>
      <Uploader
        getFiles={getAttachments}
        setFiles={setAttachments}
        fileLimit={5}
      />
    </AutoHeightAnimator>
    <section className="support-form-btn-section">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Cancel"
            secondary
            onClick={onClickCancel}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Attach Files"
            secondary
            onClick={onClickAttach}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 text-center">
          <Button
            width="100%"
            disabled={false}
            text="Submit"
            primary
            type="submit"
          />
        </div>
      </div>
    </section>
  </Form>
);

ContactSupportForm.propTypes = {
  subjectValidations: PropTypes.arrayOf(PropTypes.func),
  bodyValidations: PropTypes.arrayOf(PropTypes.func),
  onSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickAttach: PropTypes.func.isRequired,
  attachmentsIsExpanded: PropTypes.func.isRequired,
  getAttachments: PropTypes.func.isRequired,
  setAttachments: PropTypes.func.isRequired
};

export default ContactSupportForm;
