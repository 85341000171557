import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import controls from "../../../modules/controls";
import "./navMenu.css";
import Filer from "./FilerContainer";

const {
  components: { Link }
} = controls;

const NavMenu = ({
  onClickOrderHistory,
  onClickProfile,
  onClickSupportCenter,
  path
}) => (
  <MediaQuery minWidth={768}>
    {matches => {
      if (matches) {
        return (
          <ul className="i2290-nav-menu">
            <li>
              <Link
                className={path === "/order-history" ? "active" : null}
                clickHandler={onClickOrderHistory}
                text="Orders"
              />
            </li>
            <li>
              <Link
                className={path === "/account" ? "active" : null}
                clickHandler={onClickProfile}
                text="Account"
              />
            </li>
            <li>
              <Link
                className={path === "/support-center" ? "active" : null}
                clickHandler={onClickSupportCenter}
                text="Support"
              />
            </li>
            <li className="filer-link d-flex align-items-center">
              <Filer />
            </li>
          </ul>
        );
      }
      return (
        <div className="row">
          <div className="col">
            <ul className="i2290-nav-menu">
              <li>
                <Link
                  className={path === "/order-history" ? "active" : null}
                  clickHandler={onClickOrderHistory}
                  text="Orders"
                />
              </li>
              <li>
                <Link
                  className={path === "/account" ? "active" : null}
                  clickHandler={onClickProfile}
                  text="Account"
                />
              </li>
              <li>
                <Link
                  className={path === "/support-center" ? "active" : null}
                  clickHandler={onClickSupportCenter}
                  text="Support"
                />
              </li>
            </ul>
          </div>
          <div className="col my-auto d-flex justify-content-center i2290-nav-menu">
            <div className="ml-auto">
              <Filer />
            </div>
          </div>
        </div>
      );
    }}
  </MediaQuery>
);

NavMenu.propTypes = {
  onClickOrderHistory: PropTypes.func.isRequired,
  onClickProfile: PropTypes.func.isRequired,
  onClickSupportCenter: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired
};

export default NavMenu;
