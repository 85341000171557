import * as actionTypes from "./weightIncrease.actionTypes";
import doAsync from "../../modules/doAsync";
import utilities from "../../modules/utilities";
import { fetchUser } from "../../modules/userContext/userContext.actions";

const {
  transform: { arrayToMap }
} = utilities;

export const fetchWeightIncreaseVehicles = () =>
  doAsync({
    actionType: actionTypes.FETCH_WEIGHT_INCREASE_VEHICLES,
    url: "weight-increase",
    httpMethod: "get",
    mapResponseToPayload: r => {
      r.forEach(x => {
        x.value = x.Id;
        x.label = x.Vin;
      });
      return arrayToMap(r, "Id");
    },
    noBusySpinner: false
  });

export const postWeightIncrease = weightIncrease => dispatch =>
  dispatch(
    doAsync({
      actionType: actionTypes.POST_WEIGHT_INCREASE,
      url: "weight-increase",
      httpConfig: {
        body: JSON.stringify(weightIncrease),
        headers: {
          ['wait-lock-key']: weightIncrease?.Id
        }
      },
      httpMethod: "post",
      mapResponseToPayload: r => `/form?order=${r.Id}`
    })
  ).then(() => dispatch(fetchUser()));

export const setRedirect = redirect => ({
  type: actionTypes.SET_REDIRECT,
  payload: redirect
});
