import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import CompanyInfo from "./CompanyInfo";
import userContext from "../../../modules/userContext";
import { FORM_NAME } from "../newUser.constants";
import utilities from "../../../modules/utilities";
import error from "../../../modules/error";

const {
  getOrderIdFromUrl,
  transform: { mapCompanyInfoFormValuesToPayload }
} = utilities;

const {
  selectors: { getUserContext, getRedirect },
  actions: { patchUser: patchUserCreator, setRedirect: setRedirectCreator }
} = userContext;

const {
  selectors: { getErrorType },
  actions: { resetError: resetErrorCreator },
  constants: { EXISTING_EIN_ERROR }
} = error;

class CompanyInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.props.handleSubmit(this.onSuccess);
  }

  componentDidMount() {
    const {
      props: { redirect, setRedirect }
    } = this;

    if (redirect !== null) {
      setRedirect(null);
    }
  }

  handleChange = () => {
    const {
      props: { errorType, resetError }
    } = this;
    if (errorType === EXISTING_EIN_ERROR) resetError();
  };

  onSuccess = formValues => {
    const { patchUser } = this.props;
    const orderId = getOrderIdFromUrl();

    return orderId
      ? patchUser(
          mapCompanyInfoFormValuesToPayload(formValues),
          `/wizard/2?order=${orderId}`
        )
      : patchUser(mapCompanyInfoFormValuesToPayload(formValues), "/wizard/2");
  };

  render() {
    const { userContext, redirect } = this.props;
    const firstName = (userContext && userContext.FirstName) || null;
    const userContextExists = !!userContext.Id;
    return (
      <React.Fragment>
        {redirect && <Redirect to={redirect} />}
        <CompanyInfo
          userContextExists={userContextExists}
          formName={FORM_NAME}
          onSubmit={this.handleSubmit}
          firstName={firstName}
          onChange={this.handleChange}
        />
      </React.Fragment>
    );
  }
}

CompanyInfoContainer.propTypes = {
  userContext: PropTypes.object,
  initialValues: PropTypes.object, // eslint-disable-line
  patchUser: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  errorType: PropTypes.string,
  resetError: PropTypes.func.isRequired
};

CompanyInfoContainer.defaultProps = {
  redirect: null,
  initialValues: {},
  userContext: {},
  errorType: null
};

const mapStateToProps = state => ({
  userContext: getUserContext(state),
  initialValues: getUserContext(state),
  redirect: getRedirect(state),
  errorType: getErrorType(state)
});

const mapDispatchToProps = dispatch => ({
  patchUser: (user, redirect) => dispatch(patchUserCreator(user, redirect)),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect)),
  resetError: () => dispatch(resetErrorCreator())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: FORM_NAME,
      destroyOnUnmount: false,
      keepDirtyOnReinitialize: true,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnBlur: false
    })(CompanyInfoContainer)
  )
);
