import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import controls from "../../controls";
import logo from "../../assets/logo.final.png";
import {
  UNLIMITED_CART_MESSAGE,
  WEIGHT_INCREASE_CART_MESSAGE
} from "../../constants";
import UnlimitedCheckbox from "./UnlimitedCheckbox";
import utilities from "../../utilities";
import "../../../screens/form/components/formScreen.css";

const {
  math: { fastFormatMoney },
} = utilities;

const {
  components: { Button },
} = controls;

const CartContents = ({
  subTotal,
  taxAmount,
  taxPercentage,
  total,
  productPrice,
  product,
  promoCode,
  isUnlimited,
  onChangePromoCode,
  onChangeUnlimited,
  onClickClearPromo,
  onClickApply,
  promoValueCode,
  promoValueAmount,
  error,
  productDescription,
  isWeightIncrease,
  userIsCoveredByUnlimited,
  isNoCharge,
  noChargeString,
  unlimitedPrice,
  isExpanded,
  toggleIsExpanded,
  buttonText,
  isSubmissionButtonDisabled,
  onClickSubmission,
  onClickDisclaimer,
  onClickTerms,
  onClickPrivacy,
  isPaid,
}) => {
  let bodyMarkup = null;
  if (
    !isWeightIncrease &&
    !userIsCoveredByUnlimited &&
    !isNoCharge &&
    !isPaid
  ) {
    bodyMarkup = (
      <>
        {total !== null ? (
          <>
            {/* ===========================================   PROMO CODE ENTRY LINE */}
            <div className="row line-item-row">
              <div className="col col-sm-5">
                <TextField
                  className="promo-code-field"
                  fullWidth
                  label="Promo Code"
                  value={promoCode}
                  onChange={onChangePromoCode}
                  variant="outlined"
                />
              </div>
              <div className="col-sm-3">
                <Button
                  secondary
                  text="Apply"
                  disabled={false}
                  onClick={onClickApply}
                  width="100%"
                />
              </div>
            </div>
            {/* ===========================================   ERROR MSG LINE */}
            {error && (
              <div className="row line-item-row">
                <div className="col-sm-12">
                  <span className="i2290-inline-error">{error}</span>
                </div>
              </div>
            )}
            {/* ===========================================   PROMO LINE */}
            {promoValueCode && promoValueAmount && (
              <div className="row line-item-row">
                <div className="col-sm-5">
                  <span>{`Promo (${promoValueCode})`}</span>
                </div>
                <div className="col-sm-3 text-right">
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                  <span>
                    {`-${fastFormatMoney(promoValueAmount)}`}
                    
                  </span>
                </div>
                <div className="col-sm-1">
                  <span
                        className="k-icon k-i-close-outline k-i-x-outline remove-promo-icon"
                        onClick={onClickClearPromo}
                      />
                </div>
              </div>
            )}
            {/* ===========================================   SALES TAX LINE */}
            {(+taxAmount || 0) !== 0 && (
              <div className="row line-item-row">
                <div className="col-sm-5">
                  <span>Sales Tax</span>
                </div>
                <div className="col-sm-3 text-right">
                  <span>{`${fastFormatMoney(
                    taxAmount
                  )}`}</span>
                </div>
              </div>
            )}
            {/* ===========================================   TOTAL LINE */}
            <div className="row line-item-row total-line-item">
              <div className="col-sm-5">
                <span className="sub-line-parent">Total</span>
              </div>
              <div className="col-sm-3 text-right">
                <span className="">{`${fastFormatMoney(total)}`}</span>
              </div>
            </div>
          </>
        ) : (
          <div className="row line-item-row">
            <div className="col-sm-5">
              <span>Loading...</span>
            </div>
          </div>
        )}
      </>
    );
  } else if (isPaid) {
    bodyMarkup = null;
  } else {
    let message = null;

    if (userIsCoveredByUnlimited) {
      message = UNLIMITED_CART_MESSAGE;
    } else if (isNoCharge) {
      message = noChargeString;
    } else {
      message = WEIGHT_INCREASE_CART_MESSAGE;
    }

    bodyMarkup = (
      <div className="widget-body cart-widget-body my-gutter">
        <p className="mb-0">{message}</p>
      </div>
    );
  }

  return (
    <div className="summary-widget cart-widget">
      <div className="row">
        <div className="col center mb-3">
          <img
            className="logo"
            src={logo}
            alt="logo"
            style={{ width: "20em" }}
          />
        </div>
      </div>
      <div className="summary-header brand-header mb-3 cart-widget-header">
        <div className="row">
          <div className="col">
            <h1
              className="mb-0 text-center mr-gutter"
              style={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
              }}
            >
              CHECKOUT
            </h1>
          </div>
        </div>
      </div>
      <div className="form-widget-header row">
        <div className="col-12 col-md my-auto" style={{fontWeight: "700", fontSize:"20px"}}>Order Summary</div>
      </div>

      {/* ===========================================   PRODUCT LINE */}
      <div className="row mt-3 ml-1 mb-4 prod-line-item-container">
        <div className="col-sm-5 prod-line-item">
          <span
            style={{
              textTransform: "uppercase"
            }}
            className="prod-descrip"
          >
            {product}
          </span>
          {!userIsCoveredByUnlimited && (
            <span className="prod-descrip">({productDescription})</span>
          )}
        </div>
        <div className="col-sm-3 text-right prod-line-item">
          <span style={{ textTransform: isPaid ? "uppercase" : "none", fontSize: "16px"}}>
            {isWeightIncrease ||
            userIsCoveredByUnlimited ||
            isNoCharge ||
            isPaid
              ? "PAID"
              : fastFormatMoney(productPrice)}
          </span>
        </div>
      </div>
      {/* ===========================================   OTHER LINES */}
      {bodyMarkup}

      {!isWeightIncrease &&
        !userIsCoveredByUnlimited &&
        !isNoCharge &&
        !isPaid && (
          <div className="total-ctnr cart-widget-body widget-body soft-border-bottom">
            <UnlimitedCheckbox
              productPrice={unlimitedPrice}
              isUnlimited={isUnlimited}
              onChangeUnlimited={onChangeUnlimited}
            />
          </div>
        )}
    </div>
  );
};

CartContents.propTypes = {
  total: PropTypes.string,
  // subTotal: PropTypes.number,
  productPrice: PropTypes.number,
  // taxAmount: PropTypes.number,
  // taxPercentage: PropTypes.number,
  product: PropTypes.string,
  productDescription: PropTypes.string,
  promoCode: PropTypes.string,
  isUnlimited: PropTypes.bool.isRequired,
  onChangePromoCode: PropTypes.func.isRequired,
  onChangeUnlimited: PropTypes.func.isRequired,
  onClickClearPromo: PropTypes.func.isRequired,
  onClickApply: PropTypes.func.isRequired,
  promoValueCode: PropTypes.string,
  promoValueAmount: PropTypes.number,
  error: PropTypes.string,
  isWeightIncrease: PropTypes.bool,
  userIsCoveredByUnlimited: PropTypes.bool.isRequired,
  isNoCharge: PropTypes.bool.isRequired,
  noChargeString: PropTypes.string,
  unlimitedPrice: PropTypes.number,
  buttonText: PropTypes.string.isRequired,
  isSubmissionButtonDisabled: PropTypes.bool.isRequired,
  onClickSubmission: PropTypes.func.isRequired,
  onClickTerms: PropTypes.func.isRequired,
  onClickDisclaimer: PropTypes.func.isRequired,
  onClickPrivacy: PropTypes.func.isRequired,
  isPaid: PropTypes.bool.isRequired,
};

CartContents.defaultProps = {
  total: null,
  // subTotal: null,
  productPrice: null,
  // taxAmount: null,
  // taxPercentage: null,
  product: null,
  productDescription: null,
  promoCode: "",
  promoValueCode: null,
  promoValueAmount: null,
  error: null,
  noChargeString: null,
  unlimitedPrice: null,
};

export default CartContents;
