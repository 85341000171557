import React from "react";
import DropdownCell from "./DropdownCell";

const bindDropdownCell = (localizedData, changeCallback) => props => (
  <DropdownCell
    {...props}
    localizedData={localizedData}
    changeCallback={changeCallback}
  />
);

export default bindDropdownCell;
