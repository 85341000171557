import * as actionTypes from "./userContext.actionTypes";
import { FOCUS } from "redux-form/lib/actionTypes";

const initialState = {
  entity: {},
  ui: {
    redirect: null,
    companyInfoWidgetIsExpanded: false,
    isBusy: false,
    error: null,
    fieldOnTab: ""
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_ON_BLUR:
      return {
        ...state,
        ui: {
          ...state.ui,
          fieldOnTab: action.fieldOnTab
        }
      };
    case actionTypes.TOGGLE_COMPANY_INFO_WIDGET_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          companyInfoWidgetIsExpanded: !state.ui.companyInfoWidgetIsExpanded
        }
      };
    case actionTypes.SET_COMPANY_INFO_WIDGET_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          companyInfoWidgetIsExpanded: action.payload
        }
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: action.redirect
        }
      };
    // LOGIN_ASYNC
    case actionTypes.LOGIN_ASYNC.REQUESTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false
        }
      };

    case actionTypes.LOGIN_ASYNC.RECEIVED:
      return {
        ...state,
        entity: {
          ...state.entity,
          ...action.payload
        },
        ui: {
          ...state.ui,
          success: true,
          redirect: null,
          error: null
        }
      };

    case actionTypes.LOGIN_ASYNC.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          error: action.payload
        }
      };

    // LOGOUT_ASYNC
    case actionTypes.LOGOUT_ASYNC.REQUESTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false
        }
      };

    case actionTypes.LOGOUT_ASYNC.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          redirect: "/sign-in"
        }
      };

    case actionTypes.LOGOUT_ASYNC.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          error: action.payload
        }
      };

    case actionTypes.UPDATE_USER_CONTEXT: {
      return {
        ...state,
        entity: {
          ...state.entity,
          ...action.payload
        }
      };
    }

    case actionTypes.SET_USER_CONTEXT_ERR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          error: action.payload
        }
      };
    }

    case actionTypes.RESET_USER_CONTEXT: {
      return {};
    }

    case actionTypes.RESET_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          error: null
        }
      };
    }
    // FETCH_USER_ASYNC
    case actionTypes.FETCH_USER_ASYNC.REQUESTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          isBusy: true
        }
      };

    case actionTypes.FETCH_USER_ASYNC.RECEIVED: {
      return {
        ...state,
        entity: action.payload,
        ui: {
          ...state.ui,
          success: true,
          isBusy: false
        }
      };
    }

    case actionTypes.FETCH_USER_ASYNC.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          error: action.payload,
          isBusy: false
        }
      };

    // PATCH_USER_ASYNC
    // case actionTypes.PATCH_USER_ASYNC.REQUESTED:
    //   return {
    //     ...state,
    //     success: false,
    //     isBusy: true
    //   };

    case actionTypes.PATCH_USER_ASYNC.RECEIVED: {
      return {
        entity: action.payload,
        ui: {
          ...state.ui,
          error: null,
          isBusy: false,
          redirect: action.payload.redirect
        }
      };
    }

    case actionTypes.PATCH_USER_ASYNC.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          success: false,
          error: action.payload,
          isBusy: false
        }
      };

    default:
      return state;
  }
}
