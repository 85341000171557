import React from "react";
import hoc from "../../hoc";
import order from "../../../modules/order";
import controls from "../../../modules/controls";
import vehicles from "../../../modules/vehicles";
import { INVALID_VEHICLES_ERROR_MESSAGE } from "../../../modules/constants";

const {
  components: { VehiclesAnimatorContainer: VehiclesAnimator }
} = vehicles;

const {
  components: { TaxYearDropdownContainer: TaxYearDropdown }
} = order;

const {
  hof: { withAccountFrame }
} = hoc;

const {
  components: { Button }
} = controls;

const EditVehiclesScreen = ({
  onClickBack,
  invalidVehiclesExist,
  showImporter,
  onClickEditCreditVehicles,
  numberOfCreditVehicles,
  toggleShowImporter,
  vehicleStaging
}) => (
  <div className="edit-vehicles-screen">
    <div className="row">
      <div className="col-12">
        <div className="mb-gutter widget-body rad-corners p-gutter vehicle-grid-width">
          <div className="row align-items-center">
            {!showImporter && (
              <div className="col-12 col-sm mb-gutter mb-sm-0">
                <div style={{ width: "100%" }}>
                  <TaxYearDropdown label="Tax Period" width="300px" />
                </div>
              </div>
            )}
            <div
              className={`${
                showImporter
                  ? "col-12 text-nowrap d-flex justify-content-end"
                  : "col-12 col-sm-auto text-nowrap d-flex justify-content-end"
              }`}
            >
              {!vehicleStaging && (
                <Button
                  className="d-inline"
                  onClick={onClickEditCreditVehicles}
                  text={"Credits (" + numberOfCreditVehicles + ")"}
                  disabled={false}
                  primary
                />
              )}
              {showImporter && (
                <Button
                  className="grid-button-spaced"
                  onClick={toggleShowImporter}
                  text="Back"
                  disabled={false}
                  secondary
                />
              )}
              {!vehicleStaging && (
                <Button
                  className="grid-button-spaced"
                  onClick={onClickBack}
                  text="Done"
                  disabled={false}
                  primary
                />
              )}
            </div>
            {invalidVehiclesExist && (
              <div className="col-12 text-center mt-gutter">
                <span className="i2290-inline-error">
                  {INVALID_VEHICLES_ERROR_MESSAGE}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12">
        <VehiclesAnimator height="500px" />
      </div>
    </div>
  </div>
);

export default withAccountFrame(EditVehiclesScreen);
