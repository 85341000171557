import React from "react";
import PropTypes from "prop-types";
import TextField from "./TextField";

const ZipField = ({
  name,
  label,
  onBlur,
  validations,
  normalize,
  format,
  onChange
}) => (
  <TextField
    name={name}
    label={label}
    type="text"
    onBlur={onBlur}
    validate={validations}
    normalize={normalize}
    format={format}
    onChange={onChange}
  />
);

ZipField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  validations: PropTypes.arrayOf(PropTypes.func),
  normalize: PropTypes.func.isRequired,
  format: PropTypes.func.isRequired
};

ZipField.defaultProps = {
  name: "Zip",
  label: "Zip",
  validations: null,
  onBlur: null
};

export default ZipField;
