import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./profileWidget.css";

const {
  components: { Button }
} = controls;

const ProfileWidget = ({ loading, businessName, ein, onClickEdit }) => (
  <div className="profile-widget dashboard-main-bg">
    <div className="row">
      <div className="col-12 col-sm-8 col-md my-auto">
        <p>
          <i className="fas fa-user" />
          {loading ? (
            <span>Loading...</span>
          ) : (
            <span>
              <strong>{businessName || "N/A"}</strong> - {ein || "N/A"}
            </span>
          )}
        </p>
      </div>
      <div className="col text-right">
        <Button
          primary={false}
          text="Edit"
          disabled={false}
          onClick={onClickEdit}
        />
      </div>
    </div>
  </div>
);

ProfileWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  businessName: PropTypes.string,
  ein: PropTypes.string,
  onClickEdit: PropTypes.func.isRequired
};

ProfileWidget.defaultPros = {
  businessName: null,
  ein: null
};

export default ProfileWidget;
