import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WeightIncreaseScreen from "./WeightIncreaseScreen";
import userContext from "../../../modules/userContext";
import {
  fetchWeightIncreaseVehicles as fetchWeightIncreaseVehiclesCreator,
  setRedirect as setRedirectCreator
} from "../weightIncrease.actions";

const {
  actions: { fetchUser: fetchUserCreator }
} = userContext;

class WeightIncreaseScreenContainer extends Component {
  componentDidMount() {
    const {
      props: { fetchUser, fetchWeightIncreaseVehicles }
    } = this;
    fetchUser();
    fetchWeightIncreaseVehicles();
  }

  componentWillUnmount() {
    const {
      props: { setRedirect }
    } = this;
    setRedirect(null);
  }

  render() {
    return <WeightIncreaseScreen />;
  }
}

WeightIncreaseScreenContainer.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  fetchWeightIncreaseVehicles: PropTypes.func.isRequired,
  setRedirect: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserCreator()),
  fetchWeightIncreaseVehicles: () =>
    dispatch(fetchWeightIncreaseVehiclesCreator()),
  setRedirect: redirect => dispatch(setRedirectCreator(redirect))
});

export default connect(null, mapDispatchToProps)(WeightIncreaseScreenContainer);
