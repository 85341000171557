import utilities from "../utilities";

const {
  generic: { doIfNotEmpty, isEmpty },
  transform: { removeSpaces, fitToMax, formatCurrency: _formatCurrency }
} = utilities;

export const normalizeEmail = v => {
  if (!v) return v;
  return v.length > 60 ? v.slice(0, 60) : v;
};

export const normalizePin = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (!onlyNums.length) return "";
  return onlyNums.length > 5 ? onlyNums.slice(0, 5) : onlyNums;
};

export const normalizeEIN = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (!onlyNums.length) return "";
  return fitToMax(onlyNums, 9);
};

export const normalizeRoutingNum = v =>
  doIfNotEmpty(v, v => fitToMax(removeSpaces(v), 9));

export const normalizeAccountNum = v => doIfNotEmpty(v, removeSpaces);

export const normalizeVIN = v =>
  doIfNotEmpty(v, v => fitToMax(removeSpaces(v).toUpperCase(), 19));

export const normalizeTitle = v => doIfNotEmpty(v, v => fitToMax(v, 30));

export const normalizeAddress = v => doIfNotEmpty(v, v => fitToMax(v, 35));

export const normalizeCity = v => doIfNotEmpty(v, v => fitToMax(v, 50));

export const normalizeDescription = v => doIfNotEmpty(v, v => fitToMax(v, 30));

export const normalizeTaxableVehicleDescription = v => {
  if (!v) return v;
  return v.length > 50 ? v.slice(0, 50) : v;
};

export const normalizeBusinessName = v => doIfNotEmpty(v, v => fitToMax(v, 60));

export const normalizeBuyerName = v => doIfNotEmpty(v, v => fitToMax(v, 75));

export const normalizeEmailOrEIN = v =>
  /^(\d{9})|(\d{2}-\d{7})$/g.test(v) ? normalizeEIN(v) : normalizeEmail(v);

export const formatEmailOrEIN = v => (/^\d{9}$/g.test(v) ? formatEIN(v) : v);

export const formatCurrency = input => {
  if (isEmpty(input)) return "";

  const formatted = _formatCurrency(input).slice(1);

  if (Number.isInteger(input)) {
    return formatted.slice(0, -3);
  }

  return formatted;
};

// eslint-disable-next-line no-unused-vars
export const normalizeCurrency = (val, prev) => {
  if (isEmpty(val)) return undefined;

  if (!prev && val === ".") {
    return (0).toFixed(2);
  }

  if (prev && prev.toString().includes(".") && !val.includes(".")) {
    return parseInt(prev.toString().split(".")[0], 10);
  }

  const clean = val.replace(/[,$]/g, "");

  let result;

  if (clean[clean.length - 1] === ".") {
    result = parseFloat(clean.slice(0, -1)).toFixed(2);
  } else if (clean.slice(-2) === ".0") {
    result = parseFloat(clean.slice(0, -1)).toFixed(2);
  } else {
    // eslint-disable-next-line no-unused-vars
    const [leftSide, rightSide] = clean.split(".");
    if (rightSide && rightSide.length === 3) {
      const trimmed = `${leftSide}.${rightSide.slice(0, -1)}`;
      result = parseFloat(trimmed).toFixed(2);
    } else {
      result = parseFloat(clean);
    }
  }

  return result;
};

export const normalizePhone = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (!onlyNums.length) return "";
  if (onlyNums.length > 10) return onlyNums.slice(0, -1);
  return onlyNums;
};

export const normalizeMiddleInitial = v =>
  v ? fitToMax(v.toUpperCase(), 1) : v;

export const normalizeUSZip = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (!onlyNums.length) return "";
  if (onlyNums.length > 5) return onlyNums.slice(0, 5);
  return onlyNums;
};

export const normalizeMXZip = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (!onlyNums.length) return "";
  if (onlyNums.length === 6) return onlyNums.slice(0, -1);
  return onlyNums;
};

export const normalizeCAZip = v => {
  if (!v) {
    return v;
  }
  const onlyAlphaNumeric = v.replace(/[^0-9a-z]/gi, "");
  if (!onlyAlphaNumeric) return "";
  if (onlyAlphaNumeric.length === 7) return onlyAlphaNumeric.slice(0, -1);
  return onlyAlphaNumeric.toUpperCase();
};

export const normalizeZipByCountry = (val, prev, all) => {
  switch (all.Country) {
    case "US":
      return normalizeUSZip(val);
    case "MX":
      return normalizeMXZip(val);
    case "CA":
      return normalizeCAZip(val);
    default:
      return normalizeUSZip(val);
  }
};

export const normalizeBooleanDropdown = v => {
  if (v === "" || v === null || v === undefined) return null;
  return v === "true";
};

export const formatEIN = v => {
  if (!v) {
    return v;
  }
  const onlyNums = v.replace(/[^\d]/g, "");
  if (onlyNums.length > 2) {
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
  }
  return v;
};

export const formatPhone = v => {
  if (!v) {
    return v;
  }
  if (v.length <= 3) {
    return v;
  }
  if (v.length < 7) {
    return `(${v.slice(0, 3)}) ${v.slice(3)}`;
  }
  return `(${v.slice(0, 3)}) ${v.slice(3, 6)}-${v.slice(6, 10)}`;
};

export const formatUSZip = v => {
  if (!v) {
    return v;
  }
  if (v.length < 6) {
    return v;
  }
  return `${v.slice(0, 5)}-${v.slice(5)}`;
};

export const formatMXZip = v => v;

export const formatCAZip = v => {
  if (!v) {
    return v;
  }
  if (v.length < 4) {
    return v;
  }
  return `${v.slice(0, 3)} ${v.slice(3)}`;
};

export const formatBooleanDropdown = v => {
  if (v === "" || v === null || v === undefined) return "";
  return v ? "true" : "false";
};
