import React from "react";
import PropTypes from "prop-types";
import "./faqWidgetBody.css";

const FaqWidgetBody = ({ children }) => (
  <div className="widget-body faq-body">{children}</div>
);

FaqWidgetBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FaqWidgetBody;
