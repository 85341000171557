import {
  buildActionType,
  buildAsyncActionType
} from "../utilities/reduxUtilities";

// import { STATE_NAME } from "./userContext.constants";

const moduleName = "user-context";

export const SET_REDIRECT = buildActionType(moduleName, "SET_REDIRECT");

export const SAVE_ON_BLUR = buildActionType(moduleName, "SAVE_ON_BLUR");

export const TOGGLE_COMPANY_INFO_WIDGET_IS_EXPANDED = buildActionType(
  moduleName,
  "TOGGLE_COMPANY_INFO_WIDGET_IS_EXPANDED"
);

export const SET_COMPANY_INFO_WIDGET_IS_EXPANDED = buildActionType(
  moduleName,
  "SET_COMPANY_INFO_WIDGET_IS_EXPANDED"
);

// TODO clean up these state names

export const LOGIN_ASYNC = buildAsyncActionType("user-context", "LOGIN_ASYNC");

export const FETCH_USER_ASYNC = buildAsyncActionType(
  moduleName,
  "FETCH_USER_ASYNC"
);

export const PATCH_USER_ASYNC = buildAsyncActionType(
  moduleName,
  "PATCH_USER_ASYNC"
);
export const LOGOUT_ASYNC = buildAsyncActionType(moduleName, "LOGOUT_ASYNC");

export const UPDATE_USER_CONTEXT = buildActionType(
  moduleName,
  "UPDATE_USER_CONTEXT"
);

export const SET_USER_CONTEXT_ERR = buildActionType(
  moduleName,
  "SET_USER_CONTEXT_ERR"
);

export const RESET_USER_CONTEXT = buildActionType(
  moduleName,
  "RESET_USER_CONTEXT"
);

export const RESET_ERROR = buildActionType(moduleName, "RESET_ERROR");
