import React from "react";
import PropTypes from "prop-types";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { CREDIT_REASON_SUSPENDED } from "../../constants";
import utilities from "../../utilities";

const {
  math: { fastFormatMoney }
} = utilities;

class CreditAmountTextCell extends React.Component {
  handleChange = e => {
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  };

  render() {
    const dataValue = this.props.dataItem[this.props.field];

    let error = null;
    if (
      this.props.dataItem.errors &&
      this.props.dataItem.errors[this.props.field]
    ) {
      error = this.props.dataItem.errors[this.props.field];
    }

    if (
      !this.props.dataItem.inEdit ||
      this.props.dataItem.CreditReason !== CREDIT_REASON_SUSPENDED
    ) {
      return (
        <td {...this.props}>
          {dataValue === null || dataValue === undefined
            ? "$-"
            : fastFormatMoney(this.props.dataItem[this.props.field])}
        </td>
      );
    }

    return (
      <td {...this.props}>
        <div style={{ width: "100%" }}>
          <NumericTextBox
            onChange={this.handleChange}
            value={dataValue}
            format="c2"
            min={0}
          />
        </div>
        {error && <span className="i2290-inline-error">{error}</span>}
      </td>
    );
  }
}

CreditAmountTextCell.propTypes = {
  dataItem: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired
};

export default CreditAmountTextCell;
