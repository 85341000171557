import { getCurrentTaxYear, getOrder } from "modules/order/order.selectors";
import { getOrderId, getOrderIdFromUrl } from "modules/utilities/url";
import { ccAckStep, previousYearStep, STATE_NAME, stripeStep } from "./paymentModal.constants";

export const getThankYouOpen = state => state[STATE_NAME].thankYouOpen;

export const getIsVisible = state => state[STATE_NAME].dialogOpen;

export const getWarningOpen = state => state[STATE_NAME].warningDialogOpen; 

export const getCcDisclaimerDialogOpen = state => state[STATE_NAME].dialogOpenName === ccAckStep; 

export const getDialogStep = state => { // TODO: refactor API
    if (getIsVisible(state)) return stripeStep;
    if (getWarningOpen(state)) return previousYearStep;
    return state[STATE_NAME].dialogOpenName;
}

export const getErrorMessage = state => state[STATE_NAME].submitError;

export const getRedirect = state => state[STATE_NAME].redirect;

export const getLoading = state => state[STATE_NAME].loading;

export const getBankAccountValid = state => {
    const {bankValidated = null,  fullBankAccount = ''} = state[STATE_NAME];
    return {bankValidated,  fullBankAccount};
};

export const isFilingPreviousYear = state => {
    const order = getOrder(getOrderId(), state);
    if (!order || !order.TaxYear) return;
    
    return order.TaxYear < getCurrentTaxYear(state);
}

export const getPaymentMethod = state => {
    const order = getOrder(getOrderId(), state);
    if (!order || typeof(order.PaymentAccount) !== 'object') return;
    
    return order.PaymentAccount.PaymentMethod;
}

export const getPaymentAccount = state => {
    const order = getOrder(getOrderId(), state);
    if (!order || typeof(order.PaymentAccount) !== 'object') return;    
    return order.PaymentAccount;
}

export const getTotalTax = state => {
    const order = getOrder(getOrderIdFromUrl(), state);
    if (!order || !order.TotalTax) return;
    
    return order.TotalTax;
}