import React from "react";
import PropTypes from "prop-types";
// import option from "@material-ui/core/option";
import Select from "./Select";
import { states } from "../data";

const StateDropdown = ({
  country,
  name,
  label,
  validations,
  onBlur,
  onChange
}) => {
  let stateNodes;
  if (country) {
    stateNodes = [<option value={null} key="none-state" />].concat(
      states[country].map(state => (
        <option value={state.real} key={state.real}>
          {state.display}
        </option>
      ))
    );
  } else {
    stateNodes = [<option value={null} key="none-state" />].concat(
      states.US.map(state => (
        <option value={state.real} key={state.real}>
          {state.display}
        </option>
      ))
    );
  }

  return (
    <Select
      name={name}
      label={label}
      validations={validations}
      onBlur={onBlur}
      onChange={onChange}
    >
      {stateNodes}
    </Select>
  );
};

StateDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  country: PropTypes.string,
  validations: PropTypes.array,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

StateDropdown.defaultProps = {
  country: null,
  validations: null,
  onBlur: null
};

export default StateDropdown;
