import React from "react";
import SupportHero from "./SupportHeroContainer";
import hoc from "../../hoc";
import "./supportFormScreen.css";

const {
  hof: { withAccountFrame }
} = hoc;

const SupportFormScreen = ({ children }) => (
  <div className="support-form-screen">
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <SupportHero />
      </div>
    </div>
    <div className="row">
      <div className="col-md-10 offset-md-1">
        <section className="support-form-section">{children}</section>
      </div>
    </div>
  </div>
);

export default withAccountFrame(SupportFormScreen);
