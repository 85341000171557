import FormVehicleGridContainer from "./FormVehicleGridContainer";
import FormWidgetRepeater from "./FormWidgetRepeater";
import TotalTaxIndicator from "./TotalTaxIndicator";
import TotalTaxIndicatorContainer from "./TotalTaxIndicatorContainer";
import FormWidgetRepeaterContainer from "./FormWidgetRepeaterContainer";

export default {
  FormVehicleGridContainer,
  FormWidgetRepeater,
  FormWidgetRepeaterContainer,
  TotalTaxIndicator,
  TotalTaxIndicatorContainer
};
