import React from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import DesigneeForm from "./DesigneeForm";
import controls from "../../controls";

const {
  components: { Switch }
} = controls;

const DesigneeFormWidget = ({ isExpanded, onChange, onBlur, radCorners }) => (
  <div className={radCorners ? "summary-widget rad-corners" : "summary-widget"}>
    <div className="summary-header bg-white">
      <div className="company-info-widget-header row">
        <span className="col-12 col-sm-6 mb-2 my-sm-auto">
          Add 3rd Party Designee?
        </span>
        <div className="col-12 col-sm-6">
          <div style={{ width: "150px", float: "right" }}>
            <Switch name="ThirdPyesNo" onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
    <AnimateHeight duration={500} height={isExpanded ? "auto" : 0}>
      <DesigneeForm onBlur={onBlur} />
    </AnimateHeight>
  </div>
);

DesigneeFormWidget.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  radCorners: PropTypes.bool
};

DesigneeFormWidget.defaultProps = {
  radCorners: true
};

export default DesigneeFormWidget;
