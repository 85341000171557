import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormReport from "./FormReport";
import order from "../../../modules/order";
import utilities from "../../../modules/utilities";

const {
  actions: {
    fetchScheduleOneUri: fetchScheduleOneUriCreator,
    setStagedScheduleOne: setStagedScheduleOneCreator
  },
  selectors: {
    getReportOrderHOF,
    getReportFormHOF,
    getReportVehicles,
    getScheduleOneStagedForDownload
  }
} = order;

const {
  getOrderIdFromUrl,
  getFormIdFromUrl,
  lookups: { lookupMonth, getFriendlyStructureName }
} = utilities;

class FormReportContainer extends Component {
  constructor(props) {
    super(props);
    this.scheduleOneForm = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      props: { scheduleOneStagedForDownload, setStagedScheduleOne }
    } = this;
    if (
      !prevProps.scheduleOneStagedForDownload &&
      scheduleOneStagedForDownload
    ) {
      this.scheduleOneForm.current.submit();
      setStagedScheduleOne(null);
    }
  }

  handleClickScheduleOne = () => {
    const {
      props: { form, fetchScheduleOneUri }
    } = this;
    fetchScheduleOneUri(form.StampedScheduleOne);
  };

  handleClickPrint = () => window.print();

  handleClickBackToOrders = () => {
    const {
      props: {
        history: { push }
      }
    } = this;
    push("/order-history");
  };

  render() {
    const {
      handleClickPrint,
      handleClickScheduleOne,
      handleClickBackToOrders,
      props: {
        order,
        form,
        taxableVehicles,
        creditVehicles,
        scheduleOneStagedForDownload
      }
    } = this;

    let loading = true;
    let acknowledgementTimestamp = null;
    let address = null;
    let businessName = null;
    let city = null;
    let country = null;
    let eFileSubmissionDate = null;
    let ein = null;
    let email = null;
    let firstUsedDate = null;
    let orderCreateDate = null;
    let phone = null;
    let signerName = null;
    let signerTitle = null;
    let state = null;
    let taxYear = null;
    let zip = null;
    let pin = null;
    let soleProprietor = null;
    let isThirdParty = null;
    let thirdPartyName = null;
    let thirdPartyPin = null;
    let thirdPartyPhone = null;
    let stampedScheduleOne = false;
    let submissionStatus = null;
    let totalTax = null;
    let totalCredit = null;
    let netTax = null;
    let isEftps = null;
    let accountNumber = null;
    let routingNumber = null;
    let statusDescription = null;
    let isVinCorrection = null;
    let paymentMethod = null;

    if (order) {
      isVinCorrection = order.VinCorrection;
      ein = order.Ein;
      address = order.Address1;
      city = order.City;
      state = order.State;
      zip = order.Zip;
      country = order.Country;
      businessName = order.BusinessName;
      isThirdParty = order.ThirdPyesNo;
      thirdPartyName = order.ThirdPname;
      thirdPartyPhone = order.ThirdPphone;
      thirdPartyPin = order.ThirdPpin;
      taxYear = order.TaxYear;
      email = order.Email;
      signerTitle = order.OfficerTitle;
      orderCreateDate = new Date(order.CreatedDate).toLocaleString();
      pin = order.Password;
      soleProprietor = getFriendlyStructureName(order.SoleProprietor);
      phone = order.Tel1 || order.Tel2;
      signerName = `${order.FirstName}${order.MI ? order.MI : ""} ${
        order.LastName
      }`;
    }

    if (form) {
      if (order && form.DateCategory) {
        const month = lookupMonth(form.DateCategory);
        firstUsedDate =
          month === "July"
            ? `July ${order.TaxYear} or Before`
            : form.DateCategory <= 6
            ? `${month} ${parseInt(order.TaxYear, 10) + 1}`
            : `${month} ${order.TaxYear}`;
      }

      if (form.StatusDescription) {
        statusDescription = form.StatusDescription;
      }

      if (form.SubmissionDate)
        eFileSubmissionDate = new Date(form.SubmissionDate).toLocaleString();

      if (form.AcknowledgementTimestamp)
        acknowledgementTimestamp = new Date(
          form.AcknowledgementTimestamp
        ).toLocaleString();

      stampedScheduleOne = form.StampedScheduleOne;
      submissionStatus = form.SubmissionStatusLabel || "Not Submitted";
      totalTax = form.TotalTax;
      totalCredit = form.TotalCredit;
      netTax = totalTax - totalCredit;
      isEftps = form.Eftps;
      accountNumber = form.AccountNumber;
      routingNumber = form.Rtn;
      paymentMethod = form.PaymentMethod;
    }

    if (order && form) {
      loading = false;
    }

    return (
      <React.Fragment>
        <form
          action={scheduleOneStagedForDownload}
          method="GET"
          ref={this.scheduleOneForm}
          style={{ display: "none" }}
        />
        <FormReport
          isVinCorrection={isVinCorrection}
          onClickBackToOrders={handleClickBackToOrders}
          onClickScheduleOne={handleClickScheduleOne}
          statusDescription={statusDescription}
          onClickPrint={handleClickPrint}
          isEftps={isEftps}
          accountNumber={accountNumber}
          routingNumber={routingNumber}
          totalTax={totalTax}
          totalCredit={totalCredit}
          netTax={netTax}
          loading={loading}
          taxableVehicles={taxableVehicles}
          creditVehicles={creditVehicles}
          soleProprietor={soleProprietor}
          submissionStatus={submissionStatus}
          stampedScheduleOne={stampedScheduleOne}
          pin={pin}
          isThirdParty={isThirdParty}
          thirdPartyName={thirdPartyName}
          thirdPartyPhone={thirdPartyPhone}
          thirdPartyPin={thirdPartyPin}
          acknowledgementTimestamp={acknowledgementTimestamp}
          address={address}
          businessName={businessName}
          city={city}
          country={country}
          eFileSubmissionDate={eFileSubmissionDate}
          ein={ein}
          email={email}
          firstUsedDate={firstUsedDate}
          orderCreateDate={orderCreateDate}
          phone={phone}
          signerName={signerName}
          signerTitle={signerTitle}
          state={state}
          taxYear={taxYear}
          zip={zip}
          paymentMethod={paymentMethod}
        />
      </React.Fragment>
    );
  }
}

FormReportContainer.propTypes = {
  order: PropTypes.object,
  form: PropTypes.object,
  taxableVehicles: PropTypes.arrayOf(PropTypes.object),
  creditVehicles: PropTypes.arrayOf(PropTypes.object),
  fetchScheduleOneUri: PropTypes.func.isRequired,
  setStagedScheduleOne: PropTypes.func.isRequired,
  scheduleOneStagedForDownload: PropTypes.string
};

FormReportContainer.propTypes = {
  order: null,
  form: null,
  taxableVehicles: [],
  creditVehicles: [],
  scheduleOneStagedForDownload: null
};

const mapStateToProps = state => {
  const order = getReportOrderHOF(getOrderIdFromUrl())(state);
  const form = getReportFormHOF(getFormIdFromUrl())(state);

  let taxableVehicles = [];
  let creditVehicles = [];

  if (form && form.Vehicles) {
    const stateVehicles = getReportVehicles(state);
    const vehicles = form.Vehicles.map(id => stateVehicles[id]);
    taxableVehicles = vehicles.filter(x => x.IsTaxable);
    creditVehicles = vehicles.filter(x => !x.IsTaxable);
  }

  return {
    order,
    form,
    taxableVehicles,
    creditVehicles,
    scheduleOneStagedForDownload: getScheduleOneStagedForDownload(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setStagedScheduleOne: val => dispatch(setStagedScheduleOneCreator(val)),
  fetchScheduleOneUri: filename =>
    dispatch(fetchScheduleOneUriCreator(filename))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormReportContainer)
);
