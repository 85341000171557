import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getFormSyncErrors,
  getFormValues,
  reduxForm,
  registerField as registerFieldCreator
} from "redux-form";
import {
  CREDIT_VEHICLE_EDITOR_FORM_NAME,
  CREDIT_VEHICLES_SLICE_NAME
} from "../vehicles.constants";
import CreditVehicleEditor from "./CreditVehicleEditor";
import {
  postVehicle as postVehicleCreator,
  putVehicle as putVehicleCreator,
  stageVehicle as stageVehicleCreator,
  stageForDeletion as stageForDeletionCreator,
  toggleShowImporter as toggleShowImporterCreator,
  updateCreditVehicleStaging as updateCreditVehicleStagingCreator
} from "../vehicles.actions";
import utilities from "../../utilities";
import order from "../../order";
import { getCreditVehiclesStagedForCreation } from "../vehicles.selectors";
import {
  normalizeAddress,
  normalizeBuyerName,
  normalizeDescription,
  normalizeVIN,
  normalizeZipByCountry
} from "../../controls/normalization";
import {
  CREDIT_REASON_DESTROYED,
  CREDIT_REASON_SOLD,
  CREDIT_REASON_STOLEN,
  CREDIT_REASON_SUSPENDED
} from "../../constants";
import {
  computeCredit,
  computeMaxCredit,
  overrideCreditAmount
} from "../../utilities/taxYear";
import { lookupDateCategoryByJavascriptDateMonth } from "../../utilities/lookups";
import { validateCreditVehicleForm } from "../../controls/validation";
import { getTaxTable } from "../../taxYear/taxYear.selectors";
import { getCurrentTaxYear } from "../../order/order.selectors";

const {
  getOrderIdFromUrl,
  transform: { mapCreditVehicleToPayload }
} = utilities;

const {
  selectors: { getOrder }
} = order;

class CreditVehicleEditorContainer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = props.handleSubmit(this.handleSubmitSuccess);
    this.state = {
      cancelModalIsOpen: false
    };
  }

  componentDidMount() {
    const {
      props: { register, data, touch }
    } = this;
    register("BuyerName");
    register("BuyerAddress1");
    register("BuyerAddress2");
    register("BuyerCountry");
    register("BuyerCity");
    register("BuyerState");
    register("BuyerZip");
    register("IsBuyerBusiness");
    register("IsBuyerDataIncluded");
    register("CreditAmount");

    if (data.errors) {
      touch(...Object.keys(data.errors).filter(k => data.hasOwnProperty(k)));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      initialValues,
      formSyncErrors,
      touch,
      untouch,
      anyTouched
    } = this.props;

    if (!anyTouched) return;
    // console.log(formSyncErrors);
    const {
      initialValues: prevInitialValues
      // formSyncErrors: prevFormSyncErrors
    } = prevProps;
    if (
      initialValues === prevInitialValues
      // formSyncErrors === prevFormSyncErrors
    )
      return;

    if (formSyncErrors) {
      const latestErrors = validateCreditVehicleForm(initialValues);
      touch(
        ...Object.keys(formSyncErrors).filter(k =>
          initialValues.hasOwnProperty(k)
        )
      );
      untouch(
        ...Object.keys(latestErrors).filter(k => latestErrors[k] === undefined)
      );
    }
  }

  componentWillUnmount() {
    const {
      props: { data, stageVehicle }
    } = this;
    if (data) {
      stageVehicle(null);
    }
  }

  handleClickUpload = () => {
    const {
      props: { toggleShowImporter }
    } = this;
    toggleShowImporter();
  };

  toggleCancelModalIsOpen = () => {
    this.setState(s => ({ ...s, cancelModalIsOpen: !s.cancelModalIsOpen }));
  };

  handleConfirmCancelModal = () => {
    const {
      props: { stageVehicle }
    } = this;
    this.setState({ cancelModalIsOpen: false }, () => stageVehicle(null));
  };

  handleSubmitSuccess = () => {
    const {
      props: { postVehicle, putVehicle, data, orderId }
    } = this;

    return data.Id
      ? putVehicle(orderId, data.Id, mapCreditVehicleToPayload(data))
      : postVehicle(orderId, mapCreditVehicleToPayload(data));
  };

  handleClickDelete = () => {
    const {
      props: { stageForDeletion, data }
    } = this;

    stageForDeletion(data.Id);
  };

  handleCreditAmountBlur = () => {
    const {
      props: { data, updateCreditVehicleStaging, taxTable }
    } = this;

    const maxCreditAmount = computeMaxCredit(
      data.WeightCategory,
      data.Logging,
      taxTable
    );

    if (data.CreditAmount > maxCreditAmount) {
      updateCreditVehicleStaging({ ...data, CreditAmount: maxCreditAmount });
    }
  };

  handleChange = (event, value) => {
    const { updateCreditVehicleStaging, taxTable, data } = this.props;

    const {
      target: { name: field }
    } = event;

    if (!field) return;

    let update;
    switch (field) {
      case "BuyerZip":
        update = {
          [field]: normalizeZipByCountry(value, null, {
            Country: data.BuyerCountry
          })
        };
        break;
      case "BuyerName":
        update = { [field]: normalizeBuyerName(value) };
        break;
      case "BuyerAddress1":
        update = { [field]: normalizeAddress(value) };
        break;
      case "BuyerCity":
        update = { [field]: normalizeAddress(value) };
        break;
      case "Vin":
        update = { [field]: normalizeVIN(value) };
        break;
      case "Description":
        update = { [field]: normalizeDescription(value) };
        break;
      case "BuyerCountry":
        update = { [field]: value, BuyerState: "", BuyerZip: "" };
        break;
      case "BuyerState":
        update = { [field]: value, BuyerZip: "" };
        break;
      case "IsBuyerDataIncluded": {
        if (value === "true") {
          update = {
            [field]: true,
            BuyerName: "",
            BuyerAddress1: "",
            BuyerAddress2: "",
            BuyerCity: "",
            BuyerState: "",
            BuyerZip: "",
            BuyerCountry: "US",
            IsBuyerBusiness: false
          };
          break;
        } else {
          update = {
            [field]: false,
            BuyerName: null,
            BuyerAddress1: null,
            BuyerAddress2: null,
            BuyerCity: null,
            BuyerState: null,
            BuyerZip: null,
            BuyerCountry: null,
            IsBuyerBusiness: null
          };
          break;
        }
      }
      case "CreditReason":
        switch (value) {
          case null:
          case "":
          case undefined:
            update = {
              [field]: null,
              CreditEventDate: null,
              CreditAmount: null,
              IsBuyerDataIncluded: null,
              BuyerName: null,
              BuyerAddress1: null,
              BuyerAddress2: null,
              BuyerCity: null,
              BuyerState: null,
              BuyerZip: null,
              BuyerCountry: null,
              IsBuyerBusiness: null
            };
            break;
          case CREDIT_REASON_SUSPENDED:
            update = {
              [field]: value,
              CreditEventDate: new Date(),
              CreditAmount: undefined,
              IsBuyerDataIncluded: null,
              BuyerName: null,
              BuyerAddress1: null,
              BuyerAddress2: null,
              BuyerCity: null,
              BuyerState: null,
              BuyerZip: null,
              BuyerCountry: null,
              IsBuyerBusiness: null
            };
            break;
          case CREDIT_REASON_SOLD: {
            update = {
              [field]: value,
              IsBuyerDataIncluded: true,
              BuyerName: "",
              BuyerAddress1: "",
              BuyerAddress2: "",
              BuyerCity: "",
              BuyerState: "",
              BuyerZip: "",
              BuyerCountry: "US",
              IsBuyerBusiness: false,
              CreditEventDate: null
            };
            break;
          }
          case CREDIT_REASON_STOLEN:
            update = {
              [field]: value,
              CreditEventDate: null,
              IsBuyerDataIncluded: null,
              BuyerName: null,
              BuyerAddress1: null,
              BuyerAddress2: null,
              BuyerCity: null,
              BuyerState: null,
              BuyerZip: null,
              BuyerCountry: null,
              IsBuyerBusiness: null
            };
            break;
          case CREDIT_REASON_DESTROYED:
            update = {
              [field]: value,
              CreditEventDate: null,
              IsBuyerDataIncluded: null,
              BuyerName: null,
              BuyerAddress1: null,
              BuyerAddress2: null,
              BuyerCity: null,
              BuyerState: null,
              BuyerZip: null,
              BuyerCountry: null,
              IsBuyerBusiness: null
            };
            break;
          default:
            throw new Error();
        }
        // if (data.CreditReason === CREDIT_REASON_SUSPENDED) {
        //   console.log("SETTING SUSPENDED");
        //   update.CreditEventDate = null;
        //   update.CreditAmount = null;
        // }
        break;
      case "WeightCategory": {
        update = { [field]: value, Suspend: value === "W" };
        // if (data.CreditReason !== CREDIT_REASON_SUSPENDED) {
        //   update.CreditAmount = computeCredit({ ...data, ...update }, taxTable);
        // }
        break;
      }
      case "CreditEventDate": {
        const date = value;
        update = {
          [field]: date,
          DateCategory: date // TODO should do null check or no?
            ? lookupDateCategoryByJavascriptDateMonth(date.getMonth()) - 1
            : null
        };
        break;
      }
      default:
        update = { [field]: value };
    }

    const newData = { ...data, ...update };

    if (
      newData.CreditReason === CREDIT_REASON_SUSPENDED &&
      (field === "WeightCategory" || field == "Logging")
    ) {
      update.CreditAmount = overrideCreditAmount(
        newData.CreditAmount,
        newData.CreditReason,
        newData.WeightCategory,
        newData.Logging,
        taxTable
      );
    }

    if (newData.CreditReason !== CREDIT_REASON_SUSPENDED) {
      update.CreditAmount = computeCredit(newData, taxTable);
    }

    updateCreditVehicleStaging(update);
  };

  render() {
    const {
      handleSubmit,
      handleClickDelete,
      handleChange,
      handleClickUpload,
      handleConfirmCancelModal,
      toggleCancelModalIsOpen,
      handleCreditAmountBlur,
      props: { taxYear, data, taxTable },
      state: { cancelModalIsOpen }
    } = this;

    const creditAmount = data.CreditAmount;
    const minCreditEventDate = new Date(taxYear - 110, 6, 1);
    const maxCreditEventDate = new Date(taxYear + 1, 5, 30);
    const isDeletable = !!data.Id;

    return (
      <CreditVehicleEditor
        taxTable={taxTable}
        isDeletable={isDeletable}
        minCreditEventDate={minCreditEventDate}
        maxCreditEventDate={maxCreditEventDate}
        onClickUpload={handleClickUpload}
        onSubmit={handleSubmit}
        taxYear={taxYear}
        onClickCancel={toggleCancelModalIsOpen}
        onClickDelete={handleClickDelete}
        creditAmount={creditAmount}
        onChange={handleChange}
        data={data}
        cancelModalIsOpen={cancelModalIsOpen}
        onConfirmCancelModal={handleConfirmCancelModal}
        onCloseCancelModal={toggleCancelModalIsOpen}
        onCreditAmountBlur={handleCreditAmountBlur}
      />
    );
  }
}

CreditVehicleEditorContainer.propTypes = {
  stageVehicle: PropTypes.func.isRequired,
  stageForDeletion: PropTypes.func.isRequired,
  postVehicle: PropTypes.func.isRequired,
  putVehicle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleShowImporter: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  data: PropTypes.object
};

const mapStateToProps = state => {
  const taxTable = getTaxTable(state);
  const orderId = getOrderIdFromUrl();
  const order = getOrder(orderId, state);
  const data = getCreditVehiclesStagedForCreation(state);
  const currentTaxYear = getCurrentTaxYear(state);
  const taxYear = parseInt(order ? order.TaxYear : currentTaxYear, 10);

  return {
    data,
    orderId,
    taxYear,
    taxTable,
    initialValues: data,
    formValues: getFormValues(CREDIT_VEHICLE_EDITOR_FORM_NAME)(state),
    formSyncErrors: getFormSyncErrors(CREDIT_VEHICLE_EDITOR_FORM_NAME)(state)
  };
};

const mapDispatchToProps = dispatch => ({
  stageVehicle: payload =>
    dispatch(stageVehicleCreator(payload, CREDIT_VEHICLES_SLICE_NAME)),
  updateCreditVehicleStaging: payload =>
    dispatch(updateCreditVehicleStagingCreator(payload)),
  stageForDeletion: id =>
    dispatch(stageForDeletionCreator(id, CREDIT_VEHICLES_SLICE_NAME)),
  postVehicle: (orderId, payload) =>
    dispatch(postVehicleCreator(orderId, payload, CREDIT_VEHICLES_SLICE_NAME)),
  putVehicle: (orderId, vehicleId, data) =>
    dispatch(putVehicleCreator(orderId, vehicleId, data)),
  register: name =>
    dispatch(
      registerFieldCreator(CREDIT_VEHICLE_EDITOR_FORM_NAME, name, "Field")
    ),
  toggleShowImporter: () =>
    dispatch(toggleShowImporterCreator(CREDIT_VEHICLES_SLICE_NAME))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CREDIT_VEHICLE_EDITOR_FORM_NAME,
    destroyOnUnmount: false,
    touchOnBlur: false,
    enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate: validateCreditVehicleForm
  })(CreditVehicleEditorContainer)
);
