/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

const ClickContainer = ({ onClick, children, className, style, disabled }) => {
  // preserve null is no class can be derived
  const newClassName =
    className && disabled
      ? `${className} k-state-disabled`
      : className
      ? className
      : disabled
      ? "k-state-disabled"
      : null;

  return (
    <div
      className={newClassName}
      style={style ? { ...style, cursor: "pointer" } : { cursor: "pointer" }}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled}
    >
      {children}
    </div>
  );
};

ClickContainer.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ClickContainer;
