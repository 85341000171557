import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./newUserWizardFrame.css";
import WizardStepper from "../components/WizardStepperContainer";
import userContext from "../../../modules/userContext";
import {
  STEP_IS_INVALID,
  STEP_IS_VALID,
  STEP_IS_NOTCHECKED
} from "../../../modules/constants";

const {
  selectors: { getRedirect },
  actions: { logout: logoutCreator, fetchUser: fetchUserCreator }
} = userContext;

const withNewUserWizardFrame = WrappedComponent => {
  const WithWizardFrame = ({ history, redirect, logout, ...rest }) => (
    <div className="row justify-content-lg-center">
      <div className="col-2 col-sm-12 col-md-3 col-xl-2">
        <WizardStepper
          steps={[
            "Business",
            "Vehicles/Credits",
            "Signer",
            "Pay the IRS",
            "Review"
          ]}
          valid={[
            STEP_IS_INVALID,
            STEP_IS_VALID,
            STEP_IS_INVALID,
            STEP_IS_NOTCHECKED,
            STEP_IS_NOTCHECKED
          ]}
        />
      </div>
      <div className="col-10 col-sm-12 col-lg-8">
        <WrappedComponent {...rest} />
      </div>
    </div>
  );

  WithWizardFrame.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    redirect: PropTypes.string
  };

  WithWizardFrame.defaultProps = {
    redirect: null
  };

  const mapStateToProps = state => ({
    redirect: getRedirect(state)
  });

  const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logoutCreator()),
    fetchUser: () => dispatch(fetchUserCreator())
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithWizardFrame);
};

export default withNewUserWizardFrame;
