import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords
} from "@progress/kendo-react-grid";
import VehicleGridReadOnlyCell from "./VehicleGridReadOnlyCell";
import controls from "../../controls";
import utilities from "../../utilities";
import "./creditVehicleGrid.css";

const {
  generic: { isEmpty },
  math: { fastFormatMoney }
} = utilities;

const {
  components: { Button }
} = controls;

const CreditVehiclesGrid = ({
  data,
  readOnly,
  onClickAddNew,
  onClickImport,
  onClickDeleteAll,
  CommandCell,
  skip,
  take,
  onPageChange,
  totalCredit
}) => (
  <Grid
    className={`credit-grid tax-grid${
      data.length <= 10 ? " rounded-tax-grid" : ""
    }`}
    data={data.slice(skip, skip + take)}
    skip={skip}
    take={take}
    total={data.length}
    pageable={
      data.length > 10
        ? {
            type: "input",
            buttonCount: 5,
            pageSizes: [10, 20, 50, 100]
          }
        : false
    }
    onPageChange={onPageChange}
  >
    <GridNoRecords>No credit found</GridNoRecords>
    {!readOnly && (
      <GridToolbar>
        <div className="row align-items-center">
          {!readOnly && (
            <div className="col-auto my-auto text-left">
              <strong>
                <h2 className="vehicle-grid-total mb-0">
                  Number of Credits: {data.length}
                </h2>
                <h2 className="vehicle-grid-total mb-gutter mb-sm-0">
                  Total Credit Amount:{" "}
                  {isEmpty(totalCredit) ? "$-" : fastFormatMoney(totalCredit)}
                </h2>
              </strong>
            </div>
          )}
          <div className="col-12 col-sm text-nowrap d-flex justify-content-end">
            <Button
              className="d-inline"
              onClick={onClickAddNew}
              primary
              text="Add New"
              disabled={false}
            />
            <Button
              className="grid-button-spaced d-inline"
              onClick={onClickImport}
              primary={false}
              text="Upload"
              disabled={false}
            />
            <Button
              className="grid-button-spaced d-inline"
              onClick={onClickDeleteAll}
              primary={false}
              text="Delete All"
              disabled={false}
            />
          </div>
        </div>
      </GridToolbar>
    )}

    {readOnly && (
      <Column field="Vin" title="VIN" cell={VehicleGridReadOnlyCell} />
    )}
    {readOnly && (
      <Column
        field="CreditReason"
        title="Credit Reason"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {readOnly && (
      <Column
        field="WeightDescription"
        title="Credit Date"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {readOnly && (
      <Column
        field="CreditAmount"
        title="Credit Amount"
        editable={false}
        cell={VehicleGridReadOnlyCell}
      />
    )}

    {!readOnly && (
      <Column field="Vin" title="VIN" cell={VehicleGridReadOnlyCell} />
    )}
    {!readOnly && (
      <Column
        field="WeightCategory"
        title="Gross Weight"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        field="CreditReason"
        title="Credit Reason"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        field="CreditEventDate"
        title="Credit Date"
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && (
      <Column
        field="CreditAmount"
        title="Credit Amount"
        editable={false}
        cell={VehicleGridReadOnlyCell}
      />
    )}
    {!readOnly && <Column field="" title="" />}
    {!readOnly && (
      <Column className="command-cell" width="100" cell={CommandCell} locked />
    )}
  </Grid>
);

CreditVehiclesGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  onClickAddNew: PropTypes.func.isRequired,
  onClickImport: PropTypes.func.isRequired,
  onClickDeleteAll: PropTypes.func,
  CommandCell: PropTypes.node,
  skip: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalCredit: PropTypes.number
};

CreditVehiclesGrid.defaultProps = {
  data: [],
  readOnly: false
};

export default CreditVehiclesGrid;
