import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BusyIndicator from "./BusyIndicator";
import { isBusy } from "../busyIndicator.selector";

const BusyIndicatorContainer = ({ isBusy, size }) => (
  <BusyIndicator isBusy={isBusy} size={size} />
);

BusyIndicator.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  size: PropTypes.number
};

BusyIndicator.defaultProps = {
  size: 40
};

const mapStateToProps = state => ({
  isBusy: isBusy(state)
});

export default connect(mapStateToProps)(BusyIndicatorContainer);
