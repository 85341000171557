import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";

const {
  components: { TextField, PhoneField },
  normalizations: { normalizeEmail }
} = controls;

const ContactInfoSupportForm = ({
  firstNameValidations,
  lastNameValidations,
  emailValidations,
  tel1Validations,
  faxValidations,
  mobileValidations
}) => (
  <section className="contact-info-section">
    <div className="row">
      <div className="col-12 col-sm-6">
        <TextField
          name="FirstName"
          label="First Name"
          type="text"
          validations={firstNameValidations}
        />
      </div>
      <div className="col-12 col-sm-6">
        <TextField
          name="LastName"
          label="Last Name"
          type="text"
          validations={lastNameValidations}
        />
      </div>
      <div className="col-12 col-sm-6">
        <TextField
          name="Email"
          label="Email"
          type="text"
          validations={emailValidations}
          normalize={normalizeEmail}
        />
      </div>
      <div className="col-12 col-sm-6">
        <PhoneField name="Tel1" label="Phone" validations={tel1Validations} />
      </div>
      <div className="col-12 col-sm-6">
        <TextField
          name="Fax"
          label="Fax"
          type="text"
          validations={faxValidations}
        />
      </div>
      <div className="col-12 col-sm-6">
        <PhoneField
          name="Mobile"
          label="Mobile"
          validations={mobileValidations}
        />
      </div>
    </div>
  </section>
);

ContactInfoSupportForm.propTypes = {
  firstNameValidations: PropTypes.arrayOf(PropTypes.func),
  lastNameValidations: PropTypes.arrayOf(PropTypes.func),
  emailValidations: PropTypes.arrayOf(PropTypes.func),
  tel1Validations: PropTypes.arrayOf(PropTypes.func),
  faxValidations: PropTypes.arrayOf(PropTypes.func),
  mobileValidations: PropTypes.arrayOf(PropTypes.func)
};

export default ContactInfoSupportForm;
