import React, { useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { states } from "../../../modules/controls/data";
import controls from "../../../modules/controls";
import companyInfo from "../../../modules/companyInfo";
import "./account.css";

const {
  components: { BusinessInfoPanel }
} = companyInfo;

const {
  components: { TextField, Switch, Select, RadioGroup },
  normalizations: { normalizePhone, normalizeUSZip, formatUSZip },
  validations: {
    validateOptionalEmail,
    validateOptionalPhone,
    validateAccountForm,
    validateOptionalUSZip
    // validateOptionalZip
  }
} = controls;

const AccountForm = ({
  formValues,
  handleDriverAuthorityBlur,
  handleDriverAuthoritySwitchChange,
  formSyncErrors,
  initialValues,
  touch,
  untouch
}) => {
  useEffect(() => {
    if (formSyncErrors) {
      const latestErrors = validateAccountForm(initialValues);
      touch(
        ...Object.keys(formSyncErrors).filter(k =>
          initialValues.hasOwnProperty(k)
        )
      );
      untouch(
        ...Object.keys(latestErrors).filter(k => latestErrors[k] === undefined)
      );
    }
  }, [initialValues, formSyncErrors]);
  return (
    <div className="row">
      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="summary-widget rad-corners">
          <div className="summary-header p-gutter">
            <h2 className="widget-head-text">Account</h2>
          </div>
          <div className="widget-body company-info-form p-0">
            <BusinessInfoPanel />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="summary-widget rad-corners">
          <div className="summary-header p-gutter">
            <h2 className="widget-head-text">Driver Authority</h2>
          </div>
          <div className="widget-body company-info-form p-0">
            <div
              className="panel bg-light driver-authority"
              id="driver-authority"
            >
              <div className="row account-form-fluid-head">
                <div className="col-6 col-md-10 pl-0 d-flex align-items-center">
                  Do you drive under the authority of a lease or contract
                  company?
                </div>
                <div className="col-6 col-md-2 d-flex justify-content-center align-items-center">
                  <Switch
                    name="DriverAuthority.AuthorityToOperate"
                    onChange={(e, newVal) =>
                      handleDriverAuthoritySwitchChange(
                        "DriverAuthority.AuthorityToOperate",
                        newVal
                      )
                    }
                  />
                </div>
              </div>
              {formValues &&
              formValues.DriverAuthority &&
              formValues.DriverAuthority.AuthorityToOperate ? (
                <div className="driving-under-authority">
                  <div className="row custom-panel-row">
                    <div className="col-sm-6 mb-5 mb-sm-0 p-0">
                      <div className="col-12">
                        <h1 className="mb-gutter">
                          Lease/Contract Company Information
                        </h1>
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.CompanyName"
                          label="Company"
                          type="text"
                          onBlur={handleDriverAuthorityBlur}
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.Address"
                          label="Address"
                          type="text"
                          onBlur={handleDriverAuthorityBlur}
                        />
                      </div>
                      <div className="row m-0">
                        <div className="col-12 col-lg-7">
                          <TextField
                            name="DriverAuthority.City"
                            label="City"
                            type="text"
                            onBlur={handleDriverAuthorityBlur}
                          />
                        </div>
                        <div className="col-lg-5">
                          <Select
                            name="DriverAuthority.State"
                            label="State"
                            onBlur={handleDriverAuthorityBlur}
                          >
                            {[<option value={null} key="none-state" />].concat(
                              states.US.map(State => (
                                <option value={State.real} key={State.real}>
                                  {State.display}
                                </option>
                              ))
                            )}
                          </Select>
                        </div>
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.Zip"
                          label="Zip"
                          type="text"
                          validate={[validateOptionalUSZip]}
                          normalize={normalizeUSZip}
                          format={formatUSZip}
                          onBlur={handleDriverAuthorityBlur}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 p-0">
                      <div className="col-12">
                        <h1 className="mb-gutter">
                          Where do you normally send your Schedule 1?
                        </h1>
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.RecipientName"
                          label="Person"
                          type="text"
                          onBlur={handleDriverAuthorityBlur}
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.RecipientDepartment"
                          label="Department"
                          type="text"
                          onBlur={handleDriverAuthorityBlur}
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.RecipientEmail"
                          label="Email"
                          type="text"
                          onBlur={handleDriverAuthorityBlur}
                          validate={[validateOptionalEmail]}
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          name="DriverAuthority.RecipientPhone"
                          label="Phone"
                          type="text"
                          normalize={normalizePhone}
                          onBlur={handleDriverAuthorityBlur}
                          validate={[validateOptionalPhone]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row custom-panel-row m-0">
                    <div className="col pr-xl-5 justify-content-xl-end getting-authority-ctnr">
                      <h1>When might you get your own authority?</h1>
                    </div>
                    <div className="col pl-xl-4 justify-content-xl-start getting-authority-ctnr">
                      <RadioGroup
                        name="DriverAuthority.WhenMightYouGetAuthority"
                        onChange={(e, newVal) =>
                          handleDriverAuthoritySwitchChange(
                            "DriverAuthority.WhenMightYouGetAuthority",
                            newVal
                          )
                        }
                      >
                        <FormControlLabel
                          className="material-label-override"
                          value="Soon"
                          control={<Radio />}
                          label="Soon"
                        />
                        <FormControlLabel
                          className="material-label-override"
                          value="Eventually"
                          control={<Radio />}
                          label="Eventually"
                        />
                        <FormControlLabel
                          className="material-label-override"
                          value="Not Sure"
                          control={<Radio />}
                          label="Not Sure"
                        />
                        <FormControlLabel
                          className="material-label-override"
                          value="Never"
                          control={<Radio />}
                          label="Never"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row custom-panel-row">
                    <div className="col-12 col-xl-6">
                      <TextField
                        label="Your Operating Authority (MC Number)"
                        name="DriverAuthority.Mcnumber"
                        type="text"
                        onBlur={handleDriverAuthorityBlur}
                      />
                    </div>
                    <div className="col-12 col-xl-6">
                      <TextField
                        label="Which Load Board(s) do you prefer?"
                        name="DriverAuthority.LoadBoards"
                        type="text"
                        onBlur={handleDriverAuthorityBlur}
                      />
                    </div>
                  </div>
                  <div className="row custom-panel-row">
                    <div className="col-6 col-md-10 d-flex align-items-center">
                      Do you use a permit company for compliance services?
                    </div>
                    <div className="col-6 col-md-2 d-flex justify-content-center align-items-center">
                      <Switch
                        name="DriverAuthority.HasPermitCompanyCompliance"
                        onChange={(e, newVal) =>
                          handleDriverAuthoritySwitchChange(
                            "DriverAuthority.HasPermitCompanyCompliance",
                            newVal
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="row custom-panel-row">
                    <div className="col-6 col-md-10 d-flex align-items-center">
                      Do you use a factoring company?
                    </div>
                    <div className="col-6 col-md-2 d-flex justify-content-center align-items-center">
                      <Switch
                        name="DriverAuthority.HasFactoringCompany"
                        onChange={(e, newVal) =>
                          handleDriverAuthoritySwitchChange(
                            "DriverAuthority.HasFactoringCompany",
                            newVal
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired
};

export default AccountForm;
