import React from "react";
import PropTypes from "prop-types";
import controls from "../../../modules/controls";
import "./faqWidgetHeader.css";

const {
  // eslint-disable-next-line no-unused-vars
  components: { ClickContainer }
} = controls;

// eslint-disable-next-line no-unused-vars
const FaqWidgetHeader = ({ text, toggleIsExpanded, isExpanded }) => (
  <div className="faq-header" style={{ flex: "unset" }}>
    <ClickContainer onClick={toggleIsExpanded}>
      <h2
        className={
          isExpanded ? "expanded-faq-head" : "faq-head-text contracted-faq-head"
        }
      >
        {text}
      </h2>
    </ClickContainer>
  </div>
);

FaqWidgetHeader.propTypes = {
  text: PropTypes.string.isRequired,
  toggleIsExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired
};

export default FaqWidgetHeader;
