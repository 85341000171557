import React from "react";
import PropTypes from "prop-types";
import controls from "../../controls";

const {
  components: { TextField, PhoneField },
  normalizations: { normalizeEmail, normalizePin }
} = controls;

const ContactForm = ({ onBlur, showPin }) => (
  <React.Fragment>
    <TextField
      label="Email"
      name="Email"
      type="text"
      normalize={normalizeEmail}
      onBlur={onBlur}
    />
    {showPin && (
      <TextField
        label="5-digit PIN"
        name="Password"
        type="text"
        normalize={normalizePin}
        onBlur={onBlur}
      />
    )}
    <PhoneField label="Office Phone" name="Tel1" onBlur={onBlur} />
    <PhoneField label="Mobile Phone" name="Tel2" onBlur={onBlur} />
  </React.Fragment>
);

ContactForm.propTypes = {
  onBlur: PropTypes.func,
  emailValidations: PropTypes.arrayOf(PropTypes.func),
  passwordValidations: PropTypes.arrayOf(PropTypes.func),
  officePhoneValidations: PropTypes.arrayOf(PropTypes.func),
  cellPhoneValidations: PropTypes.arrayOf(PropTypes.func),
  showPin: PropTypes.bool
};

ContactForm.defaultProps = {
  onBlur: null,
  emailValidations: [],
  passwordValidations: [],
  officePhoneValidations: [],
  cellPhoneValidations: [],
  showPin: true
};

export default ContactForm;
