import components from "./components";
import * as actions from "./paymentModal.actions";
import * as constants from "./paymentModal.constants";
import * as selectors from "./paymentModal.selectors";
import reducer from "./paymentModal.reducer";

export default {
  actions,
  components,
  constants,
  reducer,
  selectors
};
