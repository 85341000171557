import * as actionTypes from "./cart.actionTypes";

const initialState = {
  entities: {},
  ui: {
    widgetIsExpanded: true,
    promoCode: "",
    promoValue: null,
    isUnlimited: false,
    error: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_WIDGET_IS_EXPANDED:
      return {
        ...state,
        ui: {
          ...state.ui,
          widgetIsExpanded: !state.ui.widgetIsExpanded
        }
      };
    case actionTypes.CHANGE_PROMO_CODE:
      return {
        ...state,
        ui: {
          ...state.ui,
          promoCode: action.payload,
          error: null
        }
      };
    case actionTypes.CHANGE_IS_UNLIMITED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUnlimited: action.payload
        }
      };
    case actionTypes.TOGGLE_IS_UNLIMITED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isUnlimited: !state.ui.isUnlimited
        }
      };
    case actionTypes.FETCH_PRODUCTS_ASYNC.RECEIVED:
      return {
        ...state,
        entities: action.payload
      };
    case actionTypes.FETCH_PROMO.RECEIVED:
      return {
        ...state,
        ui: {
          ...state.ui,
          promoCode: "",
          promoValue: action.payload,
          error: null
        }
      };
    case actionTypes.FETCH_PROMO.ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          promoValue: null,
          error: "Invalid promo code"
        }
      };
    case actionTypes.CLEAR_PROMO:
      return {
        ...state,
        ui: {
          ...state.ui,
          promoValue: null
        }
      };
    default:
      return state;
  }
}
