import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AckCopiedVehiclesModal from "./AckCopiedVehiclesModal";
import utilities from "../../utilities";
import {
  getOrderHOF,
  getAckCopiedVehiclesModalIsDismissed
} from "../order.selectors";
import {
  // eslint-disable-next-line max-len
  setAckCopiedVehiclesModalIsDismissed as setAckCopiedVehiclesModalIsDismissedCreator,
  patchOrder as patchOrderCreator
} from "../order.actions";

const { getOrderIdFromUrl } = utilities;

class AckCopiedVehiclesModalContainer extends Component {
  handleClose = () => {
    const {
      props: { setAckCopiedVehiclesModalIsDismissed }
    } = this;
    setAckCopiedVehiclesModalIsDismissed(true);
  };

  handleClickIUnderstand = () => {
    const {
      props: { patchOrder }
    } = this;
    patchOrder(getOrderIdFromUrl(), { AckCopiedVehicles: true });
  };

  render() {
    const {
      handleClickIUnderstand,
      handleClose,
      props: { ackCopiedVehicles, ackCopiedVehiclesModalIsDismissed }
    } = this;
    return (
      <AckCopiedVehiclesModal
        isVisible={
          !ackCopiedVehiclesModalIsDismissed && ackCopiedVehicles === false
        }
        onClickIUnderstand={handleClickIUnderstand}
        onClose={handleClose}
      />
    );
  }
}

AckCopiedVehiclesModalContainer.propTypes = {
  ackCopiedVehicles: PropTypes.bool,
  ackCopiedVehiclesModalIsDismissed: PropTypes.bool.isRequired,
  setAckCopiedVehiclesModalIsDismissed: PropTypes.func.isRequired,
  patchOrder: PropTypes.func.isRequired
};

AckCopiedVehiclesModalContainer.defaultProps = {
  ackCopiedVehicles: null
};

const mapStateToProps = state => {
  const order = getOrderHOF(getOrderIdFromUrl())(state);
  let ackCopiedVehicles = null;
  if (order) {
    ackCopiedVehicles = order.AckCopiedVehicles;
  }
  return {
    ackCopiedVehicles,
    ackCopiedVehiclesModalIsDismissed: getAckCopiedVehiclesModalIsDismissed(
      state
    )
  };
};

const mapDispatchToProps = dispatch => ({
  setAckCopiedVehiclesModalIsDismissed: isDismissed =>
    dispatch(setAckCopiedVehiclesModalIsDismissedCreator(isDismissed)),
  patchOrder: (id, payload) => dispatch(patchOrderCreator(id, payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AckCopiedVehiclesModalContainer);
