import React from "react";
import PropTypes from "prop-types";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
} from "react-stripe-elements";
import "./paymentModal.css";
import controls from "../../controls";

import { FORM_NAME } from "../paymentModal.constants";
import { validateZipByCcHolderCountry } from "../../controls/validation";
import Button from "modules/controls/components/Button";

const {
  components: {
    TextField,
    CountryDropdown,
    StateDropdown,
    ZipFieldContainer: ZipField,
  },
  validations: { validateRequired, validateEmail, validateDropdown },
} = controls;

export const CcPaymentForm = ({ submitError, children, onCopyAddress }) => {
  return (
    <div className="payment-modal-body">
      {children}
      <div className="form-widget-header row">
        <div className="col-12 col-md my-auto">Credit Card Information</div>
      </div>
      <div className="payment-info row mt-3">
        <div className="col-12 col-md clean-gutter-md-right">
          <span className="stripe-label">Card Number</span>
          <CardNumberElement
            options={{
              fonts: [
                {
                  src: "https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2",
                  family: "Lato",
                  style: "normal",
                },
              ],
            }}
            style /* eslint-disable quotes */={{
              base: {
                fontSize: "16px",
                fontFamily: '"Lato", Helvetica, sans-serif',
              },
            }}
          />
        </div>
        <div className="col-12 col-md clean-gutter-md-right clean-gutter-md-left">
          <span className="stripe-label">Exp. Date</span>
          <CardExpiryElement
            style={{
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
              },
            }}
          />
        </div>
        <div className="col-12 col-md clean-gutter-md-left">
          <span className="stripe-label">CVC Code</span>
          <CardCVCElement />
        </div>
      </div>
      <div class="form-widget-header row">
        <div class="col-12 col-md my-auto">Billing Information</div>
        <div className="col text-right">
          <Button
            className="d-inline"
            onClick={onCopyAddress}
            text="Copy Address"
            disabled={false}
            primary={false}
          />
        </div>
      </div>
      <div className="customer-info row mt-3">
        <div className="col-6" />
        <div className="col-12">
          <TextField
            label="Address"
            name="CcHolderAddress"
            type="text"
            validate={[validateRequired]}
          />
        </div>
        <div className="col-12 col-sm-6 clean-gutter-sm-right">
          <CountryDropdown
            name="CcHolderCountry"
            label="Country"
            formName={FORM_NAME}
            stateFieldName="CcHolderState"
            zipFieldName="CcHolderZip"
            validations={[validateDropdown]}
          />
        </div>
        <div className="col-12 col-sm-6 clean-gutter-sm-left">
          <StateDropdown
            name="CcHolderState"
            label="State"
            formName={FORM_NAME}
            countryFieldName="CcHolderCountry"
            // onBlur={onBlur}
            validations={[validateDropdown]}
          />
        </div>

        <div className="col-12 col-sm-6 clean-gutter-sm-right">
          <TextField
            label="City"
            name="CcHolderCity"
            type="text"
            validations={[validateRequired]}
          />
        </div>
        <div className="col-12 col-sm-6 clean-gutter-sm-left">
          <ZipField
            label="Zip"
            name="CcHolderZip"
            countryFieldName="CcHolderCountry"
            formName={FORM_NAME}
            validations={[validateRequired, validateZipByCcHolderCountry]}
          />
        </div>
        <div className="col-12">
          <TextField
            label="Email"
            name="CcNotifyEmail"
            type="text"
            validate={[validateRequired, validateEmail]}
          />
        </div>
      </div>
      {submitError && (
        <div className="mb-gutter">
          <span className="i2290-inline-error">{submitError}</span>
        </div>
      )}
    </div>
  );
};

CcPaymentForm.propTypes = {
  submitError: PropTypes.string,
};

export default CcPaymentForm;
