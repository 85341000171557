import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Summary from "./Summary";

class SummaryContainer extends Component {
  state = {
    isExpanded: false
  };

  toggleIsExpanded = () => {
    this.setState(prev => ({ isExpanded: !prev.isExpanded }));
  };

  render() {
    const { isExpanded, headerChildren, bodyChildren, radCorners } = this.props;

    return (
      <Summary
        isExpanded={isExpanded}
        toggleIsExpanded={this.toggleIsExpanded}
        headerChildren={headerChildren}
        bodyChildren={bodyChildren}
        radCorners={radCorners}
      />
    );
  }
}

SummaryContainer.propTypes = {
  headerChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  bodyChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isExpandedSelector: PropTypes.func.isRequired, // eslint-disable-line
  radCorners: PropTypes.bool
};

SummaryContainer.defaultProps = {
  radCorners: true
};

const mapStateToProps = (state, props) => ({
  isExpanded: props.isExpandedSelector(state)
});

export default connect(mapStateToProps)(SummaryContainer);
