import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@progress/kendo-react-dialogs";
import Disclaimer from "./Disclaimer";
import controls from "../../controls";

const {
  components: { Button }
} = controls;

const DisclaimerModal = ({ isVisible, pin, onClose }) => (
  <React.Fragment>
    {isVisible && (
      <Dialog
        title="By submitting this form as final, I AGREE to the following:"
        onClose={onClose}
      >
        <div className="disclaimer-modal-body">
          <Disclaimer pin={pin} />
        </div>
        <div className="row">
          <div className="col text-center">
            <Button
              disabled={false}
              text="OK"
              primary
              onClick={onClose}
              width="100%"
            />
          </div>
        </div>
      </Dialog>
    )}
  </React.Fragment>
);

DisclaimerModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  pin: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default DisclaimerModal;
